import { DepartmentsEnum } from '@moonpig/web-core-types-graphql'
import { getParentDepartment } from './getParentDepartment'

const parentDepartmentTitles: { [key: string]: string } = {
  ALL_CARDS: 'cards',
  ALL_FLOWERS_AND_PLANTS: 'flowers',
  ALL_GIFTS: 'gifts',
}

export const getDepartmentTitle = (department: DepartmentsEnum) => {
  const parentDepartment = getParentDepartment(department)

  return parentDepartmentTitles[parentDepartment]
}

import { SurveyTrigger } from '../types'
import { config } from './config'

const createMarkerKey = (name: string): string => `mnpg_hotjar_marker_${name}`

const setMarker = (name: string): void => {
  try {
    window.sessionStorage.setItem(createMarkerKey(name), 'true')
    // eslint-disable-next-line no-empty
  } catch {}
}

const consumeMarker = (name: string): boolean => {
  try {
    const key = createMarkerKey(name)
    if (window.sessionStorage.getItem(key)) {
      window.sessionStorage.removeItem(key)
      return true
    }
    // eslint-disable-next-line no-empty
  } catch {}
  return false
}

export const triggerHotjar = (name: string): void => {
  try {
    // This is the code that Hotjar recommend to add if there is a change it is not loaded
    // https://help.hotjar.com/hc/en-us/articles/115011624487-Using-JavaScript-Triggers-for-Feedback-Tools
    window.hj =
      window.hj ||
      function hj() {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, prefer-rest-params
        ;(window.hj!.q = window.hj!.q || []).push(arguments)
      }

    window.hj('trigger', name)
    // eslint-disable-next-line no-empty
  } catch {}
}

export const createSurveyTrigger = (): SurveyTrigger => ({
  setPageType: pageType => {
    const marker = config.markers[pageType]
    if (marker) {
      setMarker(marker.name)
    }

    const trigger = config.triggers[pageType]
    if (trigger && consumeMarker(trigger.ifMarkerSet)) {
      triggerHotjar(trigger.name)
    }
  },
})

import { createLocaleTextHook } from '@moonpig/web-core-locale-text'

const dictionary = {
  'reminders.incentivised.form.reminders_set': {
    'en-GB': ({ reminderNo }: { reminderNo: number }) =>
      `Set reminder ${reminderNo}`,
    'nl-NL': ({ reminderNo }: { reminderNo: number }) =>
      `Stel herinnering ${reminderNo} in`,
  },
  'reminders.incentivised.form.discount.set': {
    'en-GB': ({
      remindersRemaining,
      showMore,
    }: {
      remindersRemaining: number
      showMore: boolean
    }) =>
      `Set ${remindersRemaining}${showMore ? ' more' : ''} ${
        remindersRemaining === 1 ? 'reminder' : 'reminders'
      }`,
    'nl-NL': ({
      remindersRemaining,
      showMore,
    }: {
      remindersRemaining: number
      showMore: boolean
    }) => `Voeg ${remindersRemaining}${showMore ? ' meer' : ''} momenten`,
  },
  'reminders.incentivised.form.discount.for': {
    'en-GB': 'for your',
    'nl-NL': 'toe voor een',
  },
  'reminders.incentivised.form.discount.use': {
    'en-GB': 'you can use straight away.',
    'nl-NL': 'die je direct kunt gebruiken.',
  },
  'reminders.incentivised.form.welcome.cta': {
    'en-GB': 'Set Reminder & Continue',
    'nl-NL': 'Herinnering instellen en doorgaan',
  },
  'reminders.incentivised.form.welcome.marketing.consent': {
    'en-GB':
      'By setting up a reminder, you are consenting to receive emails about this reminder. View our',
    'nl-NL':
      'Als je een moment toevoegt, geef je toestemming om e-mails te ontvangen over dit moment. Lees ons',
  },
  'reminders.incentivised.form.welcome.marketing_consent.privacy': {
    'en-GB': 'Privacy Notice Page',
    'nl-NL': 'privacybeleid',
  },
  'reminders.incentivised.form.welcome.marketing_consent.more_details': {
    'en-GB': ' for more details',
    'nl-NL': ' voor meer informatie',
  },
  'reminders.incentivised.form.error': {
    'en-GB': 'There was a problem saving your reminders. Please try again.',
    'nl-NL':
      'Er ging iets mis bij het opslaan van je herinneringen. Probeer het opnieuw.',
  },
  'reminders.incentivised.form.end_image_alt': {
    'en-GB': 'End Image',
    'nl-NL': 'Einde afbeelding',
  },
  'reminders.incentivised.form.shop_for_cards': {
    'en-GB': 'Shop for Cards',
    'nl-NL': 'Kies je Kaart',
  },
  'reminders.incentivised.form.view_your_reminders': {
    'en-GB': 'View Your Reminders',
    'nl-NL': 'Je herinneringen bekijken',
  },
  'reminder_form_signin.simply': {
    'en-GB': 'Simply',
    'nl-NL': 'Meld je',
  },
  'reminder_form_signin.sign_in': {
    'en-GB': 'sign in',
    'nl-NL': 'aan',
  },
  'reminder_form_signin.or': {
    'en-GB': 'or',
    'nl-NL': 'of',
  },
  'reminder_form_signin.create_an_account': {
    'en-GB': 'create an account',
    'nl-NL': 'maak een account aan',
  },
  'reminder_form_signin.to_save_reminders': {
    'en-GB': 'to save your Reminders and claim your discounts.',
    'nl-NL': 'om je momenten op te slaan en je kortingen te verzilveren.',
  },
  'reminder_form_signin.sign_in_cta': {
    'en-GB': 'Sign In',
    'nl-NL': 'Aan',
  },
  'reminder_form_signin.create_account_cta': {
    'en-GB': 'Create account',
    'nl-NL': 'Account aanmaken',
  },
  'reminder_form_signin.new_here': {
    'en-GB': 'New here?',
    'nl-NL': 'Nieuw hier?',
  },
}

export const useLocaleText = createLocaleTextHook({
  dictionary,
})

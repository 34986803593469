import {
  Box,
  PrimaryButton,
  Flex,
  Heading,
  Modal,
  Text,
  TertiaryButton,
} from '@moonpig/launchpad-components'
import {
  IllustrationCoinCurrency,
  IconSystemMoneyBox,
} from '@moonpig/launchpad-assets'
import React, { FC, useState } from 'react'
import { useLocaleText } from '../locale'

export type CreditAmountProps = {
  creditDisplayAmount: string
  onModalOpen: () => void
  onModalDismiss: () => void
  onShopNowLinkClick: () => void
}

export const ExperimentCreditAmount: FC<
  React.PropsWithChildren<CreditAmountProps>
> = ({
  creditDisplayAmount,
  onShopNowLinkClick,
  onModalDismiss,
  onModalOpen,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const t = useLocaleText()

  const handleModalOpen = () => {
    setModalIsOpen(true)
    onModalOpen()
  }

  const handleModalDismiss = () => {
    setModalIsOpen(false)
    onModalDismiss()
  }

  const handleShopNowClick = () => {
    setModalIsOpen(false)
    onShopNowLinkClick()
  }

  return (
    <>
      <TertiaryButton onClick={handleModalOpen} height="100%">
        <IconSystemMoneyBox
          width={21}
          height={20}
          style={{ marginRight: '8px' }}
        />
        {t('credit_amount.credit_value', creditDisplayAmount)}
      </TertiaryButton>
      <Modal
        label={t('credit_amount.modal_label')}
        hasCloseButton
        onDismiss={handleModalDismiss}
        isOpen={modalIsOpen}
        mobilePagePeek
      >
        <Flex alignItems="center" flexDirection="column">
          <IllustrationCoinCurrency width={100} height={82} />
          <Heading
            level="h2"
            marginTop={6}
            typography={{
              xs: 'typeMobileDisplay03',
              md: 'typeDesktopDisplay04',
            }}
          >
            {t('credit_amount.modal_title', creditDisplayAmount)}
          </Heading>
          <Box marginBottom={6} textAlign="center">
            <Text>{t('credit_amount.modal_description')}</Text>
          </Box>
          <PrimaryButton
            mb={{ xs: 4, md: 6 }}
            onClick={handleShopNowClick}
            width={{ xs: 1, md: 1 / 2 }}
          >
            {t('credit_amount.shop_now')}
          </PrimaryButton>
        </Flex>
      </Modal>
    </>
  )
}

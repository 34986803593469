import React, { FC } from 'react'
import {
  styled,
  throttle,
  breakpoint,
  isBrowser,
  useTheme,
} from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { Container, Box } from '@moonpig/launchpad-components'
import { ThemeInterface } from '@moonpig/launchpad-theme'

import {
  HEADER_HEIGHT_COLLAPSED,
  NAV_BP,
  COLOR_KEYLINE,
  TRANSITON_TIME_MS,
  ZINDEX_STICKY_HEADER,
  KEYLINE_SIZE_PX,
  HEADER_BANNER_HEIGHT,
  HEADER_HEIGHT_DESKTOP_VARIANT,
} from '../constants'
import { useLocaleText } from '../locale'

const { useRef, useEffect, useLayoutEffect, useState } = React

const StyledSkipToContentLink = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  &:focus {
    z-index: ${ZINDEX_STICKY_HEADER + 1};
  }
`

const StyledHeaderHeightEnforcer = styled.div`
  ${breakpoint(NAV_BP)} {
    height: ${HEADER_HEIGHT_DESKTOP_VARIANT}px;
  }
`

const StyledHeaderWrapper = styled.header`
  position: relative;
  ${breakpoint(NAV_BP)} {
    /* 
      CSS variable provided by radix.
      Prevents header jumping when modal or nav is opened. 
    */
    padding-right: var(--removed-body-scroll-bar-size, 0px);

    &.is-sticky {
      position: fixed;
    }
  }
  top: 0;
  left: 0;
  right: 0;
  z-index: ${ZINDEX_STICKY_HEADER};
  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.3);
    opacity: 0;
    content: '';
    transition: opacity ${TRANSITON_TIME_MS};
  }
  &.has-shadow::before {
    opacity: 1;
  }
`

const StyledHeaderWrapperInner = styled.div`
  border-bottom: ${KEYLINE_SIZE_PX} solid ${COLOR_KEYLINE};
  ${s({ bgcolor: 'colorBackground01' })}
`

const StyledBanner = styled.div`
  ${s({
    typography: 'typeBodyCaption',
    p: 3,
    bgcolor: 'colorBackground03',
    textAlign: 'center',
  })}
`

const HeaderContent: FC<
  React.PropsWithChildren<{
    children: React.ReactNode
  }>
> = React.memo(({ children }) => (
  <Container limitWidth padding={{ [NAV_BP]: 8 }}>
    <Box position="relative" py={{ xs: 3, [NAV_BP]: 6 }} pb={{ [NAV_BP]: 4 }}>
      {children}
    </Box>
  </Container>
))

HeaderContent.displayName = 'HeaderContent'

const useScroll = (headerHeight: number, isDesktop: boolean) => {
  const [hasShadow, setHasShadow] = useState(false)
  const [isSticky, setIsSticky] = useState(false)

  useEffect(() => {
    const THROTTLE_INTERVAL_TIME_MS = 30
    let animationFrameId = 0

    const handleScroll = throttle(() => {
      animationFrameId = window.requestAnimationFrame(() => {
        const yOffset = window.pageYOffset
        setHasShadow(yOffset >= headerHeight)
        if (!document.body.classList.contains('is-locked')) {
          setIsSticky(yOffset > HEADER_BANNER_HEIGHT)
        }
      })
    }, THROTTLE_INTERVAL_TIME_MS)

    if (isDesktop) {
      window.addEventListener('scroll', handleScroll)
    }

    return () => {
      window.cancelAnimationFrame(animationFrameId)
      window.removeEventListener('scroll', handleScroll)
    }
  }, [headerHeight, isDesktop])
  return { hasShadow, isSticky }
}

const useBrowserHeaderHeight = (
  headerNode: React.MutableRefObject<HTMLElement | null> | null,
) => {
  const [headerHeight, setHeaderHeight] = useState(0)

  useEffect(() => {
    const THROTTLE_INTERVAL_TIME_MS = 250
    let animationFrameID = 0
    const onResize = throttle(() => {
      animationFrameID = window.requestAnimationFrame(() => {
        /* istanbul ignore next */
        if (headerNode?.current) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          setHeaderHeight(headerNode.current.getBoundingClientRect().height)
        }
      })
    }, THROTTLE_INTERVAL_TIME_MS)

    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
      window.cancelAnimationFrame(animationFrameID)
    }
  }, [headerNode])

  useLayoutEffect(() => {
    let animationFrameID = 0
    animationFrameID = window.requestAnimationFrame(() => {
      /* istanbul ignore next */
      if (headerNode?.current) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        setHeaderHeight(headerNode.current.getBoundingClientRect().height)
      }
    })

    return () => window.cancelAnimationFrame(animationFrameID)
  }, [headerNode])

  return headerHeight
}

export const HeaderHeightContext = React.createContext(HEADER_HEIGHT_COLLAPSED)
export const HeaderRefContext =
  React.createContext<React.MutableRefObject<HTMLElement | null> | null>(null)

const useIsDesktop = (theme: ThemeInterface) => {
  const [isDesktop, setIsDesktop] = useState(false)

  useEffect(() => {
    const checkScreenSize = () => {
      setIsDesktop(() => {
        return window.matchMedia(
          `(min-width: ${theme.breakpoints.map[NAV_BP]}px)`,
        ).matches
      })
    }
    checkScreenSize()

    window.addEventListener('resize', checkScreenSize)

    return () => {
      window.removeEventListener('resize', checkScreenSize)
    }
  }, [setIsDesktop, theme.breakpoints.map])

  return isDesktop
}

type HeaderWrapperProps = {
  bannerText?: string
}

const WrappedHeaderWrapper: FC<
  React.PropsWithChildren<
    HeaderWrapperProps & {
      theme: ThemeInterface
    }
  >
> = ({ bannerText, children, theme }) => {
  const t = useLocaleText()
  const useHeaderHeight = isBrowser()
    ? useBrowserHeaderHeight
    : () => HEADER_HEIGHT_COLLAPSED

  const isDesktop = useIsDesktop(theme)
  const headerNode = useRef(null)
  const height = useHeaderHeight(headerNode)
  const { hasShadow, isSticky } = useScroll(height, isDesktop)

  return (
    <>
      {bannerText && <StyledBanner>{bannerText}</StyledBanner>}
      <StyledHeaderHeightEnforcer data-testid="lp-nav-header-height-enforcer">
        <StyledHeaderWrapper
          data-testid="lp-nav-header-wrapper"
          ref={headerNode}
          className={`${hasShadow ? 'has-shadow' : ''} ${
            isSticky || !bannerText ? 'is-sticky' : ''
          }`}
        >
          <StyledSkipToContentLink href="#mainContent">
            <Box bgcolor="colorBackground01" p={3}>
              {t('header.skip_to_content')}
            </Box>
          </StyledSkipToContentLink>
          <StyledHeaderWrapperInner>
            <HeaderRefContext.Provider value={headerNode}>
              <HeaderHeightContext.Provider value={height}>
                <HeaderContent>{children}</HeaderContent>
              </HeaderHeightContext.Provider>
            </HeaderRefContext.Provider>
          </StyledHeaderWrapperInner>
        </StyledHeaderWrapper>
        <div id="mainContent" />
      </StyledHeaderHeightEnforcer>
    </>
  )
}

export const HeaderWrapper: FC<
  React.PropsWithChildren<HeaderWrapperProps>
> = props => {
  const theme = useTheme()

  return <WrappedHeaderWrapper {...props} theme={theme} />
}

export const DATE_TEXT = {
  'common.dates.january': {
    'en-GB': 'January',
    'nl-NL': 'januari',
  },
  'common.dates.february': {
    'en-GB': 'February',
    'nl-NL': 'februari',
  },
  'common.dates.march': {
    'en-GB': 'March',
    'nl-NL': 'maart',
  },
  'common.dates.april': {
    'en-GB': 'April',
    'nl-NL': 'april',
  },
  'common.dates.may': {
    'en-GB': 'May',
    'nl-NL': 'mei',
  },
  'common.dates.june': {
    'en-GB': 'June',
    'nl-NL': 'juni',
  },
  'common.dates.july': {
    'en-GB': 'July',
    'nl-NL': 'juli',
  },
  'common.dates.august': {
    'en-GB': 'August',
    'nl-NL': 'augustus',
  },
  'common.dates.september': {
    'en-GB': 'September',
    'nl-NL': 'september',
  },
  'common.dates.october': {
    'en-GB': 'October',
    'nl-NL': 'oktober',
  },
  'common.dates.november': {
    'en-GB': 'November',
    'nl-NL': 'november',
  },
  'common.dates.december': {
    'en-GB': 'December',
    'nl-NL': 'december',
  },
}

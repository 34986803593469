import { useState } from 'react'
import { useMutation, ApolloError } from '@moonpig/web-core-graphql'
import { ReminderInput, reminderInputSchema } from '../types'
import { CreateIncentivisedRemindersGQL } from '../gql/createIncentivisedReminders'
import {
  CreateIncentivisedRemindersMutation,
  CreateIncentivisedRemindersMutationVariables,
} from '../gql/__generated__/createIncentivisedReminders'

export type UseIncentivisedRemindersResult = {
  createIncentivisedReminders: (
    reminders: ReminderInput[],
  ) => Promise<CreateIncentivisedRemindersResult>
  isCreatingIncentivisedReminders: boolean
  createIncentivisedRemindersError: ApolloError | string | null
}

export type CreateIncentivisedRemindersResult = { type: 'success' | 'error' }

export const useCreateIncentivisedReminders =
  (): UseIncentivisedRemindersResult => {
    const [graphqlError, setGraphqlError] = useState<string | null>(null)
    const [createReminders, { loading, error }] = useMutation<
      CreateIncentivisedRemindersMutation,
      CreateIncentivisedRemindersMutationVariables
    >(CreateIncentivisedRemindersGQL)

    const createIncentivisedReminders = async (
      reminders: ReminderInput[],
    ): Promise<CreateIncentivisedRemindersResult> => {
      try {
        const inputData = {
          variables: {
            input: reminders.map(reminder =>
              reminderInputSchema.parse({
                ...reminder,
                relationship: reminder.relationship || undefined,
              }),
            ),
          } as CreateIncentivisedRemindersMutationVariables,
        }
        const response = await createReminders(inputData)

        const { data } = response
        if (
          data?.createIncentivisedReminders?.__typename ===
          'CreatedIncentivisedReminders'
        ) {
          return { type: 'success' }
        }

        setGraphqlError('error')
        return { type: 'error' }
      } catch (_) {
        return { type: 'error' }
      }
    }

    return {
      createIncentivisedReminders,
      isCreatingIncentivisedReminders: loading,
      createIncentivisedRemindersError: error || graphqlError,
    }
  }

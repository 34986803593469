import { z } from 'zod'
// Checkout schemas
import { PurchaseSchema } from '../schemas/checkout/purchase.schema'
import { ViewCartSchema } from '../schemas/checkout/viewCart.schema'
import { BeginCheckoutSchema } from '../schemas/checkout/beginCheckout.schema'
import { AddRecipientSchema } from '../schemas/checkout/addRecipient.schema'
import { DeleteRecipientSchema } from '../schemas/checkout/deleteRecipient.schema'
import { SelectRecipientSchema } from '../schemas/checkout/selectRecipient.schema'
import { UpdateRecipientSchema } from '../schemas/checkout/updateRecipient.schema'
import { RemoveFromCartSchema } from '../schemas/checkout/removeFromCart.schema'
import { ViewXsellResultsSchema } from '../schemas/checkout/viewXsellResults.schema'
import { AddDiscountSchema } from '../schemas/checkout/addDiscount.schema'
import { AddPaymentInfoSchema } from '../schemas/checkout/addPaymentInfo.schema'
import { DiscountErrorSchema } from '../schemas/checkout/discountError.schema'
import { DiscountFailureSchema } from '../schemas/checkout/discountFailure.schema'
import { RecipientErrorSchema } from '../schemas/checkout/recipientError.schema'
import { RemoveDiscountSchema } from '../schemas/checkout/removeDiscount.schema'
// Customer schemas
import { CustomerSchema } from '../schemas/customer/customer.schema'
import { LoginSchema } from '../schemas/customer/login.schema'
import { LogoutSchema } from '../schemas/customer/logout.schema'
import { SignUpSchema } from '../schemas/customer/signUp.schema'
import { LoginErrorSchema } from '../schemas/customer/loginError.schema'
import { LoginFailureSchema } from '../schemas/customer/loginFailure.schema'
import { LogoutErrorSchema } from '../schemas/customer/logoutError.schema'
import { LogoutFailureSchema } from '../schemas/customer/logoutFailure.schema'
import { SignUpErrorSchema } from '../schemas/customer/signUpError.schema'
import { SignUpFailureSchema } from '../schemas/customer/signUpFailure.schema'
import { SocialAuthenticationExceptionSchema } from '../schemas/customer/socialAuthenticationException.schema'
import { AccountDelinkErrorSchema } from '../schemas/customer/accountDelinkError.schema'
import { AccountDelinkFailureSchema } from '../schemas/customer/accountDelinkFailure.schema'
import { AccountDelinkSuccessSchema } from '../schemas/customer/accountDelinkSuccess.schema'
import { AccountLinkErrorSchema } from '../schemas/customer/accountLinkError.schema'
import { AccountLinkFailureSchema } from '../schemas/customer/accountLinkFailure.schema'
import { AccountLinkSuccessSchema } from '../schemas/customer/accountLinkSuccess.schema'
// Multiple teams schemas
import { AddToCartSchema } from '../schemas/multiple_teams/addToCart.schema'
import { ScreenViewSchema } from '../schemas/multiple_teams/screenView.schema'
import { SelectContentSchema } from '../schemas/multiple_teams/selectContent.schema'
import { SelectItemSchema } from '../schemas/multiple_teams/selectItem.schema'
import { ImpressionSchema } from '../schemas/multiple_teams/impression.schema'
import { ViewItemSchema } from '../schemas/multiple_teams/viewItem.schema'
import { ViewItemListSchema } from '../schemas/multiple_teams/viewItemList.schema'
import { MediaEditSchema } from '../schemas/multiple_teams/mediaEdit.schema'
import { MediaUpdateSchema } from '../schemas/multiple_teams/mediaUpdate.schema'
// Editor schemas
import { EditorDesignUpdateSchema } from '../schemas/editor/editorDesignUpdate.schema'
import { EditorExitSchema } from '../schemas/editor/editorExit.schema'
import { EditorPreviewSchema } from '../schemas/editor/editorPreview.schema'
import { MediaCompleteSchema } from '../schemas/editor/mediaComplete.schema'
import { MediaPauseSchema } from '../schemas/editor/mediaPause.schema'
import { MediaProgressSchema } from '../schemas/editor/mediaProgress.schema'
import { MediaStartSchema } from '../schemas/editor/mediaStart.schema'
import { MediaUploadFailureSchema } from '../schemas/editor/mediaUploadFailure.schema'
import { MediaUploadSuccessSchema } from '../schemas/editor/mediaUploadSuccess.schema'
// Explore schemas
import { PersonaliseSchema } from '../schemas/explore/personalise.schema'
import { AddToWishlistSchema } from '../schemas/explore/addToWishlist.schema'
import { RemoveFromWishlistSchema } from '../schemas/explore/removeFromWishlist.schema'
// Loyalty schemas
import { CreateReminderSchema } from '../schemas/loyalty/createReminder.schema'
import { DeleteReminderSchema } from '../schemas/loyalty/deleteReminder.schema'
import { EditReminderSchema } from '../schemas/loyalty/editReminder.schema'
import { UpdateReminderSchema } from '../schemas/loyalty/updateReminder.schema'
// Search schemas
import { SearchSchema } from '../schemas/search/search.schema'
import { SearchInputClearSchema } from '../schemas/search/searchInputClear.schema'
import { SearchSuggestionSchema } from '../schemas/search/searchSuggestion.schema'
import { SearchSuggestionClickSchema } from '../schemas/search/searchSuggestionClick.schema'
import { ViewGalleryResultsSchema } from '../schemas/search/viewGalleryResults.schema'
// Schemas with no owners
import { AbtestSchema } from '../schemas/no_owner/abtest.schema'
import { CampaignDetailsSchema } from '../schemas/no_owner/campaignDetails.schema'
import { CookieConsentSchema } from '../schemas/no_owner/cookieConsent.schema'
import { GenericEventSchema } from '../schemas/no_owner/genericEvent.schema'
import { MarketingConsentSchema } from '../schemas/no_owner/marketingConsent.schema'
import { ShareSchema } from '../schemas/no_owner/share.schema'
import { OutstandingEventNames } from './eventList'
import { GenericErrorSchema } from '../schemas/no_owner/genericError.schema'

const CatchAllSchema = z.object({}).catchall(z.any())
export const EventObjectSchema = CatchAllSchema

const ManualCatchAllSchema = z
  .object({
    event: OutstandingEventNames,
  })
  .catchall(z.any())

export const ManualEventObjectSchema = z.discriminatedUnion('event', [
  SelectContentSchema,
  PurchaseSchema,
  CustomerSchema,
  AddToCartSchema,
  ScreenViewSchema,
  ViewCartSchema,
  ViewItemListSchema,
  ViewItemSchema,
  SelectItemSchema,
  LoginSchema,
  SignUpSchema,
  LogoutSchema,
  BeginCheckoutSchema,
  AddRecipientSchema,
  DeleteRecipientSchema,
  SelectRecipientSchema,
  UpdateRecipientSchema,
  RemoveFromCartSchema,
  ViewXsellResultsSchema,
  AddDiscountSchema,
  AddPaymentInfoSchema,
  DiscountErrorSchema,
  DiscountFailureSchema,
  RecipientErrorSchema,
  RemoveDiscountSchema,
  LoginErrorSchema,
  LoginFailureSchema,
  LogoutErrorSchema,
  LogoutFailureSchema,
  SignUpErrorSchema,
  SignUpFailureSchema,
  SocialAuthenticationExceptionSchema,
  AccountDelinkErrorSchema,
  AccountDelinkFailureSchema,
  AccountDelinkSuccessSchema,
  AccountLinkErrorSchema,
  AccountLinkFailureSchema,
  AccountLinkSuccessSchema,
  EditorDesignUpdateSchema,
  EditorExitSchema,
  EditorPreviewSchema,
  MediaCompleteSchema,
  MediaPauseSchema,
  MediaProgressSchema,
  MediaStartSchema,
  MediaUploadFailureSchema,
  MediaUploadSuccessSchema,
  PersonaliseSchema,
  AddToWishlistSchema,
  RemoveFromWishlistSchema,
  CreateReminderSchema,
  DeleteReminderSchema,
  EditReminderSchema,
  UpdateReminderSchema,
  SearchSchema,
  SearchInputClearSchema,
  SearchSuggestionSchema,
  SearchSuggestionClickSchema,
  ViewGalleryResultsSchema,
  ImpressionSchema,
  AbtestSchema,
  CampaignDetailsSchema,
  CookieConsentSchema,
  GenericEventSchema,
  MarketingConsentSchema,
  ShareSchema,
  MediaEditSchema,
  MediaUpdateSchema,
  GenericErrorSchema,

  ManualCatchAllSchema,
])

export type EventObject = z.infer<typeof EventObjectSchema>

import { Metrics } from '@moonpig/web-core-monitoring'
import isbot from 'isbot'

if (isbot) {
  isbot.extend(['Amazon-Route53-Health-Check-Service'])
}

export const userIsBot = ({
  userAgent,
  metrics,
}: {
  userAgent: string
  metrics: Metrics
}) => {
  /* istanbul ignore if */
  if (!isbot) {
    return false
  }

  const botName = isbot.find(userAgent)

  const botDetected = Boolean(botName)
  if (botDetected && botName) {
    metrics.count({
      metricName: 'bot-detected',
      metricDimensions: { 'bot-name': botName as string },
    })
  }

  return botDetected
}

import { MatchType } from '../types'

const matchTypes = {
  EQUALS: 'equals',
  STARTS_WITH: 'startsWith',
  REGEX: 'regex',
  PATH_EQUALS: 'pathEquals',
}

const pathMatchesUri = (
  firstUri: string,
  matchType: MatchType | undefined,
  secondUri: string,
) => {
  if (firstUri && secondUri) {
    const lowercaseFirstUri = firstUri.toLowerCase()
    const lowercaseSecondUri = secondUri.toLowerCase()

    if (!matchType || matchType === matchTypes.EQUALS) {
      return lowercaseFirstUri === lowercaseSecondUri
    }

    if (matchType === matchTypes.PATH_EQUALS) {
      return lowercaseFirstUri.split('?')[0] === lowercaseSecondUri
    }

    if (matchType === matchTypes.STARTS_WITH) {
      return lowercaseFirstUri.startsWith(lowercaseSecondUri)
    }

    if (matchType === matchTypes.REGEX) {
      return new RegExp(lowercaseSecondUri).test(lowercaseFirstUri)
    }
  }
  return false
}

// eslint-disable-next-line import/no-default-export
export default pathMatchesUri

import React from 'react'
import { Box, Flex } from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import { ErrorAlert } from '@moonpig/web-shared-components'
import { useLocaleText } from './locale'

const EdgeStyledFlex = styled(Flex)`
  @supports (-ms-ime-align: auto) {
    min-height: 600px;
  }
`

export const IncentivisedRemindersFormContainer: React.FC<
  React.PropsWithChildren<{
    error: boolean
  }>
> = ({ children, error }) => {
  const localiseFindText = useLocaleText()

  return (
    <EdgeStyledFlex flexDirection="row">
      <Box width="24em" height="100%" position="relative">
        {
          /* istanbul ignore next */ error && (
            <ErrorAlert
              message={localiseFindText('reminders.incentivised.form.error')}
            />
          )
        }
        {children}
      </Box>
    </EdgeStyledFlex>
  )
}

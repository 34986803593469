/* eslint-disable no-restricted-syntax */
import pathToRegExp from 'path-to-regexp'
import {
  RouteDefinition,
  RouteParamsValues,
  RouteParamTypeMap,
  Router,
  Routes,
} from './types'
import { parseUrl } from './url'
import { isMultipleType, isOptionalType, mapValues, toArray } from './utils'

type CompiledRoute = {
  name: string
  route: RouteDefinition
  matcher: (path: string) => MatchedRoute | null
}

const regions = ['uk', 'us', 'au', 'ie', 'nl']

type MatchedRoute = { [key: string]: string | undefined }

const parseNumbers = (values: string[]): number[] | null => {
  const result = values.map(Number)
  if (result.some(Number.isNaN)) {
    return null
  }
  return result
}

const parsePathValue = (
  pathValue: string | undefined,
  type: keyof RouteParamTypeMap,
): string | string[] | number | number[] | null => {
  switch (type) {
    case 'path.region':
      return pathValue && regions.includes(pathValue) ? pathValue : null
    case 'path.string':
    case 'path.string.optional':
      return pathValue || null
    case 'path.number':
    case 'path.number.optional': {
      const value = Number(pathValue)
      return Number.isNaN(value) ? null : value
    }
    case 'path.string.multiple':
      return pathValue ? pathValue.split('/') : []
    case 'path.number.multiple':
      return parseNumbers(pathValue ? pathValue.split('/') : [])
    /* istanbul ignore next */
    default:
      return null
  }
}

const parseQueryValue = (
  queryValue: string | string[] | undefined,
  type: keyof RouteParamTypeMap,
  hasKey: boolean,
): string | string[] | number | number[] | boolean | null => {
  switch (type) {
    case 'query.string':
    case 'query.string.optional': {
      if (Array.isArray(queryValue) || !queryValue) {
        return null
      }
      return queryValue
    }
    case 'query.number':
    case 'query.number.optional': {
      if (Array.isArray(queryValue)) {
        return null
      }
      const result = Number(queryValue)
      return Number.isNaN(result) ? null : result
    }
    case 'query.string.multiple':
      return toArray(queryValue)
    case 'query.number.multiple':
      return parseNumbers(toArray(queryValue))
    case 'query.boolean.optional':
      if (queryValue === undefined || queryValue === '') {
        return hasKey
      }
      return queryValue === 'true'
    /* istanbul ignore next */
    default:
      return null
  }
}

const createRouteMatcher = (route: RouteDefinition) => {
  const pattern = route.path(
    mapValues(route.params, (param, type) => {
      if (isMultipleType(type)) {
        return `/:${param}*`
      }
      if (isOptionalType(type)) {
        return `/:${param}?`
      }
      return `:${param}`
    }),
  )

  const keys: pathToRegExp.Key[] = []
  const regex = pathToRegExp(pattern, keys)

  return (path: string): MatchedRoute | null => {
    const results = regex.exec(path)

    if (!results) {
      return null
    }

    const params: MatchedRoute = {}

    for (let i = 0; i < keys.length; i += 1) {
      const { name: keyName } = keys[i]
      params[keyName] = results[i + 1]
    }

    return params
  }
}

const getRouteMatches = (
  url: string,
  { route, matcher }: CompiledRoute,
): RouteParamsValues | null => {
  let parsedUrl = null
  try {
    parsedUrl = parseUrl(url)
  } catch (e) {
    return null
  }

  const { pathParts, query } = parsedUrl

  const path = `/${pathParts.join('/')}/`
  const results = matcher(path)

  if (!results) {
    return null
  }

  const params: RouteParamsValues = {}

  for (const [name, stringValue] of Object.entries(results)) {
    const type = route.params[name]
    const value = parsePathValue(stringValue, type)

    if (value !== null) {
      params[name] = value
    } else if (!isOptionalType(type)) {
      return null
    }
  }

  for (const [name, type] of Object.entries(route.params)) {
    if (type.startsWith('query.')) {
      const value = parseQueryValue(query[name], type, name in query)

      if (value !== null) {
        params[name] = value
      } else if (!isOptionalType(type)) {
        return null
      }
    }
  }

  for (const [name, value] of Object.entries(query)) {
    if (!(name in route.params)) {
      params[name] = value
    }
  }

  return params
}

export const createMatch = <TRoutes extends Routes>(
  routes: TRoutes,
): Router<TRoutes>['match'] => {
  const compiledRoutes: CompiledRoute[] = Object.entries(routes).map(
    ([name, route]) => {
      return {
        name,
        route,
        matcher: createRouteMatcher(route),
      }
    },
  )

  const match: Router<TRoutes>['match'] = url => {
    for (const compiledRoute of compiledRoutes) {
      const matches = getRouteMatches(url, compiledRoute)

      if (matches) {
        return {
          name: compiledRoute.name,
          params: matches,
          app: compiledRoute.route.app,
        }
      }
    }

    return null
  }

  return match
}

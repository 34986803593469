import { type CookieSerializeOptions } from '@moonpig/web-core-cookies'
import { isServer } from '@moonpig/web-core-utils'

const checkIfSiteSpectDisabledInConfig = () => {
  if (!process.env.SITESPECT_ENABLED_REGIONS) {
    throw new Error('SITESPECT_ENABLED_REGIONS environment variable not set')
  }

  if (!process.env.AWS_REGION) {
    return false
  }

  const sitespectEnabledRegions = JSON.parse(
    process.env.SITESPECT_ENABLED_REGIONS,
  )

  return !sitespectEnabledRegions.includes(process.env.AWS_REGION)
}

export const setupSiteSpect = (options: {
  forceDisable: boolean
  getCookie: (name: string) => string | undefined
  setCookie: (
    name: string,
    value: string,
    options?: CookieSerializeOptions,
  ) => void
}): { disableSiteSpect: boolean } => {
  // Server-side check if SiteSpect if force-disabled, or disabled in config
  if (isServer) {
    const disable = options.forceDisable || checkIfSiteSpectDisabledInConfig()

    // Set cookie so client can read it
    options.setCookie('mnpg_sitespect_enabled', disable ? 'false' : 'true', {
      path: '/',
    })
    return { disableSiteSpect: disable }
  }

  // Client should just use cookie to determine if SiteSpect is enabled
  return {
    disableSiteSpect: options.getCookie('mnpg_sitespect_enabled') === 'false',
  }
}

import { ServerResponse } from 'http'
import NextRouter from 'next/router'

const constructQueryString = (
  queryString?: string,
  returnUrl?: string,
): string => {
  const result = [
    queryString,
    returnUrl && `returnUrl=${encodeURIComponent(returnUrl)}`,
  ]
    .filter(Boolean)
    .join('&')
  return result ? `?${result}` : ''
}

export const redirect = ({
  res,
  path,
  returnUrl,
  statusCode = 302,
  queryString = '',
}: {
  res?: ServerResponse | undefined
  path: string
  returnUrl?: string
  statusCode?: 301 | 302
  queryString?: string
}) => {
  const fullQueryString = constructQueryString(queryString, returnUrl)

  const url = `${path}${fullQueryString}`

  if (res) {
    res.writeHead(statusCode, {
      Location: url,
    })
    res.end()
  } else {
    NextRouter.push(url)
  }
}

export const createWebLoyaltyConfig = (id: string) => {
  const UNSUPPORTED_WEB_LOYALTY_CONFIG = {
    hash: undefined,
    prodId: undefined,
  }
  try {
    const webLoyalty = process.env.WEB_LOYALTY
    if (!webLoyalty) return UNSUPPORTED_WEB_LOYALTY_CONFIG

    const { hash, prodId } = JSON.parse(webLoyalty)[id]

    return {
      hash: hash.length > 0 ? hash : undefined,
      prodId: prodId.length > 0 ? prodId : undefined,
    }
  } catch (e) {
    return UNSUPPORTED_WEB_LOYALTY_CONFIG
  }
}

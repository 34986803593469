import { DepartmentsEnum } from '@moonpig/web-core-types-graphql'

export const departmentMappings: { [key: number]: DepartmentsEnum } = {
  1: 'GREETING_CARDS' as DepartmentsEnum.GREETING_CARDS,
  2: 'INVITATIONS_AND_ANNOUNCEMENTS' as DepartmentsEnum.INVITATIONS_AND_ANNOUNCEMENTS,
  3: 'GIFT_EXPERIENCES' as DepartmentsEnum.GIFT_EXPERIENCES,
  5: 'WEDDING_STATIONERY' as DepartmentsEnum.WEDDING_STATIONERY,
  6: 'GUERNSEY_POSTAL_FLOWERS' as DepartmentsEnum.GUERNSEY_POSTAL_FLOWERS,
  8: 'CALENDAR_PHOTOBOX' as DepartmentsEnum.CALENDAR_PHOTOBOX,
  9: 'NON_CUSTOMISABLE_GIFTS' as DepartmentsEnum.NON_CUSTOMISABLE_GIFTS,
  10: 'FLOWERS' as DepartmentsEnum.FLOWERS,
  11: 'MUGS' as DepartmentsEnum.MUGS,
  12: 'TSHIRTS' as DepartmentsEnum.TSHIRTS,
  13: 'ALCOHOL_GIFTS' as DepartmentsEnum.ALCOHOL_GIFTS,
  14: 'CANVASES' as DepartmentsEnum.CANVASES,
  16: 'ARENA_POSTAL_FLOWERS' as DepartmentsEnum.ARENA_POSTAL_FLOWERS,
  17: 'SOFT_TOYS' as DepartmentsEnum.SOFT_TOYS,
  18: 'ACRYLICS' as DepartmentsEnum.ACRYLICS,
  19: 'FOOD_GIFTS' as DepartmentsEnum.FOOD_GIFTS,
  21: 'PLANTS' as DepartmentsEnum.PLANTS,
  22: 'CASES' as DepartmentsEnum.CASES,
  23: 'BEAUTY' as DepartmentsEnum.BEAUTY,
  24: 'GIFT_BOXES' as DepartmentsEnum.GIFT_BOXES,
  25: 'BALLOONS' as DepartmentsEnum.BALLOONS,
  26: 'WALL_ART' as DepartmentsEnum.WALL_ART,
  28: 'STATIONERY_AND_CRAFT' as DepartmentsEnum.STATIONERY_AND_CRAFT,
  29: 'GADGETS_AND_NOVELTIES' as DepartmentsEnum.GADGETS_AND_NOVELTIES,
  30: 'TOYS_AND_GAMES' as DepartmentsEnum.TOYS_AND_GAMES,
  31: 'GIFTS_FOR_HOME' as DepartmentsEnum.GIFTS_FOR_HOME,
  32: 'JEWELLERY_AND_ACCESSORIES' as DepartmentsEnum.JEWELLERY_AND_ACCESSORIES,
  33: 'LETTERBOX_GIFTS' as DepartmentsEnum.LETTERBOX_GIFTS,
  35: 'PARTY' as DepartmentsEnum.PARTY,
}

import React, { FC } from 'react'

const { createContext, useReducer, useContext, useMemo } = React

export type MegaNavState = {
  isOpen?: boolean
  openDropdownIndex?: number
  isOpenedByKeyboardOrClick?: boolean
  isDesktopNav?: boolean
  areAnyDropdownsOpen?: boolean
  dropdownHeightOffset: number
  windowHeight: number
}

type ActionPayloadIsDropdownOpen = {
  index: number
  isOpenedByKeyboardOrClick: boolean
}

type ActionPayloadToggleMegaNav = number
type ActionPayloadGetWindowHeight = number

type ActionPayloadSubDropdownOpen = string
type ActionPayloadIsDesktop = boolean

type ActionPayloads =
  | ActionPayloadIsDropdownOpen
  | ActionPayloadSubDropdownOpen
  | ActionPayloadIsDesktop
  | ActionPayloadToggleMegaNav
  | ActionPayloadGetWindowHeight

export const OPEN_DROPDOWN = 'OPEN_DROPDOWN'
export const CLOSE_DROPDOWN = 'CLOSE_DROPDOWN'
export const TOGGLE_MEGANAV = 'TOGGLE_MEGANAV'
export const SET_IS_DESKTOP = 'SET_IS_DESKTOP'
export const GET_WINDOW_HEIGHT = 'GET_WINDOW_HEIGHT'

type ActionType =
  | 'OPEN_DROPDOWN'
  | 'CLOSE_DROPDOWN'
  | 'TOGGLE_MEGANAV'
  | 'SET_IS_DESKTOP'
  | 'GET_WINDOW_HEIGHT'

export type MegaNavAction = [ActionType, ActionPayloads?]

export const initialState: MegaNavState = {
  isOpen: false,
  openDropdownIndex: -1,
  isOpenedByKeyboardOrClick: false,
  isDesktopNav: false,
  dropdownHeightOffset: 0,
  windowHeight: 0,
  areAnyDropdownsOpen: false,
}

type Context = [MegaNavState, React.Dispatch<MegaNavAction>]

const reducer = (state: MegaNavState, action: MegaNavAction): MegaNavState => {
  const [type, payload] = action
  switch (type) {
    case SET_IS_DESKTOP: {
      return {
        ...state,
        isDesktopNav: payload as boolean,
      }
    }
    case TOGGLE_MEGANAV: {
      return {
        ...state,
        isOpen: !state.isOpen,
        dropdownHeightOffset: payload as number,
      }
    }
    case OPEN_DROPDOWN: {
      const p = payload as ActionPayloadIsDropdownOpen
      return state.openDropdownIndex === p.index
        ? state
        : {
            ...state,
            isOpen: true,
            openDropdownIndex: p.index,
            isOpenedByKeyboardOrClick: p.isOpenedByKeyboardOrClick,
            areAnyDropdownsOpen: true,
          }
    }
    case CLOSE_DROPDOWN: {
      return {
        ...state,
        isOpen: state.isDesktopNav ? false : state.isOpen,
        openDropdownIndex: -1,
        isOpenedByKeyboardOrClick: false,
        areAnyDropdownsOpen: false,
      }
    }
    case GET_WINDOW_HEIGHT: {
      return {
        ...state,
        windowHeight: payload as number,
      }
    }
    default:
      return state
  }
}

const MegaNavContext = createContext<Context>([initialState, () => {}])

export const MegaNavProvider: FC<
  React.PropsWithChildren<{ state?: MegaNavState }>
> = ({ state, children }) => {
  const [newState, dispatch] = useReducer(reducer, {
    ...initialState,
    ...state,
  })
  const value = useMemo<Context>(() => [newState, dispatch], [newState])
  return (
    <MegaNavContext.Provider value={value}>{children}</MegaNavContext.Provider>
  )
}

export const useMegaNavContext = () => useContext(MegaNavContext)

import React, { FC, useEffect } from 'react'
import { Box, Flex, Heading, Link } from '@moonpig/launchpad-components'
import { system as s } from '@moonpig/launchpad-system'
import { breakpoint, styled } from '@moonpig/launchpad-utils'
import { spacingPx } from '@moonpig/launchpad-theme'
import { InspirationType } from './Inspirations/Inspiration'
import { Inspirations } from './Inspirations/Inspirations'
import { Reminder } from './Reminders/Reminder'
import { Reminders } from './Reminders/Reminders'
import type {
  InspirationItemType,
  RecentSearchItemType,
  RecentSearchType,
  ReminderItemType,
} from './types'
import { RecentSearches } from './RecentSearches/RecentSearches'
import { useLocaleText } from '../locale'

const StyledContainer = styled.div.attrs(({ isOpen }: { isOpen: boolean }) => ({
  className: !isOpen && 'hidden',
}))<{ isOpen: boolean }>`
  position: relative;
  top: ${spacingPx(12)};
  z-index: 3;
  ${s({
    pb: 4,
    pt: 6,
    px: 3,
    bgcolor: 'colorBackground01',
  })};

  &.hidden {
    display: none;
  }

  ${breakpoint('md')} {
    max-height: 75vh;
    overflow-y: auto;
    border-radius: 0px 0px 8px 8px;

    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      ${s({
        bgcolor: 'colorBlack00',
      })}
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      ${s({
        bgcolor: 'colorBlack20',
      })}
      border-radius: 85px;
    }
  }
`

const StyledHeader = styled(Heading)`
  ${s({
    fontSize: '12px',
    lineHeight: '24px',
    fontWeight: 'bold',
    color: 'colorTextLabel',
    pl: 5,
    mb: 0,
  })};
`

const StyledHeaderFlex = styled(Flex)`
  ${s({
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  })}
`

const StyledClearAllButton = styled(Link)`
  position: absolute;
  text-decoration: none;
  ${s({
    color: 'colorInteractionTextLink',
    p: 0,
    m: 0,
    right: '16px',
    typography: 'typeButtonLabel',
    fontSize: '12px',
  })}
`

type RecentSearchesWithHeaderProps = {
  recentSearches: RecentSearchType[]
  getItemProps: (props: {
    itemIndex: number
    item: RecentSearchItemType
  }) => Record<string, unknown>
  getMenuProps: () => Record<string, unknown>
  onRemoveItem: (item: RecentSearchType) => void
  onClearRecentSearches: () => void
}

const RecentSearchesWithHeader: FC<RecentSearchesWithHeaderProps> = ({
  recentSearches,
  onClearRecentSearches,
  ...recentSearchProps
}) => {
  const t = useLocaleText()

  return (
    <Box data-testid="lp-nav-focusable-recent-search" mb={6}>
      <StyledHeaderFlex>
        <StyledHeader level="h4">{t('search.recently_searched')}</StyledHeader>
        {recentSearches.length > 1 && (
          <StyledClearAllButton
            tabIndex={0}
            onClick={onClearRecentSearches}
            data-testid="lp-nav-search-recent-clear"
            aria-label={t('search.clear_all_recent_searches_label')}
          >
            {t('search.clear_recent_searches')}
          </StyledClearAllButton>
        )}
      </StyledHeaderFlex>
      <RecentSearches recentSearches={recentSearches} {...recentSearchProps} />
    </Box>
  )
}

type FocusableSearchBoxProps = {
  isOpen: boolean
  inspirations: InspirationType[]
  reminders?: Reminder[]
  recentSearches: RecentSearchType[]
  inputValue: string | null
  inspirationsHeaderText?: string
  getItemProps: (props: {
    itemIndex: number
    item: RecentSearchItemType | InspirationItemType | ReminderItemType
  }) => Record<string, unknown>
  getMenuProps: () => Record<string, unknown>
  onRemindersVisible?: () => void
  onRemoveRecentSearch: (item: RecentSearchType) => void
  onClearRecentSearches: () => void
  onSelectItem: (selectedValue: string) => void
}

export const FocusableSearchBox: FC<FocusableSearchBoxProps> = ({
  isOpen,
  inspirations,
  reminders = [],
  recentSearches,
  inputValue,
  getItemProps,
  getMenuProps,
  onRemindersVisible = () => {},
  onRemoveRecentSearch,
  onClearRecentSearches,
  onSelectItem,
}) => {
  const t = useLocaleText()
  const inspirationsVisible =
    isOpen && inspirations.length && (inputValue === null || inputValue === '')

  const recentSearchesVisible =
    isOpen &&
    recentSearches.length &&
    (inputValue === null || inputValue === '')

  const remindersVisible =
    isOpen && reminders.length && (inputValue === null || inputValue === '')

  const inspirationsHeaderVisible = inspirationsVisible || remindersVisible

  useEffect(() => {
    if (remindersVisible) {
      onRemindersVisible()
    }
  }, [onRemindersVisible, remindersVisible])

  return (
    <StyledContainer
      isOpen={inspirationsVisible || recentSearchesVisible || remindersVisible}
      onKeyDown={(e: React.KeyboardEvent<HTMLUListElement>) => {
        const { target } = e

        const targetElement = target as HTMLUListElement
        const listItemText = targetElement.textContent
        const isListElement = targetElement.tagName === 'LI'

        if (e.key === 'Enter' && isListElement && listItemText) {
          e.nativeEvent.preventDefault()
          onSelectItem(listItemText)
        }
      }}
    >
      {recentSearchesVisible ? (
        <RecentSearchesWithHeader
          recentSearches={recentSearches}
          getItemProps={getItemProps}
          getMenuProps={getMenuProps}
          onClearRecentSearches={onClearRecentSearches}
          onRemoveItem={onRemoveRecentSearch}
        />
      ) : null}
      {inspirationsHeaderVisible ? (
        <StyledHeader level="h4">{t('search.suggestions')}</StyledHeader>
      ) : null}
      {remindersVisible ? (
        <div data-testid="lp-nav-focusable-searchbox-reminders">
          <Reminders
            reminders={reminders}
            getItemProps={getItemProps}
            getMenuProps={getMenuProps}
          />
        </div>
      ) : null}
      {inspirationsVisible ? (
        <div data-testid="lp-nav-focusable-searchbox-inspirations">
          <Inspirations
            inspirations={inspirations}
            getItemProps={getItemProps}
            getMenuProps={getMenuProps}
            startIndex={recentSearches.length}
          />
        </div>
      ) : null}
    </StyledContainer>
  )
}

import { Alert, Box, Text } from '@moonpig/launchpad-components'
import React, { useEffect, useState } from 'react'
import {
  Source,
  Occasion,
} from '@moonpig/web-shared-types-graphql/graphqlTypes'
import { styled } from '@moonpig/launchpad-utils'
import { useGetBreakpoint } from '@moonpig/web-shared-utils'
import { useLocaleText } from '../../localisation'
import { trackImpression } from './analytics'
import {
  useCreateReminder,
  useDeleteReminder,
  useRemindersQuickAdd,
} from '../../hooks'
import { ReminderInput } from '../../types'
import { QuickAddButtonV2 } from './QuickAddButtonV2'

const useTrackImpression = (
  occasions: Occasion[] | undefined,
  source: Source,
) => {
  const [tracked, setTracked] = useState(false)

  useEffect(() => {
    if (occasions && occasions.length && !tracked) {
      trackImpression({ occasions, source })
      setTracked(true)
    }
  }, [occasions, tracked, source])
}

const StyledQuickAddContainerV2 = styled.div`
  display: flex;
  white-space: nowrap;
  flex-wrap: wrap;
`

type QuickAddState = { [occasion: string]: string | null }

type QuickAddComponentProps = {
  source: Source
  occasionsAlreadyAdded?: Occasion[]
  occasionsToOmit?: Occasion[]
  quickAddRecipientName?: string
  handleQuickAddToggle?: (occasion: Occasion) => void
}

export const ReminderQuickAddAndSave = ({
  occasionsAlreadyAdded = [],
  occasionsToOmit = [],
  quickAddRecipientName,
  handleQuickAddToggle,
  source,
}: QuickAddComponentProps) => {
  const breakPoint = useGetBreakpoint()

  const localiseText = useLocaleText()

  const [quickAddState, setQuickAddState] = useState<QuickAddState>({})
  const [toggledOccasion, setToggledOccasion] = useState('')

  const { reminders } = useRemindersQuickAdd({
    quickAddRecipientName,
  })

  const {
    isCreating,
    createReminder,
    createReminderError,
    resetCreateReminderState,
  } = useCreateReminder(source)

  const {
    isDeleting,
    deleteReminderError,
    deleteReminder,
    resetDeleteReminderState,
  } = useDeleteReminder(source)

  const deleteQuickAddReminder = async (
    reminder: ReminderInput,
    trackingIndex: string,
  ) => {
    const result = await deleteReminder({
      reminder: { id: quickAddState[reminder.occasion], ...reminder },
      trackingIndex,
    })
    if (result.type === 'success') {
      setQuickAddState({
        ...quickAddState,
        [reminder.occasion]: null,
      })
    }
  }

  const saveQuickAddReminder = async (
    reminder: ReminderInput,
    trackingIndex: string,
  ) => {
    resetCreateReminderState()
    resetDeleteReminderState()
    const result = await createReminder(reminder, trackingIndex)
    if (result.type === 'success') {
      setQuickAddState({
        ...quickAddState,
        [reminder.occasion]: result.reminder.id as string,
      })
    }
  }

  const onQuickAddToggle = (reminder: ReminderInput, index: number) => {
    handleQuickAddToggle?.(reminder.occasion)
    resetCreateReminderState()
    const trackingIndex = `${index + 1}/${reminders.length}`
    setToggledOccasion(reminder.occasion)
    if (quickAddState[reminder.occasion]) {
      deleteQuickAddReminder(reminder, trackingIndex)
    } else {
      saveQuickAddReminder(reminder, trackingIndex)
    }
  }

  const buttons = reminders
    .map((reminder, index) => ({
      occasion: reminder.occasion,
      onToggle: () => onQuickAddToggle(reminder, index),
      checked:
        occasionsAlreadyAdded?.includes(reminder.occasion) ||
        !!quickAddState[reminder.occasion] ||
        false,
      toggledLabelOverride: localiseText('common.reminders.quick_add.added'),
    }))
    .filter(button => !occasionsToOmit?.includes(button.occasion))

  useTrackImpression(
    buttons?.map(button => button.occasion),
    source,
  )

  if (!buttons || buttons.length === 0) return null

  return (
    <Box data-testid="loyalty-quick-add-component-v2">
      <Text
        typography={{ xs: 'typeMobileDisplay05', md: 'typeDesktopDisplay06' }}
        as="div"
        mb={6}
      >
        {localiseText('common.reminders.quick_add')}
        {breakPoint === 'xs' || breakPoint === 'sm'
          ? localiseText('common.reminders.quick_add.tap')
          : localiseText('common.reminders.quick_add.click')}
      </Text>
      <StyledQuickAddContainerV2 data-testid="quick-adds-new-design">
        {buttons.map(
          ({ occasion, onToggle, checked, toggledLabelOverride }) => (
            <QuickAddButtonV2
              key={occasion}
              occasion={occasion}
              loading={
                toggledOccasion === occasion && (isCreating || isDeleting)
              }
              disabled={isCreating || isDeleting}
              checked={checked}
              onToggle={onToggle}
              toggledLabelOverride={toggledLabelOverride}
            />
          ),
        )}
      </StyledQuickAddContainerV2>

      {createReminderError || deleteReminderError ? (
        <Alert mt={6} variant="error">
          <Text typography="typeBodyCaption">
            {createReminderError
              ? localiseText('common.reminders_quick_add_save_failed_error')
              : localiseText('common.reminders_quick_add_delete_failed_error')}
          </Text>
        </Alert>
      ) : null}
    </Box>
  )
}

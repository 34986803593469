import { Logger, LoggerConfig } from './types'
import { LocalLogger } from './LocalLogger'

let globalConfig: LoggerConfig = {}
let globalLogger: Logger | null

const createLogger = (config: LoggerConfig): Logger => {
  if (
    process.env.MNPG_ENVIRONMENT_NAME !== 'local' &&
    typeof window === 'undefined' &&
    config.awsLambda
  ) {
    // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
    const { ApiLambdaLogger } = require('@moonpig/common-logging-api-lambda')
    const { awsLambda, defaultMetadata } = config
    return new ApiLambdaLogger({
      ...awsLambda,
      defaultMetadata,
    })
  }
  if (
    process.env.MNPG_ENVIRONMENT_NAME !== 'local' &&
    typeof window === 'undefined'
  ) {
    // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
    const { NodeLogger } = require('@moonpig/common-logging-node')
    return new NodeLogger(config.defaultMetadata)
  }
  return new LocalLogger()
}

export const getLogger = (): Logger => {
  if (!globalLogger) {
    globalLogger = createLogger(globalConfig)
  }
  return globalLogger
}

export const setLoggerConfig = (config: LoggerConfig) => {
  globalConfig = config
  globalLogger = null
}

// 🙈
const proxyHandler: ProxyHandler<Record<string, unknown>> = {
  get: (_, prop) => Reflect.get(getLogger(), prop),
}

const proxyLogger =
  typeof window === 'undefined' ? new Proxy({}, proxyHandler) : getLogger()

export const logger = proxyLogger as Logger

import { createFlags } from './create-flags'
import { schema as coreSchema } from './brand-config'

export type CoreFlagIDs = keyof typeof coreSchema

const [useFlagHook, useFlagsHook, schema] = createFlags<CoreFlagIDs>(coreSchema)

export const coreFlagSchema = schema
export const useCoreFlag = useFlagHook
export const useCoreFlags = useFlagsHook

import React, { FC } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { Text, Flex } from '@moonpig/launchpad-components'
import { IconSystemCornerDownRight } from '@moonpig/launchpad-assets'
import { DepartmentsEnum } from '@moonpig/web-core-types-graphql'
import { ThemeInterface } from '@moonpig/launchpad-theme'
import { SearchItemType } from '../types'
import { useLocaleText } from '../../locale'

type EmptySuggestionProps = {
  department: { enum: DepartmentsEnum; name: string; title: string }
  searchTerm: string
  getItemProps: (props: {
    itemIndex: number
    item: SearchItemType
  }) => Record<string, unknown>
  highlighted: boolean
}

type StyledEmptySuggestionProps = {
  highlighted?: boolean
  theme: ThemeInterface
}

const ICON_SIZE_PX = '20px'

const StyledEmptySuggestion = styled(Flex).attrs<StyledEmptySuggestionProps>(
  ({ highlighted, theme }) => ({
    style: {
      backgroundColor: highlighted
        ? theme.colors.colorBackground02
        : theme.colors.colorBackground01,
      borderRadius: '12px',
    },
  }),
)<StyledEmptySuggestionProps>`
  ${s({ p: 3, alignItems: 'center' })}
  cursor: pointer;

  &:hover,
  &:focus {
    ${s({ bgcolor: 'colorBackground02', borderRadius: '12px' })}
  }
`

const StyledText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const StyledIconWrapper = styled(Flex)`
  ${s({
    bgcolor: 'colorBackground01',
    borderRadius: '8px',
    p: 4,
    mr: 4,
    color: 'colorInteractionControl',
  })}
`

export const EmptySuggestion: FC<EmptySuggestionProps> = ({
  department,
  searchTerm,
  getItemProps,
  highlighted,
}) => {
  const t = useLocaleText()

  const text = t('search.search_term_in_department', {
    departmentTitle: department.title,
    searchTerm,
  })

  return (
    <StyledEmptySuggestion
      {...getItemProps({
        itemIndex: 0,
        item: { value: searchTerm, itemType: 'search', department },
      })}
      tabIndex={0}
      highlighted={highlighted}
    >
      <StyledIconWrapper>
        <IconSystemCornerDownRight width={ICON_SIZE_PX} height={ICON_SIZE_PX} />
      </StyledIconWrapper>
      <StyledText typography="typeBodyText" color="colorTextLabel">
        {text}
      </StyledText>
    </StyledEmptySuggestion>
  )
}

import { z } from 'zod'
import {
  eventDataSchema,
  filterDataSchema,
  resultsDataSchema,
} from '../common.schema'

export const ViewXsellResultsSchema = z.object({
  event: z.literal('view_xsell_results'),

  event_data: eventDataSchema,
  results_data: resultsDataSchema,
  filter_data: filterDataSchema,
})

export type ViewXsellResultsEvent = z.infer<typeof ViewXsellResultsSchema>

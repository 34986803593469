import React, { FC } from 'react'
import {
  Box,
  CustomLink,
  LinkAsType,
  Flex,
} from '@moonpig/launchpad-components'
import { FooterNavSection } from '../FooterNav/FooterNavSection'
import { useLocaleText } from '../locale'

type ItemProps = {
  linkAs?: LinkAsType
  icon: React.ElementType
  label: string
  width?: string
  height?: string
  linkHref: string
}

type BrandProps = {
  items: ItemProps[]
}

export const Brands: FC<React.PropsWithChildren<BrandProps>> = ({ items }) => {
  const t = useLocaleText()
  return (
    <div data-testid="lp-nav-footernav-brands">
      <FooterNavSection title={t(`footer.nav.brands`)}>
        {items.map(({ linkHref, icon: Icon, width, height, label, linkAs }) => (
          <Box as="li" key={linkHref}>
            <CustomLink
              to={linkHref}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={label}
              linkAs={linkAs}
            >
              <Flex minHeight="48px" color="colorPrimary01" alignItems="center">
                <Icon width={width} height={height} />
              </Flex>
            </CustomLink>
          </Box>
        ))}
      </FooterNavSection>
    </div>
  )
}

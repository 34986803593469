import React, { FC } from 'react'
import { FooterNav } from '../FooterNav'

type FooterProps = React.ComponentProps<typeof FooterNav>

export const Footer: FC<React.PropsWithChildren<FooterProps>> = ({
  regionItems,
  linkListItems,
  appItems,
  socialItems,
  brandItems,
  paymentProviderItems,
}) => (
  <footer>
    <FooterNav
      appItems={appItems}
      socialItems={socialItems}
      regionItems={regionItems}
      linkListItems={linkListItems}
      paymentProviderItems={paymentProviderItems}
      brandItems={brandItems}
    />
  </footer>
)

import { redirectResponseProvider } from './redirectResponseProvider'
import { flattenRedirects } from './flattenRedirects'
import type { LegacySourceRedirect } from './types'

type LegacyRedirectConfig = {
  dynamicRedirects?: LegacySourceRedirect[]
  redirects?: LegacySourceRedirect[]
}

export const createLegacyRedirectHandler = (config: LegacyRedirectConfig) => {
  const flattenedDynamicRedirects = config?.dynamicRedirects
    ? flattenRedirects(config.dynamicRedirects)
    : []
  const flattenedRedirects = config?.redirects
    ? flattenRedirects(config.redirects)
    : []

  const adapter = async (
    path: string,
  ): Promise<{ source: string; destination: string } | null> => {
    const [uri, querystring = ''] = path.split('?')

    const response = await redirectResponseProvider(
      { uri, querystring },
      flattenedRedirects,
      flattenedDynamicRedirects,
    )

    if (!response) {
      return null
    }

    return {
      source: response.source,
      destination: response.headers.location[0].value,
    }
  }

  const redirect = async (path: string) => {
    const legacyRedirect = await adapter(path)
    if (legacyRedirect) {
      return {
        source: legacyRedirect.source,
        destination: legacyRedirect.destination,
        permanent: true,
      }
    }
    return null
  }

  return redirect
}

import {
  createGenericSelectContentEvent,
  trackGAEvent,
} from '@moonpig/web-core-analytics'
import { DOMAIN } from './trackReminderEvents'

type SelectContentKind =
  | 'ELLIPSIS_EDIT_SOFT_OPT_IN_REMINDER'
  | 'ELLIPSIS_DELETE_SOFT_OPT_IN_REMINDER'
  | 'SOFT_OPT_IN_TOGGLE_DELETE_REMINDER'
  | 'SOFT_OPT_IN_TOGGLE_CREATE_REMINDER'
  | 'INCENTIVISED_REMINDERS_NEXT_CTA_CLICK'
  | 'INCENTIVISED_REMINDERS_MODAL_CLOSE_CLICK'
  | 'INCENTIVISED_REMINDERS_MODAL_OPEN_CLICK'
  | 'CLICK_ON_SHOP_FOR_REMINDER'

type SelectContentContext = {
  eventAction: string
  eventLabel: string
  eventCategory?: string
}

const actionContextBySelectContentKind: {
  [kind in SelectContentKind]: SelectContentContext
} = {
  ELLIPSIS_EDIT_SOFT_OPT_IN_REMINDER: {
    eventAction: 'ellipsis click',
    eventLabel: 'soft opt in edit',
  },
  ELLIPSIS_DELETE_SOFT_OPT_IN_REMINDER: {
    eventAction: 'ellipsis click',
    eventLabel: 'soft opt in delete',
  },
  SOFT_OPT_IN_TOGGLE_DELETE_REMINDER: {
    eventAction: 'toggle click',
    eventLabel: 'soft opt in delete',
  },
  SOFT_OPT_IN_TOGGLE_CREATE_REMINDER: {
    eventAction: 'toggle click',
    eventLabel: 'soft opt in create',
  },
  INCENTIVISED_REMINDERS_NEXT_CTA_CLICK: {
    eventAction: 'modal next',
    eventLabel: '',
  },
  INCENTIVISED_REMINDERS_MODAL_CLOSE_CLICK: {
    eventAction: 'modal exit',
    eventLabel: '',
  },
  INCENTIVISED_REMINDERS_MODAL_OPEN_CLICK: {
    eventAction: 'modal open',
    eventLabel: `reminder modal`,
  },
  CLICK_ON_SHOP_FOR_REMINDER: {
    eventCategory: 'Account event reminder',
    eventAction: 'Click',
    eventLabel: '',
  },
}

// https://github.com/Moonpig/moonpig-data-tracking/blob/main/events/specs/select_content.md
export const trackReminderSelectEvent = ({
  kind,
  label,
  value,
}: {
  kind: SelectContentKind
  label?: string
  value?: number
}) => {
  const { eventAction, eventLabel, eventCategory } =
    actionContextBySelectContentKind[kind]

  return trackGAEvent(
    createGenericSelectContentEvent({
      eventCategory: eventCategory || DOMAIN,
      eventAction,
      eventLabel: label || eventLabel,
      eventValue: value,
    }),
  )
}

import React from 'react'
import { Box, TextLink, Text } from '@moonpig/launchpad-components'
import { Region } from '@moonpig/web-core-types'
import {
  Occasion,
  Source,
} from '@moonpig/web-shared-types-graphql/graphqlTypes'
import { useLocaleText } from './locale'
import { IncentivisedRemindersStepper } from './IncentivisedRemindersStepper'
import { ReminderForm } from '../ReminderForm'
import { ReminderInput } from '../../types'
import { ReminderQuickAddAndSave } from '../ReminderQuickAddAndSave'
import { useRemindersQuickAdd } from '../../hooks'

const Disclaimer = ({ region }: { region: Region }) => {
  const localiseText = useLocaleText()

  return (
    <Text display="inline-block" typography="typeBodyCaption" mt={6} mb={8}>
      <span key="disclaimer">
        {localiseText('reminders.incentivised.form.welcome.marketing.consent')}{' '}
        <TextLink href={`/${region}/privacy-notice/`} target="_blank">
          {localiseText(
            'reminders.incentivised.form.welcome.marketing_consent.privacy',
          )}
        </TextLink>{' '}
        {localiseText(
          'reminders.incentivised.form.welcome.marketing_consent.more_details',
        )}
      </span>
    </Text>
  )
}

type IncentivisedRemindersCaptureProps = {
  reminderCount: number
  remindersRequired: number
  voucherDescription: string
  region: Region
  onReminderSubmit: (input: ReminderInput) => Promise<void>
}

const defaultValues: ReminderInput = {
  name: '',
  occasion: Occasion.BIRTHDAY,
  occasionDate: {
    day: new Date().getDate(),
    month: new Date().getMonth() + 1,
  },
}

export const IncentivisedRemindersCapture: React.FC<
  IncentivisedRemindersCaptureProps
> = ({
  reminderCount,
  remindersRequired,
  voucherDescription,
  onReminderSubmit,
  region,
}) => {
  const localiseText = useLocaleText()
  const { reminders } = useRemindersQuickAdd({
    fetchPolicy: 'cache-only',
  })

  const shouldShowChristmasReminder = reminders.some(({ occasion }) => {
    return occasion === Occasion.CHRISTMAS
  })

  return (
    <>
      <IncentivisedRemindersStepper
        currentStep={reminderCount + 1}
        voucherDescription={voucherDescription}
        remindersRemaining={remindersRequired - reminderCount}
        remindersRequired={remindersRequired}
      />
      <Box mb={6}>
        <ReminderQuickAddAndSave
          occasionsToOmit={[Occasion.CHRISTMAS]}
          source={Source.WEB_INCENTIVISED_REMINDER_MODAL_QUICK_ADD}
        />
      </Box>
      <ReminderForm
        resetAfterSubmit
        defaultValues={defaultValues}
        onSubmit={onReminderSubmit}
        showChristmasCheckbox={shouldShowChristmasReminder}
        ctaText={localiseText('reminders.incentivised.form.welcome.cta')}
      />
      {!reminderCount && <Disclaimer region={region} />}
    </>
  )
}

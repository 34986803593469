import { gql } from '@moonpig/web-core-graphql'

export const GetIncentivisedReminderFormData = gql`
  query GetIncentivisedReminderFormData($path: String!, $preview: Boolean) {
    page(path: $path, preview: $preview) {
      __typename
      ... on Page {
        modules {
          ... on ModuleIncentivisedReminders {
            numberOfRemindersInOffer
            title
            introText
            introTitle
            introImage {
              url
            }
            endText
            endTitle
            voucherCode
            voucherDescription
            endImage {
              url
            }
            occasions {
              name
              id
            }
            relations {
              name
              id
            }
          }
        }
      }
    }
  }
`

import { LegacySourceRedirect, LegacyRedirect } from './types'

export const flattenRedirects = (
  redirects: LegacySourceRedirect[],
): LegacyRedirect[] => {
  const flatRedirects: LegacyRedirect[] = []
  if (redirects) {
    redirects.forEach(path => {
      if (!Array.isArray(path.old)) {
        // eslint-disable-next-line no-console
        console.log(path.old)
      }
      const currentRedirects = path.old.map(old => {
        return { ...path, old } // jshint ignore: line
      })
      flatRedirects.push(...currentRedirects)
    })
  }
  return flatRedirects
}

import React, { FC } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { ThemeInterface } from '@moonpig/launchpad-theme'
import { Text } from '@moonpig/launchpad-components'
import { HighlightText } from '../HighlightText'
import { SuggestionItemType } from '../types'

export type SuggestionType = {
  value: string
  searchKey?: string
  department?: { name: string; title: string }
  resultsCount?: number
  isMainSuggestion?: boolean
}

type StyledSuggestionProps = {
  highlighted?: boolean
  theme: ThemeInterface
}

const StyledSuggestion = styled.li.attrs<StyledSuggestionProps>(
  ({ highlighted, theme }) => ({
    style: {
      backgroundColor: highlighted
        ? theme.colors.colorBackground02
        : theme.colors.colorBackground01,
    },
  }),
)<StyledSuggestionProps>`
  ${s({ px: 5, py: 4 })}
  cursor: pointer;
  &:hover {
    ${s({ bgcolor: 'colorBackground02' })}
  }
`

type SuggestionProps = {
  item: {
    value: string
    searchKey?: string
    department?: { name: string; title: string }
    resultsCount?: number
    isMainSuggestion?: boolean
  }
  itemIndex: number
  value: string
  highlighted?: boolean
  getItemProps: (props: {
    itemIndex: number
    item: SuggestionItemType
  }) => Record<string, unknown>
} & React.HTMLProps<HTMLElement>

export const Suggestion: FC<React.PropsWithChildren<SuggestionProps>> = ({
  item,
  itemIndex,
  value,
  highlighted,
  getItemProps,
}) => (
  <StyledSuggestion
    highlighted={highlighted}
    {...getItemProps({
      itemIndex,
      item: { ...item, itemType: 'suggestion' },
    })}
  >
    {value && (
      <>
        <HighlightText text={item.value} value={value} />
        {item.department ? (
          <Text
            color={item.isMainSuggestion ? 'colorTextHeadline' : ''}
            typography="typeBodyText"
          >
            {item.isMainSuggestion ? ` in ` : ` `}
            {item.department.title}
            {item.resultsCount && ` (${item.resultsCount})`}
          </Text>
        ) : (
          item.resultsCount && (
            <Text typography="typeBodyLabel">
              {item.resultsCount && ` (${item.resultsCount})`}
            </Text>
          )
        )}
      </>
    )}
  </StyledSuggestion>
)

import {
  Occasion,
  Relationship,
  Source,
} from '@moonpig/web-shared-types-graphql/graphqlTypes'
import { z } from 'zod'

const occasionDateSchema = z.object({
  day: z.coerce.number().min(1).max(31),
  month: z.coerce.number().min(1).max(12),
})

const reminderCommonFieldsSchema = z.object({
  name: z.string().min(1).max(255),
  occasion: z.nativeEnum(Occasion),
  relationship: z.nativeEnum(Relationship).optional().nullable(),
  source: z.nativeEnum(Source).optional(),
})

export const reminderInputSchema = reminderCommonFieldsSchema.merge(
  z.object({
    id: z.string().optional().nullable(),
    occasionDate: occasionDateSchema,
    christmasReminder: z.boolean().optional(),
    checkIfReminderExists: z.boolean().optional(),
    daysBeforeOccasionToRemind: z.number().optional(),
  }),
)

const reminderSchema = reminderCommonFieldsSchema.merge(
  z.object({
    id: z.string(),
    nextDate: z.coerce.date(),
  }),
)

const reminderOccasionsSchema = z.object({
  defaultDate: occasionDateSchema.optional().nullable(),
  label: z.string(),
  value: z.nativeEnum(Occasion),
  defaultRelationship: z.nativeEnum(Relationship).optional().nullable(),
})

const reminderRelationshipsSchema = z.object({
  label: z.string(),
  value: z.nativeEnum(Relationship),
})

export type Reminder = z.infer<typeof reminderSchema>
export type ReminderInput = z.infer<typeof reminderInputSchema>
export type OccasionDate = z.infer<typeof occasionDateSchema>
export type ReminderOccasion = z.infer<typeof reminderOccasionsSchema>
export type ReminderRelationship = z.infer<typeof reminderRelationshipsSchema>

export type OrderReminderData = {
  reminderName: string
  occasionId: Occasion
  recipients: string[]
}

import React, { FC, ComponentProps } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import {
  Text,
  Box,
  Rating,
  TertiaryButton,
} from '@moonpig/launchpad-components'
import { system as s } from '@moonpig/launchpad-system'
import { IconLogoAppIcon, IconSystemCross } from '@moonpig/launchpad-assets'

type BannerProps = {
  title: string
  subtitle?: string
  /** Text in App Store CTA */
  ctaText: string
  /** URL to App Store */
  url: string
  /** Average customer rating, should be between 0 and 5 */
  rating: number
  /** Text that sits adjacent to the rating */
  totalRatingsText: string
  /** Title for the dismiss button */
  dismissTitle: string
  /** Click handler for dismiss button */
  onDismissClick: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const BUTTON_SIZE_PX = '32px'

const StyledBanner = styled.div`
  ${s({
    bgcolor: 'colorBackgroundSite',
    display: { xs: 'flex', xl: 'none' },
    justifyContent: 'space-between',
    alignItems: 'center',
    py: 4,
    borderBottom: 1,
    borderColor: 'colorBlack20',
  })};
`

const StyledButton = styled.button`
  ${s({
    color: 'colorBlack40',
    width: BUTTON_SIZE_PX,
    height: BUTTON_SIZE_PX,
  })}
`

const StyledBannerContent = styled.div`
  ${s({
    display: 'flex',
    alignItems: 'center',
  })};
`

const StyledCTA = styled(TertiaryButton)<ComponentProps<typeof TertiaryButton>>`
  &.banner-cta {
    ${s({
      pl: 0,
      color: 'colorInteractionButton',
    })}
  }
`

const StyledRatingWrapper = styled.div`
  align-items: center;
  display: flex;
`

export const Banner: FC<BannerProps> = ({
  title,
  subtitle,
  ctaText,
  url,
  totalRatingsText,
  rating,
  dismissTitle,
  onDismissClick,
}) => (
  <StyledBanner>
    <StyledBannerContent>
      <StyledButton type="button" onClick={onDismissClick} title={dismissTitle}>
        <IconSystemCross width="20px" height="20px" />
      </StyledButton>
      <IconLogoAppIcon
        style={{ display: 'Block' }}
        width-="60px"
        height="60px"
      />
      <Box pl={4}>
        <Text as="div" typography="typeBodyLabel">
          {title}
        </Text>
        {subtitle && (
          <Text as="div" typography="typeBodyCaption">
            {subtitle}
          </Text>
        )}
        <StyledRatingWrapper>
          <Rating
            rating={rating}
            starSize={16}
            totalRatingsText={totalRatingsText}
          />
        </StyledRatingWrapper>
      </Box>
    </StyledBannerContent>
    <StyledCTA className="banner-cta" href={url}>
      {ctaText}
    </StyledCTA>
  </StyledBanner>
)

export const getNextDate = ({
  month,
  day,
}: {
  month: number
  day: number
}): Date => {
  const currentDate = new Date()
  const nextDate = new Date(
    currentDate.getFullYear(),
    Number(month) - 1,
    Number(day),
  )
  if (currentDate > nextDate) {
    nextDate.setFullYear(currentDate.getFullYear() + 1)
  }
  return nextDate
}

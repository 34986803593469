import React, { AnchorHTMLAttributes } from 'react'
import {
  useTrackingData,
  trackGAEvent,
  TrackingEvent,
} from '@moonpig/web-core-analytics'
import {
  Link as RouteLink,
  routes,
  Routes,
  RouteParamsValues,
} from '@moonpig/web-core-routing'
import { styled } from '@moonpig/launchpad-utils'
import { absToRelUrl, triggerLuxInit } from '@moonpig/web-core-utils'

type HrefString = string
type HrefUrlObject = {
  url: string
  trackEvent?: TrackingEvent
}
type HrefNameParamsObject = {
  name: string
  params: RouteParamsValues
  trackEvent?: TrackingEvent
}

export const createLinkHref = <TName extends keyof Routes>(
  options:
    | {
        name: TName
        params: Partial<Routes[TName]>
        trackEvent?: TrackingEvent
      }
    | {
        url: string
        trackEvent?: TrackingEvent
      },
) => options as unknown as string

export type Href = HrefString | HrefUrlObject | HrefNameParamsObject | undefined

function hrefIsString(href: Href): href is HrefString {
  return typeof href === 'string'
}

function hrefIsUrlObject(href: Href): href is HrefUrlObject {
  return typeof href === 'object' && 'url' in href
}

function hrefIsNameParamsObject(href: Href): href is HrefNameParamsObject {
  return typeof href === 'object' && 'name' in href
}

type LinkProps = Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> & {
  href: Href
  ref?: React.Ref<HTMLElement>
  enableCSR?: boolean
}

const StyledAnchor = styled.a``
const StyledRouteLink = styled(RouteLink)``

const rewriteHrefForClientSideRouting = (
  href: LinkProps['href'],
): LinkProps['href'] => {
  if (hrefIsString(href) || hrefIsUrlObject(href)) {
    const url = hrefIsString(href) ? href : href.url
    const relUrl = absToRelUrl(url)
    const trackEvent = !hrefIsString(href) ? href.trackEvent : undefined

    const matchedRoute = routes.find(relUrl)
    if (matchedRoute) {
      const { query } = matchedRoute
      return {
        name: matchedRoute.route.name,
        params: query,
        trackEvent,
      }
    }
  }
  return href
}

const getUrlAndQueryStringFromHref = (href: Href) => {
  if (hrefIsString(href) || hrefIsUrlObject(href)) {
    const url = hrefIsString(href) ? href : href.url
    const [baseUrl, queryString] = url.split(/\?(.+)/)

    return [baseUrl, queryString]
  }
  return []
}

const queryStringIsOverlay = (queryString: string) => {
  if (queryString) {
    const query: Record<string, string> = {}
    queryString.split('&').forEach(kvp => {
      const [key, value] = kvp.split('=')
      query[key] = value
    })
    return query.overlay
  }
  return false
}

export const isModalLink = (href: Href) => {
  const [, queryString] = getUrlAndQueryStringFromHref(href)
  return queryStringIsOverlay(queryString)
}

export const Link = React.forwardRef<HTMLElement, LinkProps>(
  ({ children, onClick, href: rawHref, enableCSR, ...rest }, ref) => {
    const modalLink = isModalLink(rawHref)

    const rewriteLinks = enableCSR && !modalLink
    const href = rewriteLinks
      ? rewriteHrefForClientSideRouting(rawHref)
      : rawHref

    const trackingData = useTrackingData()

    const trackEvent =
      !href || hrefIsString(href) ? trackingData : href.trackEvent

    const handleClick = (
      event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    ) => {
      if (rewriteLinks) triggerLuxInit()
      if (trackEvent) trackGAEvent(trackEvent)
      if (onClick) onClick(event)
      if (modalLink) {
        event.preventDefault()
      }
    }

    // href suitable for client-side routing
    if (hrefIsNameParamsObject(href)) {
      return (
        <StyledRouteLink name={href.name} params={href.params} passHref>
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          <StyledAnchor onClick={handleClick} {...(rest as any)} ref={ref}>
            {children}
          </StyledAnchor>
        </StyledRouteLink>
      )
    }

    return (
      <StyledAnchor
        onClick={handleClick}
        href={hrefIsUrlObject(href) ? href.url : href}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        {...(rest as any)}
        ref={ref}
      >
        {children}
      </StyledAnchor>
    )
  },
)

Link.displayName = 'Link'

import { isServer } from '@moonpig/web-core-utils'
import type { Metrics } from '@moonpig/web-core-monitoring'
import { countInVariations } from './sitespect-api'
import {
  SiteSpectCampaign,
  Cookies,
  CampaignsResult,
  CampaignsRecord,
} from './types'
import { logWarning } from './log'

const registerVariations = async (
  campaignsData: SiteSpectCampaign[],
  cookies: Cookies,
  userAgent: string | undefined,
  siteSpectUrl: string,
  metrics: Metrics,
): Promise<Cookies> => {
  try {
    const allVariationGroupsIDs = campaignsData.map(campaignData =>
      campaignData.VariationGroup.ID.toString(),
    )

    const response = await countInVariations(
      allVariationGroupsIDs,
      {
        SSID: cookies.SSID,
        SSRT: cookies.SSRT,
      },
      userAgent,
      siteSpectUrl,
      metrics,
    )

    return response.cookies
  } catch (err) {
    logWarning(`SiteSpect - countIn - ${err}`)
    return {}
  }
}

export const createCampaigns = async (
  campaignsData: SiteSpectCampaign[],
  cookies: Cookies,
  userAgent: string | undefined,
  siteSpectUrl: string,
  metrics: Metrics,
): Promise<CampaignsResult> => {
  const campaigns = campaignsData.reduce<CampaignsRecord>(
    (acc, campaign) => ({
      ...acc,
      [campaign.Name]: { variation: campaign.VariationGroup.Name },
    }),
    {},
  )
  let newCookies = cookies
  /* istanbul ignore else */
  if (isServer) {
    newCookies = await registerVariations(
      campaignsData,
      cookies,
      userAgent,
      siteSpectUrl,
      metrics,
    )
  }

  return {
    campaigns,
    cookies: newCookies,
  }
}

import React, { FC } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { Text, Flex, Box } from '@moonpig/launchpad-components'
import { UrlSuggestionItemType } from '../types'

export type NavigationalSuggestionType = {
  value: string
  url: string
  icon?: React.ElementType
}

const StyledNavigationalSuggestion = styled.li`
  ${s({ px: 5, py: 4, bgcolor: 'colorBackground01' })}
  cursor: pointer;
  &:hover {
    ${s({ bgcolor: 'colorBackground02' })}
  }
`

type NavigationalSuggestionProps = {
  item: NavigationalSuggestionType
  itemIndex: number
  getItemProps: (props: {
    itemIndex: number
    item: UrlSuggestionItemType
  }) => Record<string, unknown>
} & React.ComponentPropsWithoutRef<typeof StyledNavigationalSuggestion>

export const NavigationalSuggestion: FC<
  React.PropsWithChildren<NavigationalSuggestionProps>
> = ({ item, itemIndex, getItemProps }) => {
  const Icon = item.icon
  return (
    <StyledNavigationalSuggestion
      {...getItemProps({
        itemIndex,
        item: { ...item, itemType: 'navigational' },
      })}
    >
      {
        <Flex>
          <Box height={8} marginRight={4}>
            {Icon && (
              <Icon
                width={'24px'}
                height={'24px'}
                aria-hidden
                typography={'typeBodyLabel'}
              />
            )}
          </Box>
          <Text typography={'typeBodyText'}>{item.value}</Text>
        </Flex>
      }
    </StyledNavigationalSuggestion>
  )
}

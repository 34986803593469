import { useEffect } from 'react'
import { trackGAEvent } from '@moonpig/web-core-analytics'

export const SendGALoginEvent = () => {
  useEffect(() => {
    const send = async () => {
      const loginEventType = localStorage?.getItem('mnpg_send_ga_login_event')

      if (!loginEventType) {
        return
      }

      trackGAEvent({
        event: 'login',

        event_data: {
          category: 'account',
          action: 'login success',
          label: loginEventType,
          non_interaction: true,
          value: undefined,
        },

        // Web only //
        content_data: undefined,
        discount_data: undefined,
        ecommerce: undefined,
        error_data: undefined,
      })

      localStorage.removeItem('mnpg_send_ga_login_event')
    }

    send()
  }, [])

  return null
}

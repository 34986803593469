import { Box, PageError } from '@moonpig/launchpad-components'
import { theme } from '@moonpig/launchpad-theme'
import { ThemeProvider } from '@moonpig/launchpad-utils'
import Head from 'next/head'
import React from 'react'
import { Page } from '../../types'
import { useLocaleText } from './Error404Page.locale'

const Error404Page: Page = () => {
  const t = useLocaleText()

  const links = [
    {
      linkHref: '/',
      linkTitle: t('homepage_link'),
    },
  ]

  const title = `${t('heading')} (404)`

  return (
    <ThemeProvider theme={theme}>
      <Head>
        <title>{title}</title>
        <meta name="title" content={title} />
        <meta name="robots" content="noindex" />
      </Head>
      <Box m={{ xs: 10, lg: 13 }}>
        <PageError title={t('heading')} links={links}>
          <p>{t('message')}</p>
        </PageError>
      </Box>
    </ThemeProvider>
  )
}

/* istanbul ignore next */
Error404Page.getInitialProps = async () => ({
  pageError: {
    statusCode: 404,
  },
})

Error404Page.layout = 'default'

export { Error404Page }

import React, { FC, useContext, createContext } from 'react'
import { TrackingEvent } from '../../types'

const TrackingContext = createContext<TrackingEvent | null>(null)

type TrackingDataProviderProps = {
  eventData: TrackingEvent
}

export const TrackingDataProvider: FC<
  React.PropsWithChildren<TrackingDataProviderProps>
> = ({ children, eventData }) => (
  <TrackingContext.Provider value={eventData}>
    {children}
  </TrackingContext.Provider>
)

export const useTrackingData = () => useContext(TrackingContext)

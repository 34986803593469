import { z } from 'zod'
import { eventDataSchema, resultsDataSchema } from '../common.schema'

export const SearchSuggestionClickSchema = z.object({
  event: z.literal('search_suggestion_click'),

  event_data: eventDataSchema,
  results_data: resultsDataSchema,
})

export type SearchSuggestionClickEvent = z.infer<
  typeof SearchSuggestionClickSchema
>

import { z } from 'zod'
import { eventDataSchema, userDataSchema } from '../common.schema'

export const RecipientErrorSchema = z.object({
  event: z.literal('recipient_error'),

  user_data: userDataSchema,
  event_data: eventDataSchema,
})

export type RecipientErrorEvent = z.infer<typeof RecipientErrorSchema>

import { z } from 'zod'
import { errorDataSchema, eventDataSchema } from '../common.schema'

export const AccountDelinkFailureSchema = z.object({
  event: z.literal('account_delink_failure'),

  event_data: eventDataSchema,
  error_data: errorDataSchema,
})

export type AccountDelinkFailureEvent = z.infer<
  typeof AccountDelinkFailureSchema
>

import { z } from 'zod'
import { ecommerceSchema, eventDataSchema } from '../common.schema'

export const AddToWishlistSchema = z.object({
  event: z.literal('add_to_wishlist'),

  event_data: eventDataSchema,
  ecommerce: ecommerceSchema,
})

export type AddToWishlistEvent = z.infer<typeof AddToWishlistSchema>

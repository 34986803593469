import React, { FC } from 'react'
import {
  PrimaryButton,
  Flex,
  SecondaryButton,
  Text,
} from '@moonpig/launchpad-components'
import {
  createGenericSelectContentEvent,
  trackGAEvent,
} from '@moonpig/web-core-analytics'
import { useQuery } from '@moonpig/web-core-graphql'
import { useRouter } from '@moonpig/web-core-routing'
import { useRemindersModalContext } from '@moonpig/web-core-context'
import { StoreProviderBrand, useStoreId } from '@moonpig/web-core-stores'
import { ErrorAlert, LoadingIndicator } from '@moonpig/web-shared-components'
import { GetIncentivisedRemindersCreatorData } from './gql/getIncentivisedRemindersCreatorData'
import { useLocaleText } from './locale'
import { IncentivisedRemindersData } from '../../../gql'

type ReminderNotificationProps = {
  hasReminders: boolean
}

const ReminderNotification: FC<ReminderNotificationProps> = ({
  hasReminders,
}) => {
  const modalContext = useRemindersModalContext()
  const router = useRouter()
  const storeId = useStoreId()

  const path = `/${storeId}/reminders/`

  const { loading, data, error } = useQuery(
    GetIncentivisedRemindersCreatorData,
    {
      variables: {
        path,
        preview: false,
      },
      errorPolicy: 'all',
    },
  )

  const localiseText = useLocaleText()

  if (loading) return <LoadingIndicator />
  if (error)
    return (
      <Flex flexWrap="wrap" p={4}>
        <ErrorAlert
          message={localiseText('reminders.incentivised.notification.error')}
        />
      </Flex>
    )

  const notificationData = data?.page?.modules.find(
    (module: IncentivisedRemindersData) =>
      module.__typename === 'ModuleIncentivisedReminders',
  )

  const notificationText = notificationData
    ? notificationData.notificationTitle
    : ''

  const handleSetRemindersClick = () => {
    if (notificationData) {
      modalContext.setShow(true)
    } else {
      router.push({ name: 'account-reminders', params: { region: storeId } })
    }
  }

  const handleViewRemindersClick = () => {
    trackGAEvent(
      createGenericSelectContentEvent({
        eventAction: 'view all reminders notification opened',
        eventLabel: `view all reminders`,
        eventCategory: 'reminders',
      }),
    )
    router.push({
      name: 'account-reminders',
      params: { region: storeId },
    })
  }

  return (
    <Flex flexWrap="wrap" justifyContent="center" mt={1} mb={10}>
      <Text
        typography={{ xs: 'typeMobileDisplay04', md: 'typeDesktopDisplay05' }}
        mb={6}
        mr={8}
        ml={8}
        textAlign="center"
      >
        {notificationText}
      </Text>
      {!hasReminders && (
        <>
          <PrimaryButton onClick={handleSetRemindersClick} width="100%">
            {localiseText('reminders.incentivised.notification.cta')}
          </PrimaryButton>
          <SecondaryButton
            mt={5}
            width="100%"
            onClick={handleViewRemindersClick}
          >
            {localiseText('common.reminder_notification.view_all')}
          </SecondaryButton>
        </>
      )}
      {hasReminders && (
        <SecondaryButton mt={5} onClick={handleSetRemindersClick} width="100%">
          {localiseText('reminders.incentivised.notification.cta')}
        </SecondaryButton>
      )}
    </Flex>
  )
}

type IncentivisedRemindersCreatorProps = {
  hasReminders: boolean
}

export const IncentivisedRemindersCreator: FC<
  IncentivisedRemindersCreatorProps
> = ({ hasReminders }) => {
  const storeId = useStoreId()
  return (
    <StoreProviderBrand storeId={storeId}>
      <ReminderNotification hasReminders={hasReminders} />
    </StoreProviderBrand>
  )
}

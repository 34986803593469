import React from 'react'
import { CoreFlagIDs, useCoreFlags } from '@moonpig/web-core-flags'
import {
  Alert,
  Flex,
  PrimaryButton,
  SecondaryButton,
} from '@moonpig/launchpad-components'
import {
  destroyBrowserCookie,
  getBrowserCookie,
  setBrowserCookie,
} from '@moonpig/web-core-cookies'
import { deleteUrlParam, insertUrlParam } from '@moonpig/web-core-utils'
import { FlagsListItem } from './FlagsListItem'
import { SearchInput } from './components/SearchInput'
import { ENABLE_COOKIE_FLAGS_COOKIE, FLAG_COOKIE_PREFIX } from './constants'

export const FlagsList: React.FC = () => {
  const enableCookieFlags = getBrowserCookie(ENABLE_COOKIE_FLAGS_COOKIE)
  const initialFlags = useCoreFlags()
  const [flags, setFlags] = React.useState(initialFlags)

  const toggleCookieOverrides = () => {
    if (enableCookieFlags === '1') {
      setBrowserCookie(ENABLE_COOKIE_FLAGS_COOKIE, '0', { path: '/' })
    } else {
      setBrowserCookie(ENABLE_COOKIE_FLAGS_COOKIE, '1', { path: '/' })
    }

    window.location.reload()
  }

  const deleteAllFlagCookies = () => {
    Object.keys(initialFlags).forEach(id => {
      destroyBrowserCookie(`${FLAG_COOKIE_PREFIX}${id}`, { path: '/' })
    })

    window.location.reload()
  }

  const handleSearchInput = (value: string) => {
    const flagKeys = Object.keys(initialFlags)
    const filteredFlagKeys = flagKeys.filter(key =>
      key.includes(value.toLowerCase()),
    )

    const filteredFlags = filteredFlagKeys.reduce(
      (acc, current) => ({
        ...acc,
        [current]: initialFlags[current as CoreFlagIDs],
      }),
      {},
    )

    setFlags(filteredFlags as { [key in CoreFlagIDs]: boolean })
  }

  const handleEnabledChange = (id: string, value: string) => {
    if (value === 'enabled') {
      insertUrlParam('flag', `${id}:on`)
    } else if (value === 'disabled') {
      insertUrlParam('flag', `${id}:off`)
    } else {
      deleteUrlParam('flag')
    }

    window.location.reload()
  }

  const handleCookieChange = (id: string, value: string) => {
    setBrowserCookie(ENABLE_COOKIE_FLAGS_COOKIE, '1', { path: '/' })

    if (value === 'enabled') {
      setBrowserCookie(`${FLAG_COOKIE_PREFIX}${id}`, '1', { path: '/' })
    } else if (value === 'disabled') {
      setBrowserCookie(`${FLAG_COOKIE_PREFIX}${id}`, '0', { path: '/' })
    } else {
      destroyBrowserCookie(`${FLAG_COOKIE_PREFIX}${id}`, { path: '/' })
    }

    window.location.reload()
  }

  return (
    <>
      <Alert variant="info" mt={5}>
        The Enabled checkbox indicates the current state of a flag. Toggle the
        &quot;Query&quot; selectbox to override the flag via query string. If
        you want to persist the change, toggle via the &quot;Cookie&quot;
        selectbox.
      </Alert>
      <Flex mt={8}>
        <PrimaryButton onClick={toggleCookieOverrides}>
          {enableCookieFlags === '1' ? 'Disable' : 'Enable'} Cookie Overrides
        </PrimaryButton>
        <SecondaryButton onClick={deleteAllFlagCookies} ml={5}>
          Delete All Flag Cookies
        </SecondaryButton>
      </Flex>
      <SearchInput placeholder="Search flag" onChange={handleSearchInput} />
      <ul>
        {Object.keys(flags).map((id: string) => {
          const flag = flags[id as CoreFlagIDs]
          return (
            <li key={id}>
              <FlagsListItem
                id={id}
                enabled={flag}
                handleEnabledChange={handleEnabledChange}
                handleCookieChange={handleCookieChange}
              />
            </li>
          )
        })}
      </ul>
    </>
  )
}

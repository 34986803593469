import { type NextPageContext } from 'next'
import { normaliseUrl } from './normaliseUrl'

export const handleNormaliseRedirects = (ctx: NextPageContext) => {
  if (ctx.req && ctx.res && ctx.req.url) {
    const normalisedUrl = normaliseUrl(ctx.req.url)
    if (ctx.req.url !== normalisedUrl) {
      const maxAge = 24 * 60 * 60 * 90 // 90 days
      ctx.res.statusCode = 301
      ctx.res.setHeader('Location', normalisedUrl)
      ctx.res.setHeader('Cache-Control', `public, max-age=${maxAge}`)
      ctx.res.end()

      return {
        pageProps: {
          statusCode: 301,
          location: normalisedUrl,
        },
      } as const
    }
  }

  return null
}

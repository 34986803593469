import {
  AuthenticatedFetch,
  FetchInfo,
  FetchInit,
  Fetch,
  Response,
} from '@moonpig/web-core-fetch'
import { HttpLink, HttpOptions } from '@apollo/client/link/http'

type MetricOptions = {
  traceName: string
  metricName: 'graphql' | 'graphql-unknown'
  metricDimensions?: {
    type: 'query' | 'persisted-query'
    'operation-name': string
  }
}

const getMetricOptions = (info: FetchInfo, init?: FetchInit): MetricOptions => {
  // Persisted queries
  const url = new URL(info as string)
  const params = new URLSearchParams(url.search)
  const operationName = params.get('operationName')
  /* istanbul ignore if */
  if (operationName) {
    return {
      traceName: `graphql-pq-${operationName}`,
      metricName: 'graphql',
      metricDimensions: {
        type: 'persisted-query',
        'operation-name': operationName,
      },
    }
  }

  // Normal queries
  if (!init?.body || typeof init.body !== 'string') {
    return {
      traceName: 'graphql-unknown',
      metricName: 'graphql-unknown',
    }
  }
  try {
    const result = JSON.parse(init.body)
    if (result.operationName) {
      return {
        traceName: `graphql-${result.operationName}`,
        metricName: 'graphql',
        metricDimensions: {
          type: 'query',
          'operation-name': result.operationName,
        },
      }
    }
  } catch (e) {
    /* empty */
  }

  /* istanbul ignore next */
  return { traceName: 'graphql-unknown', metricName: 'graphql-unknown' }
}

export const createHttpLink = ({
  uri,
  fetch: authFetch,
}: {
  uri: string
  fetch: AuthenticatedFetch
}): HttpLink => {
  const fetch: Fetch | undefined = async (info, init) => {
    return authFetch(info, {
      ...init,
      metrics: getMetricOptions(info, init),
      isUnauthenticatedCheck: async (response: Response) => {
        /* istanbul ignore if */
        if (!response.ok) {
          return false
        }
        const json = await response.json()
        if (Array.isArray(json?.errors)) {
          return json.errors.some(
            (error: { extensions: { code: string } }) =>
              error.extensions?.code === 'UNAUTHENTICATED',
          )
        }
        return false
      },
    })
  }

  return new HttpLink({
    useGETForQueries: false,
    uri,
    credentials: 'include',
    fetch: fetch as HttpOptions['fetch'],
  })
}

import React from 'react'
import { IllustrationMultipack } from '@moonpig/launchpad-assets'
import {
  Heading,
  PrimaryButton,
  Flex,
  Text,
} from '@moonpig/launchpad-components'
import { styled, breakpointUp } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { useCoreLocaleText } from '@moonpig/web-core-locale-text'

const StyledButtonWrapper = styled(Flex)`
  ${s({
    mx: 6,
  })}
  width: calc(100% - 32px);
  ${breakpointUp('md')} {
    width: auto;
    min-width: 250px;
  }
`

export const ErrorPage: React.FC = () => {
  const t = useCoreLocaleText()
  return (
    <Flex
      display="flex"
      flexDirection="column"
      textAlign="center"
      alignItems="center"
      my={8}
    >
      <IllustrationMultipack
        width={180}
        height={180}
        aria-label={t('common.basket')}
      />
      <Heading
        level="h3"
        my={6}
        mx={12}
        typography={{ xs: 'typeMobileDisplay04', md: 'typeDesktopDisplay05' }}
      >
        {t('common.sorry_about_this')}
      </Heading>
      <Text mb={6} mx={12}>
        {t('common.something_went_wrong_when_loading_this_page')}
      </Text>
      <StyledButtonWrapper mb={4}>
        <PrimaryButton
          width="100%"
          onClick={() => {
            window.location.reload()
          }}
        >
          {t('common.try_again')}
        </PrimaryButton>
      </StyledButtonWrapper>
    </Flex>
  )
}

import React, { createContext, useContext, FC, useState, useMemo } from 'react'
import { logger } from '@moonpig/web-core-monitoring'

type RemindersModalContext = {
  show: boolean
  setShow: (value: boolean) => void
}

type RemindersModalProviderProps = {
  value: {
    show: boolean
  }
}

const defaultValue = {
  show: false,
  setShow: () => {
    logger.fixToday('RemindersModalProvider is not initialised')
  },
}
const RemindersModalContext = createContext<RemindersModalContext>(defaultValue)

export const useRemindersModalContext = () => useContext(RemindersModalContext)

export const RemindersModalProvider: FC<
  React.PropsWithChildren<RemindersModalProviderProps>
> = ({ children, value }) => {
  const [show, setShow] = useState(value.show)

  const contextValue = useMemo(() => ({ show, setShow }), [show, setShow])

  return (
    <RemindersModalContext.Provider value={contextValue}>
      {children}
    </RemindersModalContext.Provider>
  )
}

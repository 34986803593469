import { createFlags } from '@moonpig/web-core-flags'
import { schema as localSchema, type Schema } from './brand-config'

type LocalFlagIDs = keyof Schema

const [useFlagHook, useFlagsHook, schema] =
  createFlags<LocalFlagIDs>(localSchema)

export type AllFlagIDs = keyof typeof schema

export const flagSchema = schema
export const useFlag = useFlagHook
export const useFlags = useFlagsHook

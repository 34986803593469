function updateUrl(params: URLSearchParams) {
  const newurl = `${window.location.protocol}//${window.location.host}${
    window.location.pathname
  }${params.toString() && '?'}${params.toString()}`
  return newurl
}

export function getUrlWithParam(url: string, key: string, value: string) {
  const parsedUrl = new URL(url)
  const searchParams = new URLSearchParams(parsedUrl.search)
  searchParams.set(key, value)
  parsedUrl.search = searchParams.toString()
  const newurl = parsedUrl.toString()
  return newurl
}

export function insertUrlParam(key: string, value: string) {
  const searchParams = new URLSearchParams(window.location.search)
  searchParams.set(key, value)
  const newurl = updateUrl(searchParams)
  window.history.pushState({ path: newurl }, '', newurl)
}

export function deleteUrlParam(key: string) {
  const searchParams = new URLSearchParams(window.location.search)
  searchParams.delete(key)
  const newurl = updateUrl(searchParams)
  window.history.pushState({ path: newurl }, '', newurl)
}

import { useQuery } from '@moonpig/web-core-graphql'
import {
  Occasion,
  Relationship,
} from '@moonpig/web-shared-types-graphql/graphqlTypes'
import { WatchQueryFetchPolicy } from '@apollo/client'
import { useLocaleText } from '../localisation'
import { GetSuggestedQuickAddsGQL, GetSuggestedQuickAddsQuery } from '../gql'
import { ReminderInput } from '../types'

type UseRemindersQuickAdd = {
  reminders: ReminderInput[]
}

type UseRemindersQuickAddProps = {
  quickAddRecipientName?: string
  fetchPolicy?: WatchQueryFetchPolicy
}

export const useRemindersQuickAdd = ({
  quickAddRecipientName,
  fetchPolicy = 'network-only',
}: UseRemindersQuickAddProps): UseRemindersQuickAdd => {
  const localiseText = useLocaleText()
  const { data } = useQuery<GetSuggestedQuickAddsQuery>(
    GetSuggestedQuickAddsGQL,
    {
      fetchPolicy,
    },
  )

  const nameOccasionMapping: { [key: string]: string } = {
    [Occasion.FATHERS_DAY]: localiseText(
      'common.reminders.quick_add.dad_default_name',
    ),
    [Occasion.MOTHERS_DAY]: localiseText(
      'common.reminders.quick_add.mum_default_name',
    ),
    [Occasion.VALENTINES_DAY]: localiseText(
      'common.reminders.quick_add.valentine_default_name',
    ),
    [Occasion.CHRISTMAS]: quickAddRecipientName || '',
  }

  const relationshipOccasionMapping: { [key: string]: Relationship | '' } = {
    [Occasion.FATHERS_DAY]: Relationship.DAD,
    [Occasion.MOTHERS_DAY]: Relationship.MUM,
    [Occasion.VALENTINES_DAY]: '',
    [Occasion.CHRISTMAS]: '',
  }

  const reminders =
    data?.suggestQuickAdds?.map(suggestQuickAdd => ({
      name: nameOccasionMapping[suggestQuickAdd.occasion],
      occasion: suggestQuickAdd.occasion,
      relationship: relationshipOccasionMapping[
        suggestQuickAdd.occasion
      ] as Relationship,
      occasionDate: {
        month: suggestQuickAdd.date.month,
        day: suggestQuickAdd.date.day,
      },
    })) || []

  return {
    reminders,
  }
}

import React from 'react'
import {
  IconStaticReminderAnniversary,
  IconStaticReminderBirthday,
  IconStaticReminderChristmas,
  IconStaticReminderFathersDay,
  IconStaticReminderCard,
  IconStaticReminderMothersDay,
  IconStaticReminderValentines,
} from '@moonpig/launchpad-assets'
import { Box } from '@moonpig/launchpad-components'

export type Occasion =
  | 'ANNIVERSARY'
  | 'BIRTHDAY'
  | 'CHRISTMAST'
  | 'FATHERS_DAY'
  | 'MOTHERS_DAY'
  | 'VALENTINES_DAY'
  | string

type ReminderIconProps = React.SVGProps<SVGSVGElement> & {
  occasion?: Occasion
}

export const OccasionIcon = ({ occasion, ...svgProps }: ReminderIconProps) => {
  switch (occasion) {
    case 'ANNIVERSARY':
      return (
        <Box data-testid="icon-anniversary" lineHeight={0}>
          <IconStaticReminderAnniversary {...svgProps} />
        </Box>
      )
    case 'BIRTHDAY':
      return (
        <Box data-testid="icon-birthday" lineHeight={0}>
          <IconStaticReminderBirthday {...svgProps} />
        </Box>
      )
    case 'CHRISTMAS':
      return (
        <Box data-testid="icon-christmas" lineHeight={0}>
          <IconStaticReminderChristmas {...svgProps} />
        </Box>
      )
    case 'FATHERS_DAY':
      return (
        <Box data-testid="icon-fathers-day" lineHeight={0}>
          <IconStaticReminderFathersDay {...svgProps} />
        </Box>
      )
    case 'MOTHERS_DAY':
      return (
        <Box data-testid="icon-mothers-day" lineHeight={0}>
          <IconStaticReminderMothersDay {...svgProps} />
        </Box>
      )
    case 'VALENTINES_DAY':
      return (
        <Box data-testid="icon-valentines-day" lineHeight={0}>
          <IconStaticReminderValentines {...svgProps} />
        </Box>
      )
    default:
      return (
        <Box data-testid="icon-generic" lineHeight={0}>
          <IconStaticReminderCard {...svgProps} />
        </Box>
      )
  }
}

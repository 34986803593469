import {
  ApolloClient,
  ApolloLink,
  from,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client'

import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import type { Fetch } from '@moonpig/web-core-fetch'
import {
  createProxyApiUrl,
  createSha256Hash,
  getApiUrl,
} from '@moonpig/web-core-utils'
import {
  createContextLink,
  createErrorLink,
  createLogLink,
  createHttpLink,
} from './links'
import { GraphQLOptions } from './types'

export const createClient = (
  options: GraphQLOptions,
  isServer: boolean,
  initialState: NormalizedCacheObject,
  fetch: Fetch,
) => {
  const { headers } = options
  const graphqlGatewayUrl = `${getApiUrl()}/graphql`
  const uri = createProxyApiUrl(graphqlGatewayUrl)

  const logLink = createLogLink()
  const contextLink = createContextLink({ headers })
  const errorLink = createErrorLink()

  /* istanbul ignore next */
  const persistedQueryLink = options.usePersistedQueries
    ? [
        new ApolloLink((operation, forward) => forward(operation)).split(
          op => JSON.stringify(op.variables).length > 4096,
          createPersistedQueryLink({
            useGETForHashedQueries: false,
            sha256: (query: string) => createSha256Hash(query),
          }),
          createPersistedQueryLink({
            useGETForHashedQueries: true,
            sha256: (query: string) => createSha256Hash(query),
          }),
        ),
      ]
    : /* istanbul ignore next */ []

  const links = [...persistedQueryLink, logLink, errorLink, contextLink]

  links.push(createHttpLink({ uri, fetch }))

  const client = new ApolloClient({
    connectToDevTools: !isServer,
    ssrMode: isServer,
    link: from(links),
    cache: new InMemoryCache().restore(initialState),
    assumeImmutableResults: true,
  })

  return client
}

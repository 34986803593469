import React, { FC, useContext, useMemo } from 'react'
import type { Platform } from '@moonpig/web-core-types'
import { getPlatform } from '@moonpig/web-core-utils'

const PlatformContext = React.createContext<Platform>({
  isMobile: true,
  source: 'MOBILE WEB',
})

export const PlatformProvider: FC<
  React.PropsWithChildren<{
    isMobile: boolean
  }>
> = ({ isMobile, children }) => {
  const value: Platform = useMemo(() => getPlatform(isMobile), [isMobile])

  return (
    <PlatformContext.Provider value={value}>
      {children}
    </PlatformContext.Provider>
  )
}

export const usePlatform = (): Platform => {
  const platform = useContext(PlatformContext)

  return platform
}

import type { Metrics } from '@moonpig/web-core-monitoring'
import { getAssignmentsForCampaigns } from './sitespect-api'
import { Cookies, SiteSpectCampaign } from './types'
import { logWarning } from './log'

export const fetchCampaigns = async (
  cookies: Cookies,
  userAgent: string | undefined,
  siteSpectUrl: string,
  metrics: Metrics,
): Promise<{ campaigns: SiteSpectCampaign[]; cookies: Cookies }> => {
  try {
    const { Campaigns = [], cookies: newCookies } =
      await getAssignmentsForCampaigns(
        cookies,
        userAgent,
        siteSpectUrl,
        metrics,
      )

    return { campaigns: Campaigns, cookies: newCookies }
  } catch (err) {
    logWarning(`SiteSpect - fetchCampaigns - error: ${err}`)

    return {
      campaigns: [],
      cookies: {},
    }
  }
}

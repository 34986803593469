import React, { FC } from 'react'
import { styled, breakpoint } from '@moonpig/launchpad-utils'
import { NAVIGATION_ICONS } from '@moonpig/web-core-brand/config'
import { Image } from '@moonpig/launchpad-components'
import { IconSystemGroup } from '@moonpig/launchpad-assets'
import { NAV_BP } from '../constants'

const StyledImage = styled(Image)`
  position: absolute;
  top: 50%;
  left: 16px;
  width: 24px;
  height: 26px;
  transform: translateY(-50%);

  ${breakpoint(NAV_BP)} {
    display: none;
  }
`

const StyledIconSystemGroup = styled(IconSystemGroup)`
  position: absolute;
  top: 50%;
  left: 16px;
  width: 24px;
  height: 26px;
  transform: translateY(-50%);

  ${breakpoint(NAV_BP)} {
    display: none;
  }
`

export const NavigationIcon: FC<{ icon: string; label: string }> = ({
  icon,
  label,
}) => {
  const iconUrl = NAVIGATION_ICONS[icon]

  /* istanbul ignore next */
  if (icon === 'IconSystemGroup') {
    return <StyledIconSystemGroup aria-hidden data-testid="navigation-icon" />
  }

  return iconUrl ? (
    <StyledImage
      src={iconUrl}
      alt={label}
      aria-hidden
      data-testid="navigation-icon"
    />
  ) : /* istanbul ignore next */ null
}

/* eslint-disable class-methods-use-this */
/* eslint-disable no-console */
import { ConsoleLogger, LogContext } from '@moonpig/common-logging-console'

const consoleArgs = (message: string, context?: LogContext, error?: Error) => {
  if (error) {
    return [message, context ?? {}, error]
  }
  if (context) {
    return [message, context]
  }
  return [message]
}

export class LocalLogger implements ConsoleLogger {
  public fixImmediately = (
    message: string,
    context?: LogContext,
    error?: Error,
  ) => {
    console.error(
      ...consoleArgs(`[Fix immediately] ${message}`, context, error),
    )
  }

  public fixToday = (message: string, context?: LogContext, error?: Error) => {
    console.error(...consoleArgs(`[Fix today] ${message}`, context, error))
  }

  public warning = (message: string, context?: LogContext, error?: Error) => {
    console.warn(...consoleArgs(`[Warning] ${message}`, context, error))
  }

  public info = (message: string, context?: LogContext, error?: Error) => {
    console.info(...consoleArgs(`[Info] ${message}`, context, error))
  }

  public debug = (message: string, context?: LogContext, error?: Error) => {
    console.debug(...consoleArgs(`[Debug] ${message}`, context, error))
  }

  /* istanbul ignore next */
  public extendContext = () => {}

  /* istanbul ignore next */
  public childLogger = () => ({} as unknown as ConsoleLogger)
}

type Options = {
  forwardSlash?: boolean
}

export const sanitizeStrings = <T extends string | undefined>(
  str: T,
  options?: Options,
): T => {
  if (str === undefined) {
    return str
  }

  const str2 = str.toLocaleLowerCase().replace(/_|-/g, ' ')

  if (options?.forwardSlash) {
    return str2.replace(/\//g, '') as T
  }

  return str2 as T
}

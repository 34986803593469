import { setSendCustomerEvent, trackGAEvent } from '@moonpig/web-core-analytics'

export const trackOneTapSuccessfulLogin = () => {
  trackGAEvent({
    event: 'login',

    event_data: {
      category: 'account',
      action: 'login success',
      label: 'google one tap',
      non_interaction: true,
      value: undefined,
    },

    content_data: undefined,
    discount_data: undefined,
    ecommerce: undefined,
    error_data: undefined,
  })

  setSendCustomerEvent({ loginMethod: 'google one tap' })
}

export const trackOneTapSuccessfulRegistration = () => {
  trackGAEvent({
    event: 'sign_up',

    event_data: {
      category: 'account',
      action: 'sign up success',
      label: 'google one tap',
      non_interaction: true,
      value: undefined,
    },

    content_data: undefined,
    discount_data: undefined,
    ecommerce: undefined,
    error_data: undefined,
  })

  setSendCustomerEvent({ loginMethod: 'google one tap' })
}

export const trackOneTapFailedLogin = () => {
  trackGAEvent({
    event: 'login_failure',
    content_data: {
      content_type: 'account | google one tap | login error',
      item_id: undefined,
    },

    event_data: {
      category: 'account',
      action: 'login error',
      label: 'google one tap',
      non_interaction: true,
      value: undefined,
    },

    discount_data: undefined,
    ecommerce: undefined,
    error_data: undefined,
  })
}

export const trackOneTapAccountLinkRequired = () => {
  trackGAEvent({
    event: 'impression',
    content_data: {
      content_type: `account | google one tap | linking required`,
      item_id: undefined,
    },
    event_data: {
      category: 'account',
      action: 'google one tap login',
      label: `google one tap | linking required`,
      non_interaction: true,
      value: undefined,
    },
    discount_data: undefined,
    ecommerce: undefined,
    error_data: undefined,
  })
}

export const trackOneTapAccountLinkSuccess = () => {
  trackGAEvent({
    event: 'account_link_success',

    event_data: {
      category: 'account',
      action: 'account link success',
      label: 'google one tap',
      non_interaction: true,
      value: undefined,
    },

    content_data: undefined,
    discount_data: undefined,
    ecommerce: undefined,
    error_data: undefined,
  })
}

export const trackOneTapAccountLinkFailure = ({
  errorCode,
  errorMessage,
}: {
  errorCode?: number
  errorMessage?: string
}) => {
  trackGAEvent({
    event: 'account_link_failure',

    event_data: {
      category: 'account',
      action: 'account link failure',
      label: 'google one tap',
      non_interaction: true,
      value: undefined,
    },

    error_data: {
      id: errorCode || '0',
      message: errorMessage,
    },

    content_data: undefined,
    discount_data: undefined,
    ecommerce: undefined,
  })
}

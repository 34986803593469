const snakeToCamel = (str: string) =>
  str.replace(/([-_][a-z0-9])/g, group =>
    group.toUpperCase().replace('-', '').replace('_', ''),
  )

type Experiments = { [key: string]: string | undefined }

export const getCamelCaseExperiments = (experiments: Experiments) => {
  const camelCaseExperiments = Object.keys(experiments).reduce(
    (agg, key) => ({
      ...agg,
      [snakeToCamel(key)]: experiments[key],
    }),
    {},
  )
  return camelCaseExperiments
}

import React, { FC } from 'react'
import { CustomLink, LinkAsType } from '@moonpig/launchpad-components'
import { FooterNavSection } from './FooterNavSection'
import { useLocaleText } from '../locale'

export type ItemProps = {
  to: string
  linkAs?: LinkAsType
  icon: React.ElementType
  label: string
  width: string
  height: string
}

type AppProps = {
  items: ItemProps[]
}

export const Apps: FC<React.PropsWithChildren<AppProps>> = ({ items }) => {
  const t = useLocaleText()
  return (
    <div data-testid="lp-nav-footernav-apps">
      <FooterNavSection title={t(`footer.nav.apps`)} colGap={{ xs: 4, md: 5 }}>
        {items.map(
          ({ to, label, linkAs, icon: Icon, width, height }, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={index}>
              <CustomLink
                to={to}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={label}
                linkAs={linkAs}
              >
                <Icon
                  width={width}
                  height={height}
                  style={{ display: 'block' }}
                />
              </CustomLink>
            </li>
          ),
        )}
      </FooterNavSection>
    </div>
  )
}

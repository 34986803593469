import React, { createContext, FC, useContext } from 'react'
import { router } from './singleton'
import { Router } from './types'

const routerContext = createContext(router)

export const RouterProvider: FC<
  React.PropsWithChildren<{ router: Router }>
> = ({ router: contextRouter, children }) => (
  <routerContext.Provider value={contextRouter}>
    {children}
  </routerContext.Provider>
)

export const useRouter = () => useContext(routerContext)

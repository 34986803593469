import { getBrowserCookies } from '@moonpig/web-core-cookies'

export type CreateProxyApiUrlOptions = {
  forwardAbsolute?: boolean
  forceProxy?: boolean
}

export const createProxyApiUrl = (
  targetUrl: string,
  options?: CreateProxyApiUrlOptions,
) => {
  const cookies = getBrowserCookies()
  const usingNamespace = !!cookies['mnpg-proxy-api-store-namespace']

  const PROXY_HOST = `http://localhost:${process.env.PROXY_API_PORT}`

  const opts = options || {}

  const shouldUseProxy =
    opts.forceProxy || usingNamespace || process.env.ENABLE_PROXY_API === 'true'

  if (!shouldUseProxy) {
    return targetUrl
  }

  let url: URL = null as unknown as URL
  try {
    url = new URL(targetUrl)
  } catch (e) {
    return targetUrl
  }

  if (opts.forwardAbsolute) {
    return `${PROXY_HOST}/?proxyUrl=${encodeURIComponent(url.href)}`
  }

  return `${PROXY_HOST}${url.pathname}${url.search}`
}

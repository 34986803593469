import { z } from 'zod'
import {
  eventDataSchema,
  filterDataSchema,
  resultsDataSchema,
} from '../common.schema'

export const SearchSchema = z.object({
  event: z.literal('search'),

  event_data: eventDataSchema,
  results_data: resultsDataSchema,
  filter_data: filterDataSchema,
})

export type SearchEvent = z.infer<typeof SearchSchema>

import { Footer as LaunchpadFooter, HeaderProps } from '@moonpig/web-core-nav'
import { Region } from '@moonpig/web-core-types'

type LaunchpadFooterProps = React.ComponentProps<typeof LaunchpadFooter>

export type BrandFooterProps = Omit<
  LaunchpadFooterProps,
  'socialItems' | 'appItems'
> & {
  region: Region
}

export type BrandHeaderProps = HeaderProps & { region: Region }

export enum UtilityName {
  FAVOURITES = 'favourites',
  MY_ACCOUNT = 'my account',
  BASKET = 'basket',
  ACCOUNT_LOGIN = 'account login',
}

export type HideSearchBarOption = 'mobile' | 'desktop' | 'all'

import React, { FC, memo } from 'react'
import { system as s } from '@moonpig/launchpad-system'
import { styled, breakpointDown, breakpoint } from '@moonpig/launchpad-utils'
import { spacingPx } from '@moonpig/launchpad-theme'
import { IconSystemChevronRight as IconChevron } from '@moonpig/launchpad-assets'
import { useStoreId } from '@moonpig/web-core-stores'
import { useExperiment } from '@moonpig/web-core-experiments'
import { Box } from '@moonpig/launchpad-components'
import {
  NAV_BP,
  COLOR_KEYLINE,
  COLOR_LINK_HOVER,
  KEYLINE_SIZE_PX,
  ICON_SIZE_PX,
  COLOR_DEFAULT_TEXT,
  COLOR_FOCUS_INDICATOR,
} from '../constants'
import { DropdownProps, MegaNavItemProps } from './types'
import { NavLink } from '../NavLink'
import { Dropdown } from './Dropdown'
import {
  MegaNavAction,
  OPEN_DROPDOWN,
  useMegaNavContext,
} from './MegaNavContext'
import { useLocaleText } from '../locale'
import { NavigationIcon } from './NavigationIcon'
import { DropdownVariant, NavItemNode } from './DropdownVariant'
import { SEOHiddenNavItems } from './SEOHiddenNavItems'

type CommonPrimaryItemProps = {
  index: number
  buttonTabIndex: number
  onButtonKeyDown: (e: React.KeyboardEvent) => void
  onButtonFocus: (index: number) => void
  // shouldButtonFocus?: boolean
  openedNode: null | React.Ref<HTMLElement>
  primaryItemButtons: React.Ref<Array<HTMLButtonElement | HTMLAnchorElement>>
}

type PrimaryItemProps = CommonPrimaryItemProps & {
  onMouseEnter: (index: number) => void
  onMouseLeave: () => void
  item: MegaNavItemProps
  isDesktopNav?: boolean
}

type PrimaryLinkContainerProps = CommonPrimaryItemProps & {
  href: string
  label: string
  icon: string
  buttonAs?:
    | keyof JSX.IntrinsicElements
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | React.ComponentType<React.PropsWithChildren<any>>
  dispatch: (action: MegaNavAction) => void
  isDropdownOpen: boolean
  hasChildren: boolean
}

// const { useMegaNavContext, OPEN_DROPDOWN } = MegaNavContext

const StyledPrimaryLinkHitRegion = styled.span`
  display: none;

  &::before,
  &::after {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: -${KEYLINE_SIZE_PX};
    width: 1.875rem;
    content: ' ';
  }

  &::before {
    left: 0;
    transform: skewX(-45deg);
  }

  &::after {
    right: 0;
    transform: skewX(45deg);
  }
`

const StyledPrimaryLink = styled(NavLink)`
  position: relative;
  color: ${COLOR_DEFAULT_TEXT};

  &.primary-link {
    ${s({ typography: 'typeButtonLabel', px: 6, py: 5 })}
    width: 100%;
    outline: none;

    ${breakpoint(NAV_BP)} {
      padding: ${spacingPx(6)};

      &::before {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        height: ${KEYLINE_SIZE_PX};
        content: ' ';
      }
    }

    ${breakpointDown(NAV_BP)} {
      &.is-active > span {
        background-image: none;
      }
    }
  }
`

const StyledPrimaryLinkContainer = styled.div`
  position: relative;

  ${breakpointDown(NAV_BP)} {
    border-bottom: ${KEYLINE_SIZE_PX} solid ${COLOR_KEYLINE};
    cursor: pointer;
  }

  &:hover {
    color: ${COLOR_LINK_HOVER};
  }
`

const StyledPrimaryItem = styled.li<{ isActive: boolean }>`
  ${breakpoint(NAV_BP)} {
    &.is-active,
    &:focus,
    .focus-visible {
      outline: 2px solid ${COLOR_FOCUS_INDICATOR};
    }

    .focus-visible + a {
      color: ${COLOR_LINK_HOVER};
    }
  }

  &:hover {
    ${StyledPrimaryLinkHitRegion} {
      display: block;
    }
  }
`

const StyledPrimaryLinkButton = styled.button<{ isOpen: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: block;
  width: 100%;
  color: transparent;

  ${breakpointDown(NAV_BP)} {
    cursor: pointer;
  }

  ${breakpoint(NAV_BP)} {
    ${s({
      borderRadius: 1,
    })}
    z-index: -1;

    &:focus {
      z-index: 1;
    }
  }
`

const StyledChevron = styled(IconChevron)`
  position: absolute;
  top: 50%;
  right: 12px;
  width: ${ICON_SIZE_PX};
  height: ${ICON_SIZE_PX};
  transform: translateY(-50%);

  ${breakpoint(NAV_BP)} {
    display: none;
  }
`

const StyledLabel = styled.div`
  padding-left: ${spacingPx(10)};

  ${breakpoint(NAV_BP)} {
    padding-left: 0;
  }
`

const PrimaryLinkContainer: FC<
  React.PropsWithChildren<PrimaryLinkContainerProps>
> = memo<PrimaryLinkContainerProps>(
  ({
    index,
    isDropdownOpen,
    dispatch,
    label,
    icon,
    href,
    buttonAs,
    buttonTabIndex,
    onButtonKeyDown,
    onButtonFocus,
    openedNode,
    primaryItemButtons,
    hasChildren,
  }) => {
    const storeId = useStoreId()
    const showNavigationIcons = storeId === 'uk'
    const t = useLocaleText()

    const onFocus = () => {
      onButtonFocus(index)
    }

    const onClick = () => {
      if (openedNode) {
        /* eslint-disable no-param-reassign, @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        openedNode.current = primaryItemButtons.current[index]
        /* eslint-enable */
      }
      dispatch([
        OPEN_DROPDOWN,
        {
          index: index || 0,
          isOpenedByKeyboardOrClick: true,
        },
      ])
    }
    const ref = (node: HTMLButtonElement) => {
      if (primaryItemButtons) {
        /* eslint-disable no-param-reassign, @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        primaryItemButtons.current[index] = node
        /* eslint-enable */
      }
    }

    const anchorRef = (node: HTMLAnchorElement) => {
      if (primaryItemButtons) {
        /* eslint-disable no-param-reassign, @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        if (!primaryItemButtons.current[index])
          // @ts-ignore
          primaryItemButtons.current[index] = node
        /* eslint-enable */
      }
    }

    return (
      <StyledPrimaryLinkContainer>
        {hasChildren && (
          <StyledPrimaryLinkButton
            as={buttonAs}
            isOpen={false}
            // eslint-disable-next-line no-return-assign
            ref={ref}
            onFocus={onFocus}
            tabIndex={buttonTabIndex}
            onKeyDown={onButtonKeyDown}
            data-testid={`lp-nav-primary-button-${index}`}
            onClick={onClick}
          >
            <span>{t('mega_nav.primary_item', label)}</span>
          </StyledPrimaryLinkButton>
        )}
        <StyledPrimaryLink
          className="primary-link"
          href={href}
          ref={anchorRef}
          isActive={isDropdownOpen}
          onKeyDown={onButtonKeyDown}
          aria-hidden="true"
          data-testid={`lp-nav-primary-link-${index}`}
        >
          {showNavigationIcons && NavigationIcon({ icon, label })}
          <StyledPrimaryLinkHitRegion />
          {hasChildren && (
            <StyledChevron
              data-testid={`lp-nav-chevron-${index}`}
              aria-hidden
            />
          )}
          {showNavigationIcons ? <StyledLabel>{label}</StyledLabel> : label}
        </StyledPrimaryLink>
      </StyledPrimaryLinkContainer>
    )
  },
)

PrimaryLinkContainer.displayName = 'PrimaryLinkContainer'

const mapToNavItems = ({
  title,
  dropdown,
}: {
  title: string
  dropdown: DropdownProps[]
}): NavItemNode => {
  return {
    __typename: 'NODE',
    title,
    items: dropdown.map(dropdownItem => {
      if (dropdownItem.items && dropdownItem.items.length > 0) {
        return {
          __typename: 'NODE',
          title: dropdownItem.title,
          items: dropdownItem.items.map(item => ({
            __typename: 'LINK',
            title: item.title,
            url: item.href,
          })),
        }
      }

      return {
        __typename: 'LINK',
        title: dropdownItem.title,
        url: dropdownItem.href || '',
      }
    }),
  }
}

const WrappedPrimaryItem: FC<
  React.PropsWithChildren<
    PrimaryItemProps & {
      isDropdownOpen: boolean
      dispatch: (action: MegaNavAction) => void
    }
  >
> = memo(
  ({
    item: { href, title, icon, buttonAs, dropdown },
    onMouseEnter,
    onMouseLeave,
    index,
    dispatch,
    isDropdownOpen,
    onButtonKeyDown,
    primaryItemButtons,
    onButtonFocus,
    openedNode,
    isDesktopNav,
  }) => {
    const storeId = useStoreId()
    const isRedesignExperiment =
      useExperiment('explore-nav-redesign')?.toLowerCase() === 'enabled' &&
      ['uk', 'nl'].includes(storeId)

    const renderDropdownVariant = () =>
      isDropdownOpen ? (
        <DropdownVariant item={mapToNavItems({ title, dropdown })} />
      ) : (
        <Box display="none" aria-hidden>
          <SEOHiddenNavItems items={dropdown} />
        </Box>
      )

    const renderDropdown = () => (
      <Dropdown
        index={index}
        label={title}
        items={dropdown}
        href={href}
        buttonAs={buttonAs}
      />
    )

    const renderComponent = () => {
      if (dropdown && dropdown.length > 0) {
        return isRedesignExperiment && !isDesktopNav
          ? renderDropdownVariant()
          : renderDropdown()
      }

      return null
    }

    return (
      <StyledPrimaryItem
        onMouseEnter={() => onMouseEnter(index)}
        onMouseLeave={onMouseLeave}
        isActive={isDropdownOpen}
        aria-expanded={isDropdownOpen}
        data-testid={`lp-nav-primary-item-${index}`}
        aria-haspopup="true"
      >
        <PrimaryLinkContainer
          index={index}
          isDropdownOpen={isDropdownOpen}
          dispatch={dispatch}
          label={title}
          icon={icon}
          href={href}
          buttonAs={buttonAs}
          buttonTabIndex={0}
          onButtonKeyDown={onButtonKeyDown}
          primaryItemButtons={primaryItemButtons}
          onButtonFocus={onButtonFocus}
          openedNode={openedNode}
          hasChildren={dropdown && dropdown.length > 0}
        />
        {renderComponent()}
      </StyledPrimaryItem>
    )
  },
)

WrappedPrimaryItem.displayName = 'WrappedPrimaryItem'

export const PrimaryItem: FC<
  React.PropsWithChildren<PrimaryItemProps>
> = props => {
  const [state, dispatch] = useMegaNavContext()
  const { openDropdownIndex, isDesktopNav } = state
  // eslint-disable-next-line react/destructuring-assignment
  const isDropdownOpen = openDropdownIndex === props.index
  return (
    <WrappedPrimaryItem
      dispatch={dispatch}
      isDropdownOpen={isDropdownOpen}
      isDesktopNav={isDesktopNav}
      {...props}
    />
  )
}

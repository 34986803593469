import React, { FC } from 'react'
import { styled, breakpoint } from '@moonpig/launchpad-utils'
import { BrandLogo } from '@moonpig/launchpad-assets'
import { colorValue } from '@moonpig/launchpad-theme'
import { Link, Image, AspectRatio } from '@moonpig/launchpad-components'

const HEADER_LINK_HEIGHT = '48px'
const BREAKPOINT = 'xl'

const StyledLogo = styled(BrandLogo)`
  display: block;
  height: 32px;
  width: 100%;
  color: ${colorValue('colorPrimary01')};

  ${breakpoint(BREAKPOINT)} {
    height: 100%;
  }
`

const StyledLogoPostFix = styled.div`
  display: none;

  ${breakpoint(BREAKPOINT)} {
    height: 100%;
    display: block;
  }
`

const StyledLink = styled(Link)`
  display: flex;
  max-width: 128px;
  width: 25vw;
  height: 40px;
  align-items: center;

  ${breakpoint(BREAKPOINT)} {
    max-width: 200px;
    height: ${HEADER_LINK_HEIGHT};
    width: 100%;
  }
`

type LogoLinkProps = {
  href: string
  title: string
  /** Url for optional postfix icon */
  complementaryImageUrl?: string
}

export const LogoLink: FC<React.PropsWithChildren<LogoLinkProps>> = ({
  href,
  title,
  complementaryImageUrl,
}) => {
  return (
    <StyledLink
      title={title}
      href={href}
      data-testid="lp-nav-logo"
      aria-label={title}
    >
      <StyledLogo aria-hidden />
      {complementaryImageUrl && (
        <StyledLogoPostFix>
          <AspectRatio ratio={1} height={HEADER_LINK_HEIGHT} width="48px">
            <Image
              src={complementaryImageUrl}
              lazyLoad
              aria-hidden
              data-testid="logo-postfix-icon"
            />
          </AspectRatio>
        </StyledLogoPostFix>
      )}
    </StyledLink>
  )
}

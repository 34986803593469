import React, { FC } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import {
  Alert,
  Box,
  Heading,
  TertiaryButton,
} from '@moonpig/launchpad-components'
import { useOneTapLocaleText } from './GoogleOneTap.locale'

const AlertContainer = styled(Box)`
  position: fixed;
  z-index: 2001;
  top: 36px;
  width: 100vw;
  left: 50%;
  transform: translateX(-50%);
  max-width: 600px;
  margin: auto;
  pointer-events: all;
  ${s({
    paddingX: 4,
  })}

  animation: alert-fade-in 500ms;

  @keyframes alert-fade-in {
    from {
      bottom: 0;
      opacity: 0;
    }
    to {
      bottom: 30px;
      opacity: 1;
    }
  }
`

type GoogleOneTapErrorProps = {
  onDismiss: () => void
  onLinkClick: () => void
}

export const GoogleOneTapError: FC<GoogleOneTapErrorProps> = ({
  onDismiss,
  onLinkClick,
}) => {
  const localiseText = useOneTapLocaleText()

  return (
    <AlertContainer>
      <Alert
        variant="error"
        my={5}
        onClickClose={onDismiss}
        testId={'google-one-tap-error'}
      >
        <Heading
          level="h4"
          mb={0}
          typography={{
            xs: 'typeMobileDisplay05',
            md: 'typeDesktopDisplay06',
          }}
        >
          {localiseText('error_title')}
        </Heading>
        <TertiaryButton onClick={onLinkClick} inline>
          {localiseText('error_link_text')}
        </TertiaryButton>
      </Alert>
    </AlertContainer>
  )
}

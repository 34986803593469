import { getExperimentCampaigns } from './getExperimentCampaigns'

const getVariationPromise = async (
  params: { [key: string]: string },
  key: string,
  getVariation: (campaignKey: string) => Promise<string>,
  variationKey?: string,
): Promise<string> => {
  const result =
    (params[key] as string) || (await getVariation(variationKey || key))
  return result
}

export const getExperiments = async (
  params: { [key: string]: string },
  getVariation: (campaignName: string) => Promise<string>,
) => {
  const experimentCampaigns = getExperimentCampaigns()
  const experimentVariants = await Promise.all(
    experimentCampaigns.map(async campaignName =>
      getVariationPromise(params, campaignName, getVariation),
    ),
  )
  const experiments: { [key: string]: string } = {}

  experimentCampaigns.forEach((campaignName, index) => {
    experiments[campaignName] = experimentVariants[index]
  })

  return experiments
}

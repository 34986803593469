import React, { FC, createContext, useContext } from 'react'
import type { CoreFlagIDs } from './core-flags'
import { schema as coreSchema } from './brand-config'

type Flags<T extends string = string> = { [id in T]: boolean }

const FlagsContext = createContext<Flags | null>(null)

export const FlagsProvider: FC<React.PropsWithChildren<{ flags: Flags }>> = ({
  flags,
  children,
}) => {
  return <FlagsContext.Provider value={flags}>{children}</FlagsContext.Provider>
}

type FlagSchema<FlagIDs extends string> = {
  [id in FlagIDs]: { default: boolean }
}

export const createFlags = <LocalFlagIDs extends string>(
  localSchema: FlagSchema<LocalFlagIDs>,
): [
  (id: LocalFlagIDs | CoreFlagIDs) => boolean,
  () => { [id in LocalFlagIDs | CoreFlagIDs]: boolean },
  FlagSchema<LocalFlagIDs | CoreFlagIDs>,
] => {
  type AllFlagIDs = LocalFlagIDs | CoreFlagIDs
  type AllFlags = Flags<AllFlagIDs>

  const useFlag = (id: AllFlagIDs): boolean => {
    const context = useContext(FlagsContext)
    if (!context) {
      throw new Error('No FlagsProvider found.')
    }
    return context[id]
  }

  const useFlags = (): AllFlags => {
    const context = useContext(FlagsContext)
    if (!context) {
      throw new Error('No FlagsProvider found.')
    }
    return context
  }

  const schema = { ...coreSchema, ...localSchema }

  return [useFlag, useFlags, schema]
}

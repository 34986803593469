import React, { FC } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { ThemeInterface } from '@moonpig/launchpad-theme'
import { Flex, Text } from '@moonpig/launchpad-components'
import { DepartmentsEnum } from '@moonpig/web-core-types-graphql'
import { IconSystemSearch } from '@moonpig/launchpad-assets'
import { HighlightText } from '../HighlightText'
import { SuggestionItemType } from '../types'
import { LIST_ICON_SIZE_PX } from '../constants'

export type SuggestionType = {
  value: string
  searchKey?: string
  department: { enum: DepartmentsEnum; name: string; title: string }
  resultsCount?: number
  isMainSuggestion?: boolean
}

type StyledSuggestionProps = {
  highlighted?: boolean
  theme: ThemeInterface
}

const StyledSuggestion = styled.li.attrs<StyledSuggestionProps>(
  ({ highlighted, theme }) => ({
    style: {
      backgroundColor: highlighted
        ? theme.colors.colorBackground02
        : theme.colors.colorBackground01,
      borderRadius: '12px',
    },
  }),
)<StyledSuggestionProps>`
  ${s({ p: 3, alignItems: 'center', color: 'colorTextLabel' })}
  display: flex;
  cursor: pointer;

  &:hover,
  &:focus {
    ${s({ bgcolor: 'colorBackground02', borderRadius: '12px' })}
  }

  &:active {
    ${s({ bgcolor: 'colorBlack20' })}
  }
`

const StyledIconWrapper = styled(Flex)`
  ${s({
    bgcolor: 'colorBackground01',
    borderRadius: '8px',
    p: 4,
    mr: 4,
    color: 'colorInteractionControl',
  })}
`
const StyledText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`

type SuggestionProps = {
  item: {
    value: string
    searchKey?: string
    department: { name: string; title: string; enum: DepartmentsEnum }
    resultsCount?: number
    isMainSuggestion?: boolean
  }
  itemIndex: number
  value: string
  highlighted?: boolean
  getItemProps: (props: {
    itemIndex: number
    item: SuggestionItemType
  }) => Record<string, unknown>
} & React.HTMLProps<HTMLElement>

export const Suggestion: FC<React.PropsWithChildren<SuggestionProps>> = ({
  item,
  itemIndex,
  value,
  highlighted,
  getItemProps,
}) => (
  <StyledSuggestion
    highlighted={highlighted}
    {...getItemProps({
      itemIndex,
      item: { ...item, itemType: 'suggestion' },
    })}
    data-testid="nav-search-suggestion"
  >
    <StyledIconWrapper>
      <IconSystemSearch width={LIST_ICON_SIZE_PX} height={LIST_ICON_SIZE_PX} />
    </StyledIconWrapper>
    <StyledText>
      <HighlightText text={item.value} value={value} />
    </StyledText>
  </StyledSuggestion>
)

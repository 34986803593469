import React, {
  FC,
  useRef,
  useEffect,
  useContext,
  useCallback,
  memo,
} from 'react'
import {
  styled,
  breakpoint,
  breakpointDown,
  withTheme,
  debounce,
  breakpointUp,
} from '@moonpig/launchpad-utils'
import { ThemeInterface } from '@moonpig/launchpad-theme'
import { system as s } from '@moonpig/launchpad-system'
import { RemoveScrollBar } from 'react-remove-scroll-bar'
import { HeaderHeightContext } from '../HeaderWrapper'
import { MegaNavItemProps } from './types'
import { Toggle } from './Toggle'
import { Content } from './Content'
import { NAV_BP, TRANSITON_TIME_MS } from '../constants'
import {
  GET_WINDOW_HEIGHT,
  MegaNavAction,
  MegaNavProvider,
  SET_IS_DESKTOP,
  TOGGLE_MEGANAV,
  useMegaNavContext,
} from './MegaNavContext'
import { useLocaleText } from '../locale'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StyledMegaNavClose = styled<any>('button').attrs(({ isOpen }) => ({
  style: { opacity: isOpen ? 1 : 0 },
}))`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  outline: none;
  background-color: rgba(0, 0, 0, 0.25);
  transition: opacity ${TRANSITON_TIME_MS};

  ${breakpoint(NAV_BP)} {
    display: none;
  }
`

const StyledMegaNavInner = styled.div<{
  headerHeight: number
  isOpen: boolean
  heightOffset: number
  windowHeight: number
}>`
  ${s({ mb: { [NAV_BP]: -6 } })}

  ${breakpointDown(NAV_BP)} {
    position: absolute;
    top: ${({ headerHeight }) => headerHeight}px;
    left: -100vw;
    height: ${({ windowHeight, heightOffset }) => {
      const height = windowHeight - heightOffset
      return height < 0 ? 0 : height
    }}px;
    width: 100vw;
    overflow: hidden;
    transition-delay: ${TRANSITON_TIME_MS};
  }

  &.is-open {
    ${breakpointDown(NAV_BP)} {
      left: 0;
      transition-delay: 0ms;
    }
  }
`

const StyledMegaNav = styled.nav`
  position: static;

  ${breakpointUp('md')} {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
  }

  ${breakpointUp('xl')} {
    position: relative;
    display: flex;
    justify-content: start;
  }
`

type MegaNavProps = {
  items: MegaNavItemProps[]
  toggleAs?:
    | keyof JSX.IntrinsicElements
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | React.ComponentType<React.PropsWithChildren<any>>
}

type WrappedMegaNavComponentProps = MegaNavProps & {
  isOpen: boolean
  isDesktopNav: boolean
  dispatch: (action: MegaNavAction) => void
  theme: ThemeInterface
  dropdownHeightOffset: number
  windowHeight: number
}

const WrappedMegaNavComponent: FC<
  React.PropsWithChildren<WrappedMegaNavComponentProps>
> = memo<WrappedMegaNavComponentProps>(
  ({
    items,
    theme,
    isOpen,
    isDesktopNav,
    dispatch,
    toggleAs,
    dropdownHeightOffset,
    windowHeight,
  }) => {
    const headerHeight = useContext(HeaderHeightContext)
    const megaNavNode = useRef(
      null,
    ) as React.MutableRefObject<HTMLElement | null>

    const handleToggle = useCallback(() => {
      dispatch([TOGGLE_MEGANAV, 0])
    }, [dispatch])

    useEffect(() => {
      const isDesktop = window.matchMedia(
        `(min-width: ${theme.breakpoints.map[NAV_BP]}px)`,
      ).matches
      dispatch([SET_IS_DESKTOP, isDesktop])
    }, [dispatch, headerHeight, theme.breakpoints.map])

    useEffect(() => {
      const DEBOUNCE_INTERVAL_TIME_MS = 250
      const getWindowHeight = debounce(() => {
        dispatch([GET_WINDOW_HEIGHT, window.innerHeight])
      }, DEBOUNCE_INTERVAL_TIME_MS)
      getWindowHeight()

      window.addEventListener('resize', getWindowHeight)

      return () => {
        window.removeEventListener('resize', getWindowHeight)
      }
    }, [dispatch])
    const t = useLocaleText()

    return (
      <>
        {isOpen && <RemoveScrollBar />}
        <StyledMegaNav ref={megaNavNode} aria-label={t('mega_nav')}>
          <Toggle isOpen={isOpen} onClick={handleToggle} as={toggleAs} />
          <StyledMegaNavInner
            id="mega-nav-content"
            data-testid="lp-nav-meganav-inner"
            headerHeight={headerHeight}
            windowHeight={windowHeight}
            heightOffset={dropdownHeightOffset + headerHeight}
            isOpen={isOpen}
            className={isOpen && !isDesktopNav ? 'is-open' : ''}
          >
            <StyledMegaNavClose
              aria-hidden
              data-testid="lp-nav-meganav-close"
              onClick={() => dispatch([TOGGLE_MEGANAV])}
              isOpen={isOpen}
              tabIndex={-1}
              aria-label={t('mega_nav.close')}
            />
            <Content items={items} />
          </StyledMegaNavInner>
        </StyledMegaNav>
      </>
    )
  },
)

WrappedMegaNavComponent.displayName = 'WrappedMegaNavComponent'

const MegaNavComponent: FC<
  React.PropsWithChildren<
    MegaNavProps & {
      theme: ThemeInterface
    }
  >
> = props => {
  const [state, dispatch] = useMegaNavContext()
  const { isOpen, isDesktopNav, dropdownHeightOffset, windowHeight } = state

  return (
    <WrappedMegaNavComponent
      {...props}
      isOpen={Boolean(isOpen)}
      dispatch={dispatch}
      isDesktopNav={Boolean(isDesktopNav)}
      dropdownHeightOffset={dropdownHeightOffset}
      windowHeight={windowHeight}
    />
  )
}

export const MegaNav = (props: MegaNavProps) => {
  const NavWithTheme = withTheme(megaNavProps => (
    <MegaNavProvider>
      <MegaNavComponent {...megaNavProps} />
    </MegaNavProvider>
  ))

  return <NavWithTheme {...props} />
}

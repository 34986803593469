import { trackGAEvent } from '@moonpig/web-core-analytics'

export const trackRemindersSeen = (remindersSeen: number) =>
  trackGAEvent({
    event: 'impression',
    content_data: {
      content_type: 'reminders | view notifications | notifications',
    },

    event_data: {
      category: 'reminders',
      action: 'view notifications',
      label: `notifications | ${remindersSeen}`,
      non_interaction: true,
      value: 1,
    },

    discount_data: undefined,
    ecommerce: undefined,
    error_data: undefined,
  })

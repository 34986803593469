import React from 'react'
import { Flex, Box, ScreenReaderOnly } from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { getBrowserCookie } from '@moonpig/web-core-cookies'
import { SelectBox } from './components/SelectBox'
import { FLAG_COOKIE_PREFIX } from './constants'

const StyledBox = styled(Box)`
  ${s({
    py: 6,
    borderBottom: 1,
    borderColor: 'colorBlack10',
  })}
`

const StyledTitle = styled.p<{
  enabled: boolean
}>`
  ${({ enabled }) =>
    s({
      color: 'colorPrimary02',
      fontSize: { xs: '16px', md: '24px' },
      fontWeight: enabled ? '700' : '400',
      mb: 0,
    })};
`

const StyledInputContainer = styled(Flex)`
  display: inline-flex;
  align-items: center;
  ${s({
    mt: 5,
  })};

  &:not(:last-child) {
    ${s({
      mr: 5,
    })};
  }
`

const StyledLabel = styled.label`
  margin-right: 4px;

  ${s({
    fontSize: { xs: '16px', md: '24px' },
  })}
`

const StyledInput = styled.input`
  width: 24px;
  height: 24px;
`

const getFlagQueryValue = (id: string) => {
  const urlParams = new URLSearchParams(window.location.search)
  const flag = urlParams.get('flag')

  if (flag?.includes(id)) {
    return flag?.split(':')[1]
  }
}

export const FlagsListItem: React.FC<{
  id: string
  enabled: boolean
  handleEnabledChange: (id: string, value: string) => void
  handleCookieChange: (id: string, value: string) => void
}> = ({ id, enabled, handleEnabledChange, handleCookieChange }) => {
  const flag = getFlagQueryValue(id)
  const cookie = getBrowserCookie(`${FLAG_COOKIE_PREFIX}${id}`)
  const enabledId = `flag-enabled-${id}`
  const queryId = `flag-query-${id}`
  const cookieId = `flag-cookie-${id}`

  const getQuerySelectedValue = () => {
    if (flag === 'on') {
      return 'enabled'
    }
    if (flag === 'off') {
      return 'disabled'
    }

    return 'not set'
  }

  const getCookieSelectedValue = () => {
    if (cookie === '1') {
      return 'enabled'
    }
    if (cookie === '0') {
      return 'disabled'
    }

    return 'not set'
  }

  const toggleEnabled = (value: string) => {
    handleEnabledChange(id, value)
  }

  const toggleCookie = (value: string) => {
    handleCookieChange(id, value)
  }

  return (
    <StyledBox>
      <StyledTitle enabled={enabled}>{id}</StyledTitle>
      <Flex flexWrap="wrap">
        <StyledInputContainer>
          <StyledLabel htmlFor={enabledId}>
            <ScreenReaderOnly>{id} </ScreenReaderOnly>Enabled
          </StyledLabel>
          <StyledInput
            id={enabledId}
            type="checkbox"
            value={id}
            checked={enabled}
            disabled
          />
        </StyledInputContainer>
        <StyledInputContainer>
          <StyledLabel htmlFor={queryId}>
            <ScreenReaderOnly>{id} </ScreenReaderOnly>Query
          </StyledLabel>
          <SelectBox
            id={queryId}
            selectedValue={getQuerySelectedValue()}
            options={['enabled', 'disabled', 'not set']}
            onChange={toggleEnabled}
          />
        </StyledInputContainer>
        <StyledInputContainer>
          <StyledLabel htmlFor={cookieId}>
            <ScreenReaderOnly>{id} </ScreenReaderOnly>Cookie
          </StyledLabel>
          <SelectBox
            id={cookieId}
            selectedValue={getCookieSelectedValue()}
            options={['enabled', 'disabled', 'not set']}
            onChange={toggleCookie}
          />
        </StyledInputContainer>
      </Flex>
    </StyledBox>
  )
}

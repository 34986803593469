import { z } from 'zod'
import { eventDataSchema, remindersDataSchema } from '../common.schema'

export const UpdateReminderSchema = z.object({
  event: z.literal('update_reminder'),

  event_data: eventDataSchema,
  reminder_data: remindersDataSchema,
})

export type UpdateReminderEvent = z.infer<typeof UpdateReminderSchema>

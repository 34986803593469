import React, { FC, useMemo } from 'react'
import { useTaggEventStatus, useTaggExperience } from './hooks'
import { TaggstarContextProps, TaggstarContext } from './TaggstarContext'

type TaggstarProviderProps = {
  currentExperience: string
}

export const TaggstarProvider: FC<
  React.PropsWithChildren<TaggstarProviderProps>
> = ({ currentExperience, children }) => {
  const taggExperience = useTaggExperience(currentExperience)
  const canPublishEvent = useTaggEventStatus()

  const context: TaggstarContextProps = useMemo(
    (): TaggstarContextProps => ({
      taggExperience,
      canPublishEvent,
    }),
    [taggExperience, canPublishEvent],
  )

  return (
    <TaggstarContext.Provider value={context}>
      {children}
    </TaggstarContext.Provider>
  )
}

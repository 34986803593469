import React, { FC } from 'react'
import {
  Image,
  Text,
  PrimaryButton,
  Box,
  TextLink,
} from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { useLocaleText } from './locale'

type ReminderFormSignInProps = {
  src: string
  alt: string
  onClick: () => void
  createAccountLink: string
}

const StyledText = styled(Text)`
  ${s({
    typography: { xs: 'typeMobileDisplay03', md: 'typeDesktopDisplay05' },
  })}
`
const StyledImage = styled(Image)`
  max-width: 100%;
`

export const IncentivisedRemindersFormSignIn: FC<ReminderFormSignInProps> = ({
  src,
  alt,
  onClick,
  createAccountLink,
}) => {
  const t = useLocaleText()
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        my={8}
        px={10}
      >
        <StyledText textAlign="center">
          {t('reminder_form_signin.simply')}{' '}
          <strong>{t('reminder_form_signin.sign_in')}</strong>{' '}
          {t('reminder_form_signin.or')}{' '}
          <strong>{t('reminder_form_signin.create_an_account')}</strong>{' '}
          {t('reminder_form_signin.to_save_reminders')}
        </StyledText>
      </Box>
      <Box px={10}>
        <Box mb={8}>
          <StyledImage src={src} alt={alt} loading={'lazy'} />
        </Box>
        <PrimaryButton
          data-testid="signin-button"
          width="100%"
          mb={8}
          px={6}
          onClick={onClick}
        >
          {t('reminder_form_signin.sign_in_cta')}
        </PrimaryButton>
        <>
          <Text textAlign="center">
            {`${t('reminder_form_signin.new_here')}\u00A0`}
            <TextLink href={createAccountLink}>
              {t('reminder_form_signin.create_account_cta')}
            </TextLink>
          </Text>
        </>
      </Box>
    </>
  )
}

import React, { FC } from 'react'
import { UpcomingRemindersContent } from './UpcomingRemindersContent'
import { Reminder } from '../../../types'

type UpcomingRemindersProps = {
  reminders: Reminder[] | undefined
  loading: boolean
}

export const UpcomingReminders: FC<UpcomingRemindersProps> = ({
  reminders,
  loading,
}) => {
  return <UpcomingRemindersContent reminders={reminders} loading={loading} />
}

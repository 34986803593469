import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Alert, Heading, Modal, Text } from '@moonpig/launchpad-components'
import { useRemindersModalContext } from '@moonpig/web-core-context'
import { useLoggedIn } from '@moonpig/web-core-auth'
import { useRouter } from '@moonpig/web-core-routing'
import { StoreProviderBrand } from '@moonpig/web-core-stores'
import { styled } from '@moonpig/launchpad-utils'
import { Region } from '@moonpig/web-core-types'
import { ModalContent } from './ModalContent'
import { useLocaleText } from './locale'
import { clearDeferredSaving, isDeferredSaving } from '../../utils'
import { trackReminderSelectEvent } from '../../analytics'

const StyledAlert = styled(Alert)`
  width: 24em;
`

export const IncentivisedRemindersModal: React.FC = () => {
  const localiseFindText = useLocaleText()
  const modalContext = useRemindersModalContext()
  const hadRendered = useRef(false)
  const [reminderCount, setReminderCount] = useState(0)
  const [requiredRemindersCount, setRequiredRemindersCount] = useState<
    number | undefined
  >()
  const [showConfirmationAlert, setShowConfirmationAlert] =
    useState<boolean>(false)
  const [region, setRegion] = useState<Region>('' as Region)

  const router = useRouter()
  useEffect(() => {
    setRegion(window.location.pathname.split('/')[1] as Region)
  }, [router])

  const url = `/${region}/reminders/`

  const { loggedIn } = useLoggedIn()

  useEffect(() => {
    if (loggedIn && isDeferredSaving()) {
      modalContext.setShow(true)
    }

    if (modalContext.show) {
      hadRendered.current = true
      trackReminderSelectEvent({
        kind: 'INCENTIVISED_REMINDERS_MODAL_OPEN_CLICK',
      })
      closeConfirmationAlert()
    } else if (hadRendered.current) {
      trackReminderSelectEvent({
        kind: 'INCENTIVISED_REMINDERS_MODAL_CLOSE_CLICK',
        label: `reminder modal | reminder ${reminderCount}`,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn, modalContext])

  const closeConfirmationAlert = useCallback(() => {
    setShowConfirmationAlert(false)
  }, [])

  const leaveIncentivisedRemindersFlow = useCallback(() => {
    clearDeferredSaving()
    modalContext.setShow(false)
    setShowConfirmationAlert(false)
  }, [modalContext])

  const closeModal = () => {
    if (
      requiredRemindersCount !== undefined &&
      reminderCount < requiredRemindersCount &&
      !showConfirmationAlert
    ) {
      setShowConfirmationAlert(true)
    } else {
      setShowConfirmationAlert(false)
      leaveIncentivisedRemindersFlow()
    }
  }

  const onSetReminderCount = (count: number) => {
    setReminderCount(count)
    closeConfirmationAlert()
  }

  return (
    <StoreProviderBrand storeId={region}>
      <Modal
        label={localiseFindText('reminders.incentivised.modal.label')}
        isOpen={modalContext.show}
        onDismiss={closeModal}
        hasCloseButton
        closeButtonLabel={localiseFindText(
          'reminders.incentivised.modal.close',
        )}
      >
        {showConfirmationAlert && (
          <StyledAlert
            marginBottom={10}
            actions={[
              {
                onClick: leaveIncentivisedRemindersFlow,
                title: localiseFindText('reminders.incentivised.modal.leave'),
              },
              {
                onClick: closeConfirmationAlert,
                title: localiseFindText(
                  'reminders.incentivised.modal.continue',
                ),
              },
            ]}
            variant="warning"
          >
            <>
              <Heading
                level="h4"
                mb={0}
                typography={{
                  xs: 'typeMobileDisplay05',
                  md: 'typeDesktopDisplay06',
                }}
              >
                {localiseFindText(
                  'reminders.incentivised.modal.leaveFlow.alertTitle',
                )}
              </Heading>
              <Text as="p" m={0}>
                {localiseFindText(
                  'reminders.incentivised.modal.leaveFlow.alertBody',
                )}
              </Text>
            </>
          </StyledAlert>
        )}
        <ModalContent
          region={region}
          url={url}
          onError={leaveIncentivisedRemindersFlow}
          closeModal={closeModal}
          reminderCount={reminderCount}
          setReminderCount={onSetReminderCount}
          setRequiredRemindersCount={setRequiredRemindersCount}
        />
      </Modal>
    </StoreProviderBrand>
  )
}

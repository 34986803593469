import { z } from 'zod'
import {
  customFontDataSchema,
  eventDataSchema,
  mediaDataSchema,
} from '../common.schema'

export const MediaUpdateSchema = z.object({
  event: z.literal('media_update'),

  media_data: mediaDataSchema,
  event_data: eventDataSchema,
  custom_font_data: customFontDataSchema,
})

export type MediaUpdateEvent = z.infer<typeof MediaUpdateSchema>

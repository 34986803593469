let requestCache: { [key: string]: unknown } | undefined

export const clearRequestCache = () => {
  requestCache = undefined
}

export const getRequestCache = <T extends { [id: string]: unknown }>(
  key: string,
) => {
  if (!requestCache) {
    requestCache = {}
  }

  if (!requestCache[key]) {
    requestCache[key] = {}
  }

  return requestCache[key] as T
}

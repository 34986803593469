import { URLSearchParams } from 'url'

const trackingParamWriter = (
  path: string,
  trackingParams: { name: string; value: string | null }[],
) => {
  const pathParts = path.split('?')

  const urlParams =
    pathParts.length !== 2
      ? new URLSearchParams()
      : new URLSearchParams(pathParts[1])

  trackingParams.forEach(trackingParam => {
    urlParams.append(trackingParam.name, trackingParam.value || '')
  })

  const queryString = urlParams.toString()

  if (queryString) {
    return `${pathParts[0]}?${urlParams.toString()}`
  }

  return path
}

// eslint-disable-next-line import/no-default-export
export default trackingParamWriter

import { z } from 'zod'
import {
  basketDataSchema,
  ecommerceSchema,
  eventDataSchema,
  userDataSchema,
  zodAddressCountString,
  zodHyphenatedString,
  zodLowerCaseString,
  zodNumber,
} from '../common.schema'
import { refinedEcommerceItemSchema } from '../refinements/purchase-ecommerce.refine'

export const PurchaseSchema = z.object({
  event: z.literal('purchase'),

  user_data: userDataSchema
    .required()
    .extend({ address_count: zodAddressCountString.optional() }),
  basket_data: basketDataSchema.optional(),
  event_data: eventDataSchema.extend({
    label: zodHyphenatedString,
  }),

  ecommerce: ecommerceSchema.extend({
    transaction_id: zodLowerCaseString,
    shipping: zodNumber,
    items: z.array(refinedEcommerceItemSchema),
  }),
})

export type PurchaseEvent = z.infer<typeof PurchaseSchema>

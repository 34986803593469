import { IAssignmentEvent, offlineInit } from '@eppo/js-client-sdk'
import { trackExperiment } from '../trackExperiment/trackExperiment'
import { EppoConfiguration } from '../../types'

type CreateEppoClient = {
  flagsConfiguration: EppoConfiguration
}

const logAssignment = (assignment: IAssignmentEvent) => {
  trackExperiment(assignment)
}

export const createEppoClient = ({ flagsConfiguration }: CreateEppoClient) => {
  if (!flagsConfiguration) return null

  return offlineInit({
    flagsConfiguration,
    isObfuscated: false,
    assignmentLogger: { logAssignment },
  })
}

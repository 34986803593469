import { gql } from '@moonpig/web-core-graphql'

export const CreateReminderGQL = gql`
  mutation CreateReminderCommonLoyalty($input: ReminderInput!) {
    createReminder(input: $input) {
      ... on CreatedReminder {
        id
      }
      ... on ReminderAlreadyExists {
        message
      }
      ... on BadRequest {
        message
      }
    }
  }
`

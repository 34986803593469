import { LocaleTextHook } from '@moonpig/web-core-locale-text'
import { DATE_TEXT } from '../../localisation'

export const defaultSelectValue = {
  value: '',
  label: '',
}

export const formattedMonths = (
  localiseText: LocaleTextHook<typeof DATE_TEXT>,
) => [
  { value: '', label: '' },
  { value: '1', label: localiseText('common.dates.january') },
  { value: '2', label: localiseText('common.dates.february') },
  { value: '3', label: localiseText('common.dates.march') },
  { value: '4', label: localiseText('common.dates.april') },
  { value: '5', label: localiseText('common.dates.may') },
  { value: '6', label: localiseText('common.dates.june') },
  { value: '7', label: localiseText('common.dates.july') },
  { value: '8', label: localiseText('common.dates.august') },
  { value: '9', label: localiseText('common.dates.september') },
  { value: '10', label: localiseText('common.dates.october') },
  { value: '11', label: localiseText('common.dates.november') },
  { value: '12', label: localiseText('common.dates.december') },
]

export const getDaysInMonth = (month: number | undefined) => {
  if (!month) return 31
  const daysInMonth: Record<string, number> = {
    '1': 31,
    '2': 28,
    '3': 31,
    '4': 30,
    '5': 31,
    '6': 30,
    '7': 31,
    '8': 31,
    '9': 30,
    '10': 31,
    '11': 30,
    '12': 31,
  }

  return daysInMonth[month]
}

export const getDays = (month: number | undefined) => {
  const days = [...Array(getDaysInMonth(month)).keys()].map(day => ({
    value: `${day + 1}`,
    label: `${day + 1}`,
  }))

  return [defaultSelectValue, ...days]
}

import { createLocaleTextHook } from '@moonpig/web-core-locale-text'

const dictionary = {
  'reminders.landing_page.loading': {
    'en-GB': 'Loading',
    'nl-NL': 'Aan het laden',
  },
  'reminders.incentivised.modal.label': {
    'en-GB': 'Reminders Modal',
    'nl-NL': 'Herinneringenvenster',
  },
  'reminders.incentivised.modal.close': {
    'en-GB': 'Close',
    'nl-NL': 'Sluiten',
  },
  'reminders.incentivised.modal.leave': {
    'en-GB': 'Leave',
    'nl-NL': 'Stoppen',
  },
  'reminders.incentivised.modal.continue': {
    'en-GB': 'Continue',
    'nl-NL': 'Doorgaan',
  },
  'reminders.incentivised.modal.leaveFlow.alertTitle': {
    'en-GB': 'Are you sure you want to leave?',
    'nl-NL': 'Weet je zeker dat je wilt stoppen?',
  },
  'reminders.incentivised.modal.leaveFlow.alertBody': {
    'en-GB': 'You need to setup all 3 reminders to get the offer. 😭',
    'nl-NL':
      'Zet alle drie de momenten in je Greetz-kalender om deze aanbieding te krijgen. 😭',
  },
}

export const useLocaleText = createLocaleTextHook({
  dictionary,
})

import React from 'react'
import { ErrorPage } from './ErrorPage'

type ErrorBoundaryProps = {
  hasError: boolean
}

export class ErrorBoundary extends React.Component<
  Record<string, unknown>,
  ErrorBoundaryProps
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  render(): React.ReactNode {
    const { hasError } = this.state
    const { children } = this.props

    if (hasError) {
      return <ErrorPage />
    }

    return children as React.ReactNode
  }
}

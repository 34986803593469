import { Operation } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { ServerError } from '@apollo/client/link/utils'
import { print } from 'graphql'
import { logger } from '@moonpig/web-core-monitoring'

const containsMutation = (operation: Operation): boolean =>
  !!operation.query.definitions.find(
    d => d.kind === 'OperationDefinition' && d.operation === 'mutation',
  )

const toArray = <T>(a: T | readonly T[]): T[] =>
  Array.isArray(a) ? a : ([a] as T[])

export const createErrorLink = () =>
  onError(({ graphQLErrors, networkError, operation }) => {
    const query = print(operation.query)
    const loggableVars = containsMutation(operation) ? {} : operation.variables
    if (networkError) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { statusCode } = networkError as ServerError
      logger.warning(
        'Network error',
        {
          status_code: statusCode,
          graphql_query: query,
          graphql_operation_variables: JSON.stringify(loggableVars),
        },
        networkError,
      )
    }

    if (graphQLErrors) {
      graphQLErrors.forEach(({ message: msg, locations, path, extensions }) => {
        const isPersistedQueryNotFound =
          extensions && extensions.code === 'PERSISTED_QUERY_NOT_FOUND'

        const pathStr = toArray(path).join('/')
        if (!isPersistedQueryNotFound) {
          logger.warning(`GQL error: ${pathStr}`, {
            graphql_error_path: pathStr,
            graphql_error_message: msg,
            graphql_locations: toArray(locations || [])
              .map(location => JSON.stringify(location))
              .join(', '),
            graphql_operation_name: operation.operationName,
            graphql_operation_variables: JSON.stringify(loggableVars),
            graphql_query: query,
          })
        }
      })
    }
  })

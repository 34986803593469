import { useEffect } from 'react'

let pendingLuxTracking = false

export const useLuxLabel = (label: string) => {
  useEffect(() => {
    /* istanbul ignore next */
    window.LUX = window.LUX || {}
    window.LUX.label = label
  }, [label])
}

export const triggerLuxInit = () => {
  if (!pendingLuxTracking && window.LUX && window.LUX.init) {
    window.LUX.init()
    pendingLuxTracking = true
  }
}

const triggerLuxSend = () => {
  if (pendingLuxTracking && window.LUX && window.LUX.send) {
    window.LUX.send()
    pendingLuxTracking = false
  }
}

export const useLuxMeasure = (clientSideRoutingEnabled: boolean) => {
  useEffect(() => {
    /* istanbul ignore next */
    if (window.LUX && window.LUX.measure) {
      if (clientSideRoutingEnabled) {
        triggerLuxSend()
      } else {
        window.LUX.measure('componentMountTime')
      }
    }
  }, [clientSideRoutingEnabled])
}

export const useLuxAddData = (
  abTests: { name: string; value: string | undefined }[],
) => {
  useEffect(() => {
    abTests.forEach(test => {
      /* istanbul ignore next */
      if (window.LUX && window.LUX.addData && test.value) {
        window.LUX.addData(test.name, test.value)
      }
    })
  }, [abTests])
}

import { createLocaleTextHook } from '@moonpig/web-core-locale-text'

const dictionary = {
  login_expired_title: {
    'en-GB': 'Your session has expired',
    'nl-NL': 'Je sessie is verlopen',
  },
  login_expired_message: {
    'en-GB': 'Please sign in to continue.',
    'nl-NL': 'Log in om verder te gaan.',
  },
  sign_in: {
    'en-GB': 'Sign In',
    'nl-NL': 'Inloggen',
  },
}

export const useLocaleText = createLocaleTextHook({
  dictionary,
})

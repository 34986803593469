import React, { FC } from 'react'
import { styled, breakpointUp } from '@moonpig/launchpad-utils'
import { IconSystemArrowBack } from '@moonpig/launchpad-assets'
import { system as s } from '@moonpig/launchpad-system'
import { sharedButtonStyles } from './sharedButtonStyles'
import { BACK_BUTTON_ICON_SIZE_PX } from '../constants'
import { useLocaleText } from '../locale'

const StyledBackButton = styled.button`
  ${sharedButtonStyles}
  z-index: 2;
  ${s({
    paddingX: 4,
  })}

  ${breakpointUp('md')} {
    display: none;
  }
`

type BackButtonProps = {
  onClick: () => void
} & React.HTMLProps<HTMLInputElement>

export const BackButton: FC<React.PropsWithChildren<BackButtonProps>> = ({
  onClick,
}) => {
  const t = useLocaleText()

  return (
    <StyledBackButton
      data-testid="lp-nav-search-back-button"
      aria-label={t('search.close_search')}
      type="button"
      onClick={onClick}
    >
      <IconSystemArrowBack
        width={BACK_BUTTON_ICON_SIZE_PX}
        height={BACK_BUTTON_ICON_SIZE_PX}
      />
    </StyledBackButton>
  )
}

import React, { FC, useEffect } from 'react'
import { trackGAEvent } from '@moonpig/web-core-analytics'
import { ONE_TRUST_DOMAIN_SCRIPT_ID } from '@moonpig/web-core-brand/config'
import { useCoreFlag } from '@moonpig/web-core-flags'

type OneTrustCookieBannerProps = {
  environmentName: string | undefined
}

export const OneTrustCookieBanner: FC<OneTrustCookieBannerProps> = ({
  environmentName,
}) => {
  const enableThirdPartyScripts = useCoreFlag('third-party-scripts')

  useEffect(() => {
    trackGAEvent({
      event: 'cookie_consent',
      consent_data: {
        modal_name: 'NonDismissible',
      },
    })
  }, [])

  if (!enableThirdPartyScripts) {
    return null
  }

  return (
    <>
      <script
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        type="text/javascript"
        // eslint-disable-next-line react/no-unknown-property
        charSet="UTF-8"
        data-domain-script={`${ONE_TRUST_DOMAIN_SCRIPT_ID}${
          environmentName === 'local' ? '-test' : ''
        }`}
        data-testid={
          environmentName === 'local'
            ? 'one-trust-cookie-banner-local'
            : 'one-trust-cookie-banner'
        }
      />
      <script type="text/javascript">
        {`function OptanonWrapper() {
      document.activeElement.blur()
    }`}
      </script>
    </>
  )
}

import { z } from 'zod'

// due to a bug in GTM we are forced to explicitly set the whole set of data objects to either `object` or `undefined`
// to prevent previous values from bleeding into subsequent events in the datalayer
export const transformEventPayload = (data: Record<string, unknown>) => ({
  ...data,
  event: data.event,
  ecommerce: data.ecommerce,
  application_data: data.application_data,
  basket_data: data.basket_data,
  content_data: data.content_data,
  discount_data: data.discount_data,
  error_data: data.error_data,
  event_data: data.event_data,
  filter_data: data.filter_data,
  product_data: data.product_data,
  reminder_data: data.reminder_data,
  results_data: data.results_data,
  screen_type: data.screen_type,
  screen_name: data.screen_name,
  screen_data: data.screen_data,
  user_data: data.user_data,
})

const zodString = z.string().max(500)
const zodBoolean = z.boolean()
export const zodNumber = z.number().min(0)

export const zodHyphenatedString = zodString.regex(/^[a-z0-9 -]+$/, {
  message:
    'Value must be lowercase. Only hyphens are allowed as special characters',
})

export const zodLowerCaseString = zodString.regex(/^[a-z0-9 ]+$/, {
  message: 'Value must be lowercase and should not contain special characters',
})

const zodCurrencyEnum = z.enum(['AUD', 'GBP', 'EUR', 'USD'])
export const zodLoginMethodEnum = z.enum([
  'email',
  'google',
  'apple',
  'google one tap',
  'password',
  'magic link',
])

export const zodCustomerTypeEnum = z.enum([
  'prospect',
  'new customer',
  'existing customer',
])
export const zodEmailString = zodString.regex(/^[a-z0-9]+$/, {
  message:
    '`email` field value should be hashed and cannot contain any special characters!',
})

const zodDelimitedLowerCaseString = zodString
  .regex(/^[a-z0-9| ]+$/, {
    message: 'Value must be lowercase and only ` | ` is allowed as a delimiter',
  })
  .regex(/^[^|]+( \| [^|]+)*$/, {
    message:
      'Value must contain ` | ` as a delimiter between values, with no trailing delimiter',
  })

export const zodAddressCountString = zodDelimitedLowerCaseString.refine(
  /* istanbul ignore next */ data => {
    return /^(\d+ ?\| ?\d+)$/.test(data)
  },
)

export const eventDataSchema = z.object({
  action: zodLowerCaseString,
  category: zodLowerCaseString,
  label: zodLowerCaseString,
  non_interaction: z.literal(true).optional(),
  value: zodNumber.optional(),
})

export const applicationDataSchema = z.object({
  datalayer_version: zodLowerCaseString,
})

export const basketDataSchema = z.object({
  id: zodLowerCaseString,
  size: zodNumber.optional(),
  update_initiator: z
    .enum(['delete', 'duplicate', 'quantity', 'size', 'add to cart'])
    .optional(),
})

export const contentDataSchema = z.object({
  content_type: zodDelimitedLowerCaseString,
  item_id: zodLowerCaseString.optional(),
})

export const errorDataSchema = z.object({
  id: zodLowerCaseString.optional(),
  message: zodLowerCaseString,
})

export const filterDataSchema = z.object({
  filter_name: zodLowerCaseString,
  show_rude_products: zodBoolean.optional(),
  number_of_active_filters: zodNumber.optional(),
  last_filter_interaction: zodLowerCaseString.optional(),
})

export const productDataSchema = z
  .object({
    bundle_id: zodDelimitedLowerCaseString,
    design_id: zodLowerCaseString,
    group_card_id: zodLowerCaseString,
    product_brand: zodLowerCaseString,
    product_category: zodLowerCaseString,
    product_id: zodLowerCaseString,
    product_orientation: zodLowerCaseString,
    product_name: zodLowerCaseString,
    product_price: zodNumber,
    product_quantity: zodNumber,
    product_variant: zodLowerCaseString,
    project_id: zodLowerCaseString,
  })
  .partial()

export const remindersDataSchema = z
  .object({
    days_until: zodNumber,
    filter: zodLowerCaseString,
    index: zodNumber,
    origin: zodLowerCaseString,
    relation: zodLowerCaseString,
  })
  .partial()
  .extend({
    occasion: zodLowerCaseString,
  })

export const resultsDataSchema = z
  .object({
    corrected_search_term: zodLowerCaseString,
    index: zodNumber,
    input_type: z.enum(['facet suggestion', 'keyword']),
    number_of_results: zodNumber,
    suggestion_type: z.enum(['navigational', 'trending', 'popular']),
    sort_by: z.enum(['newness', 'popularity']),
  })
  .partial()
  .extend({
    product_category: z.enum(['cards', 'gifts', 'flowers']),
    results_list: zodLowerCaseString,
  })

export const screenDataSchema = z.object({
  document_referrer: zodLowerCaseString.optional(),
  document_title: zodLowerCaseString.optional(),
  document_url: zodLowerCaseString.optional(),
  render_type: z.enum(['client', 'server']),
})

export const userDataSchema = z
  .object({
    address_count: zodAddressCountString,
    customer_id: zodHyphenatedString,
    customer_type: zodCustomerTypeEnum,
    email: zodEmailString,
    is_logged_in: zodBoolean,
    lifetime_order_count: zodNumber,
    login_method: zodLoginMethodEnum,
  })
  .partial()
  .extend({
    is_logged_in: zodBoolean,
  })

export const discountDataSchema = z
  .object({
    name: zodLowerCaseString,
    type: zodLowerCaseString.optional(),
    value: zodNumber.optional(),
    currency: zodCurrencyEnum.optional(),
  })
  .refine(
    /* istanbul ignore next */ data => {
      const bothPresent =
        data.currency !== undefined && data.value !== undefined
      const bothAbsent = data.currency === undefined && data.value === undefined

      return bothPresent || bothAbsent
    },
    {
      message:
        'Both value and currency must be either both present or both absent',
      path: ['currency', 'value'],
    },
  )

export const ecommerceItemSchema = z
  .object({
    coupon: zodLowerCaseString,
    currency: zodCurrencyEnum,
    discount: zodNumber,
    index: zodNumber,
    is_sponsored: zodBoolean,
    item_brand: zodLowerCaseString,
    item_category: zodLowerCaseString,
    item_category2: zodLowerCaseString,
    item_category3: zodLowerCaseString,
    item_category4: zodLowerCaseString,
    item_category5: zodLowerCaseString,
    item_id: zodLowerCaseString,
    item_list_id: zodDelimitedLowerCaseString,
    item_list_name: zodDelimitedLowerCaseString,
    item_name: zodLowerCaseString,
    item_variant: zodLowerCaseString,
    price: zodNumber,
    quantity: zodNumber,
    postage_option: zodLowerCaseString,
    recipient_type: zodLowerCaseString,
  })
  .partial()
  .refine(
    data =>
      Boolean(
        typeof data.item_id === 'string' || typeof data.item_name === 'string',
      ),
    {
      message: 'Provide either `item_id` or `item_name`',
    },
  )

export const ecommerceSchema = z
  .object({
    coupon: zodLowerCaseString,
    discount: zodNumber,
    payment_type: zodLowerCaseString,
    saved_payment: zodBoolean,
    shipping: zodNumber,
    shipping_tier: zodLowerCaseString,
    shipping_type: zodLowerCaseString,
    tax: zodNumber,
    transaction_id: zodLowerCaseString,
    value: zodNumber,
    items: z.array(ecommerceItemSchema),
  })
  .partial()
  .extend({
    currency: zodCurrencyEnum,
    value: zodNumber,
  })

export const mediaDataSchema = z
  .object({
    media_duration: zodNumber,
    media_milestone: zodNumber,
    media_provider: zodLowerCaseString,
    media_status: zodString,
    media_title: zodLowerCaseString,
    media_type: zodLowerCaseString,
    media_url: zodString,
  })
  .partial()

export const cookieConsentDataSchema = z.object({
  functional: zodBoolean,
  performance: zodBoolean,
  strictly_necessary: zodBoolean,
  targeting: zodBoolean,
})

export const marketingDataSchema = z.object({
  advertising_id_enabled: zodBoolean,
  email: zodBoolean,
  fathers_day: zodBoolean,
  general: zodBoolean,
  group_cards: zodBoolean,
  mothers_day: zodBoolean,
  notifications: zodBoolean,
  onboarding: zodBoolean,
  orders: zodBoolean,
  reminders: zodBoolean,
})

export const customFontDataSchema = z.object({
  character_set: zodLowerCaseString,
  character_count: zodLowerCaseString,
  language: zodLowerCaseString,
  pagination: zodString,
})

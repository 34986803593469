import React from 'react'
import {
  Box,
  Flex,
  Heading,
  Image,
  PrimaryButton,
  SecondaryButton,
  Text,
} from '@moonpig/launchpad-components'
import { Region } from '@moonpig/web-core-types'
import { useLocaleText } from './locale'

type ReminderSuccessProps = {
  endTitle: string
  endText: string
  endImageUrl: string
  closeModal?: () => void
  region: Region
}

export const IncentivisedRemindersSuccess: React.FC<ReminderSuccessProps> = ({
  endTitle,
  endText,
  endImageUrl,
  closeModal,
  region,
}) => {
  const localiseFindText = useLocaleText()

  return (
    <>
      <Heading level="h2" as="h1">
        {endTitle}
      </Heading>
      <Text>{endText}</Text>
      <Flex justifyContent="center" mb={8} my={12}>
        <Image
          src={endImageUrl}
          alt={localiseFindText('reminders.incentivised.form.end_image_alt')}
          style={{ maxWidth: '300px' }}
        />
      </Flex>
      <Box>
        <PrimaryButton
          onClick={closeModal}
          width="100%"
          mb={6}
          href={`/${region}/`}
        >
          {localiseFindText('reminders.incentivised.form.shop_for_cards')}
        </PrimaryButton>
        <SecondaryButton href={`/${region}/account/reminders/`} width="100%">
          {localiseFindText('reminders.incentivised.form.view_your_reminders')}
        </SecondaryButton>
      </Box>
    </>
  )
}

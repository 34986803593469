import { z } from 'zod'
import { eventDataSchema } from '../common.schema'

export const SignUpSchema = z.object({
  event: z.literal('sign_up'),

  event_data: eventDataSchema,
})

export type SignUpEvent = z.infer<typeof SignUpSchema>

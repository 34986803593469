import {
  Occasion,
  Relationship,
} from '@moonpig/web-shared-types-graphql/graphqlTypes'

export const OccasionsLocale: Record<
  string,
  { 'en-GB': string; 'nl-NL': string; facetValue: string }
> = {
  [Occasion.IN_REMEMBRANCE]: {
    'en-GB': 'In Remembrance',
    'nl-NL': 'In memoriam',
    facetValue: 'in remembrance',
  },
  [Occasion.ANNIVERSARY]: {
    'en-GB': 'Anniversary',
    'nl-NL': 'Jubileum',
    facetValue: 'anniversary',
  },
  [Occasion.CHRISTMAS]: {
    'en-GB': 'Christmas',
    'nl-NL': 'Kerstmis',
    facetValue: 'christmas',
  },
  [Occasion.LOVE]: { 'en-GB': 'Love', 'nl-NL': 'Liefde', facetValue: 'love' },
  [Occasion.MOTHERS_DAY]: {
    'en-GB': "Mother's Day",
    'nl-NL': 'Moederdag',
    facetValue: "mother's day",
  },
  [Occasion.SAINT_NICHOLAS]: {
    'en-GB': 'Saint Nicholas',
    'nl-NL': 'Sinterklaas',
    facetValue: 'saint nicholas',
  },
  [Occasion.SPECIAL_OCCASION]: {
    'en-GB': 'Special Occasion',
    'nl-NL': 'Speciaal moment',
    facetValue: 'special occasion',
  },
  [Occasion.WEDDING]: {
    'en-GB': 'Wedding',
    'nl-NL': 'Trouwdag',
    facetValue: 'wedding',
  },
  [Occasion.FATHERS_DAY]: {
    'en-GB': "Father's Day",
    'nl-NL': 'Vaderdag',
    facetValue: "father's day",
  },
  [Occasion.BIRTHDAY]: {
    'en-GB': 'Birthday',
    'nl-NL': 'Verjaardag',
    facetValue: 'birthday',
  },
  [Occasion.JUST_A_NOTE]: {
    'en-GB': 'Just a note',
    'nl-NL': 'Zomaar',
    facetValue: 'just a note',
  },
  [Occasion.NEW_BABY]: {
    'en-GB': 'New Baby',
    'nl-NL': 'Geboorte',
    facetValue: 'new baby',
  },
  [Occasion.VALENTINES_DAY]: {
    'en-GB': "Valentine's Day",
    'nl-NL': 'Valentijnsdag',
    facetValue: "valentine's day",
  },
}

export const RelationshipsLocale: Record<
  string,
  { 'en-GB': string; 'nl-NL': string }
> = {
  [Relationship.AUNTIE]: { 'en-GB': 'Auntie', 'nl-NL': 'Tante' },
  [Relationship.BOYFRIEND]: {
    'en-GB': 'Boyfriend',
    'nl-NL': 'Vriend (partner)',
  },
  [Relationship.BROTHER]: { 'en-GB': 'Brother', 'nl-NL': 'Broer' },
  [Relationship.DAD]: { 'en-GB': 'Dad', 'nl-NL': 'Vader' },
  [Relationship.DAUGHTER]: { 'en-GB': 'Daughter', 'nl-NL': 'Dochter' },
  [Relationship.FEMALE_FRIEND]: {
    'en-GB': 'Female - Friend',
    'nl-NL': 'Vriendin',
  },
  [Relationship.GIRLFRIEND]: {
    'en-GB': 'Girlfriend',
    'nl-NL': 'Vriendin (partner)',
  },
  [Relationship.GRANDAD]: { 'en-GB': 'Grandad', 'nl-NL': 'Opa' },
  [Relationship.GRANDDAUGHTER]: {
    'en-GB': 'Granddaughter',
    'nl-NL': 'Kleindochter',
  },
  [Relationship.GRANDSON]: { 'en-GB': 'Grandson', 'nl-NL': 'Kleinzoon' },
  [Relationship.GRANNY]: { 'en-GB': 'Granny', 'nl-NL': 'Oma' },
  [Relationship.HUSBAND]: { 'en-GB': 'Husband', 'nl-NL': 'Echtgenoot' },
  [Relationship.MALE_FRIEND]: {
    'en-GB': 'Male - Friend',
    'nl-NL': 'Vriend',
  },
  [Relationship.MUM]: { 'en-GB': 'Mum', 'nl-NL': 'Moeder' },
  [Relationship.UNCLE_NEPHEW]: { 'en-GB': 'Nephew', 'nl-NL': 'Neef' },
  [Relationship.NIECE]: { 'en-GB': 'Niece', 'nl-NL': 'Nicht' },
  [Relationship.SISTER]: { 'en-GB': 'Sister', 'nl-NL': 'Zus' },
  [Relationship.SON]: { 'en-GB': 'Son', 'nl-NL': 'Zoon' },
  [Relationship.UNCLE]: { 'en-GB': 'Uncle', 'nl-NL': 'Oom' },
  [Relationship.WIFE]: { 'en-GB': 'Wife', 'nl-NL': 'Echtgenote' },
}

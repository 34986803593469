import { ApolloLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

import { Headers } from '../types'

export const createContextLink = ({
  headers,
}: {
  headers: Headers
}): ApolloLink =>
  setContext(() => ({
    headers,
  }))

import { Sha256 } from '@aws-crypto/sha256-universal'

const toHex = (v: number) => {
  return `0${v.toString(16)}`.slice(-2)
}

export const createSha256Hash = async (str: string) => {
  const sha256 = new Sha256()
  sha256.update(str)
  const d = await sha256.digest()
  const hash = Array.from(d).map(toHex).join('')
  return hash
}

import { z } from 'zod'
import {
  contentDataSchema,
  errorDataSchema,
  eventDataSchema,
} from '../common.schema'

export const MediaUploadFailureSchema = z.object({
  event: z.literal('media_upload_failure'),

  content_data: contentDataSchema,
  event_data: eventDataSchema,
  error_data: errorDataSchema,
})

export type MediaUploadFailureEvent = z.infer<typeof MediaUploadFailureSchema>

import { z } from 'zod'
import { contentDataSchema, eventDataSchema } from '../common.schema'

export const GenericEventSchema = z.object({
  event: z.literal('generic_event'),

  content_data: contentDataSchema,
  event_data: eventDataSchema,
})

export type GenericEvent = z.infer<typeof GenericEventSchema>

import { sessionStore } from '@moonpig/web-core-utils'
import {
  Occasion,
  Relationship,
  Source,
  Store,
} from '@moonpig/web-shared-types-graphql/graphqlTypes'
import { ReminderInput } from '../../types'

type SessionReminderInput = {
  name: string
  occasionDate: { day: number; month: number }
  daysBeforeOccasionToRemind?: number
  occasion: Occasion
  relationship?: Relationship | null
  source: Source
  store?: Store
}

export const getStoredReminders = (): SessionReminderInput[] => {
  try {
    const reminders = sessionStore.getItem('reminders')
    if (!reminders) {
      return []
    }
    return reminders
  } catch /* istanbul ignore next */ {
    return []
  }
}

const setStoredReminders = (reminders: SessionReminderInput[]) => {
  sessionStore.setItem('reminders', reminders)
}

export const clearStoredReminders = () => {
  sessionStore.removeItem('reminders')
  sessionStore.removeItem('reminders_deferred')
}

export const isDeferredSaving = () =>
  !!sessionStore.getItem('reminders_deferred')

export const setDeferredSaving = () => {
  sessionStore.setItem('reminders_deferred', 'true')
}

export const clearDeferredSaving = () =>
  sessionStore.removeItem('reminders_deferred')

export const storeReminderInSessionStorage = (values: ReminderInput) => {
  const { name, occasionDate, occasion, relationship } = values
  const reminder: SessionReminderInput = {
    name,
    occasionDate,
    occasion,
    relationship,
    source: Source.WEB_INCENTIVISED_REMINDER_MODAL,
    daysBeforeOccasionToRemind: 7,
  }

  const reminders = getStoredReminders()
  reminders.push(reminder)
  setStoredReminders(reminders)
}

import { getRequestCache } from '@moonpig/web-core-utils'
import { Fetch, RequestInfo, Response, RequestInitWithDedupe } from '../types'

const CORE_FETCH_DEDUPE_PENDING_KEY = 'core-fetch-dedupe-pending'

export const dedupeFetch = async (
  fetchImpl: Fetch,
  info: RequestInfo,
  init?: RequestInitWithDedupe,
  hashInput?: { [id: string]: string | number | boolean },
): Promise<Response> => {
  const pending = getRequestCache<{
    [key: string]: Promise<Response> | undefined
  }>(CORE_FETCH_DEDUPE_PENDING_KEY)

  const hashObj = {
    info,
    init,
    hashInput,
  }
  const hash = JSON.stringify(hashObj)

  const inflight = pending[hash]
  if (inflight) {
    return inflight
  }

  const promise = fetchImpl(info, init)
  promise.finally(() => {
    pending[hash] = undefined
  })
  pending[hash] = promise

  return promise
}

import NextRouter from 'next/router'
import { createBuild } from './router-build'
import { createMatch } from './router-match'
import { RouteApp, Router, Routes, RoutesValues } from './types'

export const createRouter = <TRoutes extends Routes>(
  routes: TRoutes,
): Router<TRoutes> => {
  let path: string | null = null

  const setPath: Router<TRoutes>['setPath'] = newPath => {
    path = newPath
  }

  const build = createBuild(routes)

  const match = createMatch(routes)

  const getCurrentRoute: Router<TRoutes>['getCurrentRoute'] = <
    TName extends keyof Routes,
  >() => {
    const result = match(path || NextRouter.asPath)
    return result as {
      name: TName
      params: RoutesValues<TRoutes>[TName]
      app: RouteApp
    }
  }

  const push: Router<TRoutes>['push'] = ({ name, params, shallow }) => {
    const { url, as, app } = build(name, params)

    const route = getCurrentRoute()
    if (!route || route.app === app) {
      window.scrollTo(0, 0)
      NextRouter.push({ pathname: url, query: params }, as, { shallow })
    } else {
      window.location.assign(as)
    }
  }

  const replace: Router<TRoutes>['replace'] = ({
    name,
    params,
    shallow,
    scroll = true,
  }) => {
    const { url, as, app } = build(name, params)

    const route = getCurrentRoute()
    if (!route || route.app === app) {
      if (scroll) {
        window.scrollTo(0, 0)
      }
      NextRouter.replace({ pathname: url, query: params }, as, { shallow })
    } else {
      window.location.replace(as)
    }
  }

  // Temporary adapter
  const find: Router<TRoutes>['find'] = url => {
    const result = match(url)
    return result
      ? {
          route: { name: result.name, page: result.name, app: result.app },
          query: result.params,
        }
      : null
  }

  // Temporary adapter
  const findByName: Router<TRoutes>['findByName'] = name => {
    if (!(name in routes)) {
      return null
    }

    return {
      getHrefAndAs: params => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const { url, as, app } = build(name, params as any)

        return {
          href: {
            pathname: url,
            query: params,
          },
          as,
          app,
        }
      },
    }
  }

  const onRouteChangeStart: Router<TRoutes>['onRouteChangeStart'] =
    callback => {
      NextRouter.events.on('routeChangeStart', callback)

      return () => {
        NextRouter.events.off('routeChangeStart', callback)
      }
    }

  const onRouteChangeComplete: Router<TRoutes>['onRouteChangeStart'] =
    callback => {
      NextRouter.events.on('routeChangeComplete', callback)

      return () => {
        NextRouter.events.off('routeChangeComplete', callback)
      }
    }

  const back: Router<TRoutes>['back'] = () => {
    NextRouter.back()
  }

  const reload: Router<TRoutes>['reload'] = () => {
    NextRouter.reload()
  }

  return {
    setPath,
    build,
    match,
    push,
    replace,
    find,
    findByName,
    getCurrentRoute,
    onRouteChangeStart,
    onRouteChangeComplete,
    back,
    reload,
  }
}

/* istanbul ignore file */
import { trackGAEvent } from '@moonpig/web-core-analytics'
import { DepartmentsEnum } from '@moonpig/web-core-types-graphql'
import { createSearchGAEvent } from './GAEvents'

type SearchSuggestion = {
  value: string
  department: {
    enum: DepartmentsEnum
    name: string
    title: string
  }
  isMainSuggestion: boolean
  resultsCount?: number
  score: number
  searchKey?: string
  path?: string
}

type GAEventsProps = {
  event: string
  value?: string
  suggestions?: SearchSuggestion[]
}

export const triggerGAEvent = ({
  event,
  value,
  suggestions,
}: GAEventsProps): void => {
  const getSuggestionPosition = (): string => {
    let position = 'position unknown'
    suggestions?.forEach((suggestion, i) => {
      if (value === suggestion.value) {
        position = `${i + 1} / ${suggestions.length}`
      }
    })

    return position
  }

  switch (event) {
    case 'ga_search_inspiration_click':
      trackGAEvent(
        createSearchGAEvent({
          eventAction: 'suggestion clicked',
          eventLabel: `no input | ${value} | ${getSuggestionPosition()}`,
        }),
      )
      break

    case 'ga_searchInput':
      trackGAEvent(
        createSearchGAEvent({
          eventAction: 'form input',
        }),
      )
      break

    case 'ga_searchSuggestion':
      trackGAEvent(
        createSearchGAEvent({
          eventAction: 'suggestions displayed',
          eventLabel: `no input | suggestions shown: ${suggestions?.length}`,
        }),
      )
      break

    case 'ga_searchFocus':
      trackGAEvent(
        createSearchGAEvent({
          eventAction: 'form focus',
        }),
      )
      break

    case 'ga_suggestionDismissed':
      trackGAEvent(
        createSearchGAEvent({
          eventAction: 'suggestion dimissed',
          eventLabel: 'outer click',
        }),
      )

      break
  }
}

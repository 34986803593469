import { z } from 'zod'
import { eventDataSchema, mediaDataSchema } from '../common.schema'

export const MediaCompleteSchema = z.object({
  event: z.literal('media_complete'),

  event_data: eventDataSchema,
  media_data: mediaDataSchema.optional(),
})

export type MediaCompleteEvent = z.infer<typeof MediaCompleteSchema>

import { useState } from 'react'

type UseFocusNavigator = (props: { items: { items: object[] }[] }) => {
  focusedItem: object | undefined
  moveLeft: () => void
  moveRight: () => void
  movePrevious: () => void
  moveNext: () => void
  focus: () => void
}

export const useFocusNavigator: UseFocusNavigator = ({ items }) => {
  const unset = [-1, -1]
  const firstPosition = [0, 0]
  const lastPosition = [
    items.length - 1,
    items[items.length - 1].items.length - 1,
  ]
  const [position, setPosition] = useState(unset)

  const focus = () => {
    if (position[0] === -1) {
      return setPosition(firstPosition)
    }
  }

  const moveLeft = () => {
    if (position[0] === firstPosition[0]) {
      return setPosition(lastPosition)
    }

    setPosition([position[0] - 1, 0])
  }

  const moveRight = () => {
    if (position[0] === lastPosition[0]) {
      return setPosition(firstPosition)
    }

    setPosition([position[0] + 1, 0])
  }

  const movePrevious = () => {
    if (position[0] === firstPosition[0] && position[1] === firstPosition[1]) {
      return setPosition(lastPosition)
    }

    if (position[1] === 0) {
      return setPosition([
        position[0] - 1,
        items[position[0] - 1].items.length - 1,
      ])
    }

    setPosition([position[0], position[1] - 1])
  }

  const moveNext = () => {
    if (position[1] === items[position[0]].items.length - 1) {
      if (position[0] === lastPosition[0]) {
        return setPosition(firstPosition)
      }

      return setPosition([position[0] + 1, 0])
    }

    setPosition([position[0], position[1] + 1])
  }

  return {
    focusedItem: items[position[0]]?.items[position[1]],
    moveLeft,
    moveRight,
    movePrevious,
    moveNext,
    focus,
  }
}

import { transformEventPayload } from './schemas/common.schema'
import { EventObject, ManualEventObjectSchema } from './schemaTypes'

type Value =
  | string
  | Value[]
  | { [key: string]: Value }
  | boolean
  | number
  | undefined

const sanitizeValue = <T extends Value>(value: T): Value => {
  if (typeof value === 'string') {
    return value.replace(/%/g, '')
  }
  if (Array.isArray(value)) {
    return value.map(sanitizeValue)
  }
  if (typeof value === 'object' && value) {
    return Object.entries(value).reduce<{ [key: string]: Value }>(
      (acc, [key, item]) => {
        acc[key] = sanitizeValue(item)
        return acc
      },
      {},
    )
  }
  return value
}

export const trackGAEvent = (event: EventObject) => {
  if (typeof window !== 'undefined') {
    const sanitizedEvent = sanitizeValue(event)
    let result = sanitizedEvent

    if (window.location.href?.includes('show-analytics-debug')) {
      const validationResult = ManualEventObjectSchema.safeParse(sanitizedEvent)

      if (!validationResult.success) {
        const errors = validationResult.error.issues.map(e => ({
          message: e.message,
          path: e.path.join('.'),
        }))
        throw new Error(
          `Event payload is not conforming to the event schema:\n${JSON.stringify(
            errors,
            null,
            2,
          )}\n\nOriginal event payload:\n${JSON.stringify(
            sanitizedEvent,
            null,
            2,
          )}`,
        )
      }

      result = transformEventPayload(validationResult.data) as EventObject
    }

    window.dataLayer = window.dataLayer || []

    if (typeof sanitizedEvent === 'object' && 'ecommerce' in sanitizedEvent) {
      window.dataLayer.push({ event: 'ecom_clear', ecommerce: null })
    }

    window.dataLayer.push(result)
  }
}

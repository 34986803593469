import React from 'react'
import {
  TrackedAnchor,
  createNavigationEvent,
} from '@moonpig/web-core-analytics'
import { CustomLink, CustomLinkProps } from '@moonpig/launchpad-components'
import {
  AppItem,
  RegionItem,
  SocialItem,
  WithIconAsComponent,
} from '@moonpig/web-core-brand/config'
import { Region } from '@moonpig/web-core-types'
import { Footer as LaunchpadFooter } from '@moonpig/web-core-nav'
import { LogoGreetz, LogoMoonpig } from '@moonpig/launchpad-assets'

const makeSocialTrackingAnchor = (eventLabel: string) => {
  const SocialTrackingAnchor = (props: CustomLinkProps) => {
    const eventPayload = createNavigationEvent({
      action: 'footer',
      label: `social | ${eventLabel}`,
    })

    return <TrackedAnchor {...props} eventData={eventPayload} />
  }

  return SocialTrackingAnchor
}

export const trackedSocialItems = (
  socialLinkItems: WithIconAsComponent<SocialItem>[],
) =>
  socialLinkItems?.map(socialItem => ({
    ...socialItem,
    linkAs: makeSocialTrackingAnchor(socialItem.label),
  }))

const makeRegionTrackingAnchor = (eventLabel: string) => {
  const RegionTrackingAnchor = (
    props: React.ComponentProps<typeof CustomLink>,
  ) => {
    const eventPayload = createNavigationEvent({
      action: 'footer',
      label: `regions | ${eventLabel}`,
    })

    return <TrackedAnchor {...props} eventData={eventPayload} />
  }

  return RegionTrackingAnchor
}

export const trackedRegionItems = (params: {
  region: Region
  regionItems?: WithIconAsComponent<RegionItem>[]
}) =>
  params.regionItems?.slice().map(regionItem => ({
    ...regionItem,
    current: regionItem.region === params.region,
    linkAs: makeRegionTrackingAnchor(regionItem.label),
  }))

const makeAppTrackingAnchor = (eventLabel: string) => {
  const AppTrackingAnchor = (
    props: React.ComponentProps<typeof CustomLink>,
  ) => {
    const eventPayload = createNavigationEvent({
      action: 'footer',
      label: `apps | ${eventLabel}`,
    })

    return <TrackedAnchor {...props} eventData={eventPayload} />
  }

  return AppTrackingAnchor
}

export const trackedAppItems = (
  appItems: (WithIconAsComponent<AppItem> & { label: string })[],
) =>
  appItems?.map(appItem => ({
    ...appItem,
    linkAs: makeAppTrackingAnchor(appItem.label),
  }))

const makeFooterTrackingAnchor = (eventLabel: string, sectionLabel: string) => {
  const FooterTrackingAnchor = (
    props: React.ComponentProps<typeof CustomLink>,
  ) => {
    const eventPayload = createNavigationEvent({
      action: 'footer',
      label: `${sectionLabel} | ${eventLabel}`,
    })

    return <TrackedAnchor {...props} eventData={eventPayload} />
  }

  return FooterTrackingAnchor
}

type LinkListItems = React.ComponentProps<
  typeof LaunchpadFooter
>['linkListItems']

export const buildTrackedLinkListItems = (linkListItems: LinkListItems) => {
  return linkListItems.map(linkList => {
    const items = linkList.items.map(item => ({
      ...item,
      linkAs: makeFooterTrackingAnchor(item.title, linkList.title),
    }))

    return {
      ...linkList,
      items,
    }
  })
}

const greetzBrand = {
  linkHref: 'https://www.greetz.nl/',
  label: 'Greetz',
  icon: LogoGreetz,
  width: '130px',
  height: '48px',
}

const moonpigBrand = {
  linkHref: 'https://www.moonpig.com/',
  label: 'Moonpig',
  icon: LogoMoonpig,
  width: '133px',
  height: '32px',
}

const brandItemsUK = [
  {
    linkHref: '/uk/',
    label: 'Moonpig',
    icon: LogoMoonpig,
    width: '133px',
    height: '32px',
  },
  greetzBrand,
] as const

const brandItemsUS = [
  {
    linkHref: '/us/',
    label: 'Moonpig',
    icon: LogoMoonpig,
    width: '133px',
    height: '32px',
  },
  greetzBrand,
] as const

const brandItemsAU = [
  {
    linkHref: '/au/',
    label: 'Moonpig',
    icon: LogoMoonpig,
    width: '133px',
    height: '32px',
  },
  greetzBrand,
] as const

const brandItemsIE = [
  {
    linkHref: '/ie/',
    label: 'Moonpig',
    icon: LogoMoonpig,
    width: '133px',
    height: '32px',
  },
  greetzBrand,
] as const

const brandItemsNL = [greetzBrand, moonpigBrand] as const

const regionalBrandItems = {
  uk: brandItemsUK,
  au: brandItemsAU,
  us: brandItemsUS,
  ie: brandItemsIE,
  nl: brandItemsNL,
}

const makeTrackedAnchorWithLabel = (eventLabel: string) => {
  const BrandTrackingAnchor = (props: CustomLinkProps) => {
    const eventPayload = createNavigationEvent({
      action: 'footer',
      label: `brands | ${eventLabel}`,
    })

    return <TrackedAnchor {...props} eventData={eventPayload} />
  }

  return BrandTrackingAnchor
}

export const trackedBrandItems = (region: Region) =>
  regionalBrandItems[region].slice().map(brandItem => ({
    ...brandItem,
    linkAs: makeTrackedAnchorWithLabel(brandItem.label),
  }))

const pathMatchesUriDynamic = (requestUri: string, redirectRule: string) => {
  if (requestUri && redirectRule) {
    const lowercaseRequestUri = requestUri.toLowerCase()
    const lowercaseRedirectRule = redirectRule.toLowerCase()

    return lowercaseRequestUri.startsWith(lowercaseRedirectRule)
  }
  return false
}

// eslint-disable-next-line import/no-default-export
export default pathMatchesUriDynamic

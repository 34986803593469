import { ThemeInterface } from '@moonpig/launchpad-theme'
import { useEffect, useState } from 'react'
import { useGetBreakpoint } from '../useGetBreakpoint'

const mobileBreakpoints: (keyof ThemeInterface['breakpoints']['map'])[] = [
  'xs',
  'sm',
]

export function useIsMobile() {
  const currentBreakpoint = useGetBreakpoint()
  const [isMobile, setIsMobile] = useState(
    mobileBreakpoints.includes(currentBreakpoint),
  )

  useEffect(() => {
    setIsMobile(mobileBreakpoints.includes(currentBreakpoint))
  }, [currentBreakpoint])

  return isMobile
}

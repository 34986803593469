import NextRouter from 'next/router'
import { routes } from './routes'
import { router } from './singleton'
import { createLinkComponent } from './createLinkComponent'
import type { RoutesValues } from './types'

export type Routes = RoutesValues<typeof routes>

export { router }

export { useRouter, RouterProvider } from './use-router'

// Temporary adapter
export { router as routes }

export type RouterType = typeof router
export type { RouteParamsValues } from './types'

// Temporary adapter
export { redirect } from './redirect'

// Temporary adapter
export const Router: typeof NextRouter & {
  pushByName<TName extends keyof Routes>(
    name: TName,
    params: Partial<Routes[TName]>,
  ): void
  replaceByName<TName extends keyof Routes>(
    name: TName,
    params: Partial<Routes[TName]>,
  ): void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pushCurrent(params: any, options?: { shallow?: boolean }): void
  getCurrentRoute<TName extends keyof Routes>(): {
    name: TName
    params: Partial<Routes[TName]>
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} = NextRouter as any

Router.pushByName = (name, params) => {
  router.push({ name, params })
}

Router.replaceByName = (name, params) => {
  router.replace({ name, params })
}

Router.pushCurrent = (params, options = {}) => {
  const matched = router.match(Router.asPath)

  if (matched) {
    router.push({ name: matched.name, params, shallow: options.shallow })
  }
}

Router.getCurrentRoute = () => router.getCurrentRoute()

// Temporary adapter
export const Link = createLinkComponent(router)

import { type AuthFetch } from '@moonpig/web-core-auth'
import { logger } from '@moonpig/web-core-monitoring'

export type OidcLoginStatus =
  | 'AUTHENTICATED'
  | 'CREATED'
  | 'LINKING_REQUIRED'
  | 'ERROR'

type OidcTokenLoginInput = {
  token: string
  nonce: string
}

export type OidcTokenLoginAccountLinkData = {
  email: string
  flowId: string
  methods: string[]
  providerName: string
  providers: string[]
}

type OidcTokenLoginResult =
  | {
      status: Exclude<OidcLoginStatus, 'LINKING_REQUIRED'>
    }
  | {
      status: Extract<OidcLoginStatus, 'LINKING_REQUIRED'>
      data: OidcTokenLoginAccountLinkData
    }

type AccountLinkInput = {
  email: string
  password: string
  flowId: string
}

export type AccountLinkResult =
  | {
      status: 'SUCCESS'
    }
  | {
      status: 'ERROR' | 'BAD_REQUEST' | 'ACCOUNT_LOCKED'
      code: number
      errorMessage: string
    }

export const oneTapAuthenticationService = (authFetch: AuthFetch) => ({
  oidcTokenLogin: async (
    input: OidcTokenLoginInput,
  ): Promise<OidcTokenLoginResult> => {
    const loginResponse = await authFetch({
      path: '/oidc/login',
      body: JSON.stringify({
        upstreamParameters: { id_token: input.token },
        nonce: input.nonce,
        provider: 'google',
        uxMode: 'web-one-tap',
      }),
    })

    switch (loginResponse.status) {
      case 200: {
        return { status: 'AUTHENTICATED' }
      }
      case 201: {
        return { status: 'CREATED' }
      }
      case 422: {
        return { status: 'LINKING_REQUIRED', ...(await loginResponse.json()) }
      }
      default: {
        logger.fixToday('Failed to login with Google One Tap', {
          statusCode: loginResponse.status,
        })
        return { status: 'ERROR' }
      }
    }
  },

  linkAccount: async (input: AccountLinkInput): Promise<AccountLinkResult> => {
    const linkResponse = await authFetch({
      path: '/login',
      body: JSON.stringify({
        email: input.email,
        password: input.password,
        flowId: input.flowId,
      }),
    })

    if (linkResponse.ok) {
      return { status: 'SUCCESS' }
    }

    const json = await linkResponse.json()

    switch (linkResponse.status) {
      case 400:
        return {
          status: 'BAD_REQUEST',
          code: linkResponse.status,
          errorMessage: json.errorMessage,
        }
      case 401:
        return {
          status: 'ERROR',
          code: linkResponse.status,
          errorMessage: json.errorMessage,
        }
      case 403:
        return {
          status: 'ACCOUNT_LOCKED',
          code: linkResponse.status,
          errorMessage: json.errorMessage,
        }
      default:
        logger.fixToday('Failed to link Google One Tap Account', {
          response: JSON.stringify(json),
          statusCode: linkResponse.status,
        })
        return {
          status: 'ERROR',
          code: linkResponse.status,
          errorMessage: json.errorMessage,
        }
    }
  },
})

import { z } from 'zod'
import {
  eventDataSchema,
  filterDataSchema,
  resultsDataSchema,
} from '../common.schema'

export const ViewGalleryResultsSchema = z.object({
  event: z.literal('view_gallery_results'),

  event_data: eventDataSchema,
  results_data: resultsDataSchema,
  filter_data: filterDataSchema,
})

export type ViewGalleryResultsEvent = z.infer<typeof ViewGalleryResultsSchema>

/* eslint-disable jsx-a11y/no-redundant-roles */
import {
  Grid,
  Box,
  CustomLink,
  LinkAsType,
  Flex,
  Heading,
} from '@moonpig/launchpad-components'

import { system as s } from '@moonpig/launchpad-system'
import { spacingPx } from '@moonpig/launchpad-theme'
import { styled } from '@moonpig/launchpad-utils'
import React, { FC } from 'react'

export type BoxProps = {
  children?: React.ReactNode
}

const StyledLink = styled(CustomLink)`
  display: block;
  ${s({
    color: 'colorInteractionIcon',
    typography: 'typeButtonTextLink',
  })}

  &:hover {
    ${s({
      color: 'colorInteractionIconHover',
    })}
  }
`

const StyledFlex = styled(Flex)`
  flex-direction: column;
  gap: ${spacingPx(6)};
`

type ItemProp = {
  linkAs?: LinkAsType
  linkHref: string
  title: string
}

export type Items = {
  title: string
  items: ItemProp[]
}

export type LinkListProps = {
  items: Items[]
}

export const LinkList: FC<React.PropsWithChildren<LinkListProps>> = ({
  items,
}) => (
  <Grid rowGap={10} colGap={5} data-testid="lp-nav-footernav-linklist">
    {items.map(({ title, items: linkListItems }) => (
      <Box key={title} width={{ xs: 1, sm: 1 / 2, md: 1 / 4 }}>
        <Heading
          level="h3"
          mb={5}
          typography={{ xs: 'typeMobileDisplay04', md: 'typeDesktopDisplay05' }}
        >
          {title}
        </Heading>
        <StyledFlex as="ul" role="list">
          {linkListItems.map(({ title: itemTitle, linkHref, linkAs }) => (
            <li key={itemTitle}>
              <StyledLink linkAs={linkAs} to={linkHref}>
                {itemTitle}
              </StyledLink>
            </li>
          ))}
        </StyledFlex>
      </Box>
    ))}
  </Grid>
)

import { createProxyApiUrl } from '@moonpig/web-core-utils'
import { AuthData } from './types'

export const isAuthData = (data: unknown): data is AuthData => {
  const check =
    data !== null &&
    typeof data === 'object' &&
    'accessToken' in data &&
    'accessTokenExpiresIn' in data &&
    'refreshToken' in data &&
    'refreshTokenExpiresIn' in data &&
    'isLoggedIn' in data &&
    typeof data.accessToken === 'string' &&
    typeof data.accessTokenExpiresIn === 'number' &&
    typeof data.refreshToken === 'string' &&
    typeof data.refreshTokenExpiresIn === 'number' &&
    typeof data.isLoggedIn === 'boolean'

  return check
}

export const isServer = () => typeof window === 'undefined'

export const getAuthBaseUrl = (): string => {
  if (isServer()) {
    if (!process.env.AUTH_URL_SERVER) {
      throw new Error('env variable AUTH_URL_SERVER must be defined')
    }

    const url = process.env.AUTH_URL_SERVER
    return url
  }

  if (!process.env.AUTH_URL) {
    throw new Error('env variable AUTH_URL must be defined')
  }

  const url = createProxyApiUrl(process.env.AUTH_URL)
  return url
}

export const getAuthSecretHeader = (): Record<string, string> => {
  return process.env.AUTH_SECRET_HEADER
    ? { 'x-mnpg-auth-key': process.env.AUTH_SECRET_HEADER }
    : {}
}

import { toError } from '@moonpig/web-core-utils'
import { augmentedFetch } from '../augmentedFetch'
import type { RequestInit, Response } from '../types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type JSON = any

type Result = {
  ok: boolean
  status: number
  statusText: string
  json?: JSON
  jsonError?: Error
}

export type DedupeFetch = (
  info: RequestInfo,
  init?: RequestInit,
) => Promise<Result>

export const createDedupeFetchJson = () => {
  const pending: {
    [key: string]: Promise<Result> | undefined
  } = {}

  const fetch: DedupeFetch = async (
    info: RequestInfo,
    init?: RequestInit,
  ): Promise<Result> => {
    const hashObj = {
      info,
      init,
    }
    const hash = JSON.stringify(hashObj)

    const inflight = pending[hash]
    if (inflight) {
      return inflight
    }

    const promise = augmentedFetch(info, init)

    const process = async (promiseResponse: Promise<Response>) => {
      const response = await promiseResponse

      let json
      let jsonError
      try {
        json = await response.json()
      } catch (error) {
        jsonError = toError(error)
      }

      return {
        ok: response.ok,
        status: response.status,
        statusText: response.statusText,
        json,
        jsonError,
      }
    }

    const pendingResult = process(promise)

    pendingResult.finally(() => {
      pending[hash] = undefined
    })

    pending[hash] = pendingResult

    return pendingResult
  }

  return fetch
}

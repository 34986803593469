import {
  Campaign,
  SiteSpectCampaigns,
  Cookies,
  createCampaigns,
  fetchCampaigns,
} from '@moonpig/web-core-ab-testing'
import { sanitizeStrings, trackGAEvent } from '@moonpig/web-core-analytics'
import type { CookieSerializeOptions } from '@moonpig/web-core-cookies'
import type { Metrics } from '@moonpig/web-core-monitoring'
import { sessionStore } from '@moonpig/web-core-utils'
import { toArray } from '../utils'

export type ABTestingProps = {
  campaigns: SiteSpectCampaigns
  campaignsToTrack: Record<string, string>
}

type ABTestingServer = {
  initialProps: ABTestingProps
  getVariation(campaignName: string): Promise<string>
  getAllCampaigns(): Promise<SiteSpectCampaigns>
}

let cachedInitialProps: ABTestingProps | null = null
let cachedCookies: Cookies

const siteSpectUrl =
  process.env.SITESPECT_URL ||
  'https://moonpig.api.ssopt.net/__ssobj/api?format=array'

const trackCampaigns = (props: ABTestingProps) => {
  Object.entries(props.campaignsToTrack).forEach(
    ([campaignName, variation]) => {
      const sanitizedVariation = sanitizeStrings(variation)
      const variationLabel =
        sanitizedVariation === 'not bucketed'
          ? 'no cohort assigned'
          : sanitizedVariation
      trackGAEvent({
        event: 'abtest',
        event_data: {
          category: 'ab test',
          action: sanitizeStrings(campaignName),
          label: `${variationLabel} | cohort`,
          non_interaction: true,
          value: undefined,
        },
      })
    },
  )
}

const parseVariationQuery = (
  variationQuery: string | string[],
): { [campaignName: string]: string | undefined } => {
  const variationQueryArray = toArray(variationQuery)

  return variationQueryArray.reduce<{
    [campaignName: string]: string | undefined
  }>((acc, variationQueryString) => {
    const [name, value] = variationQueryString.split(':')

    acc[name] = value
    return acc
  }, {})
}

const noCampaigns = {} as {
  [x: string]: Campaign
}

export const getABTestingServer = async (
  cookies: { [k: string]: string | undefined },
  userAgent: string | undefined,
  variationParam: string[],
  forceControlVariant: boolean,
  metrics: Metrics,
  disableSiteSpect = false,
  setCookie?: (
    name: string,
    value: string,
    options?: CookieSerializeOptions,
  ) => void,
): Promise<ABTestingServer> => {
  const variationOverrides = parseVariationQuery(variationParam)

  const getAllCampaignsAndCookies = () => {
    if (disableSiteSpect) {
      return {
        campaigns: [],
        cookies: {},
      }
    }

    return cachedInitialProps
      ? { campaigns: cachedInitialProps.campaigns, cookies }
      : fetchCampaigns(cookies, userAgent, siteSpectUrl, metrics)
  }

  const { campaigns: newCampaigns, cookies: initialResponseCookies } =
    await getAllCampaignsAndCookies()

  const initialProps = { campaigns: newCampaigns, campaignsToTrack: {} }
  const { campaigns, cookies: countInResponseCookies } =
    disableSiteSpect || newCampaigns.length === 0
      ? { campaigns: noCampaigns, cookies: [] }
      : await createCampaigns(
          initialProps.campaigns,
          initialResponseCookies,
          userAgent,
          siteSpectUrl,
          metrics,
        )

  if (countInResponseCookies && setCookie) {
    const oneYearInSeconds = 60 * 60 * 24 * 365
    Object.entries(countInResponseCookies).map(([name, value]) =>
      setCookie(name, value || '', { path: '/', maxAge: oneYearInSeconds }),
    )
  }

  return {
    initialProps,
    getVariation: async (campaignName: string) => {
      const variationOverride = variationOverrides[campaignName]

      if (variationOverride) {
        return variationOverride
      }

      const campaign = campaigns[campaignName]

      if (!campaign || forceControlVariant) {
        return 'Not bucketed'
      }

      ;(initialProps.campaignsToTrack as Record<string, string>)[campaignName] =
        campaign.variation

      return campaign.variation
    },
    getAllCampaigns: async () => {
      const { campaigns: allCampaigns } = await getAllCampaignsAndCookies()
      return allCampaigns
    },
  }
}

export const trackABTestingBrowser = (
  initialProps: ABTestingProps,
  cookies: Cookies,
) => {
  cachedInitialProps = initialProps

  const experimentsTracked =
    sessionStore.getItem('mnpg_ga_experiments_sent') === 1

  if (cachedCookies?.SSSC !== cookies.SSSC && !experimentsTracked) {
    trackCampaigns(cachedInitialProps || initialProps)
    sessionStore.setItem('mnpg_ga_experiments_sent', 1)
    cachedCookies = cookies
  }
}

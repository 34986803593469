import React, { FC, useState, useEffect } from 'react'
import {
  SelectContentEvent,
  trackGAEvent,
  TrackingDataProvider,
} from '@moonpig/web-core-analytics'
import {
  isLocalStorageAvailable,
  isMobileUserAgent,
} from '@moonpig/web-core-utils'
import { Banner } from './Banner'
import { EventAction, EventType, createAppBannerEvent } from './tracking'

const HAS_DISMISSED_APP_BANNER_KEY = 'mnpg-app-banner-has-been-dismissed'
const APP_BANNER_DISPLAY_COUNT_KEY = 'mnpg-app-banner-visited-pages'

const getAppBannerDisplayCount = () => {
  const item = localStorage.getItem(APP_BANNER_DISPLAY_COUNT_KEY)
  if (!item) {
    return 0
  }

  return parseInt(item, 10) || 0
}

const hasUserDismissed = () =>
  localStorage.getItem(HAS_DISMISSED_APP_BANNER_KEY)

const hasAutoDismissed = () => getAppBannerDisplayCount() > 999

const shouldOpenAppBanner = () =>
  isMobileUserAgent() && !hasUserDismissed() && !hasAutoDismissed()

const setHasUserDismissed = () =>
  localStorage.setItem(HAS_DISMISSED_APP_BANNER_KEY, 'true')

const incrementViewCount = () => {
  localStorage.setItem(
    APP_BANNER_DISPLAY_COUNT_KEY,
    (getAppBannerDisplayCount() + 1).toString(),
  )
}

type AppBannerProps = {
  path: string
  shouldFormat: boolean
  voucherCode: string | null
} & Omit<React.ComponentProps<typeof Banner>, 'onDismissClick'>

export const AppBanner: FC<AppBannerProps> = ({
  title,
  subtitle,
  ctaText,
  url,
  shouldFormat,
  voucherCode,
  totalRatingsText,
  rating,
  dismissTitle,
  path,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    try {
      if (!shouldOpenAppBanner()) {
        setIsOpen(false)
        return
      }

      incrementViewCount()
      setIsOpen(true)
      // eslint-disable-next-line no-empty
    } catch {}

    trackGAEvent(
      createAppBannerEvent({
        eventType: EventType.Impression,
        promotionTitle: title,
        action: EventAction.View,
      }),
    )
  }, [path, title])

  if (!isLocalStorageAvailable() || !isOpen) {
    return null
  }

  const createCrplFragment = () =>
    parseInt((Math.random() * 1000000000).toString(), 10)
      .toString()
      .padStart(9, '0')

  const formatUrl = (link: string, voucher: string | null) => {
    const rand1 = createCrplFragment()
    const rand2 = createCrplFragment()
    return link
      .replace('{voucher}', `${encodeURIComponent(voucher || '')}`)
      .replace('{rand1}', rand1)
      .replace('{rand2}', rand2)
  }

  const handleDismissClick = () => {
    setHasUserDismissed()
    setIsOpen(false)
    trackGAEvent(
      createAppBannerEvent({
        eventType: EventType.SelectContent,
        promotionTitle: title,
        action: EventAction.Dismiss,
      }),
    )
  }

  const appBannerURL = shouldFormat ? formatUrl(url, voucherCode) : url

  return (
    <TrackingDataProvider
      eventData={
        createAppBannerEvent({
          eventType: EventType.SelectContent,
          promotionTitle: title,
          action: EventAction.Click,
          link: appBannerURL,
        }) as SelectContentEvent
      }
    >
      <Banner
        title={title}
        subtitle={subtitle}
        ctaText={ctaText}
        url={appBannerURL}
        totalRatingsText={totalRatingsText}
        rating={rating}
        onDismissClick={handleDismissClick}
        dismissTitle={dismissTitle}
      />
    </TrackingDataProvider>
  )
}

import { z } from 'zod'
import { eventDataSchema } from '../common.schema'

export const EditorDesignUpdateSchema = z.object({
  event: z.literal('editor_design_update'),

  event_data: eventDataSchema,
})

export type EditorDesignUpdateEvent = z.infer<typeof EditorDesignUpdateSchema>

export const isLocalStorageAvailable = (): boolean => {
  try {
    return typeof localStorage === 'object'
  } catch {
    // Ensure we don't throw due to security sandbox issues e.g. Chrome's
    // "Failed to read 'localStorage' from 'Window': Access denied for this document"
    /* istanbul ignore next */
    return false
  }
}

const getItem = async (key: string): Promise<unknown> => {
  try {
    const item = localStorage.getItem(key)
    return item && JSON.parse(item)
  } catch {
    return null
  }
}

const setItem = async (key: string, value: unknown): Promise<void> => {
  try {
    return localStorage.setItem(key, JSON.stringify(value))
  } catch {
    return undefined
  }
}

const removeItem = async (key: string): Promise<void> => {
  try {
    return localStorage.removeItem(key)
  } catch {
    return undefined
  }
}

const clear = async (): Promise<void> => {
  try {
    return localStorage.clear()
  } catch {
    return undefined
  }
}

export const localStore = {
  getItem,
  setItem,
  removeItem,
  clear,
}

import { GraphQLOptions } from '@moonpig/web-core-graphql'
import { createProxyApiUrl, isMobileUserAgent } from '@moonpig/web-core-utils'
import type { NextPageContext } from 'next'
import { getProxyApiStoreNamespace } from './proxyApiStoreNamespace'

export const getGraphQLOptions = ({
  ctx,
  sessionId,
  storeId,
}: {
  ctx: NextPageContext
  localHostName: string
  sessionId: string
  storeId: string
}): GraphQLOptions => {
  if (!process.env.AUTH_URL) {
    throw new Error('env variable AUTH_URL must be defined')
  }

  const proxyApiStoreNamespace = getProxyApiStoreNamespace(ctx)

  const headers = {
    'mnpg-client-name': 'web',
    'mnpg-session-id': sessionId,
    'mnpg-store': storeId,
    'mnpg-is-mobile-browser': isMobileUserAgent() ? 'true' : 'false',
  }

  const authBaseUrl = createProxyApiUrl(process.env.AUTH_URL)

  const additionalHeaders = proxyApiStoreNamespace
    ? {
        'mnpg-contentful-environment-id': 'e2e',
        'mnpg-proxy-api-store-namespace': proxyApiStoreNamespace,
      }
    : {}

  const usePersistedQueries = !(
    process.env.ENABLE_PROXY_API || !!proxyApiStoreNamespace
  )

  return {
    authBaseUrl,
    headers: { ...headers, ...additionalHeaders },
    usePersistedQueries,
  }
}

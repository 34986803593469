const localStorageKey = 'mnpg_upcoming_reminder_ids'

const adjust = (date: Date) => {
  const newDate = new Date(date.toISOString())
  newDate.setHours(0)
  newDate.setMinutes(0)
  newDate.setSeconds(0)
  newDate.setMilliseconds(0)
  return newDate
}

const daysBetween = (earlier: Date, later: Date) => {
  const adjustedEarlier = adjust(earlier)
  const adjustedLater = adjust(later)
  const timeUntilEvent = adjustedLater.getTime() - adjustedEarlier.getTime()
  return Math.ceil(timeUntilEvent / (1000 * 3600 * 24))
}

type ReminderSaveState = {
  id: string
  nextDate: Date
  daysUntil: number
}[]

type ReminderInput = {
  id: string
  nextDate: string
}

const mapToStorage = (reminders: ReminderInput[]): ReminderSaveState => {
  const todaysDate = new Date(Date.now())

  return reminders.map(r => {
    const nextDate = new Date(r.nextDate)
    const daysUntil = daysBetween(todaysDate, nextDate)

    return {
      id: r.id,
      daysUntil,
      nextDate,
    }
  })
}

export const saveInLocalStorage = (reminders: ReminderInput[]) => {
  try {
    const newSaveState = mapToStorage(reminders)
    localStorage.setItem(localStorageKey, JSON.stringify(newSaveState))
    return newSaveState
  } catch (e) {
    return []
  }
}

const loadLocalStorage = (): ReminderSaveState => {
  const rawState = localStorage.getItem(localStorageKey)
  try {
    return rawState ? JSON.parse(rawState) : []
  } catch (e) {
    // Invalid JSON is being stored in storage.
    localStorage.removeItem(localStorageKey)
    throw e
  }
}

const checkForRemindersThatAreNowUpcoming = (
  newSaveState: ReminderSaveState,
  originalSaveState: ReminderSaveState,
  daysUntilReminder: number,
) =>
  newSaveState.some(rNew => {
    const rOld = originalSaveState.find(it => it.id === rNew.id)

    /* istanbul ignore next */
    if (!rOld) return false
    return (
      rOld.daysUntil > daysUntilReminder && rNew.daysUntil <= daysUntilReminder
    )
  })

export const shouldShowNotificationBadgeForReminders = (
  reminders: ReminderInput[],
): boolean => {
  if (reminders.length === 0) {
    return false
  }

  let originalSaveState: ReminderSaveState

  try {
    originalSaveState = loadLocalStorage()
  } catch {
    return false
  }

  const newSaveState = mapToStorage(reminders)

  if (originalSaveState.length === 0) {
    return true
  }

  const everyReminderAlreadySeen = newSaveState.every(rNew =>
    originalSaveState.some(rOld => rNew.id === rOld.id),
  )

  if (!everyReminderAlreadySeen) return true

  if (checkForRemindersThatAreNowUpcoming(newSaveState, originalSaveState, 7)) {
    return true
  }

  if (checkForRemindersThatAreNowUpcoming(newSaveState, originalSaveState, 3)) {
    return true
  }

  if (checkForRemindersThatAreNowUpcoming(newSaveState, originalSaveState, 1)) {
    return true
  }

  return false
}

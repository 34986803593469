import { RouteParamTypeMap, Router, Routes } from './types'
import { isMultipleType, isOptionalType, mapValues } from './utils'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isValidParam = (value: any) => value !== undefined && value !== ''

const serialiseQueryParam = (
  name: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  type: keyof RouteParamTypeMap,
): string[] => {
  if (Array.isArray(value)) {
    return value
      .filter(isValidParam)
      .map(item => `${name}=${encodeURIComponent(item)}`)
  }
  if (type === 'query.boolean.optional') {
    return value === true ? [name] : []
  }
  if (isValidParam(value)) {
    return [`${name}=${encodeURIComponent(String(value))}`]
  }
  return []
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const serialisePathParam = (value: any, type: keyof RouteParamTypeMap) => {
  if (isMultipleType(type)) {
    const parts: string[] = (Array.isArray(value) ? value : []).filter(
      isValidParam,
    )
    return parts.length > 0 ? `/${parts.map(encodeURIComponent).join('/')}` : ''
  }
  if (isOptionalType(type)) {
    return isValidParam(value) ? `/${encodeURIComponent(String(value))}` : ''
  }
  return encodeURIComponent(String(value))
}

export const createBuild = <TRoutes extends Routes>(
  routes: TRoutes,
): Router<TRoutes>['build'] => {
  const build: Router<TRoutes>['build'] = (name, params) => {
    const route = routes[name]

    const serialisedQueryParams = Object.entries(route.params).reduce<string[]>(
      (acc, [key, type]) => {
        return type.startsWith('query.')
          ? [...acc, ...serialiseQueryParam(key, params[key], type)]
          : acc
      },
      [],
    )

    const additionalQueryParams = Object.entries(params).reduce<string[]>(
      (acc, [key, value]) => {
        return !(key in route.params)
          ? [...acc, ...serialiseQueryParam(key, value, 'query.string')]
          : acc
      },
      [],
    )

    const serialisedPathParams = mapValues(route.params, (key, type) => {
      return serialisePathParam(params[key], type)
    })

    const rawPath = route.path(serialisedPathParams)
    const queryParams = [...serialisedQueryParams, ...additionalQueryParams]

    const path = rawPath.endsWith('/') ? rawPath : `${rawPath}/`

    const as =
      queryParams.length > 0 ? `${path}?${queryParams.join('&')}` : path

    const url = `/${String(name)}`

    return { url, as, app: route.app }
  }

  return build
}

import React, { FC, createRef, useState } from 'react'
import {
  IconSystemChevronRight as IconChevron,
  IconFlagGlobe as IconGlobe,
} from '@moonpig/launchpad-assets'
import { breakpoint, styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import {
  Box,
  PrimaryButton,
  Flex,
  Heading,
  Modal,
  ScreenReaderOnly,
} from '@moonpig/launchpad-components'
import { StoreOptions } from './StoreOptions'
import { StoreOption } from './types'
import { useLocaleText } from '../locale'

export type StoreSelectorProps = {
  selectedIndex: number
  storeOptions: StoreOption[]
  onStoreSelection: (selectedStore: StoreOption) => void
  onModalOpen: () => void
  onModalDismiss: () => void
}

const ICON_FLAG_WIDTH_PX = '24px'
const ICON_FLAG_HEIGHT_PX = '16px'
const ICON_GLOBE_SIZE_PX = '22px'

const StyledButton = styled('button')`
  display: flex;
  align-items: center;
  gap: 8px;
  color: inherit;
  font-family: inherit;
  font-size: 1rem;

  ${s({
    px: 6,
    py: { xs: 4, md: 5, xl: 0 },
    typography: { xs: 'typeMobileBodyCaption', md: 'typeDesktopBodyText' },
  })}
`

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledChevron = styled(({ isModalOpen, ...props }) => (
  <IconChevron {...props} />
)).attrs(({ isModalOpen }) => ({
  style: {
    transform: `rotate(${isModalOpen ? '-' : ''}90deg)`,
  },
}))<{ isModalOpen: boolean }>`
  width: ${ICON_FLAG_WIDTH_PX};
  height: ${ICON_FLAG_WIDTH_PX};
  ${breakpoint('md')} {
    display: none;
  }
`

export const StoreSelector: FC<React.PropsWithChildren<StoreSelectorProps>> = ({
  selectedIndex,
  storeOptions,
  onStoreSelection,
  onModalOpen,
  onModalDismiss,
}) => {
  const [selectedStore, setSelectedStore] = React.useState(
    storeOptions[selectedIndex],
  )
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const {
    label: storeName,
    shortLabel: shortStoreName,
    icon: Icon,
  } = storeOptions[selectedIndex]

  const modalTriggerRef = createRef<HTMLButtonElement>()

  const t = useLocaleText()

  const handleModalOpen = () => {
    setModalIsOpen(true)
    onModalOpen()
  }

  const handleModalDismiss = () => {
    setModalIsOpen(false)
    onModalDismiss()
  }

  const handleStoreSelection = () => {
    onStoreSelection(selectedStore)
    setModalIsOpen(false)
  }

  return (
    <>
      <StyledButton onClick={handleModalOpen} ref={modalTriggerRef}>
        <span aria-hidden>
          {t('store_selector.deliver_to', shortStoreName || storeName)}
        </span>
        {Icon ? (
          <Icon width={ICON_FLAG_WIDTH_PX} height={ICON_FLAG_HEIGHT_PX} />
        ) : (
          <IconGlobe width={ICON_GLOBE_SIZE_PX} height={ICON_GLOBE_SIZE_PX} />
        )}
        <StyledChevron isModalOpen={modalIsOpen} />
        <ScreenReaderOnly>
          {t(
            'store_selector.deliver_to_screen_reader_only',
            shortStoreName || storeName,
          )}
        </ScreenReaderOnly>
      </StyledButton>
      <Modal
        label={t('store_selector.modal_label')}
        hasCloseButton
        onDismiss={handleModalDismiss}
        isOpen={modalIsOpen}
        mobilePagePeek
        triggerRef={modalTriggerRef}
      >
        <Flex alignItems="center" flexDirection="column">
          <Heading
            level="h2"
            typography={{
              xs: 'typeMobileDisplay03',
              md: 'typeDesktopDisplay04',
            }}
            mb={{ xs: 4, md: 6 }}
          >
            {t('store_selector.heading')}
          </Heading>
          <Box mb={6}>
            <StoreOptions
              selectedOption={selectedStore}
              options={storeOptions}
              onChanged={setSelectedStore}
            />
          </Box>
          <PrimaryButton
            mb={{ xs: 4, md: 6 }}
            onClick={handleStoreSelection}
            width={{ xs: 1, md: 1 / 2 }}
          >
            {t('store_selector.done')}
          </PrimaryButton>
        </Flex>
      </Modal>
    </>
  )
}

import { Flex, Grid, Heading } from '@moonpig/launchpad-components'
import React, { FC } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'

type Gap = boolean | number | string | Record<string, unknown>

export type FooterNavSectionProps = {
  title: string
  colGap?: Gap
  rowGap?: Gap
  description?: string
  as?:
    | keyof JSX.IntrinsicElements
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | React.ComponentType<React.PropsWithChildren<any>>
}

const StyledText = styled.p`
  ${s({
    typography: 'typeBodyCaption',
    marginTop: { xs: 4, md: 5 },
    marginBottom: 0,
    order: 1,
  })}
`
export const FooterNavSection: FC<
  React.PropsWithChildren<FooterNavSectionProps>
> = ({ children, title, colGap = 5, rowGap = 4, description, as }) => {
  return (
    <Flex flexDirection="column">
      <Heading
        level="h3"
        textAlign="left"
        mb={{ xs: 4, md: 5 }}
        typography={{ xs: 'typeMobileDisplay04', md: 'typeDesktopDisplay05' }}
      >
        {title}
      </Heading>
      {description && <StyledText>{description}</StyledText>}
      <Grid
        colGap={colGap}
        rowGap={rowGap}
        alignItems="center"
        justifyContent="start"
        style={{ listStyleType: 'none' }}
        as={as || 'ul'}
        role={'list'}
        order={0}
      >
        {children}
      </Grid>
    </Flex>
  )
}

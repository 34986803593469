import { gql } from '@moonpig/web-core-graphql'

export const GetIncentivisedRemindersCreatorData = gql`
  query GetIncentivisedRemindersCreatorDataGQL(
    $path: String!
    $preview: Boolean
  ) {
    page(path: $path, preview: $preview) {
      __typename
      ... on Page {
        modules {
          ... on ModuleIncentivisedReminders {
            notificationTitle
          }
        }
      }
    }
  }
`

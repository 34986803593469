import React, { FC, FocusEvent } from 'react'
import Downshift from 'downshift'
import { system as s } from '@moonpig/launchpad-system'
import { styled, breakpointUp, breakpointDown } from '@moonpig/launchpad-utils'
import { ScreenReaderOnly, Box } from '@moonpig/launchpad-components'
import { spacingPx } from '@moonpig/launchpad-theme'
import { Input } from './Input'
import { SubmitButton } from './SubmitButton'
import { ClearButton } from './ClearButton'
import { BackButton } from './BackButton'
import { AutocompleteSearchBox } from './AutocompleteSearchBox'
import { InspirationType } from './Inspirations/Inspiration'
import { CloseButton } from './CloseButton'
import { FocusableSearchBox } from './FocusableSearchBox'
import { SuggestionType } from './Suggestions/Suggestion'
import { NavigationalSuggestionType } from './NavigationalSuggestions/NavigationalSuggestion'
import { Reminder } from './Reminders/Reminder'
import type { SubmitItemType, RecentSearchType } from './types'
import { useLocaleText } from '../locale'

const StyledForm = styled.form.attrs(({ isActive }: { isActive: boolean }) => ({
  className: isActive && 'is-active',
}))<{ isActive: boolean }>`
  height: ${spacingPx(12)};
  position: relative;

  &.is-active {
    z-index: 1;
    ${s({ bgcolor: 'colorBackground01' })}
  }
`

const GreyPanelOverlay = styled(Box)`
  ${breakpointUp('md')} {
    ${s({ bgcolor: 'colorBlack100' })}
    display: block;
    opacity: 0.4;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;
  }
`

const WhitePanelOverlay = styled(Box)`
  ${s({ bgcolor: 'colorBackground01' })}
  display: block;
  opacity: 1;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100vw;
  z-index: 2;
  ${breakpointDown('md')} {
    height: 100%;
  }
  ${breakpointUp('md')} {
    min-height: 500px;
    height: 500px;
    max-height: 500px;
  }
`

const StyledWrapper = styled.div.attrs(
  ({ isActive }: { isActive: boolean }) => ({
    className: isActive && 'is-active',
  }),
)<{ isActive: boolean }>`
  ${s({ bgcolor: 'colorBackground01' })}
  position: relative;
  top: 0;
  opacity: 1;
  z-index: 7;
  &.is-active {
    ${breakpointDown('md')} {
      position: fixed;
      top: 16px;
      right: 16px;
      left: 16px;
    }
  }
`

type OnSubmit = (submitItem: SubmitItemType) => void

type OnKeyUp = (value: string) => void
type OnFocus = (value: string) => void
type OnClear = () => void
type OnSuggestionsVisible = () => void
type OnRemindersVisible = () => void
type ClearButtonAs = ({ value }: { value: string }) => React.ReactNode
type OnRemoveRecentSearch = (value: RecentSearchType) => void
type OnClearRecentSearches = () => void

type SearchProps = {
  items: SuggestionType[]
  navigationalSuggestions?: NavigationalSuggestionType[]
  inspirations?: InspirationType[]
  recentSearches?: RecentSearchType[]
  reminders?: Reminder[]
  onSubmit: OnSubmit
  onKeyUp: OnKeyUp
  onFocus: OnFocus
  onSuggestionsVisible: OnSuggestionsVisible
  onRemoveRecentSearch: OnRemoveRecentSearch
  onClearRecentSearches: OnClearRecentSearches
  onRemindersVisible: OnRemindersVisible
  onClear?: OnClear
  clearButtonAs?: ClearButtonAs
  searchTerm?: string
  maxSuggestions?: number
  maxNavigationalSuggestions?: number
  navigationalHeaderText?: string
  suggestionsLoading?: boolean
}

export const Search: FC<React.PropsWithChildren<SearchProps>> = ({
  items = [],
  onSubmit,
  onKeyUp,
  onFocus,
  onClear,
  clearButtonAs,
  searchTerm = '',
  onSuggestionsVisible,
  onRemoveRecentSearch,
  onClearRecentSearches,
  onRemindersVisible,
  maxSuggestions,
  navigationalSuggestions = [],
  maxNavigationalSuggestions,
  navigationalHeaderText,
  inspirations = [],
  recentSearches = [],
  reminders = [],
}) => {
  const [isSearchOpen, setIsSearchOpen] = React.useState(false)
  const searchInput = React.useRef<HTMLInputElement>(null)
  const t = useLocaleText()

  const handleInputFocus = (searchOpen: boolean) => {
    setIsSearchOpen(searchOpen)
    if (searchOpen) {
      onFocus(searchTerm)
    }
  }

  const closeSearch = (reset?: () => void) => {
    if (reset) {
      reset()
    }
    if (searchInput.current) {
      searchInput.current.blur()
    }
    handleInputFocus(false)
  }

  const handleClearClick = (reset: () => void) => {
    reset()
    if (onClear) {
      onClear()
    }
    if (searchInput.current) {
      searchInput.current.focus()
    }
  }

  const handleSubmit = (submitItem: SubmitItemType) => {
    onSubmit(submitItem)
    if (searchInput.current) {
      searchInput.current.blur()
      handleInputFocus(false)
      closeSearch()
    }
  }

  const handleBlur = (event: FocusEvent) => {
    if (
      event.relatedTarget &&
      !event.currentTarget.contains(event.relatedTarget)
    ) {
      handleInputFocus(false)
    }
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    // istanbul ignore next
    if (!isSearchOpen && event.key !== 'Escape') {
      handleInputFocus(true)
    }
    if (!isSearchOpen) return

    if (event.key === 'Escape') {
      if (searchInput.current) {
        searchInput.current.focus()
      }
      setIsSearchOpen(false)
    }
  }

  return (
    <StyledForm
      data-testid="lp-nav-search-form"
      isActive={isSearchOpen}
      onBlur={handleBlur}
      onFocus={() => {
        setIsSearchOpen(true)
      }}
      onKeyDown={handleKeyDown}
    >
      <Downshift
        isOpen={isSearchOpen}
        onChange={selection => {
          if (selection) {
            handleSubmit(selection)
          }
        }}
        itemToString={item =>
          item ? item.value : searchInput.current?.value || ''
        }
        initialInputValue={searchTerm}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          getLabelProps,
          getRootProps,
          selectItem,
          isOpen,
          inputValue,
          highlightedIndex,
          reset,
          clearSelection,
        }) => {
          const inputProps = getInputProps({
            onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === 'Enter') {
                if (highlightedIndex === null) {
                  e.nativeEvent.preventDefault()
                  return selectItem({ value: inputValue })
                }
                /* istanbul ignore next */
                reset()
              }
              return undefined
            },
            onKeyUp: () => onKeyUp(inputValue || ''),
            onFocus: () => handleInputFocus(true),
          })

          const { value, ...filteredInputProps } = inputProps

          const hasActiveSuggestions =
            (items.length || navigationalSuggestions.length) && inputValue
          const hasActiveInspirations =
            inspirations.length && (!inputValue || inputValue.length === 1)
          const hasActiveReminders =
            reminders.length && (!inputValue || inputValue.length === 1)
          const hasActiveRecentSearches =
            recentSearches.length && (!inputValue || inputValue.length === 1)
          const hasActiveSuggestionsOrInspirations =
            hasActiveSuggestions ||
            hasActiveInspirations ||
            hasActiveReminders ||
            hasActiveRecentSearches

          return (
            <StyledWrapper
              isActive={isOpen && hasActiveSuggestionsOrInspirations}
              {...getRootProps()}
            >
              <ScreenReaderOnly as="label" {...getLabelProps()}>
                {t('search.search')}
              </ScreenReaderOnly>
              {isOpen && hasActiveSuggestionsOrInspirations ? (
                <>
                  <WhitePanelOverlay data-testid="lp-nav-search-overlay">
                    <CloseButton
                      onClick={() => closeSearch()}
                      aria-label={'Close search'}
                    />
                  </WhitePanelOverlay>
                  <GreyPanelOverlay
                    data-testid="lp-nav-search-grey-overlay"
                    onClick={() => closeSearch()}
                  />
                </>
              ) : null}
              {isOpen && hasActiveSuggestionsOrInspirations ? (
                <BackButton
                  onClick={() => closeSearch()}
                  aria-label={t('search.close_search')}
                />
              ) : null}
              <Input
                {...filteredInputProps}
                value={value as string}
                isSearchOpen={Boolean(
                  isOpen && hasActiveSuggestionsOrInspirations,
                )}
                placeholder={t('search.placeholder')}
                ref={searchInput}
                type="search"
              />
              <ClearButton
                hidden={!inputValue}
                onClick={() =>
                  handleClearClick(() => {
                    reset()
                    clearSelection()
                    handleInputFocus(true)
                  })
                }
                as={
                  (clearButtonAs &&
                    clearButtonAs({ value: inputValue || '' })) as
                    | keyof JSX.IntrinsicElements
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    | React.ComponentType<React.PropsWithChildren<any>>
                }
              />
              <SubmitButton
                disabled={!inputValue}
                onClick={() => {
                  handleSubmit({ itemType: 'search', value: inputValue || '' })
                  reset()
                  clearSelection()
                }}
              />
              <FocusableSearchBox
                data-testid="inspirations-list"
                isOpen={isOpen}
                inspirations={inspirations}
                reminders={reminders}
                recentSearches={recentSearches}
                inputValue={inputValue}
                getItemProps={getItemProps}
                getMenuProps={getMenuProps}
                onRemindersVisible={onRemindersVisible}
                onRemoveRecentSearch={onRemoveRecentSearch}
                onClearRecentSearches={onClearRecentSearches}
                onSelectItem={(selectedValue: string) =>
                  selectItem({ value: selectedValue })
                }
              />
              <AutocompleteSearchBox
                isOpen={isOpen}
                suggestions={items}
                inputValue={inputValue}
                getItemProps={getItemProps}
                getMenuProps={getMenuProps}
                highlightedIndex={highlightedIndex}
                value={inputValue || ''}
                onSuggestionsVisible={onSuggestionsVisible}
                maxSuggestions={maxSuggestions}
                navigationalSuggestions={navigationalSuggestions}
                maxNavigationalSuggestions={maxNavigationalSuggestions}
                navigationalHeaderText={navigationalHeaderText}
              />
            </StyledWrapper>
          )
        }}
      </Downshift>
    </StyledForm>
  )
}

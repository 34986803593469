import React, { createContext, useContext, useMemo, FC } from 'react'
import { Fetch } from './authenticatedFetch'

type FetchContextType = {
  fetch: Fetch
}

const FetchContext = createContext<FetchContextType | null>(null)

export const FetchProvider: FC<React.PropsWithChildren<{ fetch: Fetch }>> = ({
  children,
  fetch,
}) => {
  const value = useMemo(() => ({ fetch }), [fetch])
  return <FetchContext.Provider value={value}>{children}</FetchContext.Provider>
}

export const useFetch = () => useContext(FetchContext) as FetchContextType

import React, { FC } from 'react'
import { system as s } from '@moonpig/launchpad-system'
import { theme } from '@moonpig/launchpad-theme'
import { Flex } from '@moonpig/launchpad-components'
import { styled, breakpoint } from '@moonpig/launchpad-utils'

const ANIMATION_DURATION_MS = '6650ms'
const ARROW_SIZE_PX = '8px'
const LARGE_SCREEN_OFFSET_PX = '56px'
const SMALL_SCREEN_OFFSET_PX = '50px'

type TooltipProps = {
  text: string
}

const StyledTooltip = styled(Flex)`
  position: absolute;
  align-items: center;
  justify-content: center;
  text-align: center;
  pointer-events: none;
  transform: translateY(-${SMALL_SCREEN_OFFSET_PX});
  width: 180px;
  animation: ${ANIMATION_DURATION_MS} ease-in-out fadeInOut;
  opacity: 0;
  z-index: 8;

  ${s({
    bgcolor: 'colorBackground05',
    color: 'colorTextHeadline',
    py: 3,
    px: 5,
    borderRadius: 5,
    gap: 3,
  })}

  ${breakpoint('md')} {
    transform: translateY(${LARGE_SCREEN_OFFSET_PX});

    ${s({
      py: 4,
    })}
  }

  &::before {
    content: '';
    position: absolute;
    border-style: solid;
    border-right: ${ARROW_SIZE_PX} solid transparent;
    border-left: ${ARROW_SIZE_PX} solid transparent;
    border-top: ${ARROW_SIZE_PX} solid ${theme.colors.colorBackground05};
    border-bottom: 0;
    bottom: 1px;
    margin-bottom: -${ARROW_SIZE_PX};

    ${breakpoint('md')} {
      bottom: auto;
      margin-bottom: auto;
      border-bottom: ${ARROW_SIZE_PX} solid ${theme.colors.colorBackground05};
      border-top: 0;
      top: 1px;
      margin-top: -${ARROW_SIZE_PX};
    }
  }

  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    4% {
      opacity: 1;
    }
    93% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`

export const Tooltip: FC<TooltipProps> = ({ text }) => {
  return <StyledTooltip aria-hidden>{text}</StyledTooltip>
}

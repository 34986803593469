import { createLocaleTextHook } from '@moonpig/web-core-locale-text'

const dictionary = {
  heading: {
    'en-GB': 'Page not found',
    'nl-NL': 'Pagina niet gevonden',
  },
  message: {
    'en-GB':
      "Sorry, this page isn't here. Fear not. Just click below and we'll get you out of here:",
    'nl-NL':
      'Sorry, de pagina die je zocht is niet hier. Maar gelukkig kun je wel gewoon verder, klik op de link hieronder:',
  },
  homepage_link: {
    'en-GB': 'Homepage',
    'nl-NL': 'Startpagina',
  },
}

export const useLocaleText = createLocaleTextHook({
  dictionary,
})

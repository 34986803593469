import React, {
  FC,
  useEffect,
  memo,
  useState,
  useContext,
  useCallback,
} from 'react'
import { system as s } from '@moonpig/launchpad-system'
import { styled, breakpoint, breakpointDown } from '@moonpig/launchpad-utils'
import { Grid, Box, ScreenReaderOnly } from '@moonpig/launchpad-components'
import { IconSystemChevronRight as IconChevron } from '@moonpig/launchpad-assets'
import { spacingPx } from '@moonpig/launchpad-theme'
import { useExperiment } from '@moonpig/web-core-experiments'
import { useStoreId } from '@moonpig/web-core-stores'
import { SubDropdown, type SubDropdownItem } from './SubDropdown'
import { QuickLinks } from './QuickLinks'
import { DropdownProps, MegaNavItemProps } from './types'

import {
  NAV_BP,
  COLOR_LINK_HOVER,
  COLOR_KEYLINE,
  MOBILE_NAV_WIDTH,
  KEYLINE_SIZE,
  KEYLINE_SIZE_PX,
  COLOR_DEFAULT_TEXT,
  ICON_SIZE_PX,
} from '../constants'
import { HeaderRefContext } from '../HeaderWrapper'
import {
  MegaNavAction,
  CLOSE_DROPDOWN,
  useMegaNavContext,
} from './MegaNavContext'
import { useLocaleText } from '../locale'
import { Link } from '../Link'
import { useFocusNavigator } from './focusNavigator'
import { split } from './utils'

type StyledDropdownContentProps = {
  dropdownHeightOffset: number
  isDesktopNav: boolean
}

const StyledDropdownContent = styled.div.attrs(
  ({ dropdownHeightOffset, isDesktopNav }: StyledDropdownContentProps) => ({
    style: {
      maxHeight: isDesktopNav
        ? `calc(100vh - ${dropdownHeightOffset}px)`
        : 'initial',
    },
  }),
)<StyledDropdownContentProps>`
  ${s({ bgcolor: 'colorBackground01' })}

  ${breakpoint(NAV_BP)} {
    overflow: auto;
    padding: ${spacingPx(10)} ${spacingPx(6)};
    box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.3);
  }
`

const StyledMobileCloseButton = styled.button<{ isOpen: boolean }>`
  position: relative;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 16px;
  padding-right: 16px;
  width: ${MOBILE_NAV_WIDTH - KEYLINE_SIZE}px;
  border: 0;
  border-bottom: ${KEYLINE_SIZE_PX} solid ${COLOR_KEYLINE};
  color: ${COLOR_DEFAULT_TEXT};
  background-color: transparent;
  ${s({ typography: 'typeButtonLabel' })}
  cursor: pointer;

  ${breakpoint(NAV_BP)} {
    display: none;
  }
`

const StyledDesktopPrimaryLinkWrapper = styled.div`
  ${s({ pb: 8 })}
  text-align: center;

  ${breakpointDown(NAV_BP)} {
    display: none;
  }
`

const StyledDesktopPrimaryLink = styled(Link)`
  ${s({ py: 3 })}
  display: inline-block;
  color: ${COLOR_LINK_HOVER};
  text-decoration: none;

  &:hover,
  &:focus {
    color: ${COLOR_LINK_HOVER};
    text-decoration: underline;
  }
`

const StyledMobilePrimaryLink = styled(Link)`
  display: block;
  ${s({ typography: 'typeButtonLabel', px: 6, py: 5 })}
  border-bottom: ${KEYLINE_SIZE_PX} solid ${COLOR_KEYLINE};
  color: inherit;

  &:hover,
  &:focus {
    text-decoration: none;
    color: ${COLOR_LINK_HOVER};
  }

  ${breakpoint(NAV_BP)} {
    display: none;
  }
`

const StyledChevron = styled(IconChevron)`
  position: absolute;
  top: 50%;
  left: 12px;
  width: ${ICON_SIZE_PX};
  height: ${ICON_SIZE_PX};
  transform: rotate(180deg) translateY(50%);
  color: ${COLOR_DEFAULT_TEXT};

  ${breakpoint(NAV_BP)} {
    display: none;
  }
`

type DropdownContentProps = {
  label: string
  href: string
  isOpen?: boolean
  dropdownIndex: number
  buttonAs?:
    | keyof JSX.IntrinsicElements
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | React.ComponentType<React.PropsWithChildren<any>>
  items: MegaNavItemProps['dropdown']
}

type WrappedDropdownContentProps = DropdownContentProps & {
  isOpenedByKeyboardOrClick: boolean
  isDesktopNav: boolean
  dispatch: (action: MegaNavAction) => void
}

const byQuickLinks = (item: DropdownProps) =>
  !!item.href && item.items.length === 0

const allItem = Object.seal({})

type UseDropDown = (props: {
  isExperiment: boolean
  isDesktop: boolean
  isOpenedByKeyboard: boolean
  items: DropdownProps[]
}) => {
  quickLinkItems: DropdownProps[]
  subDropdownItems: SubDropdownItem[]
  totalItems: { items: object[] }[]
  columnCount: number
}

const BUCKET_LIMIT = 13

const useDropDown: UseDropDown = ({
  isExperiment,
  isDesktop,
  isOpenedByKeyboard,
  items,
}) => {
  const [quickLinkItems, subDropdownItems] = split(items, byQuickLinks)

  const subDropDownItemsWithBuckets = subDropdownItems.map<SubDropdownItem>(
    i => ({
      ...i,
      items:
        isDesktop && isExperiment
          ? i.items.reduce<SubDropdownItem['items']>((acc, item, index) => {
              if (index % BUCKET_LIMIT === 0) acc.push([])
              acc[acc.length - 1].push(item)
              return acc
            }, [])
          : [i.items],
    }),
  )

  const flattenedBuckets = subDropDownItemsWithBuckets.flatMap(i => i.items)

  const totalItems: { items: object[] }[] = flattenedBuckets.map(i => ({
    items: i,
  }))

  let columnCount = 6

  if (isExperiment) {
    columnCount = flattenedBuckets.length
  }

  if (isExperiment && quickLinkItems.length > 0) {
    totalItems.unshift({ items: quickLinkItems })
    columnCount += 1
  } else {
    quickLinkItems.length = 0
  }

  if (isOpenedByKeyboard) {
    totalItems.unshift({ items: [allItem] })
  }

  return {
    quickLinkItems,
    subDropdownItems: subDropDownItemsWithBuckets,
    totalItems,
    columnCount,
  }
}

const WrappedDropdownContent = memo<WrappedDropdownContentProps>(
  ({
    label,
    isOpen,
    href,
    dropdownIndex,
    isOpenedByKeyboardOrClick,
    isDesktopNav,
    dispatch,
    buttonAs,
    items,
  }) => {
    const storeId = useStoreId()
    const isRedesignExperiment =
      useExperiment('explore-nav-redesign')?.toLowerCase() === 'enabled' &&
      ['uk', 'nl'].includes(storeId)

    const { quickLinkItems, subDropdownItems, totalItems, columnCount } =
      useDropDown({
        isExperiment: isRedesignExperiment,
        isDesktop: isDesktopNav,
        isOpenedByKeyboard: isOpenedByKeyboardOrClick,
        items,
      })

    const { focusedItem, moveLeft, moveNext, movePrevious, moveRight, focus } =
      useFocusNavigator({
        items: totalItems,
      })

    const [dropdownHeightOffset, setDropdownHeightOffset] = useState(0)
    const headerNode = useContext(HeaderRefContext)
    const localise = useLocaleText()

    useEffect(() => {
      if (isDesktopNav && headerNode && headerNode.current) {
        const { height, y } = headerNode.current.getBoundingClientRect()
        setDropdownHeightOffset(height + y)
      }
    }, [headerNode, isDesktopNav])

    const handleCloseClick = useCallback(
      () => dispatch([CLOSE_DROPDOWN]),
      [dispatch],
    )

    useEffect(() => {
      if (isOpenedByKeyboardOrClick) {
        return focus()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, isOpenedByKeyboardOrClick])

    const handleOnKeyDown = useCallback(
      (e: React.KeyboardEvent) => {
        switch (e.key) {
          case 'ArrowUp':
            e.preventDefault()
            return movePrevious()
          case 'ArrowLeft':
            e.preventDefault()
            return moveLeft()
          case 'ArrowDown':
            e.preventDefault()
            return moveNext()
          case 'ArrowRight':
            e.preventDefault()
            return moveRight()
          case 'Tab':
            e.preventDefault()
            return e.shiftKey ? movePrevious() : moveNext()
        }
      },
      [movePrevious, moveLeft, moveNext, moveRight],
    )

    const canShowDesktopQuickLinks = isDesktopNav && quickLinkItems.length > 0

    return (
      <StyledDropdownContent
        data-testid={`lp-nav-dropdown-${dropdownIndex}-content`}
        dropdownHeightOffset={dropdownHeightOffset}
        isDesktopNav={isDesktopNav}
        onFocus={() => focus()}
      >
        <StyledMobileCloseButton
          isOpen
          as={buttonAs}
          data-testid={`lp-nav-dropdown-${dropdownIndex}-mobile-close-button`}
          tabIndex={-1}
          onClick={handleCloseClick}
        >
          {label}
          <ScreenReaderOnly>
            {localise('mega_nav.dropdown_content.close', label)}
          </ScreenReaderOnly>
          <StyledChevron aria-hidden />
        </StyledMobileCloseButton>
        {isOpen && isOpenedByKeyboardOrClick && isDesktopNav && (
          <StyledDesktopPrimaryLinkWrapper>
            <StyledDesktopPrimaryLink
              data-testid={`lp-nav-dropdown-${dropdownIndex}-landing-page-desktop-link`}
              href={href}
              onKeyDown={handleOnKeyDown}
              isFocused={focusedItem === allItem}
            >
              {localise('mega_nav.dropdown_content.primary_link', label)}
            </StyledDesktopPrimaryLink>
          </StyledDesktopPrimaryLinkWrapper>
        )}
        <Grid justifyContent="center">
          <Box width={{ xs: 1, [NAV_BP]: 11 / 12 }}>
            <Grid justifyContent="center" gap={{ xs: 0, [NAV_BP]: 8 }} as="ul">
              {canShowDesktopQuickLinks && (
                <Box as="li" width={{ xs: 1, [NAV_BP]: 1 / columnCount }}>
                  <QuickLinks
                    items={quickLinkItems}
                    focusedItem={focusedItem}
                    onLinkKeyDown={handleOnKeyDown}
                  />
                </Box>
              )}
              {subDropdownItems.map((subDropdown, subDropdownIndex) => (
                <Box
                  // eslint-disable-next-line react/no-array-index-key
                  key={subDropdownIndex}
                  as="li"
                  width={{
                    xs: 1,
                    [NAV_BP]: subDropdown.items.length / columnCount,
                  }}
                >
                  <SubDropdown
                    isDesktopNav={isDesktopNav}
                    focusedItem={focusedItem}
                    onLinkKeyDown={handleOnKeyDown}
                    dropdownIndex={dropdownIndex}
                    subDropdown={subDropdown}
                  />
                </Box>
              ))}
            </Grid>
          </Box>
        </Grid>
        {!isDesktopNav && isOpen && (
          <StyledMobilePrimaryLink
            data-testid={`lp-nav-dropdown-${dropdownIndex}-landing-page-mobile-link`}
            href={href}
          >
            {localise('mega_nav.dropdown_content.primary_link', label)}
          </StyledMobilePrimaryLink>
        )}
      </StyledDropdownContent>
    )
  },
)

WrappedDropdownContent.displayName = 'WrappedDropdownContent'

export const DropdownContent: FC<
  React.PropsWithChildren<DropdownContentProps>
> = props => {
  const [state, dispatch] = useMegaNavContext()
  const { isOpenedByKeyboardOrClick = false, isDesktopNav = false } = state
  const wrappedProps = {
    ...props,
    isOpenedByKeyboardOrClick: !!isDesktopNav && isOpenedByKeyboardOrClick,
    isDesktopNav,
    dispatch,
  }
  return <WrappedDropdownContent {...wrappedProps} />
}

import { useEffect, useMemo, useRef } from 'react'
import { useExperiment } from '@moonpig/web-core-experiments'
import {
  TAGG_EXPERIENCE_DATA_ATTRIBUTE,
  TAGG_EXPERIENCE_EXPERIMENT_NAME,
} from '../../../constants'
import { getTaggExperience } from '../../../utils'

const setTaggExperince = (experience: string) => {
  if (typeof window !== 'undefined') {
    document.body.setAttribute(TAGG_EXPERIENCE_DATA_ATTRIBUTE, experience)
  }
}

export const useTaggExperience = (currentExperience: string) => {
  const taggExperience = useRef(currentExperience)
  const tagStarExperimentBucket = useExperiment(TAGG_EXPERIENCE_EXPERIMENT_NAME)

  const experimentBucket = useMemo(
    () => getTaggExperience(tagStarExperimentBucket),
    [tagStarExperimentBucket],
  )

  useEffect(() => {
    if (experimentBucket !== taggExperience.current) {
      setTaggExperince(experimentBucket)
      taggExperience.current = experimentBucket
    }
  }, [experimentBucket])

  return experimentBucket
}

import { shared } from './shared'
import type { Schema } from './types'

export const schema: Schema = {
  ...shared,
  'digital-gift-card-options-free-ecard': {
    default: true,
  },
  'digital-gifts-with-ecards': {
    default: true,
  },
  'gift-cards-with-ecards': {
    default: false,
  },
  'modular-digital-gift-pdp': {
    default: true,
  },
  'explore-enable-dynamic-hero': {
    default: true,
  },
  'disable-moonpig-plus-pills': {
    default: false,
  },
}

import { init } from '@eppo/node-server-sdk'
import { createMetrics, logger } from '@moonpig/web-core-monitoring'
import { isBrowser } from '@moonpig/web-core-utils'
import { EppoClient } from '@eppo/js-client-sdk-common'

type CreateEppoClient = (args?: {
  numInitialRequestRetries?: number
}) => Promise<EppoClient | null>

export const createEppoClient: CreateEppoClient = async args => {
  try {
    if (isBrowser) return null

    const metrics = createMetrics()

    const eppoClient = await metrics.traceAsync(
      {
        metricName: 'fetch-eppo-configuration',
        traceName: 'fetch-eppo-configuration',
      },
      () =>
        init({
          apiKey: process.env.EPPO_API_SDK_KEY || '',
          assignmentLogger: {
            logAssignment:
              /* istanbul ignore next */
              () => ({}),
          },
          numInitialRequestRetries: args?.numInitialRequestRetries,
          throwOnFailedInitialization: true,
        }),
    )

    return eppoClient
  } catch (error) {
    logger.fixImmediately('Failed to Initialise Eppo Server SDK', error)
    return null
  }
}

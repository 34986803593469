import { z } from 'zod'
import { eventDataSchema, remindersDataSchema } from '../common.schema'

export const CreateReminderSchema = z.object({
  event: z.literal('create_reminder'),

  event_data: eventDataSchema,
  reminder_data: remindersDataSchema,
})

export type CreateReminderEvent = z.infer<typeof CreateReminderSchema>

import React, {
  ComponentType,
  useEffect,
  useRef,
  type FC,
  type PropsWithChildren,
} from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { all, AllSystemProps } from '@moonpig/launchpad-system'
import { Link as LinkComponent } from '@moonpig/launchpad-components'
import { COLOR_LINK_HOVER, COLOR_DEFAULT_TEXT } from '../constants'

const withFocus = <P,>(
  WrappedComponent: ComponentType<P>,
): FC<PropsWithChildren<P & { isFocused?: boolean }>> => {
  return props => {
    const ref = useRef<HTMLElement>(null)

    useEffect(() => {
      if (props.isFocused) {
        ref.current?.focus()
      }
    }, [props.isFocused])

    return <WrappedComponent ref={ref} {...props} />
  }
}

export const Link = styled(withFocus(LinkComponent))<AllSystemProps>`
  ${all}
  color: ${COLOR_DEFAULT_TEXT};
  display: block;

  &:hover,
  &:focus {
    color: ${COLOR_LINK_HOVER};
  }
`

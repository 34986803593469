import { Metrics } from '@moonpig/web-core-monitoring'
import { NextPageContext } from 'next/types'

export type ServerRedirectResponse = {
  source: string
  destination: string
  permanent: boolean
}

export type ServerRedirect = (
  asPath: string,
) => Promise<null | ServerRedirectResponse>

export const handleServerRedirects = async (
  ctx: NextPageContext,
  metrics: Metrics,
  redirect?: ServerRedirect,
) => {
  if (ctx.asPath && ctx.res && redirect) {
    const redirectResponse = await redirect(ctx.asPath)
    if (redirectResponse) {
      const source = redirectResponse.source
      const destination = redirectResponse.destination
      const permanent = redirectResponse.permanent
      const statusCode = permanent ? 301 : 302
      ctx.res.statusCode = statusCode
      ctx.res.setHeader('Location', destination)
      if (permanent) {
        ctx.res.setHeader('Cache-Control', 'public, max-age=2628000')
      }
      metrics.count({
        metricName: 'redirect',
        metricCount: 1,
        metricDimensions: {
          source: source.substring(0, 255),
          permanent: permanent ? 'true' : 'false',
        },
      })
      ctx.res.end()

      return {
        pageProps: {
          statusCode,
          location: destination,
        },
      } as const
    }
  }
  return null
}

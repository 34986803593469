import { Config } from './types'

export const config: Config = {
  markers: {
    customise: {
      name: 'new-editor',
    },
  },
  triggers: {
    'add-a-gift': {
      name: 'new-editor-attach',
      ifMarkerSet: 'new-editor',
    },
  },
}

/* istanbul ignore file */
import { logger } from '@moonpig/web-core-monitoring'

export const logFixImmediately = (
  ...args: Parameters<typeof logger.fixImmediately>
) => {
  logger.fixImmediately(...args)
}

export const logFixToday = (...args: Parameters<typeof logger.fixToday>) => {
  logger.fixToday(...args)
}

export const logDebug = (...args: Parameters<typeof logger.debug>) => {
  logger.info(...args)
}

export const logInfo = (...args: Parameters<typeof logger.info>) => {
  logger.info(...args)
}

import React, { FC } from 'react'
import { Reminder } from './Reminder'
import { ReminderItemType } from '../types'

type RemindersProps = {
  reminders: Reminder[]
  getItemProps: (props: {
    itemIndex: number
    item: ReminderItemType
  }) => Record<string, unknown>
  getMenuProps: () => Record<string, unknown>
}

export const Reminders: FC<RemindersProps> = ({
  reminders,
  getItemProps,
  getMenuProps,
}) => {
  return (
    <ul {...getMenuProps()} data-testid={'lp-nav-search-reminders'}>
      {reminders.map((item, index) => {
        return (
          <Reminder
            item={item}
            key={`reminder-suggestion-${item.value}`}
            getItemProps={getItemProps}
            itemIndex={index}
          />
        )
      })}
    </ul>
  )
}

import {
  CookieSerializeOptions,
  getBrowserCookies,
  getCookies as getNextCookies,
  setCookie as setNextCookie,
} from '@moonpig/web-core-cookies'
import type { NextPageContext } from 'next'
import {
  type Metrics,
  type MetricsTraceOptions,
} from '@moonpig/web-core-monitoring'
import { isServer, getApiUrl } from '@moonpig/web-core-utils'
import { augmentedFetch } from '../augmentedFetch'
import type { Response, RequestInit } from '../types'
import { proxyResponse } from '../proxyResponse'
import {
  COOKIE_ACCESS_TOKEN,
  COOKIE_HAS_REFRESH_TOKEN,
  COOKIE_IS_AUTHENTICATED,
  COOKIE_REFRESH_TOKEN,
  HEADER_SESSION_ID,
} from './constants'
import { fetchAccessToken } from './fetchAccessToken'
import { fetchRefreshToken } from './fetchRefreshToken'
import { getAuthCookieDomain } from './getAuthCookieDomain'
import { normalizeFetchRequestHeaders } from './normalizeFetchRequestHeaders'
import { AuthData } from './types'
import { createDedupeFetchJson } from './dedupeFetchJson'

type CoreFetchArgs = Parameters<typeof augmentedFetch>
type CoreFetchResponse = ReturnType<typeof augmentedFetch>

export type FetchInfo = CoreFetchArgs[0]
export type FetchInit = CoreFetchArgs[1] & {
  metrics?: MetricsTraceOptions
  isUnauthenticatedCheck?: (response: Response) => Promise<boolean>
}
export type FetchResponse = Awaited<CoreFetchResponse>

export type Fetch = (info: FetchInfo, init?: FetchInit) => Promise<Response>

export type AuthFetch = {
  fetch: Fetch
  writeCookies: () => void
}

const getAccessToken = (
  pendingAuthData: AuthData | undefined,
  cookies: {
    [key: string]: string
  },
) => {
  if (pendingAuthData) {
    return pendingAuthData.accessToken
  }
  return cookies[COOKIE_ACCESS_TOKEN]
}

const getRefreshToken = (
  pendingAuthData: AuthData | undefined,
  cookies: {
    [key: string]: string
  },
) => {
  if (pendingAuthData) {
    return pendingAuthData.refreshToken
  }
  return cookies[COOKIE_REFRESH_TOKEN]
}

const getAuthHeader = (
  pendingAuthData: AuthData | undefined,
  cookies: {
    [key: string]: string
  },
): { [name: string]: string } => {
  const accessToken = getAccessToken(pendingAuthData, cookies)
  if (!accessToken) {
    return {}
  }
  return { authorization: `Bearer ${accessToken}` }
}

const getCloudflareBypassHeader = (): {
  [name: string]: string | string[]
} => {
  return {
    'mnpg-edge-api-waf-bypass':
      process.env.MNPG_EDGE_API_WAF_BYPASS || /* istanbul ignore next */ '',
  }
}

const hasRefreshToken = (
  pendingAuthData: AuthData | undefined,
  cookies: Record<string, string>,
): boolean => {
  if (pendingAuthData) {
    return true
  }

  return cookies[COOKIE_HAS_REFRESH_TOKEN] === 'true'
}

const defaultIsUnauthenticatedCheck = (response: Response) => {
  if (response.status === 401) {
    return true
  }
  return false
}

const createDefaultMetrics = (info: FetchInfo): MetricsTraceOptions => {
  if (typeof info === 'string') {
    const url = new URL(info)
    let path = url.pathname.slice(1)
    path = path.endsWith('/') ? path.slice(0, -1) : path
    const name = `fetch-${path.replace(/\//g, '-')}`
    return {
      traceName: name,
      metricName: name,
    }
  }

  /* istanbul ignore next */
  return {
    traceName: 'fetch-unknown',
    metricName: 'fetch-unknown',
  }
}

export const createAuthenticatedFetch = (
  enableServerAuth: boolean,
  sessionId: string,
  metrics: Metrics,
  ctx?: NextPageContext,
): AuthFetch => {
  const enableAuth = isServer ? enableServerAuth : true

  const getCookies = () =>
    ctx && ctx.req ? getNextCookies(ctx) : getBrowserCookies()

  const setServerCookie = (
    name: string,
    value: string,
    options: CookieSerializeOptions | undefined,
  ) => {
    if (ctx && ctx.res) {
      setNextCookie(name, value, options, ctx)
    }
  }

  let pendingAuthData: AuthData | undefined

  const writeCookies = () => {
    const domain = getAuthCookieDomain()

    const encodeDisabled = (v: string) => {
      return v
    }

    const sharedOptions = {
      encode: encodeDisabled,
    }
    const securityOptions =
      domain !== 'localhost'
        ? ({
            domain,
            sameSite: 'lax',
            secure: true,
          } as const)
        : /* istanbul ignore next */ {}

    if (pendingAuthData) {
      setServerCookie(COOKIE_ACCESS_TOKEN, pendingAuthData.accessToken, {
        ...sharedOptions,
        ...securityOptions,
        path: '/',
        httpOnly: true,
        maxAge: Number(pendingAuthData.accessTokenExpiresIn),
      })
      setServerCookie(
        COOKIE_IS_AUTHENTICATED,
        pendingAuthData.isLoggedIn === true ? 'true' : 'false',
        {
          ...sharedOptions,
          ...securityOptions,
          path: '/',
          ...(pendingAuthData.isLoggedIn
            ? { maxAge: Number(pendingAuthData.refreshTokenExpiresIn) }
            : { expires: new Date(0) }),
        },
      )
      setServerCookie(COOKIE_REFRESH_TOKEN, pendingAuthData.refreshToken, {
        ...sharedOptions,
        ...securityOptions,
        path: '/',
        httpOnly: true,
        maxAge: Number(pendingAuthData.refreshTokenExpiresIn),
      })
      setServerCookie(COOKIE_HAS_REFRESH_TOKEN, 'true', {
        ...sharedOptions,
        ...securityOptions,
        path: '/',
        maxAge: Number(pendingAuthData.refreshTokenExpiresIn),
      })
    }
  }

  const dedupeFetch = createDedupeFetchJson()

  const getRequestUrl = (requestInfo: FetchInfo) => {
    if (typeof requestInfo === 'string') {
      return requestInfo
    }

    /* istanbul ignore next */
    if ('url' in requestInfo) {
      return requestInfo.url
    }

    /* istanbul ignore next */
    return ''
  }

  const urlMatchesDomain = (requestInfo: FetchInfo, domain: string) => {
    const url = getRequestUrl(requestInfo)
    const { hostname } = new URL(url)

    return hostname === domain || hostname.endsWith(`.${domain}`)
  }

  const shouldIncludeCredentials = (
    requestInfo: FetchInfo,
    requestInit: RequestInit,
  ) => {
    if (requestInit.credentials !== 'include') {
      return false
    }

    const authCookieDomain = getAuthCookieDomain()
    if (urlMatchesDomain(requestInfo, authCookieDomain)) {
      return true
    }

    if (urlMatchesDomain(requestInfo, new URL(getApiUrl()).hostname)) {
      return true
    }

    return false
  }

  const shouldIncludeApiWafBypass = (requestInfo: FetchInfo) => {
    if (!isServer) {
      return false
    }

    const { hostname } = new URL(getApiUrl())
    return urlMatchesDomain(requestInfo, hostname)
  }

  const fetch = async (
    argInfo: FetchInfo,
    argInit?: FetchInit,
  ): Promise<FetchResponse> => {
    const initialCookies = getCookies()
    const argOptions = argInit || {}
    const metricsOptions = argOptions.metrics || createDefaultMetrics(argInfo)

    const traceId = metrics.traceId()

    const includeCredentials = shouldIncludeCredentials(argInfo, argOptions)
    const passTraceHeader = isServer && traceId.value && includeCredentials
    const useAuth = enableAuth && includeCredentials
    const passAuthHeader = isServer && useAuth
    const includeApiWafBypass = shouldIncludeApiWafBypass(argInfo)
    const argHeaders = normalizeFetchRequestHeaders(argOptions.headers || {})

    const doFetch = async (cookies: { [key: string]: string }) => {
      const headers = {
        ...(passTraceHeader ? { [traceId.header]: traceId.value } : {}),
        ...(passAuthHeader ? getAuthHeader(pendingAuthData, cookies) : {}),
        ...(includeApiWafBypass ? getCloudflareBypassHeader() : {}),
        ...(sessionId ? { [HEADER_SESSION_ID]: sessionId } : {}),
        ...argHeaders,
      }

      const options = {
        ...argOptions,
        headers,
      }

      const response = await metrics.traceAsync(metricsOptions, () => {
        return augmentedFetch(argInfo, options)
      })

      // .clone() behaviour in node-fetch (server-side) differs
      // to that in the browser.
      //
      // As a workaround we can proxy the text() / json() method to return
      // the same cached result.
      //
      // See: https://github.com/node-fetch/node-fetch/issues/1568
      return proxyResponse(response)
    }

    const fetchAndValidateAccessToken = async () => {
      // Get auth token if we don't have one already
      const authData = await fetchAccessToken({
        sessionId,
        metrics,
        fetch: dedupeFetch,
      })

      // Only track pending server cookie writes on server
      // In browser we let fetch set-cookie update document.cookie
      return isServer ? authData : null
    }

    const refreshAccessToken = async () => {
      const refreshToken = getRefreshToken(pendingAuthData, getCookies())
      // Trying refreshing the access token
      const authData = await fetchRefreshToken({
        sessionId,
        refreshToken,
        metrics,
        fetch: dedupeFetch,
      })

      // Only track pending server cookie writes on server
      // In browser we let fetch set-cookie update document.cookie
      return isServer ? authData : null
    }

    if (!useAuth) {
      // Fetch without auth
      return doFetch({})
    }

    if (!hasRefreshToken(pendingAuthData, initialCookies)) {
      // Get auth token as we don't have one already

      const accessToken = await fetchAndValidateAccessToken()
      if (accessToken) pendingAuthData = accessToken
    }

    const response = await doFetch(initialCookies)
    const isUnauthenticatedCheck =
      argInit?.isUnauthenticatedCheck || defaultIsUnauthenticatedCheck

    if (await isUnauthenticatedCheck(response)) {
      const accessToken = await refreshAccessToken()
      if (accessToken) pendingAuthData = accessToken
      return doFetch(getCookies())
    }

    return response
  }

  return {
    fetch,
    writeCookies,
  }
}

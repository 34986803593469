import unfetch from 'isomorphic-unfetch'
import type { RequestInfo, RequestInitWithTimeout, Response } from '../types'

export const timeoutFetch = async (
  input: RequestInfo,
  init?: RequestInitWithTimeout,
): Promise<Response> => {
  const abortController =
    typeof AbortController !== 'undefined'
      ? new AbortController()
      : /* istanbul ignore next */ null

  /* istanbul ignore next */
  const { timeout, ...config } = init || {}

  /* istanbul ignore next */
  const requestTimeout = setTimeout(() => {
    abortController?.abort()
  }, timeout)

  const response = await unfetch(
    input as globalThis.RequestInfo,
    {
      ...config,
      signal: abortController?.signal,
    } as globalThis.RequestInit,
  )

  clearTimeout(requestTimeout)
  return response
}

import { createLocaleTextHook } from '@moonpig/web-core-locale-text'
import { Occasion } from '@moonpig/web-core-types-graphql'

const OccasionsLocale: Record<string, { 'en-GB': string; 'nl-NL': string }> = {
  [Occasion.IN_REMEMBRANCE]: {
    'en-GB': 'In Remembrance',
    'nl-NL': 'In memoriam',
  },
  [Occasion.ANNIVERSARY]: {
    'en-GB': 'Anniversary',
    'nl-NL': 'Jubileum',
  },
  [Occasion.CHRISTMAS]: {
    'en-GB': 'Christmas',
    'nl-NL': 'Kerstmis',
  },
  [Occasion.LOVE]: { 'en-GB': 'Love', 'nl-NL': 'Liefde' },
  [Occasion.MOTHERS_DAY]: {
    'en-GB': "Mother's Day",
    'nl-NL': 'Moederdag',
  },
  [Occasion.SAINT_NICHOLAS]: {
    'en-GB': 'Saint Nicholas',
    'nl-NL': 'Sinterklaas',
  },
  [Occasion.SPECIAL_OCCASION]: {
    'en-GB': 'Special Occasion',
    'nl-NL': 'Speciaal moment',
  },
  [Occasion.WEDDING]: {
    'en-GB': 'Wedding',
    'nl-NL': 'Trouwdag',
  },
  [Occasion.FATHERS_DAY]: {
    'en-GB': "Father's Day",
    'nl-NL': 'Vaderdag',
  },
  [Occasion.BIRTHDAY]: {
    'en-GB': 'Birthday',
    'nl-NL': 'Verjaardag',
  },
  [Occasion.JUST_A_NOTE]: {
    'en-GB': 'Just a note',
    'nl-NL': 'Zomaar',
  },
  [Occasion.NEW_BABY]: {
    'en-GB': 'New Baby',
    'nl-NL': 'Geboorte',
  },
  [Occasion.VALENTINES_DAY]: {
    'en-GB': "Valentine's Day",
    'nl-NL': 'Valentijnsdag',
  },
}

const dictionary = {
  clear_search_input: {
    'en-GB': 'Clear search input',
    'nl-NL': 'Zoekopdracht wissen',
  },
  close_search: {
    'en-GB': 'Close search',
    'nl-NL': 'Zoekfunctie sluiten',
  },
  search_for: {
    'en-GB': (c: { departmentTitle: string }) =>
      `Search for ${c.departmentTitle}…`,
    'nl-NL': (c: { departmentTitle: string }) =>
      `Zoeken naar ${getNlDepartment(c.departmentTitle)}…`,
  },
  submit_search: {
    'en-GB': 'Submit search',
    'nl-NL': 'Zoekopdracht uitvoeren',
  },
  navigational_suggestion_header: {
    'en-GB': 'Services',
    'nl-NL': 'Hulp & Tips',
  },
}

const getNlDepartment = (department: string) => {
  switch (department.toLowerCase()) {
    case 'gifts':
      return 'cadeaus'
    case 'flowers':
      return 'bloemen'
    case 'cards':
    default:
      return 'kaarten'
  }
}

const createPossessive = (name: string) => `${name}’s`
const getPrintableOccasion = (
  occasion: Occasion,
  locale: 'en-GB' | 'nl-NL',
) => {
  return OccasionsLocale[occasion]
    ? OccasionsLocale[occasion][locale]
    : OccasionsLocale[Occasion.SPECIAL_OCCASION][locale]
}

export const REMINDER_TEXT = {
  'reminder_card.heading': {
    'en-GB': ({
      occasion,
      name,
      withLineBreak,
    }: {
      occasion: Occasion
      name: string
      withLineBreak?: boolean
    }) => {
      const nameOccasion = () =>
        `${createPossessive(name)}${
          withLineBreak ? /* istanbul ignore next */ '<br />' : ' '
        }${getPrintableOccasion(occasion, 'en-GB')}`

      const occasionName = () =>
        `${getPrintableOccasion(occasion, 'en-GB')} for${
          withLineBreak ? /* istanbul ignore next */ '<br />' : ' '
        }${name}`

      const occasionMap = new Map([
        [Occasion.BIRTHDAY, nameOccasion],
        [Occasion.SPECIAL_OCCASION, nameOccasion],
        [Occasion.CHRISTMAS, occasionName],
        [Occasion.FATHERS_DAY, occasionName],
        [Occasion.MOTHERS_DAY, occasionName],
        [Occasion.VALENTINES_DAY, occasionName],
        [Occasion.ANNIVERSARY, nameOccasion],
        [Occasion.NEW_BABY, nameOccasion],
        [Occasion.WEDDING, nameOccasion],
        [Occasion.JUST_A_NOTE, occasionName],
        [Occasion.SAINT_NICHOLAS, occasionName],
        [Occasion.LOVE, occasionName],
        [Occasion.IN_REMEMBRANCE, occasionName],
      ])

      const createString = occasionMap.get(occasion) || nameOccasion

      return createString()
    },
    'nl-NL': ({
      occasion,
      name,
      withLineBreak,
    }: {
      occasion: Occasion
      name: string
      withLineBreak?: boolean
    }) => {
      const nameOccasion = () =>
        `${createPossessive(name)}${
          withLineBreak ? /* istanbul ignore next */ '<br />' : ' '
        }${getPrintableOccasion(occasion, 'nl-NL')}`

      const occasionName = () =>
        `${getPrintableOccasion(occasion, 'nl-NL')} voor${
          withLineBreak ? /* istanbul ignore next */ '<br />' : ' '
        }${name}`

      const occasionMap = new Map([
        [Occasion.BIRTHDAY, nameOccasion],
        [Occasion.SPECIAL_OCCASION, nameOccasion],
        [Occasion.CHRISTMAS, occasionName],
        [Occasion.FATHERS_DAY, occasionName],
        [Occasion.MOTHERS_DAY, occasionName],
        [Occasion.VALENTINES_DAY, occasionName],
        [Occasion.ANNIVERSARY, nameOccasion],
        [Occasion.NEW_BABY, nameOccasion],
        [Occasion.WEDDING, nameOccasion],
        [Occasion.JUST_A_NOTE, occasionName],
        [Occasion.SAINT_NICHOLAS, occasionName],
        [Occasion.LOVE, occasionName],
        [Occasion.IN_REMEMBRANCE, occasionName],
      ])

      const createString = occasionMap.get(occasion) || nameOccasion

      return createString()
    },
  },
}

export const useLocaleText = createLocaleTextHook({
  dictionary,
})

import React, { FC } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { IconSystemCross } from '@moonpig/launchpad-assets'
import { spacingPx } from '@moonpig/launchpad-theme'
import { system as s } from '@moonpig/launchpad-system'
import { sharedButtonStyles } from './sharedButtonStyles'
import { useLocaleText } from '../locale'
import { ICON_SIZE_PX } from './constants'

const StyledClearButton = styled.button`
  ${sharedButtonStyles}
  right: ${spacingPx(12)};
  z-index: 2;

  ${s({ color: 'colorInteractionControl' })}

  &[hidden] {
    display: none;
  }

  &:hover {
    ${s({ color: 'colorInteractionButtonHover' })}
  }
  &:active {
    ${s({ color: 'colorInteractionButtonFocus' })}
  }
`

type ClearButtonProps = {
  onClick: (e: React.MouseEvent<HTMLElement>) => void
  hidden?: boolean
  as?:
    | keyof JSX.IntrinsicElements
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | React.ComponentType<React.PropsWithChildren<any>>
}

export const ClearButton: FC<React.PropsWithChildren<ClearButtonProps>> = ({
  hidden = false,
  onClick,
  as,
}) => {
  const t = useLocaleText()

  return (
    <StyledClearButton
      hidden={hidden}
      onClick={onClick}
      aria-label={t('search.clear_search')}
      type="button"
      as={as}
    >
      <IconSystemCross aria-hidden width={ICON_SIZE_PX} height={ICON_SIZE_PX} />
    </StyledClearButton>
  )
}

import type { Headers } from '../types'

const headersInstanceToObject = (
  headers: Headers,
): { [name: string]: string } => {
  const result: { [name: string]: string } = {}
  headers.forEach((value: string, key: string) => {
    result[key] = value
  })
  return result
}

const headersArrayToObject = (
  headers: string[][],
): { [name: string]: string } => {
  const result: { [name: string]: string } = {}
  headers.forEach(entry => {
    result[entry[0]] = entry[1]
  })
  return result
}

// Fetch API request header instantiation is quite flexible, which makes
// it difficult to modify (add / remove headers etc.).
//
// This function normalizes to a key value object.
//
// Note: some information could be lost e.g. multiple headers with same
// name (e.g. set-cookie).
//
// See: https://developer.mozilla.org/en-US/docs/Web/API/Headers/Headers
export const normalizeFetchRequestHeaders = (
  input: HeadersInit,
): { [key: string]: string } => {
  if (Array.isArray(input)) {
    return headersArrayToObject(input)
  }
  if (typeof input.forEach === 'function') {
    return headersInstanceToObject(input as Headers)
  }
  return input as { [key: string]: string }
}

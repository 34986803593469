import { z } from 'zod'
import { eventDataSchema } from '../common.schema'

export const AccountLinkSuccessSchema = z.object({
  event: z.literal('account_link_success'),

  event_data: eventDataSchema,
})

export type AccountLinkSuccessEvent = z.infer<typeof AccountLinkSuccessSchema>

import { logger } from '@moonpig/web-core-monitoring'

export const metricsLogger = {
  durationSince: async (name: string, startTime: number) => {
    logger.debug(`metricsLogger.durationSince(${name}, ${startTime})`)
  },
  count: async (id: string) => {
    logger.debug(`metricsLogger.count(${id})`)
  },
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useLanguage } from '@moonpig/web-core-stores'
import { useCallback } from 'react'

const DEFAULT_LOCALE = 'en-GB'

export type Locale = 'en-GB' | 'en-US' | 'en-AU' | 'en-IE' | 'nl-NL'

export type TextLocaleEntry<TProps extends any[]> =
  | ((...ctx: TProps) => string)
  | string

export type LocaleTextDictionaryItem<TProps extends any[] = any[]> = {
  [lang: string]: TextLocaleEntry<TProps>
}

export type LocaleTextDictionary<TKey extends string = string> = {
  [key in TKey]: LocaleTextDictionaryItem
}

type ParamsOrEmpty<T> = T extends (...args: infer P) => any ? P : []

export type LocaleTextHook<TDictionary extends LocaleTextDictionary> = <
  TKey extends keyof TDictionary,
>(
  key: TKey,
  ...context: ParamsOrEmpty<TDictionary[TKey][typeof DEFAULT_LOCALE]>
) => string

export const createLocaleTextHook = <TDictionary extends LocaleTextDictionary>({
  dictionary,
}: {
  dictionary: TDictionary
}) => {
  const useLocaleText = () => {
    const language = useLanguage()
    return useCallback<LocaleTextHook<TDictionary>>(
      (key, ...context) => {
        const match = dictionary[key]
        const getText = match[language] || match[DEFAULT_LOCALE]

        return typeof getText === 'function' ? getText(...context) : getText
      },
      [language],
    )
  }
  return useLocaleText
}

import React from 'react'

const PageRequiresLoginContext = React.createContext(false)

export const usePageRequiresLogin = () =>
  React.useContext(PageRequiresLoginContext)

export const PageRequiresLoginProvider: React.FC<
  React.PropsWithChildren<{
    value: boolean
  }>
> = ({ children, value }) => (
  <PageRequiresLoginContext.Provider value={value}>
    {children}
  </PageRequiresLoginContext.Provider>
)

import React, { FC } from 'react'
import { Suggestion, SuggestionType } from './Suggestion'
import { SuggestionItemType } from '../types'

type SuggestionsProps = {
  suggestions: SuggestionType[]
  maxSuggestions: number
  value: string
  highlightedIndex: number | null
  getItemProps: (props: {
    itemIndex: number
    item: SuggestionItemType
  }) => Record<string, unknown>
  getMenuProps: () => Record<string, unknown>
}

export const Suggestions: FC<SuggestionsProps> = ({
  suggestions,
  maxSuggestions,
  value,
  highlightedIndex,
  getItemProps,
  getMenuProps,
}) => {
  return (
    <ul data-testid={'lp-nav-search-suggestions'} {...getMenuProps()}>
      {suggestions.map(
        (suggestion, index) =>
          index < maxSuggestions && (
            <Suggestion
              getItemProps={getItemProps}
              item={suggestion}
              itemIndex={index}
              value={value}
              highlighted={highlightedIndex === index}
              key={
                suggestion.department
                  ? `${suggestion.value}-${suggestion.department.title}`
                  : suggestion.value
              }
            />
          ),
      )}
    </ul>
  )
}

import React, { createContext, useContext, FC } from 'react'

type NotificationsContext = {
  notifications: React.ReactNode[]
}

type NotificationsProviderProps = {
  value: NotificationsContext
}

const NotificationsContext = createContext<NotificationsContext>({
  notifications: [],
})

export const useNotificationsContext = () => useContext(NotificationsContext)

export const NotificationsProvider: FC<
  React.PropsWithChildren<NotificationsProviderProps>
> = ({ children, value }) => {
  return (
    <NotificationsContext.Provider value={value}>
      {children}
    </NotificationsContext.Provider>
  )
}

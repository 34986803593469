import { VariationType } from '@eppo/js-client-sdk-common'
import { getInstance } from '@eppo/node-server-sdk'

export type GetAllExperimentVariants = () => Record<
  string,
  string | boolean | number
>

export const setupGetAllExperimentVariants = ({
  subjectKey,
}: {
  subjectKey: string
}) => {
  const getAllExperimentVariants: GetAllExperimentVariants = () => {
    const client = getInstance()

    const allFlags = client.getFlagKeys()
    const flagConfiguration = client.getFlagConfigurations()
    const experiments: Record<string, string> = {}

    allFlags.forEach(flagKey => {
      const { variationType } = flagConfiguration[flagKey]

      switch (variationType) {
        case VariationType.STRING:
          experiments[flagKey] = client.getStringAssignment(
            flagKey,
            subjectKey,
            {},
            'control',
          )
          break

        case VariationType.BOOLEAN:
          experiments[flagKey] = client
            .getBooleanAssignment(flagKey, subjectKey, {}, false)
            .toString()
          break
        case VariationType.INTEGER:
          experiments[flagKey] = client
            .getIntegerAssignment(flagKey, subjectKey, {}, 0)
            .toString()
          break
      }
    })

    return experiments
  }
  return getAllExperimentVariants
}

import { useQuery } from '@moonpig/web-core-graphql'
import { useMemo } from 'react'
import { GetSupportedReminderFacetsQuery } from '../gql/__generated__/getSupportedReminderFacets'
import { GetSupportedReminderFacetsGQL } from '../gql'
import { ReminderOccasion, ReminderRelationship } from '../types'

export type ReminderFacetsResult = {
  occasions: ReminderOccasion[]
  relationships: ReminderRelationship[]
  loading: boolean
  error: boolean
}

export const useSupportedReminderFacets = (): ReminderFacetsResult => {
  const { data, loading, error } = useQuery<GetSupportedReminderFacetsQuery>(
    GetSupportedReminderFacetsGQL,
  )

  const value = useMemo(() => {
    if (loading || error) {
      return {
        occasions: [],
        relationships: [],
        loading,
        error: Boolean(error),
      }
    }
    const occasions =
      data?.reminderOccasions.map(occ => ({
        label: occ.label,
        defaultRelationship: occ.defaultRelationship,
        value: occ.occasion,
        defaultDate: occ.defaultDate
          ? { day: occ.defaultDate.day, month: occ.defaultDate.month }
          : null,
      })) || []
    const relationships =
      data?.reminderRelationships.map(rel => ({
        label: rel.label,
        value: rel.relationship,
      })) || []
    return {
      occasions,
      relationships,
      loading: false,
      error: false,
    }
  }, [data, loading, error])

  return value
}

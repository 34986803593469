import unfetch from 'isomorphic-unfetch'
import {
  RequestInfo,
  DedupeOpts,
  RequestInit,
  RequestInitWithDedupe,
  Response,
} from '../types'
import { dedupeFetch } from './dedupeFetch'
import { timeoutFetch } from './timeoutFetch'

const getDedupeOpts = (
  input: RequestInfo,
  init?: RequestInitWithDedupe,
): DedupeOpts | null => {
  if (!init?.dedupe) {
    return null
  }

  if (typeof input !== 'string') {
    return null
  }

  if (init.dedupe === true) {
    return {}
  }

  return init.dedupe
}

export const augmentedFetch = async (
  info: RequestInfo,
  init?: RequestInit,
): Promise<Response> => {
  const baseFetch = init?.timeout ? timeoutFetch : unfetch

  const dedupeOpts = getDedupeOpts(info, init)

  if (dedupeOpts) {
    return dedupeFetch(baseFetch, info, init, dedupeOpts)
  }

  return baseFetch(info, init)
}

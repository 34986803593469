import React, { type FC } from 'react'
import { Box } from '@moonpig/launchpad-components'
import { MegaNavItemProps } from './types'
import { Link } from '../Link'

type Props = {
  items: MegaNavItemProps['dropdown']
  focusedItem: object | undefined
  onLinkKeyDown: (e: React.KeyboardEvent) => void
}

export const QuickLinks: FC<Props> = ({
  items,
  focusedItem,
  onLinkKeyDown,
}) => {
  return (
    <ul aria-label="quick links">
      {items.map(item => {
        return (
          <Box key={item.title} as="li" pb={4}>
            <Link
              fontWeight={700}
              href={item.href}
              isFocused={Object.is(item, focusedItem)}
              onKeyDown={onLinkKeyDown}
            >
              {item.title}
            </Link>
          </Box>
        )
      })}
    </ul>
  )
}

import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useMemo,
} from 'react'
import type { RequestInit, Response } from '@moonpig/web-core-fetch'
import { authFetch } from './authFetch'

export type AuthFetch = (
  input: { path: string } & RequestInit,
) => Promise<Response>

type AuthContext = {
  authBaseUrl?: string
}

const authContext = createContext<AuthContext | null>(null)

export const AuthProvider: FC<React.PropsWithChildren<AuthContext>> = ({
  authBaseUrl,
  children,
}) => {
  const value = useMemo<AuthContext>(() => ({ authBaseUrl }), [authBaseUrl])

  return <authContext.Provider value={value}>{children}</authContext.Provider>
}

const useAuthBaseUrl = (): string => {
  const value = useContext(authContext)

  /* istanbul ignore if */
  if (!value || !value.authBaseUrl) {
    throw new Error('Missing AuthProvider')
  }

  return value.authBaseUrl
}

export const useAuthFetch = (): AuthFetch => {
  const authBaseUrl = useAuthBaseUrl()

  return useCallback<AuthFetch>(
    input => {
      return authFetch({ authBaseUrl, ...input })
    },
    [authBaseUrl],
  )
}

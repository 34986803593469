import React from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'

const StyledInput = styled.input`
  width: 100%;
  ${s({
    borderRadius: 2,
    fontSize: { xs: '16px', md: '24px' },
    p: 5,
    my: 8,
  })};
`

export const SearchInput: React.FC<{
  placeholder?: string
  onChange: (value: string) => void
}> = ({ placeholder = '', onChange }) => {
  return (
    <StyledInput
      type="text"
      placeholder={placeholder}
      onChange={({ target }) => onChange(target.value)}
    />
  )
}

import { Response } from './types'

// .clone() behaviour in node-fetch (server-side) differs
// to that in the browser.
//
// As a workaround we can proxy the text() / json() method to return
// the same cached result.
//
// See: https://github.com/node-fetch/node-fetch/issues/1568
export const proxyResponse = (response: Response) => {
  let cachedText: string | undefined

  return new Proxy(response, {
    get: (target, prop) => {
      if (prop === 'text') {
        const textFn = async () => {
          if (!cachedText) {
            cachedText = await target.text()
          }
          return cachedText
        }
        return textFn
      }

      if (prop === 'json') {
        const jsonFn = async () => {
          if (!cachedText) {
            cachedText = await target.text()
          }
          return JSON.parse(cachedText)
        }
        return jsonFn
      }

      return Reflect.get(target, prop)
    },
  })
}

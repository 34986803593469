import React, { FC, useEffect, useState } from 'react'
import {
  useAuthFetch,
  useGoogleOneTap,
  useLoggedIn,
} from '@moonpig/web-core-auth'
import { logger } from '@moonpig/web-core-monitoring'
import { GOOGLE_AUTH_CLIENT_ID } from '@moonpig/web-core-brand/config'
import { useExperiment } from '@moonpig/web-core-experiments'
import { useCoreFlag } from '@moonpig/web-core-flags'
import { redirect } from '@moonpig/web-core-routing'
import { useStoreId } from '@moonpig/web-core-stores'
import type {
  GoogleOneTapConfig,
  GoogleOneTapResponse,
  GoogleOneTapPromptMomentNotification,
} from './types'
import {
  trackOneTapFailedLogin,
  trackOneTapSuccessfulLogin,
  trackOneTapAccountLinkRequired,
  trackOneTapSuccessfulRegistration,
} from './tracking'
import { GoogleOneTapLinkModal } from './GoogleOneTapLinkModal'
import {
  OidcTokenLoginAccountLinkData,
  oneTapAuthenticationService,
} from './GoogleOneTapService'
import { GoogleOneTapError } from './GoogleOneTapError'

declare let google: {
  accounts: {
    id: {
      initialize: (config: GoogleOneTapConfig) => void
      prompt: (
        callback?: (notification: GoogleOneTapPromptMomentNotification) => void,
      ) => void
    }
  }
}

export const GoogleOneTap: FC = () => {
  const storeId = useStoreId()
  const authFetch = useAuthFetch()
  const { loggedIn, setLoggedIn } = useLoggedIn()
  const { setOneTapState } = useGoogleOneTap()
  const [showLinkModal, setShowLinkModal] = useState(true)
  const [accountLinkData, setAccountLinkData] =
    useState<OidcTokenLoginAccountLinkData>()

  const enableGoogleOneTapFlag = useCoreFlag('enable-google-one-tap')
  const enableGoogleOneTapExperiment =
    useExperiment('customer-google-one-tap') === 'enabled'
  const enableGoogleOneTap =
    enableGoogleOneTapFlag && enableGoogleOneTapExperiment

  const [showError, setShowError] = useState(false)

  useEffect(() => {
    if (!GOOGLE_AUTH_CLIENT_ID || !enableGoogleOneTap || !window.google) {
      return
    }

    function generateNonce() {
      const array = new Uint8Array(16)
      crypto.getRandomValues(array)
      return Array.from(array, byte => byte.toString(16).padStart(2, '0')).join(
        '',
      )
    }

    const nonce = generateNonce()

    const handleCredentialResponse = async (response: GoogleOneTapResponse) => {
      try {
        setOneTapState('authenticating')

        const authService = oneTapAuthenticationService(authFetch)

        const loginResponse = await authService.oidcTokenLogin({
          token: response.credential,
          nonce,
        })

        switch (loginResponse.status) {
          case 'AUTHENTICATED': {
            setOneTapState('success')
            trackOneTapSuccessfulLogin()
            // Hack to show the success state before re-render on UtilityMenu
            setTimeout(() => {
              setLoggedIn(true)
            }, 2000)
            break
          }
          case 'CREATED': {
            setOneTapState('success')
            trackOneTapSuccessfulRegistration()
            break
          }
          case 'LINKING_REQUIRED': {
            setOneTapState('warning')
            setAccountLinkData(loginResponse.data)
            setShowLinkModal(true)
            trackOneTapAccountLinkRequired()
            break
          }
          default: {
            setShowError(true)
            setOneTapState('error')
            trackOneTapFailedLogin()
            break
          }
        }
      } catch (error) {
        setShowError(true)
        setOneTapState('error')
        trackOneTapFailedLogin()
        logger.fixToday(
          'Failed to login with Google One Tap (exception)',
          undefined,
          error,
        )
      } finally {
        setTimeout(() => {
          setOneTapState('idle')
        }, 2000)
      }
    }

    if (!loggedIn) {
      try {
        google.accounts.id.initialize({
          client_id: GOOGLE_AUTH_CLIENT_ID,
          callback: handleCredentialResponse,
          auto_select: false,
          cancel_on_tap_outside: false,
          nonce,
        })

        google.accounts.id.prompt()
      } catch (error) {
        logger.fixToday('Failed to initialise Google One Tap', undefined, error)
      }
    }
  }, [loggedIn, setLoggedIn, enableGoogleOneTap, authFetch, setOneTapState])

  const handleErrorLinkClick = () => {
    redirect({
      path: `/${storeId}/account/login/`,
      returnUrl: window.location.pathname + window.location.search,
    })
  }

  return (
    <>
      {accountLinkData && (
        <GoogleOneTapLinkModal
          isOpen={showLinkModal}
          onClose={() => {
            setShowLinkModal(false)
            setAccountLinkData(undefined)
          }}
          data={accountLinkData}
        />
      )}
      {showError && (
        <GoogleOneTapError
          onDismiss={() => setShowError(false)}
          onLinkClick={handleErrorLinkClick}
        />
      )}
    </>
  )
}

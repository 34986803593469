/** ****************** BEWARE ************************
Additional experiments affect caching
- Only provide experiments actively used by the API's
- Expiry must be within 90 days
- Your experiment will not be passed after the expiry
- Extend expiry via additional PR's only if necessary
- Expiry date should be in format new Date('YYYY-MM-DD')
*****************************************************/
export type Experiment = {
  key: string
  expiry: Date
}
export const graphQlExperimentsWhitelist: Experiment[] = [
  {
    key: 'browse-navigation-caching',
    expiry: new Date('2024-08-05'),
  },
  {
    key: 'loyalty-mp-plus-499',
    expiry: new Date('2024-03-01'),
  },
  {
    key: 'attach-personalised-promotion-model',
    expiry: new Date('2025-02-11'),
  },
  {
    key: 'mnpg-price-trial',
    expiry: new Date('2025-01-01'),
  },
  {
    key: 'fulfilment-pricing-1C-increase-Oct-2024',
    expiry: new Date('2024-12-01'),
  },
  {
    key: 'fulfilment-pricing-T24-Large-Oct-2024',
    expiry: new Date('2024-12-01'),
  },
  {
    key: 'search-use-department-suggestions',
    expiry: new Date('2025-02-10'),
  },
  {
    key: 'search-trending-searches',
    expiry: new Date('2025-02-25'),
  },
]

let cached: Record<string, string | undefined> | undefined

export const getRouteData = ():
  | Record<string, string | undefined>
  | undefined => {
  return cached
}

export const setRouteData = (routeData: Record<string, string | undefined>) => {
  cached = routeData
}

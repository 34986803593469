import { z } from 'zod'
import { cookieConsentDataSchema, eventDataSchema } from '../common.schema'

export const CookieConsentSchema = z.object({
  event: z.literal('cookie_consent'),

  cookie_consent_data: cookieConsentDataSchema,
  event_data: eventDataSchema,
})

export type CookieConsentEvent = z.infer<typeof CookieConsentSchema>

import React, { useContext } from 'react'
import { DEFAULT_TAGG_EXPERIENCE } from '../../constants'

export type TaggstarContextProps = {
  taggExperience: string
  canPublishEvent: boolean
}

export const TaggstarContext = React.createContext<TaggstarContextProps>({
  taggExperience: DEFAULT_TAGG_EXPERIENCE,
  canPublishEvent: false,
})

export const useTaggstar = () => useContext(TaggstarContext)

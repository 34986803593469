import { fetch } from '@moonpig/web-core-fetch'
import type { RequestInit } from '@moonpig/web-core-fetch'
import type { Metrics, MetricsTraceOptions } from '@moonpig/web-core-monitoring'
import type { SiteSpectResponse } from './types'
import { logWarning } from './log'

const callSiteSpect = async (
  config: RequestInit,
  siteSpectUrl: string,
  metrics: Metrics,
  metricOptions: MetricsTraceOptions,
): Promise<SiteSpectResponse> => {
  const response = await metrics.traceAsync(metricOptions, () =>
    fetch(siteSpectUrl, { ...config, method: 'POST', timeout: 350 }),
  )

  if (response?.status >= 400) {
    throw new Error('Failed to call SiteSpect')
  }

  const json = await response.json()

  if (response.status === 204 || !json) {
    throw new Error('Failed to return data')
  }

  if (!json.cookies) {
    throw new Error('Failed to return cookies')
  }

  if (!json.Campaigns || json.Campaigns.length === 0) {
    logWarning('There were no campaigns in the SiteSpect response', {
      sitespectResponse: json,
    })
  }
  return json
}

const createCookiesHeader = (cookies: Record<string, string | undefined>) => {
  if (!cookies.SSRT || !cookies.SSID) {
    return ''
  }
  return `SSRT=${cookies.SSRT};SSID=${cookies.SSID}`
}

export const getAssignmentsForCampaigns = async (
  cookies: Record<string, string | undefined>,
  userAgent: string | undefined = '',
  siteSpectUrl: string,
  metrics: Metrics,
): Promise<SiteSpectResponse> => {
  const config: RequestInit = {
    credentials: 'include',
    headers: {
      Cookie: createCookiesHeader(cookies),
      'User-Agent': userAgent,
    },
    mode: 'no-cors',
  }

  return callSiteSpect(config, siteSpectUrl, metrics, {
    metricName: 'fetch-sitespect',
    metricDimensions: { action: 'getAssignmentsForCampaigns' },
    traceName: 'fetch-sitespect-getAssignmentsForCampaigns',
  })
}

export const countInVariations = async (
  variationIds: string[],
  cookies: Record<string, string | undefined>,
  userAgent: string | undefined = '',
  siteSpectUrl: string,
  metrics: Metrics,
): Promise<SiteSpectResponse> => {
  const config: RequestInit = {
    body: JSON.stringify({
      visits: [{ AsmtCounted: variationIds }],
    }),
    credentials: 'include',
    headers: {
      Cookie: createCookiesHeader(cookies),
      'User-Agent': userAgent,
    },
    mode: 'no-cors',
  }

  return callSiteSpect(config, siteSpectUrl, metrics, {
    metricName: 'fetch-sitespect',
    metricDimensions: { action: 'countInVariations' },
    traceName: 'fetch-sitespect-countInVariations',
  })
}

import React from 'react'
import {
  Alert as LaunchpadAlert,
  Flex,
  Heading,
  Text,
} from '@moonpig/launchpad-components'
import { useCoreLocaleText } from '@moonpig/web-core-locale-text'

const Alert: React.FC<{
  title?: string
  message?: string
  centered?: boolean
}> = props => {
  const getLocaleText = useCoreLocaleText()

  const headingText = props.title || getLocaleText('common.error_unknown')
  const messageText = props.message || getLocaleText('common.error_reload')

  return (
    <LaunchpadAlert variant="error" my={5}>
      <Heading
        level="h4"
        mb={0}
        typography={{ xs: 'typeMobileDisplay05', md: 'typeDesktopDisplay06' }}
      >
        {headingText}
      </Heading>
      <Text>{messageText}</Text>
    </LaunchpadAlert>
  )
}

export const ErrorAlert: React.FC<{
  title?: string
  message?: string
  centered?: boolean
}> = props => {
  const alert = <Alert title={props.title} message={props.message} />

  if (props.centered) {
    return (
      <Flex
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        {alert}
      </Flex>
    )
  }

  return alert
}

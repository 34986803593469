import { gql } from '@moonpig/web-core-graphql'

export const CreateIncentivisedRemindersGQL = gql`
  mutation CreateIncentivisedReminders($input: [ReminderInput!]!) {
    createIncentivisedReminders(input: $input) {
      ... on CreatedIncentivisedReminders {
        reminders {
          id
        }
      }
      ... on BadRequest {
        __typename
        message
      }
    }
  }
`

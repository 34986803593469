import { gql } from '@moonpig/web-core-graphql'

export const DeleteReminderGQL = gql`
  mutation DeleteReminder($id: ID!) {
    deleteReminder(id: $id) {
      ... on DeletedReminder {
        id
      }
      ... on ReminderNotFound {
        message
      }
    }
  }
`

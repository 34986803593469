import { RouteParamTypeMap } from './types'

export const mapValues = <TFrom, TTo>(
  obj: Record<string, TFrom>,
  fn: (name: string, value: TFrom) => TTo,
): Record<string, TTo> =>
  Object.entries(obj).reduce<Record<string, TTo>>((acc, [name, value]) => {
    acc[name] = fn(name, value)
    return acc
  }, {})

export const toArray = <T>(value: T | T[] | undefined): T[] => {
  if (Array.isArray(value)) {
    return value
  }
  return value === undefined ? [] : [value]
}

export const isOptionalType = (type: keyof RouteParamTypeMap): boolean =>
  type.endsWith('.optional')

export const isMultipleType = (type: keyof RouteParamTypeMap): boolean =>
  type.endsWith('.multiple')

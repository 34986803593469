import React, { FC } from 'react'
import { ApolloProvider } from '@apollo/client'
import { getDataFromTree } from '@apollo/client/react/ssr'
import type { Fetch } from '@moonpig/web-core-fetch'
import { createClient } from './client'
import { GraphQLContext, GraphQLInitialState, GraphQLOptions } from './types'

type GraphQLProviderProps = {
  context: GraphQLContext
}

const createGraphQLContext = (
  options: GraphQLOptions,
  isServer: boolean,
  initialState: GraphQLInitialState = { apolloInitialState: {} },
  fetch: Fetch,
): GraphQLContext => {
  let cachedOptions = options
  let apolloClient = createClient(
    options,
    isServer,
    initialState.apolloInitialState,
    fetch,
  )

  return {
    getApolloClient: () => apolloClient,
    query: async ({ query, variables, fetchPolicy }) => {
      const { data, loading, errors } = await apolloClient.query({
        query,
        variables,
        fetchPolicy,
      })
      return { data, loading, errors }
    },
    setOptions: newOptions => {
      if (cachedOptions !== newOptions) {
        cachedOptions = newOptions
        apolloClient = createClient(
          newOptions,
          isServer,
          apolloClient.cache.extract(),
          fetch,
        )
      }
    },
  }
}

export const createServerGraphQLContext = (
  options: GraphQLOptions,
  fetch: Fetch,
  initialState?: GraphQLInitialState,
): GraphQLContext => createGraphQLContext(options, true, initialState, fetch)

export const createBrowserGraphQLContext = (
  options: GraphQLOptions,
  fetch: Fetch,
  initialState?: GraphQLInitialState,
): GraphQLContext => createGraphQLContext(options, false, initialState, fetch)

export const getGraphQLInitialState = async (
  element: React.ReactNode,
  context: GraphQLContext,
): Promise<GraphQLInitialState> => {
  await getDataFromTree(
    <ApolloProvider client={context.getApolloClient()}>
      {element}
    </ApolloProvider>,
  )
  return {
    apolloInitialState: context.getApolloClient().cache.extract(),
  }
}

export const GraphQLProvider: FC<
  React.PropsWithChildren<GraphQLProviderProps>
> = ({ context: { getApolloClient }, children }) => (
  <ApolloProvider client={getApolloClient()}>{children}</ApolloProvider>
)

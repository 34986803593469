import React from 'react'
import { Footer as LaunchpadFooter } from '@moonpig/web-core-nav'
import { Text, Container, Flex } from '@moonpig/launchpad-components'
import {
  FOOTER as FOOTER_CONFIG,
  IconDescriptor,
  SocialItem,
  WithIconAsComponent,
} from '@moonpig/web-core-brand/config'
import { Region } from '@moonpig/web-core-types'
import {
  IconFlagUnitedKingdom,
  IconFlagAustralia,
  IconFlagUnitedStates,
  IconFlagIreland,
  IconSystemSocialFacebook,
  IconSystemSocialInstagram,
  IconSystemSocialPinterest,
  IconSystemSocialTwitter,
  IconSystemSocialYoutube,
  IconLogoAppleAppStore,
  IconLogoGoogleAppStore,
  IconSystemSocialLinkedin,
} from '@moonpig/launchpad-assets'

import { Markup } from '@moonpig/web-core-utils'
import { useLocaleText } from '../../AppShell.locale'
import {
  buildTrackedLinkListItems,
  trackedAppItems,
  trackedBrandItems,
  trackedRegionItems,
  trackedSocialItems,
} from './tracking'

type LaunchpadFooterProps = React.ComponentProps<typeof LaunchpadFooter>

export type FooterProps = Omit<
  LaunchpadFooterProps,
  'socialItems' | 'appItems' | 'brandItems'
> & {
  region: Region
}

type IconMap = {
  [Key in IconDescriptor]: (props: React.SVGProps<SVGSVGElement>) => JSX.Element
}

const iconMap: IconMap = {
  'uk-flag': IconFlagUnitedKingdom,
  'us-flag': IconFlagUnitedStates,
  'aus-flag': IconFlagAustralia,
  'ie-flag': IconFlagIreland,
  facebook: IconSystemSocialFacebook,
  twitter: IconSystemSocialTwitter,
  youtube: IconSystemSocialYoutube,
  linkedin: IconSystemSocialLinkedin,
  instagram: IconSystemSocialInstagram,
  pinterest: IconSystemSocialPinterest,
  'apple-app-store': IconLogoAppleAppStore,
  'google-app-store': IconLogoGoogleAppStore,
}

export const Footer: React.FC<FooterProps> = ({
  region,
  linkListItems,
  ...props
}) => {
  const t = useLocaleText()
  const appLabelMap = {
    apple: t('download_on_the_app_store'),
    google: t('get_it_on_google_play'),
  }

  const {
    appItems: appItemsConfig,
    socialItems: socialItemsConfig,
    paymentProviders,
    regionItems: regionItemsConfig,
    // istanbul ignore next
    legal,
  } = FOOTER_CONFIG

  const socialItems = socialItemsConfig[region]?.map(item => ({
    ...item,
    icon: iconMap[item.icon],
  }))

  const appItems = appItemsConfig.map(item => ({
    ...item,
    label: appLabelMap[item.id],
    icon: iconMap[item.icon],
  }))

  const regionItems = regionItemsConfig?.map(item => ({
    ...item,
    icon: iconMap[item.icon],
  }))

  const linkListItemsWithTracking = buildTrackedLinkListItems(linkListItems)
  const regionItemsWithTracking = trackedRegionItems({ region, regionItems })
  const appItemsWithTracking = trackedAppItems(appItems)
  const socialItemsWithTracking = trackedSocialItems(
    socialItems as WithIconAsComponent<SocialItem>[],
  )
  const brandItemsWithTracking = trackedBrandItems(region)

  return (
    <>
      <LaunchpadFooter
        {...props}
        paymentProviderItems={paymentProviders}
        socialItems={socialItemsWithTracking}
        appItems={appItemsWithTracking}
        regionItems={regionItemsWithTracking}
        linkListItems={linkListItemsWithTracking}
        brandItems={brandItemsWithTracking}
      />
      {
        /* istanbul ignore next */
        legal ? (
          <Container>
            <Flex
              paddingTop={10}
              paddingBottom={10}
              width="100%"
              alignItems="center"
              justifyContent="center"
              flexWrap="wrap"
            >
              <a href={legal.image.link} target="_blank" rel="noreferrer">
                <img
                  src={legal.image.src}
                  alt={legal.image.description}
                  height="64px"
                />
              </a>
              <Text typography="typeBodyCaption" ml={4}>
                <Markup htmlString={legal.htmlString} />
              </Text>
            </Flex>
          </Container>
        ) : null
      }
    </>
  )
}

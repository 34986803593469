import React, { FC } from 'react'
import { system as s } from '@moonpig/launchpad-system'
import { styled } from '@moonpig/launchpad-utils'
import { Link } from '@moonpig/launchpad-components'

import { COLOR_LINK_HOVER, KEYLINE_SIZE_PX } from '../constants'

const StyledLink = styled(Link)`
  display: inline-block;
  color: inherit;
  ${s({
    typography: 'typeBodyCaption',
    py: 3,
    borderRadius: 1,
  })}

  &.is-active,
  &:hover,
  &:focus {
    color: ${COLOR_LINK_HOVER};
    text-decoration: none;

    span {
      background-image: linear-gradient(
        to right,
        ${COLOR_LINK_HOVER} 0%,
        ${COLOR_LINK_HOVER} 100%
      );
      background-repeat: repeat-x;
      background-position: 0 100%;
      background-size: 100% ${KEYLINE_SIZE_PX};
    }
  }
`

type NavLinkProps = React.ComponentProps<typeof Link> & { isActive?: boolean }

export const NavLink: FC<React.PropsWithChildren<NavLinkProps>> =
  React.forwardRef(
    ({ children, href, isActive, className: _className, ...rest }, ref) => {
      const className = [_className, isActive && 'is-active']
        .filter(v => Boolean(v))
        .join(' ')
      return (
        <StyledLink {...rest} ref={ref} href={href} className={className}>
          <span>{children}</span>
        </StyledLink>
      )
    },
  )

import React from 'react'
import { createApp } from '@moonpig/web-core-app'
import { flagSchema } from '@moonpig/web-explore-flags'
import {
  RemindersNotification,
  IncentivisedRemindersModal,
} from '@moonpig/web-shared-reminders'
import { legacyRedirectsConfig } from '@moonpig/web-core-brand/redirects'
import { createLegacyRedirectHandler } from '@moonpig/web-core-redirects'

const redirect = createLegacyRedirectHandler(legacyRedirectsConfig)

export default createApp({
  notifications: [<RemindersNotification key="reminders" />],
  inject: <IncentivisedRemindersModal />,
  redirect,
  flagSchema,
})

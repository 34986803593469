import { SelectContentEvent } from './schemas/multiple_teams/selectContent.schema'

export const createGenericSelectContentEvent = ({
  eventCategory,
  eventAction,
  eventLabel,
  eventValue = undefined,
  itemId,
}: {
  eventCategory: string
  eventAction: string
  eventLabel: string
  eventValue?: number
  itemId?: string
}): SelectContentEvent => {
  return {
    event: 'select_content',
    content_data: {
      content_type: `${eventCategory} | ${eventAction} | ${eventLabel}`
        .substring(0, 100)
        .toLocaleLowerCase(),
      item_id: itemId?.toLocaleLowerCase(),
    },
    // For GA3 backward compatibility
    event_data: {
      category: eventCategory.toLocaleLowerCase(),
      action: eventAction.toLocaleLowerCase(),
      label: `${eventLabel.toLocaleLowerCase()}${
        itemId ? ` | ${itemId.toLocaleLowerCase()}` : ''
      }`,
      non_interaction: true,
      value: eventValue,
    },
    // Web only //
    discount_data: undefined,
    ecommerce: undefined,
    error_data: undefined,
  }
}

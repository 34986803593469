import { DepartmentsEnum } from '@moonpig/web-core-types-graphql'

export const getParentDepartment = (department: string): DepartmentsEnum => {
  switch (department) {
    case 'MINI_CARDS':
    case 'GREETING_CARDS':
    case 'INVITATIONS_AND_ANNOUNCEMENTS':
    case 'WEDDING_STATIONERY':
    case 'ALL_CARDS': {
      return DepartmentsEnum.ALL_CARDS
    }
    case 'GUERNSEY_POSTAL_FLOWERS':
    case 'FLOWERS':
    case 'ARENA_POSTAL_FLOWERS':
    case 'PLANTS':
    case 'ALL_FLOWERS_AND_PLANTS': {
      return DepartmentsEnum.ALL_FLOWERS_AND_PLANTS
    }
    case 'GIFT_EXPERIENCES':
    case 'GIFT_VOUCHERS':
    case 'NON_CUSTOMISABLE_GIFTS':
    case 'MUGS':
    case 'TSHIRTS':
    case 'ALCOHOL_GIFTS':
    case 'CANVASES':
    case 'FRAMES':
    case 'SOFT_TOYS':
    case 'ACRYLICS':
    case 'FOOD_GIFTS':
    case 'CASES':
    case 'BEAUTY':
    case 'GIFT_BOXES':
    case 'BALLOONS':
    case 'WALL_ART':
    case 'CALENDAR_PHOTOBOX':
    case 'STATIONERY_AND_CRAFT':
    case 'GADGETS_AND_NOVELTIES':
    case 'TOYS_AND_GAMES':
    case 'GIFTS_FOR_HOME':
    case 'JEWELLERY_AND_ACCESSORIES':
    case 'LETTERBOX_GIFTS':
    case 'PARTY':
    case 'DIGITAL_GIFTS':
    case 'DIGITAL_GIFT_CARDS':
    case 'ALL_GIFTS': {
      return DepartmentsEnum.ALL_GIFTS
    }
    default:
      return DepartmentsEnum.ALL_CARDS
  }
}

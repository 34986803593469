import { logger } from '@moonpig/web-core-monitoring'

const LOG_WARNINGS = process.env.MNPG_ENVIRONMENT_NAME !== 'local'

type LogWarning = typeof logger.warning

export const logWarning: LogWarning = (...args) => {
  /* istanbul ignore else */
  if (LOG_WARNINGS) {
    logger.warning(...args)
  }
}

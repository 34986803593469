import { logger } from '@moonpig/web-core-monitoring'
import { appendSlash } from './path-utilities/appendSlash'
import pathMatchesUri from './path-utilities/pathMatchesUri'
import pathMatchesUriDynamic from './path-utilities/pathMatchesUriDynamic'
import { MatchType, LegacyRedirect, LegacyRedirectType } from './types'
import { metricsLogger } from './metricsLogger'

const redirectTypeEnum = {
  dynamic: 'dynamic-redirect',
  static: 'static-redirect',
}

const areAllNumbers = (string: string) => string.match(/^[0-9]+$/)

const prependLabelToId = (dynamicPathPart: string) => {
  const parts = dynamicPathPart.split('/').filter(part => part !== '')
  const newParts = parts.map(part => {
    const isId = areAllNumbers(part)

    if (isId) {
      return `grtz${part}`
    }
    return part
  })
  return `${newParts.join('/')}/`
}

const findRedirectInCollectionByType = (
  redirectType: 'dynamic-redirect' | 'static-redirect',
  redirects: LegacyRedirect[],
  normalisedUri: string,
) => {
  let result
  if (redirectType === redirectTypeEnum.dynamic) {
    result = redirects.find(redirect =>
      pathMatchesUriDynamic(normalisedUri, redirect.old),
    )
  } else {
    result = redirects.find(redirect =>
      pathMatchesUri(normalisedUri, redirect.matchType, redirect.old),
    )
  }

  return result ? { ...result } : null
}

const logRedirectMatch = (
  matchingRedirect: LegacyRedirect,
  normalisedUri: string,
  patchMatchType?: MatchType,
) => {
  logger.debug('Matched {type} {path_match} for URI {request_url}', {
    path_match: matchingRedirect.old,
    redirect_to_path: matchingRedirect.new,
    path_match_type: patchMatchType || matchingRedirect.matchType || 'equals',
    request_url: normalisedUri,
    type: 'redirect',
  })
}

const getNewDynamicRedirectPath = (
  normalisedUri: string,
  matchingDynamicPath: LegacyRedirect,
) => {
  const dynamicPathPart = normalisedUri.substring(
    matchingDynamicPath.old.length,
  )
  const dynamicPartWithLabel = prependLabelToId(dynamicPathPart)
  const redirectPath = `${matchingDynamicPath.new}${dynamicPartWithLabel}`

  if (!redirectPath.endsWith('/')) {
    appendSlash(redirectPath)
  }

  return redirectPath
}

const getRedirect = async (
  normalisedUri: string,
  redirects: LegacyRedirect[],
  redirectType: LegacyRedirectType,
) => {
  const startTime = Date.now()
  const matchingRedirect = findRedirectInCollectionByType(
    redirectType,
    redirects,
    normalisedUri,
  )
  await metricsLogger.durationSince(
    `find-matching-${redirectType}-duration`,
    startTime,
  )

  if (matchingRedirect) {
    if (redirectType === redirectTypeEnum.dynamic) {
      matchingRedirect.new = getNewDynamicRedirectPath(
        normalisedUri,
        matchingRedirect,
      )
    }

    logRedirectMatch(
      matchingRedirect,
      normalisedUri,
      redirectType === redirectTypeEnum.dynamic
        ? 'startsWith'
        : matchingRedirect.matchType,
    )

    return matchingRedirect
  }

  return null
}

export const getMatchingRedirect = async (
  normalisedUri: string,
  staticRedirects: LegacyRedirect[],
  dynamicRedirects: LegacyRedirect[],
) => {
  const staticRedirect = await getRedirect(
    normalisedUri,
    staticRedirects,
    'static-redirect',
  )
  if (staticRedirect) {
    return staticRedirect
  }

  const dynamicRedirect = await getRedirect(
    normalisedUri,
    dynamicRedirects,
    'dynamic-redirect',
  )
  if (dynamicRedirect) {
    return dynamicRedirect
  }

  return null
}

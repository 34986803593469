import { colorValue } from '@moonpig/launchpad-theme'

export const NAV_BP = 'xl'
export const HEADER_HEIGHT_COLLAPSED = 74
export const HEADER_HEIGHT_DESKTOP_VARIANT = 132
export const HEADER_MENU_ICON_WIDTH_PX = '104px'
export const MOBILE_NAV_WIDTH = 320
export const MOBILE_NAV_WIDTH_PX = `${MOBILE_NAV_WIDTH}px`
export const KEYLINE_SIZE = 2
export const KEYLINE_SIZE_PX = `${KEYLINE_SIZE}px`
export const ICON_SIZE_PX = '28px'
export const BACK_BUTTON_ICON_SIZE_PX = '24px'
export const TRANSITON_TIME_MS = '200ms'

export const COLOR_DEFAULT_TEXT = colorValue('colorInteractionIcon')
export const COLOR_KEYLINE = colorValue('colorBackgroundSite')
export const COLOR_LINK_HOVER = colorValue('colorInteractionButton')
export const COLOR_FOCUS_INDICATOR = colorValue(
  'colorInteractionFocusIndicator',
)

export const ZINDEX_STICKY_HEADER = 1000

export const HEADER_BANNER_HEIGHT = 32

export const createReminderDateText =
  (lang: 'en-GB' | 'nl-NL') =>
  ({ date }: { date: Date }) => {
    if (lang === 'nl-NL') {
      const day = date.getDate()
      const dayOfWeek = date.toLocaleString('nl-nl', { weekday: 'long' })
      return `${dayOfWeek} ${day} ${date.toLocaleString('nl-nl', {
        month: 'long',
      })}`
    }

    const suffix = ['th', 'st', 'nd', 'rd']
    const day = date.getDate()
    const v = day % 100
    const dayOfWeek = date.toLocaleString('en-gb', { weekday: 'long' })
    return `${dayOfWeek} ${day}${
      suffix[(v - 20) % 10] || suffix[v] || suffix[0]
    } ${date.toLocaleString('en-gb', { month: 'long' })}`
  }

/*
 * Mapping from TrackJS application to page type (key in routes.ts)
 *
 * See package README for more information.
 */

const appToPageConfig: {
  [appName: string]: string[]
} = {
  'moonpig-web-app-account': ['forgotten-password', 'login', 'reset-password'],
  'moonpig-web-app-checkout': [
    'addresses',
    'basket',
    'ecard-details',
    'multi-address',
    'order-confirmation',
    'pay',
    'payment-methods',
    'product-soldout',
    'review-pay',
  ],
  'moonpig-web-app-ecard': ['ecard'],
  'moonpig-web-app-editor': [
    'customise',
    'customise-duplicate',
    'customise-edit',
  ],
  'moonpig-web-app-explore': [
    'digital-gift-card-options',
    'dynamic',
    'error404',
    'pay-per-click',
  ],
  'moonpig-web-app-content': ['content'],
  'moonpig-web-app-browse': ['product-details', 'home', 'favourites'],
  'moonpig-web-app-media': ['media'],
  'moonpig-web-app-search': ['search'],
  'moonpig-web-group-cards': [
    'group-cards-collect-messages',
    'group-cards-create-project',
    'group-cards-dashboard',
    'group-cards-edit-project',
  ],
  'moonpig-web-work': ['work-create-project', 'work-order-confirmation'],
  'moonpig-web-loyalty': [
    'account-reminders',
    'membership-details',
    'reminder-inflow',
    'reminder-share',
    'reminder-unsubscribe',
    'reminder-landing',
    'reminders',
  ],
  'moonpig-web-app-attach': [
    'see-more',
    'basket',
    'add-a-gift',
    'add-a-card',
    'upgrade-your-card',
  ],
}

export const pageToAppConfig = Object.keys(appToPageConfig).reduce<{
  [page: string]: string
}>((acc, curr) => {
  const values = appToPageConfig[curr]
  values.forEach(value => {
    acc[value] = curr
  })
  return acc
}, {})

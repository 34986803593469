import React, { FC } from 'react'
import { LogoLink as LaunchpadLogoLink } from '../../shared/LogoLink'

export const LogoLink: FC<{
  href: string
  title: string
  complementaryImageUrl?: string
}> = ({ href, title, complementaryImageUrl }) => {
  return (
    <LaunchpadLogoLink
      href={href}
      title={title}
      complementaryImageUrl={complementaryImageUrl}
    />
  )
}

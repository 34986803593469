import { useEffect } from 'react'
import { gql, useLazyQuery } from '@moonpig/web-core-graphql'
import { useLoggedIn } from '@moonpig/web-core-auth'
import {
  GetAllUpcomingRemindersQuery,
  GetAllUpcomingRemindersQueryVariables,
} from './__generated__/useFetchReminders'

export const GetAllUpcomingRemindersGQL = gql`
  query GetAllUpcomingReminders($endDate: String) {
    reminders(input: { endDate: $endDate }) {
      id
      nextDate
    }
  }
`

export const useFetchReminders = () => {
  const { loggedIn } = useLoggedIn()

  const [fetchReminders, { data }] = useLazyQuery<
    GetAllUpcomingRemindersQuery,
    GetAllUpcomingRemindersQueryVariables
  >(GetAllUpcomingRemindersGQL)

  useEffect(() => {
    if (loggedIn) {
      const date = new Date(Date.now())
      date.setDate(date.getDate() + 30)
      fetchReminders({ variables: { endDate: date.toISOString() } })
    }
  }, [fetchReminders, loggedIn])

  return data?.reminders || []
}

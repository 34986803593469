import { RouteApp, RouteDefinition, RouteParams } from './types'

export const defineRoute = <TParams extends RouteParams>(
  params: TParams,
  builder: (names: { [name in keyof TParams]: string | number }) => string,
  app: RouteApp,
): RouteDefinition<TParams> => ({
  params,
  path: values => builder(values),
  app,
})

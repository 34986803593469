import React, { useContext, useState } from 'react'
import { deleteUrlParam, insertUrlParam } from '@moonpig/web-core-utils'
import {
  ExperimentCampaignKeys,
  ExperimentCampaigns,
  experimentCampaigns,
  experimentsContext,
} from '@moonpig/web-core-experiments'
import { ExperimentsListItem } from './ExperimentsListItem'
import { SearchInput } from './components/SearchInput'

type ExperimentCampaignsListType = ExperimentCampaigns | Record<string, never>

export const ExperimentsList: React.FC = () => {
  const experiments = useContext(experimentsContext)
  const [experimentsList, setExperimentsList] =
    useState<ExperimentCampaignsListType>({
      ...experimentCampaigns,
    })

  const handleSearchInput = (value: string) => {
    const filteredExperimentsListKeys = Object.keys(experimentCampaigns).filter(
      exp => exp.includes(value.toLowerCase()),
    ) as ExperimentCampaignKeys[]

    const filteredExperimentsList = filteredExperimentsListKeys.reduce(
      (acc, current) => ({ ...acc, [current]: experimentCampaigns[current] }),
      {},
    )

    setExperimentsList(filteredExperimentsList)
  }

  const handleSelectChange = ({
    title,
    value,
  }: {
    title: string
    value: string
  }) => {
    if (value === 'Not bucketed') {
      deleteUrlParam(title)
    } else {
      insertUrlParam(title, value)
    }

    window.location.reload()
  }

  return (
    <>
      <SearchInput
        placeholder="Search experiment"
        onChange={handleSearchInput}
      />
      <ul>
        {Object.keys(experimentsList).map(experiment => (
          <ExperimentsListItem
            key={experiment}
            title={experiment}
            values={experimentsList[experiment as ExperimentCampaignKeys]}
            selectedValue={experiments[experiment]}
            onChange={handleSelectChange}
          />
        ))}
      </ul>
    </>
  )
}

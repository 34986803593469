import React, { useEffect } from 'react'
import { Box, Flex, Text, TertiaryButton } from '@moonpig/launchpad-components'
import { useRouter } from '@moonpig/web-core-routing'
import { IllustrationReminders } from '@moonpig/launchpad-assets'
import {
  createGenericSelectContentEvent,
  trackGAEvent,
} from '@moonpig/web-core-analytics'
import { useStoreId } from '@moonpig/web-core-stores'
import { LoadingIndicator, Markup } from '@moonpig/web-shared-components'
import { useLocaleText } from '../../../localisation'
import { trackRemindersSeen } from './analytics'
import { ReminderCard } from '../../ReminderCard'
import { Reminder } from '../../../types'

type ReminderNotificationContentProps = {
  reminders: Reminder[] | undefined
  loading: boolean
}

enum State {
  NO_REMINDERS_LOADED,
  LOADING,
  NO_REMINDERS,
  HAS_REMINDERS,
}

type PageState =
  | { state: State.NO_REMINDERS_LOADED }
  | {
      state: State.LOADING
    }
  | { state: State.NO_REMINDERS }
  | { state: State.HAS_REMINDERS; reminders: Reminder[] }

const useReminderNoticiationState = ({
  reminders,
  loading,
}: ReminderNotificationContentProps): PageState => {
  if (loading) return { state: State.LOADING }
  if (reminders && reminders.length === 0) return { state: State.NO_REMINDERS }
  if (reminders) return { state: State.HAS_REMINDERS, reminders }
  return { state: State.NO_REMINDERS_LOADED }
}

export const UpcomingRemindersContent: React.FC<
  ReminderNotificationContentProps
> = props => {
  const pageState = useReminderNoticiationState(props)

  switch (pageState.state) {
    case State.LOADING:
      return <LoadingIndicator data-testid="loading-spinner" />
    case State.HAS_REMINDERS:
      return <Reminders reminders={pageState.reminders} />
    case State.NO_REMINDERS:
    case State.NO_REMINDERS_LOADED:
    default:
      return <NoReminders />
  }
}

const Reminders = ({ reminders }: { reminders: Reminder[] }) => {
  const router = useRouter()
  const storeId = useStoreId()
  const localiseText = useLocaleText()

  useEffect(() => {
    trackRemindersSeen(reminders.length)
  }, [reminders.length])

  return (
    <Box>
      {reminders.map((reminder, index) => (
        <ReminderCard
          reminder={reminder}
          key={reminder.id}
          withShop
          shopButtonFilled
          marginBottom={index === reminders.length - 1 ? 7 : 6}
        />
      ))}
      <TertiaryButton
        width="100%"
        onClick={() => {
          trackGAEvent(
            createGenericSelectContentEvent({
              eventAction: 'view all reminders notification opened',
              eventLabel: `view all reminders`,
              eventCategory: 'reminders',
            }),
          )
          router.push({
            name: 'account-reminders',
            params: { region: storeId },
          })
        }}
      >
        {localiseText('common.reminder_notification.view_all')}
      </TertiaryButton>
    </Box>
  )
}

const NoReminders = () => {
  const localiseText = useLocaleText()

  useEffect(() => {
    trackRemindersSeen(0)
  }, [])

  return (
    <Flex flexDirection="column" data-testid="no-reminders" textAlign="center">
      <Box margin="auto">
        <IllustrationReminders width="56px" />
      </Box>
      <Text
        typography={{ xs: 'typeMobileDisplay03', md: 'typeDesktopDisplay04' }}
        mb={3}
      >
        <Markup
          htmlString={localiseText(
            'common.reminder_notification.other_customers',
          )}
        />
      </Text>
      <Text typography="typeBodyCaption">
        {localiseText('common.reminder_notification.set_prompt')}
      </Text>
    </Flex>
  )
}

const OCCASIONS_LOCALE: Record<
  string,
  { 'en-GB': string; 'nl-NL': string; facetValue: string }
> = {
  IN_REMEMBRANCE: {
    'en-GB': 'In Remembrance',
    'nl-NL': 'In memoriam',
    facetValue: 'in remembrance',
  },
  ANNIVERSARY: {
    'en-GB': 'Anniversary',
    'nl-NL': 'Jubileum',
    facetValue: 'anniversary',
  },
  CHRISTMAS: {
    'en-GB': 'Christmas',
    'nl-NL': 'Kerstmis',
    facetValue: 'christmas',
  },
  LOVE: { 'en-GB': 'Love', 'nl-NL': 'Liefde', facetValue: 'love' },
  MOTHERS_DAY: {
    'en-GB': "Mother's Day",
    'nl-NL': 'Moederdag',
    facetValue: "mother's day",
  },
  SAINT_NICHOLAS: {
    'en-GB': 'Saint Nicholas',
    'nl-NL': 'Sinterklaas',
    facetValue: 'saint nicholas',
  },
  SPECIAL_OCCASION: {
    'en-GB': 'Special Occasion',
    'nl-NL': 'Speciaal moment',
    facetValue: 'special occasion',
  },
  WEDDING: {
    'en-GB': 'Wedding',
    'nl-NL': 'Trouwdag',
    facetValue: 'wedding',
  },
  FATHERS_DAY: {
    'en-GB': "Father's Day",
    'nl-NL': 'Vaderdag',
    facetValue: "father's day",
  },
  BIRTHDAY: {
    'en-GB': 'Birthday',
    'nl-NL': 'Verjaardag',
    facetValue: 'birthday',
  },
  JUST_A_NOTE: {
    'en-GB': 'Just a note',
    'nl-NL': 'Zomaar',
    facetValue: 'just a note',
  },
  NEW_BABY: {
    'en-GB': 'New Baby',
    'nl-NL': 'Geboorte',
    facetValue: 'new baby',
  },
  VALENTINES_DAY: {
    'en-GB': "Valentine's Day",
    'nl-NL': 'Valentijnsdag',
    facetValue: "valentine's day",
  },
}

type Occasion =
  | 'IN_REMEMBRANCE'
  | 'ANNIVERSARY'
  | 'CHRISTMAS'
  | 'LOVE'
  | 'MOTHERS_DAY'
  | 'SAINT_NICHOLAS'
  | 'SPECIAL_OCCASION'
  | 'WEDDING'
  | 'FATHERS_DAY'
  | 'BIRTHDAY'
  | 'JUST_A_NOTE'
  | 'NEW_BABY'
  | 'VALENTINES_DAY'
  | string

const createPossessive = (text: string) => `${text}'s`
const getPrintableOccasion = (
  occasion: Occasion,
  locale: 'en-GB' | 'nl-NL',
) => {
  return OCCASIONS_LOCALE[occasion]
    ? OCCASIONS_LOCALE[occasion][locale]
    : OCCASIONS_LOCALE.SPECIAL_OCCASION[locale]
}

export const createReminderHeadingText =
  (lang: 'en-GB' | 'nl-NL') =>
  ({
    occasion,
    name,
    withLineBreak,
  }: {
    occasion: Occasion
    name: string
    withLineBreak?: boolean
  }) => {
    if (lang === 'nl-NL') {
      const nameOccasion = () =>
        `${createPossessive(name)}${
          withLineBreak ? '<br />' : ' '
        }${getPrintableOccasion(occasion, 'nl-NL')}`

      const occasionName = () =>
        `${getPrintableOccasion(occasion, 'nl-NL')} voor${
          withLineBreak ? '<br />' : ' '
        }${name}`

      const occasionMap = new Map([
        ['BIRTHDAY', nameOccasion],
        ['SPECIAL_OCCASION', nameOccasion],
        ['CHRISTMAS', occasionName],
        ['FATHERS_DAY', occasionName],
        ['MOTHERS_DAY', occasionName],
        ['VALENTINES_DAY', occasionName],
        ['ANNIVERSARY', nameOccasion],
        ['NEW_BABY', nameOccasion],
        ['WEDDING', nameOccasion],
        ['JUST_A_NOTE', occasionName],
        ['SAINT_NICHOLAS', occasionName],
        ['LOVE', occasionName],
        ['IN_REMEMBRANCE', occasionName],
      ])

      const createString = occasionMap.get(occasion) || nameOccasion

      return createString()
    }

    const nameOccasion = () =>
      `${createPossessive(name)}${
        withLineBreak ? '<br />' : ' '
      }${getPrintableOccasion(occasion, 'en-GB')}`

    const occasionName = () =>
      `${getPrintableOccasion(occasion, 'en-GB')} for${
        withLineBreak ? '<br />' : ' '
      }${name}`

    const occasionMap = new Map([
      ['BIRTHDAY', nameOccasion],
      ['SPECIAL_OCCASION', nameOccasion],
      ['CHRISTMAS', occasionName],
      ['FATHERS_DAY', occasionName],
      ['MOTHERS_DAY', occasionName],
      ['VALENTINES_DAY', occasionName],
      ['ANNIVERSARY', nameOccasion],
      ['NEW_BABY', nameOccasion],
      ['WEDDING', nameOccasion],
      ['JUST_A_NOTE', occasionName],
      ['SAINT_NICHOLAS', occasionName],
      ['LOVE', occasionName],
      ['IN_REMEMBRANCE', occasionName],
    ])

    const createString = occasionMap.get(occasion) || nameOccasion

    return createString()
  }

import React, { useState, useEffect } from 'react'
import { Box, LoadingIndicator } from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import { useQuery } from '@moonpig/web-core-graphql'
import { Region } from '@moonpig/web-core-types'
import { useLocaleText } from './locale'
import { IncentivisedReminders } from '../IncentivisedReminders'
import {
  GetIncentivisedReminderFormData,
  IncentivisedRemindersData,
} from '../../gql'

const StyledLoadingIndicator = styled(LoadingIndicator)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

type ModalContentProps = {
  url: string
  onError: () => void
  region: Region
  closeModal: () => void
  reminderCount: number
  setReminderCount: (count: number) => void
  setRequiredRemindersCount: React.Dispatch<
    React.SetStateAction<number | undefined>
  >
}

export const ModalContent: React.FC<ModalContentProps> = ({
  url,
  onError,
  region,
  closeModal,
  reminderCount,
  setReminderCount,
  setRequiredRemindersCount,
}) => {
  const localeText = useLocaleText()

  const [pendingOnError, setPendingOnError] = useState<boolean | undefined>(
    undefined,
  )

  const { loading, data, error } = useQuery(GetIncentivisedReminderFormData, {
    variables: {
      path: url,
      preview: false,
    },
    errorPolicy: 'all',
  })

  useEffect(() => {
    // Call onError after render to avoid issues modifying parent state
    if (pendingOnError === true) {
      onError()
      setPendingOnError(false)
    }
  }, [pendingOnError, onError])

  const incentivisedRemindersData: IncentivisedRemindersData =
    data?.page?.modules?.find(
      (module: IncentivisedRemindersData) =>
        module.__typename === 'ModuleIncentivisedReminders',
    )

  useEffect(() => {
    if (incentivisedRemindersData?.numberOfRemindersInOffer) {
      setRequiredRemindersCount(
        incentivisedRemindersData.numberOfRemindersInOffer,
      )
    }
  }, [
    incentivisedRemindersData?.numberOfRemindersInOffer,
    setRequiredRemindersCount,
  ])

  if (loading) {
    return (
      <Box width="24em" height="30rem">
        <StyledLoadingIndicator
          label={localeText('reminders.landing_page.loading')}
        />
      </Box>
    )
  }

  if (!data || !data.page || error) {
    if (pendingOnError === undefined) {
      // Only set pendingOnError once
      setPendingOnError(true)
    }
    return null
  }

  return (
    <IncentivisedReminders
      region={region}
      remindersData={incentivisedRemindersData}
      closeModal={closeModal}
      reminderCount={reminderCount}
      setReminderCount={setReminderCount}
    />
  )
}

export const groupCardSearchTerms = [
  'group card',
  'group cards',
  'leaving',
  'new job',
  'promotion',
  'retirement',
  'thank you',
  'thank you teacher',
  'teacher',
  'good luck',
  'wedding',
  'sympathy',
  'sorry',
  'thinking of you',
  'just a note',
]

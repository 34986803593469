import React from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { trackGAEvent } from './trackGAEvent'
import { EventObject } from './schemaTypes'

const StyledButton = styled.button``

type TrackedButtonProps = {
  onClick?: (e: React.MouseEvent) => void
  eventData: EventObject
}

export const TrackedButton = React.forwardRef<
  HTMLButtonElement,
  TrackedButtonProps
>((props, ref) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { eventData, onClick: originalOnClick = () => {}, ...rest } = props
  return (
    <StyledButton
      ref={ref}
      onClick={event => {
        trackGAEvent(eventData)
        originalOnClick(event)
      }}
      {...rest}
    />
  )
})

TrackedButton.displayName = 'TrackedButton'

type Options = {
  removeWord?: string
}

export const addSpacesToCamelCase = <T extends string | undefined>(
  str: T,
  options?: Options,
): T => {
  if (str === undefined) {
    return str
  }

  const str2 = str
    .split(/([A-Z][a-z]+)/) // splits camelCase including abbreviations
    .filter((word: string) => word)
    .join(' ')

  if (options?.removeWord) {
    return str2.replace(options.removeWord, '').trim() as T
  }

  return str2 as T
}

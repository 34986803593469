/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/destructuring-assignment */
import { getCookies as getNextCookies } from '@moonpig/web-core-cookies'
import { isServer, clearRequestCache } from '@moonpig/web-core-utils'
import {
  BrowserErrorTracker,
  Environment,
  logger,
  setLoggerConfig,
  createMetrics,
  Metrics,
} from '@moonpig/web-core-monitoring'
import { router, Routes } from '@moonpig/web-core-routing'
import React, { ComponentProps, FC, ReactNode } from 'react'
import { SurveyTrigger } from '@moonpig/web-core-analytics'
import { STORES } from '@moonpig/web-core-brand/config'
import { createStores } from '@moonpig/web-core-stores'
import { AppCore } from '../AppCore'
import { Error404Page } from '../../components/Error404Page'
import { Error500Page } from '../../components/Error500Page'
import { RedirectPage } from '../../components/RedirectPage'
import { createLoggerConfig } from '../createLoggerConfig'
import { first } from '../utils'
import { createLinkHeader } from './linkHeader'

const { hasStore } = createStores(STORES)

type Route = {
  asPath: string
  pathname: string
  query: { [name: string]: string | string[] | undefined }
}

type Context = {
  browserErrorTracker: BrowserErrorTracker
  Component: any
  ctx: any
  environment: Environment
  flagSchema: { [id: string]: { default: boolean } }
  notifications: React.ReactNode[]
  route: Route
}

type MatchedRoute = {
  name: keyof Routes
  params: { [key: string]: any }
}

type AppProps =
  | {
      statusCode: 200 | 404 | 410
      appProps: ComponentProps<typeof AppCore>
      pageProps: { [key: string]: any }
      route: MatchedRoute
    }
  | {
      statusCode: 301 | 302 | 500
    }

type Headers = { [name: string]: string | string[] }

type AppInitialProps = {
  statusCode: number
  headers: Headers
  props: AppProps
}

type AppFC = FC<
  AppProps & {
    browserErrorTracker: BrowserErrorTracker
    Component: any
    inject?: ReactNode
    location?: string | string[]
    notifications: ReactNode[]
    surveyTrigger: SurveyTrigger
  }
> & {
  getInitialProps: (context: Context) => Promise<AppInitialProps>
}

const createLogContext = ({ asPath, pathname, query }: Route, ctx: any) => {
  const path = asPath.split(/\?/)[0]
  const xForwardedFor = ctx?.req?.headers?.['x-forwarded-for'] || ''

  return {
    x_forwarded_for: xForwardedFor,
    page_path: path,
    page_pathname: pathname,
    page_as_path: asPath,
    page_query: JSON.stringify(query),
  }
}

const updateBrowserErrorTracker = (
  browserErrorTracker: BrowserErrorTracker,
  route: MatchedRoute,
) => {
  browserErrorTracker.setPageType(route.name)
  browserErrorTracker.addMetadata('route', JSON.stringify(route))
}

export const App: AppFC = props => {
  if (props.statusCode === 301 || props.statusCode === 302) {
    const location = first(props.location)

    if (location && !isServer) {
      const url = router.match(location)

      if (url) {
        router.push(url)
        return <RedirectPage />
      }

      // handle external redirects
      window.location.assign(location)
      return <RedirectPage />
    }

    return <Error500Page />
  }

  if (props.statusCode === 404) {
    const { appProps, pageProps } = props

    return (
      <AppCore {...appProps} browserErrorTracker={props.browserErrorTracker}>
        <Error404Page {...pageProps} />
      </AppCore>
    )
  }

  if (props.statusCode === 200 || props.statusCode === 410) {
    const { appProps, pageProps, Component, route } = props

    updateBrowserErrorTracker(props.browserErrorTracker, route)
    props.surveyTrigger.setPageType(route.name)

    return (
      <AppCore
        {...appProps}
        browserErrorTracker={props.browserErrorTracker}
        notifications={props.notifications}
      >
        {props.inject}
        <Component {...pageProps} />
      </AppCore>
    )
  }

  return <Error500Page />
}

const getUserAgent = (ctx: any) => {
  if (ctx.req?.headers?.['user-agent']) {
    return ctx.req.headers['user-agent']
  }

  if (typeof window !== 'undefined') {
    return window.navigator.userAgent
  }

  /* istanbul ignore next */
  return ''
}

App.getInitialProps = async ({
  browserErrorTracker,
  Component,
  ctx,
  environment,
  flagSchema,
  notifications,
  route,
}): Promise<AppInitialProps> => {
  let metricsOrUndefined: Metrics | undefined
  try {
    const metrics = createMetrics()
    metricsOrUndefined = metrics

    clearRequestCache()
    setLoggerConfig(createLoggerConfig(ctx, {}))

    let cacheable = true
    let serverInitialCookies: {
      [k: string]: string
    } | null = null

    const getCookie = (name: string): string | undefined => {
      const getCookies = () => {
        if (isServer) {
          if (!serverInitialCookies) {
            serverInitialCookies = getNextCookies(ctx)
          }
          return serverInitialCookies
        }
        return getNextCookies(ctx)
      }

      const value = getCookies()[name]

      // This needs to match web router config
      // https://github.com/Moonpig/moonpig-web-router/blob/master/deploy/global/web-router/resources-cloudfront.tf
      const COOKIE_CACHE_ALLOWLIST = [
        'mnpg-store',
        'mnpg_exclude_rude_products',
      ]

      if (!COOKIE_CACHE_ALLOWLIST.includes(name)) {
        cacheable = false
      }

      return value
    }

    const userAgent = getUserAgent(ctx)

    const createResponseHeaders = ({
      cacheMaxAgeSeconds,
    }: {
      cacheMaxAgeSeconds: number | undefined
    }): Headers => {
      const shouldCache = cacheMaxAgeSeconds && cacheable
      const cacheControlHeader = shouldCache
        ? `public, max-age=${cacheMaxAgeSeconds}, stale-while-revalidate=${
            cacheMaxAgeSeconds * 4
          }`
        : 'private, max-age=0'

      return {
        Link: createLinkHeader(),
        'Cache-Control': cacheControlHeader,
      }
    }

    const create404Response = async (params: { [key: string]: any } = {}) => {
      logger.warning('Page Not Found', createLogContext(route, ctx))

      const appInitialProps404 = await metrics.traceAsync(
        {
          metricName: 'app-getInitialProps-404',
          traceName: 'app-getInitialProps-404',
          traceAnnotations: {
            'route-as-path': route.asPath,
            'route-pathname': route.pathname,
            'route-query': JSON.stringify(route.query),
          },
        },
        () =>
          AppCore.getInitialProps({
            browserErrorTracker,
            Component: Error404Page as any,
            ctx,
            environment,
            flagSchema,
            getCookie,
            metrics,
            notifications,
            params,
            userAgent,
          }),
      )

      if (appInitialProps404.statusCode !== 404) {
        throw new Error('Failed to render 404 page')
      }

      return {
        statusCode: 404,
        headers: {
          'Cache-Control': 'private, max-age=0',
        },
        props: {
          statusCode: 404 as const,
          appProps: appInitialProps404.appProps,
          pageProps: {},
          route: {
            name: 'error404' as const,
            params: {},
          },
        },
      }
    }

    const matchHasStore = (match: any) => {
      return !(
        match &&
        match.params.region &&
        !hasStore(match.params.region as string)
      )
    }

    const match = router.match(route.asPath)

    // Not match for route
    if (!match || !matchHasStore(match)) {
      return await create404Response()
    }

    // Matching route does not match Lambda
    if (
      match &&
      process.env.PAGE_NAME &&
      process.env.PAGE_NAME !== match.name
    ) {
      logger.warning(
        `Lambda page name (${process.env.PAGE_NAME}) does not match page (${match.name})`,
      )
      return await create404Response()
    }

    updateBrowserErrorTracker(browserErrorTracker, match)

    router.setPath(route.asPath)

    const appInitialProps = await metrics.traceAsync(
      {
        metricName: 'app-getInitialProps',
        traceName: 'app-getInitialProps',
        traceAnnotations: {
          'route-as-path': route.asPath,
          'route-pathname': route.pathname,
          'route-query': JSON.stringify(route.query),
        },
      },
      () =>
        AppCore.getInitialProps({
          browserErrorTracker,
          Component,
          ctx,
          environment,
          flagSchema,
          getCookie,
          metrics,
          notifications,
          params: match.params,
          userAgent,
        }),
    )

    switch (appInitialProps.statusCode) {
      case 200: {
        const { appProps, pageProps, cacheMaxAgeSeconds } = appInitialProps

        const statusCode = pageProps?.content?.props
          ?.shouldDisplaySoldOutMessage
          ? 410
          : 200

        return {
          statusCode,
          headers: createResponseHeaders({ cacheMaxAgeSeconds }),
          props: {
            statusCode,
            appProps,
            pageProps,
            route: {
              name: match.name,
              params: match.params,
            },
          },
        }
      }
      case 301:
      case 302: {
        return {
          statusCode: appInitialProps.statusCode,
          headers: {
            location: appInitialProps.location,
          },
          props: {
            statusCode: appInitialProps.statusCode,
          },
        }
      }
      case 404:
      default: {
        return await create404Response(match.params)
      }
    }
  } catch (error) {
    logger.fixImmediately('Uncaught Error', createLogContext(route, ctx), error)

    // Let Next.js render stack trace in local development.
    if (environment.isLocalDev) {
      throw error
    }

    return {
      statusCode: 500,
      headers: {
        'Cache-Control': 'private, max-age=0',
      },
      props: { statusCode: 500 },
    }
  } finally {
    /* Ensure all metrics are published to CloudWatch */
    await metricsOrUndefined
      ?.settlePromises()
      .catch(/* istanbul ignore next */ () => {})
  }
}

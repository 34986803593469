import React from 'react'
import {
  Flex,
  LoadingIndicator as LaunchpadLoadingIndicator,
} from '@moonpig/launchpad-components'
import { useCoreLocaleText } from '@moonpig/web-core-locale-text'

type LoadingIndicatorProps = {
  label?: string
}

export const LoadingIndicator = (props: LoadingIndicatorProps) => {
  const localiseText = useCoreLocaleText()
  const label = props.label || localiseText('common.loading')
  return (
    <Flex
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
    >
      <LaunchpadLoadingIndicator label={label} />
    </Flex>
  )
}

import { trackGAEvent, sanitizeStrings } from '@moonpig/web-core-analytics'
import { Source } from '@moonpig/web-shared-types-graphql/graphqlTypes'
import { DOMAIN } from './trackReminderEvents'

type ERROR_KIND = 'REMINDER_CREATION_ERROR' | 'REMINDER_DELETION_ERROR'

type DeleteEventContext = {
  eventAction: string
}

const actionContextByErrorKind: {
  [kind in ERROR_KIND]: DeleteEventContext
} = {
  REMINDER_CREATION_ERROR: {
    eventAction: 'create reminder error',
  },
  REMINDER_DELETION_ERROR: {
    eventAction: 'delete reminder error',
  },
}

// https://github.com/Moonpig/moonpig-data-tracking/blob/main/events/specs/error.md
export const trackErrorEvent = ({
  source,
  kind,
  errorMessage,
}: {
  source: Source
  kind: ERROR_KIND
  errorMessage?: string
}) => {
  const { eventAction } = actionContextByErrorKind[kind]
  trackGAEvent({
    event: 'error',
    content_data: {
      content_type: `${DOMAIN} | ${eventAction}`,
    },
    event_data: {
      action: eventAction,
      label: sanitizeStrings(source, { forwardSlash: true }),
      category: DOMAIN,
      non_interaction: true,
    },
    error_data: {
      id: sanitizeStrings(source, { forwardSlash: true }),
      message: sanitizeStrings(errorMessage, { forwardSlash: true }),
    },
  })
}

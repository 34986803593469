import { createLocaleTextHook } from '@moonpig/web-core-locale-text'

const dictionary = {
  heading: {
    'en-GB': 'The Moonpig website is not currently available',
    'nl-NL': 'De Greetz-website is momenteel niet bereikbaar',
  },
  message: {
    'en-GB': 'Please try again later.',
    'nl-NL': 'Probeer het straks nog eens.',
  },
}

export const useLocaleText = createLocaleTextHook({
  dictionary,
})

import React, { FC } from 'react'
import { CustomLink, LinkAsType } from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { ICON_SIZE_PX } from '../constants'
import { FooterNavSection } from './FooterNavSection'
import { useLocaleText } from '../locale'

export type ItemProps = {
  icon: React.ElementType
  linkHref: string
  linkAs?: LinkAsType
  label: string
}

export type SocialProps = {
  items: ItemProps[]
}

const StyledLink = styled(CustomLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 200ms ease;
  ${s({ color: 'colorInteractionIcon' })}

  &:hover {
    ${s({ color: 'colorInteractionIconHover' })}
  }

  &:focus {
    outline: transparent;
    ${s({ color: 'colorInteractionSelectedState', boxShadow: 5 })}
  }
`

export const Social: FC<React.PropsWithChildren<SocialProps>> = ({ items }) => {
  const t = useLocaleText()

  return (
    <div data-testid="lp-nav-footernav-social">
      <FooterNavSection title={t(`footer.nav.social`)}>
        {items.map(({ icon: Icon, linkHref, linkAs, label }) => (
          <li key={linkHref}>
            <StyledLink
              to={linkHref}
              linkAs={linkAs}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={t('footer.nav.social.navigate', label)}
            >
              <Icon width={ICON_SIZE_PX} height={ICON_SIZE_PX} />
            </StyledLink>
          </li>
        ))}
      </FooterNavSection>
    </div>
  )
}

export type Url = {
  pathParts: string[]
  query: { [key: string]: string | string[] }
}

export const parseUrl = (url: string): Url => {
  const queryStringIndex = url.indexOf('?')
  const path = queryStringIndex === -1 ? url : url.slice(0, queryStringIndex)
  const queryString =
    queryStringIndex === -1 ? '' : url.slice(queryStringIndex + 1)

  const query = queryString
    ? queryString.split('&').reduce<Url['query']>((acc, keyValue) => {
        const [key, value] = keyValue.split('=')
        const existing = acc[key]
        const decodedKey = decodeURIComponent(key)
        const decodedValue = value && decodeURIComponent(value)
        if (existing) {
          acc[decodedKey] = [
            ...(Array.isArray(existing) ? existing : [existing]),
            decodedValue,
          ]
        } else {
          acc[decodedKey] = decodedValue
        }
        return acc
      }, {})
    : {}

  return {
    pathParts: path.split('/').filter(Boolean).map(decodeURIComponent),
    query,
  }
}

import React, { createContext, useContext, FC, useState, useMemo } from 'react'
import { logger } from '@moonpig/web-core-monitoring'

type LoginModal = {
  show: boolean
  setShow: (value: boolean) => void
}

const defaultValue = {
  show: false,
  setShow: /* istanbul ignore next */ () => {
    logger.fixToday('LoginModalProvider is not initialised')
  },
}
const LoginModalContext = createContext<LoginModal>(defaultValue)

export const useLoginModal = () => useContext(LoginModalContext)

export const LoginModalProvider: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [show, setShow] = useState(false)

  const value = useMemo(() => ({ show, setShow }), [show])

  return (
    <LoginModalContext.Provider value={value}>
      {children}
    </LoginModalContext.Provider>
  )
}

import React from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'

const StyledSelect = styled.select`
  ${s({
    fontSize: { xs: '16px', md: '24px' },
  })};
`

export const SelectBox: React.FC<{
  options: string[]
  selectedValue: string
  onChange: (value: string) => void
  id?: string
  defaultValue?: string
}> = ({ options, selectedValue, onChange, id, defaultValue }) => {
  return (
    <StyledSelect
      id={id}
      defaultValue={selectedValue}
      onChange={e => onChange(e.target.value)}
    >
      {defaultValue && <option value={defaultValue}>{defaultValue}</option>}
      {options.map(op => (
        <option key={op} value={op}>
          {op}
        </option>
      ))}
    </StyledSelect>
  )
}

import { z } from 'zod'
import { eventDataSchema } from '../common.schema'

export const AbtestSchema = z.object({
  event: z.literal('abtest'),

  event_data: eventDataSchema,
})

export type AbtestEvent = z.infer<typeof AbtestSchema>

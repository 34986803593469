import { createSurveyTrigger } from '@moonpig/web-core-analytics'
import {
  Environment,
  createMetrics,
  installBrowserErrorTracker,
} from '@moonpig/web-core-monitoring'
import NextApp, { AppContext as NextAppContext } from 'next/app'
import React, { ComponentProps, ReactNode } from 'react'
import { App } from './App'
import { handleServerRedirects } from './serverRedirects'
import type { ServerRedirect } from './serverRedirects'
import { handleNormaliseRedirects } from './normaliseRedirects'

export type CreateAppArgs = {
  notifications: ReactNode[]
  inject?: ReactNode
  redirect?: ServerRedirect
  flagSchema: { [id: string]: { default: boolean } }
}

export const createApp = ({
  notifications,
  inject,
  redirect,
  flagSchema,
}: CreateAppArgs) => {
  const environment: Environment = {
    isLocalDev: process.env.NODE_ENV === 'development',
    environmentName: process.env.MNPG_ENVIRONMENT_NAME || 'local',
    trackJSAppName: process.env.TRACKJS_APP_NAME_DEFAULT || '',
    appVersion: process.env.APP_VERSION || '',
    trackJSEnableLocal: Boolean(process.env.TRACKJS_ENABLE_LOCAL),
    trackJSToken: process.env.TRACKJS_TOKEN || '',
    localHostName: 'localhost',
  }

  type NextAppAdapterProps = {
    pageProps: Omit<
      ComponentProps<typeof App>,
      | 'Component'
      | 'browserErrorTracker'
      | 'surveyTrigger'
      | 'notifications'
      | 'inject'
    >
  }

  const browserErrorTracker = installBrowserErrorTracker(environment)
  const surveyTrigger = createSurveyTrigger()

  class NextAppAdapter extends NextApp<NextAppAdapterProps> {
    public static async getInitialProps({
      Component,
      ctx,
    }: NextAppContext): Promise<NextAppAdapterProps> {
      const metrics = createMetrics()

      const redirectNormalised = handleNormaliseRedirects(ctx)
      if (redirectNormalised) {
        return redirectNormalised
      }

      const redirectResult = await handleServerRedirects(ctx, metrics, redirect)
      if (redirectResult) {
        return redirectResult
      }

      const localHostName = (
        ctx.req ? ctx.req.headers.host || '' : window.location.host
      ).replace(/:\d+$/, '')

      const appInitialProps = await App.getInitialProps({
        ctx,
        route: {
          asPath: ctx.asPath || '',
          query: ctx.query,
          pathname: ctx.pathname,
        },
        environment: { ...environment, localHostName },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Component: Component as any,
        browserErrorTracker,
        notifications,
        flagSchema,
      })

      const { res } = ctx
      if (res) {
        res.statusCode = appInitialProps.statusCode

        Object.entries(appInitialProps.headers).forEach(([name, value]) => {
          res.setHeader(name, value)
        })
      }

      const pageProps = {
        ...appInitialProps.props,
        ...((appInitialProps.statusCode === 301 ||
          appInitialProps.statusCode === 302) &&
        appInitialProps.headers?.location
          ? {
              location: appInitialProps.headers.location,
            }
          : {}),
      }

      return {
        pageProps,
      }
    }

    public componentDidCatch(error: Error) {
      browserErrorTracker.track(error)
    }

    public render() {
      const { pageProps, Component } = this.props

      return (
        <App
          {...pageProps}
          Component={Component}
          inject={inject}
          browserErrorTracker={browserErrorTracker}
          surveyTrigger={surveyTrigger}
          notifications={notifications}
        />
      )
    }
  }

  return NextAppAdapter
}

const ENABLE_COOKIE_FLAGS_COOKIE = 'mnpg_enable-cookie-flags'
const FLAG_COOKIE_PREFIX = 'mnpg_flag_'

const toArray = <T>(value: T | T[]): T[] => {
  if (Array.isArray(value)) {
    return value
  }
  return [value]
}

const first = <T = string>(value: T | T[] | undefined): T | undefined => {
  if (Array.isArray(value)) {
    return value[0]
  }
  return value
}

export const createAppFlags = ({
  schema,
  header,
  query,
  getCookie,
}: {
  schema: {
    [id: string]: {
      default: boolean
    }
  }
  header: string | string[] | undefined
  query: string | string[] | undefined
  getCookie: null | ((name: string) => string | undefined)
}): { [id: string]: boolean } => {
  const headerOverrides = (first(header) || '')
    .split(';')
    .reduce((acc, flag) => {
      const [key, value] = flag.split('=')
      acc[key] = value === 'true'
      return acc
    }, {} as { [id: string]: boolean })

  const queryOverrides = toArray(query || []).reduce((acc, flag) => {
    const [key, value] = flag.split(':')
    if (value === 'on') {
      acc[key] = true
    } else if (value === 'off') {
      acc[key] = false
    }
    return acc
  }, {} as { [id: string]: boolean })

  const flags = Object.entries(schema).reduce((acc, [key, value]) => {
    const enableCookieFlags =
      getCookie && getCookie(ENABLE_COOKIE_FLAGS_COOKIE) === '1'

    // Query string
    if (queryOverrides[key] !== undefined) {
      acc[key] = queryOverrides[key]
    }
    // Cookie
    else if (
      enableCookieFlags &&
      getCookie(`${FLAG_COOKIE_PREFIX}${key}`) !== undefined
    ) {
      acc[key] = getCookie(`${FLAG_COOKIE_PREFIX}${key}`) === '1'
    }
    // Header
    else if (headerOverrides[key] !== undefined) {
      acc[key] = headerOverrides[key]
    }
    // Default
    else {
      acc[key] = value.default
    }
    return acc
  }, {} as { [id: string]: boolean })

  return flags
}

import React, { createContext, useContext, useState, useMemo, FC } from 'react'

type LoggedInContext = {
  loggedIn: boolean
  setLoggedIn: (loggedIn: boolean) => void
}

type LoggedInProviderProps = {
  value: {
    loggedIn: boolean
  }
}

/* istanbul ignore next */
const loggedInContext = createContext<LoggedInContext>({
  loggedIn: false,
  setLoggedIn: () => {},
})

export const useLoggedIn = () => useContext(loggedInContext)

export const LoggedInProvider: FC<
  React.PropsWithChildren<LoggedInProviderProps>
> = ({ value, children }) => {
  const [loggedIn, setLoggedIn] = useState(value.loggedIn)

  const contextValue = useMemo(() => ({ loggedIn, setLoggedIn }), [loggedIn])

  return (
    <loggedInContext.Provider value={contextValue}>
      {children}
    </loggedInContext.Provider>
  )
}

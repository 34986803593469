import { BreakpointsInterface } from '@moonpig/launchpad-theme'
import { debounce, useTheme } from '@moonpig/launchpad-utils'
import { useCallback, useEffect, useMemo, useState } from 'react'

type BreakPointIdentifier = keyof BreakpointsInterface
type BreakPoint = {
  key: BreakPointIdentifier
  value: number
}

export const useGetBreakpoint = () => {
  const theme = useTheme()
  const { xs, sm, md, lg, xl, xxl } = theme.breakpoints.map

  const breakpoints: Array<BreakPoint> = useMemo(
    () => [
      { key: 'xxl', value: xxl },
      { key: 'xl', value: xl },
      { key: 'lg', value: lg },
      { key: 'md', value: md },
      { key: 'sm', value: sm },
      { key: 'xs', value: xs },
    ],
    [lg, md, sm, xl, xs, xxl],
  )

  const [breakpoint, setBreakpoint] = useState<BreakPointIdentifier>('xs')

  const monitorSize = useCallback(() => {
    const size = window.innerWidth
    const foundBreakPoint = breakpoints.find(bp => size > bp.value)
    setBreakpoint(foundBreakPoint?.key ?? 'xs')
  }, [breakpoints])

  const monitorSizeDebounce = debounce(monitorSize, 200)

  useEffect(() => {
    monitorSize()
    window.addEventListener('resize', monitorSizeDebounce)
    return () => {
      window.removeEventListener('resize', monitorSizeDebounce)
    }
  }, [monitorSize, monitorSizeDebounce])

  return breakpoint
}

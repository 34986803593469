import React, { createContext, FC, useContext } from 'react'
import { useTrackExperiment } from './useTrackExperiment'

export type Experiments = {
  [key: string]: string
}

export const experimentsContext = createContext<Experiments>({})

export const ExperimentsProvider: FC<
  React.PropsWithChildren<{ experiments: Experiments }>
> = ({ children, experiments }) => (
  <experimentsContext.Provider value={experiments}>
    {children}
  </experimentsContext.Provider>
)

export const useExperiment = (key: string, fallback?: string) => {
  const experiments = useContext(experimentsContext)
  const variant = experiments[key]

  useTrackExperiment({ key, variant })

  return variant || fallback
}

export const useAllExperiments = (): Experiments => {
  return useContext(experimentsContext)
}

import { useState, useEffect } from 'react'

export const useReturnUrl = () => {
  const [returnUrl, setReturnUrl] = useState('')

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setReturnUrl(
        encodeURIComponent(
          `${window.location.pathname}${window?.location.search || ''}`,
        ),
      )
    }
  }, [])
  return returnUrl
}

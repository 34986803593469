import React from 'react'

export type FormProps = React.DetailedHTMLProps<
  React.FormHTMLAttributes<HTMLFormElement>,
  HTMLFormElement
>

export const Form: React.FC<FormProps> = props => {
  const method = props.method || 'post'

  return (
    <form {...props} method={method}>
      {props.children}
    </form>
  )
}

import { createProxyApiUrl } from '@moonpig/web-core-utils'
import { IS_AUTHENTICATED_COOKIE, HAS_REFRESH_TOKEN_COOKIE } from './cookies'

type GetCookie = (name: string) => string | undefined

export const isLoggedIn = (
  cookiesOrGetter: Record<string, string | undefined> | GetCookie,
): boolean => {
  if (cookiesOrGetter instanceof Function) {
    return (
      cookiesOrGetter(IS_AUTHENTICATED_COOKIE) === 'true' &&
      cookiesOrGetter(HAS_REFRESH_TOKEN_COOKIE) === 'true'
    )
  }

  return (
    cookiesOrGetter[IS_AUTHENTICATED_COOKIE] === 'true' &&
    cookiesOrGetter[HAS_REFRESH_TOKEN_COOKIE] === 'true'
  )
}

export const hasRefreshToken = (cookies: Record<string, string>): boolean =>
  cookies[HAS_REFRESH_TOKEN_COOKIE] === 'true'

export const getAuthUrl = (): string => {
  if (!process.env.AUTH_URL) {
    throw new Error('env variable AUTH_URL must be defined')
  }

  const url = createProxyApiUrl(process.env.AUTH_URL)
  return url
}

import { isLocalStorageAvailable } from '@moonpig/web-core-utils'

const storageKey = 'search_history'
const historyCapacity = 10

export type SearchHistoryItem = {
  searchTerm: string
  searchKey?: string
  department: string
}

export const getSearchHistory = (): SearchHistoryItem[] => {
  /* istanbul ignore next */
  if (!isLocalStorageAvailable()) return []

  const rawHistory = localStorage.getItem(storageKey)
  const parsedHistory = rawHistory ? JSON.parse(rawHistory) : []

  return parsedHistory.filter(
    (hi: SearchHistoryItem) => typeof hi.department === 'string',
  )
}

export const appendToSearchHistory = (
  historyItem: SearchHistoryItem,
): SearchHistoryItem[] => {
  /* istanbul ignore next */
  if (!isLocalStorageAvailable()) return []

  const history = getSearchHistory()
  const departmentHistory = history.filter(
    hi => hi.department === historyItem.department,
  )

  const duplicateItem = departmentHistory.find(
    hi => hi.searchTerm === historyItem.searchTerm,
  )

  if (duplicateItem) {
    history.splice(history.indexOf(duplicateItem), 1)
  } else if (departmentHistory.length >= historyCapacity) {
    const lastItem = departmentHistory[departmentHistory.length - 1]
    history.splice(history.indexOf(lastItem), 1)
  }

  history.unshift(historyItem)
  localStorage.setItem(storageKey, JSON.stringify(history))

  return history
}

export const removeSearchHistoryItem = (
  historyItem: SearchHistoryItem,
): SearchHistoryItem[] => {
  /* istanbul ignore next */
  if (!isLocalStorageAvailable()) return []

  const history = getSearchHistory()
  const indexToRemove = history.findIndex(
    hi =>
      hi.searchTerm === historyItem.searchTerm &&
      hi.department === historyItem.department,
  )

  if (indexToRemove !== -1) {
    history.splice(indexToRemove, 1)
    localStorage.setItem(storageKey, JSON.stringify(history))
  }

  return history
}

export const clearSearchHistory = (): void => {
  /* istanbul ignore next */
  if (!isLocalStorageAvailable()) return

  localStorage.removeItem(storageKey)
}

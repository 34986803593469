import React from 'react'
import {
  Box,
  PrimaryButton,
  Flex,
  Link,
  TertiaryButton,
  Text,
} from '@moonpig/launchpad-components'
import { system as s } from '@moonpig/launchpad-system'
import { styled } from '@moonpig/launchpad-utils'
import { router } from '@moonpig/web-core-routing'
import { LocaleTextHook } from '@moonpig/web-core-locale-text'
import { useStoreId } from '@moonpig/web-core-stores'
import { daysBetween } from '@moonpig/web-shared-utils'
import { Occasion } from '@moonpig/web-shared-types-graphql/graphqlTypes'
import { trackShopGAEvent } from './analytics'
import { REMINDER_TEXT, useLocaleText } from '../../localisation'
import { OccasionIcon } from '../OccasionIcon'
import { Reminder } from '../../types'

type ReminderCardProps = {
  reminder: Reminder
  onShopClicked?: () => void
  withShop?: boolean
  shopButtonFilled?: boolean
} & React.ComponentProps<typeof Box>

const ContainerBox = styled(Box)`
  ${s({
    borderRadius: 1,
    boxShadow: 1,
    bgcolor: 'colorBackground01',
  })}
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-self: stretch;
  height: 100%;
`

const printDaysUntilMessage = (
  daysUntilEvent: number,
  localiseText: LocaleTextHook<typeof REMINDER_TEXT>,
) => {
  if (daysUntilEvent === 1) {
    return localiseText('common.reminder_card.tomorrow')
  }
  if (daysUntilEvent === 0) {
    return localiseText('common.reminder_card.today')
  }

  return localiseText('common.reminder_card.days_until', { daysUntilEvent })
}

export const ReminderCard: React.FC<ReminderCardProps> = props => {
  const { reminder, withShop, onShopClicked } = props
  const { id } = reminder
  const storeId = useStoreId()

  const onShop = () => {
    router.push({ name: 'reminder-landing', params: { region: storeId, id } })
    trackShopGAEvent()
    if (onShopClicked) {
      onShopClicked()
    }
  }

  if (withShop) {
    return (
      <Link
        onClick={onShop}
        style={{ color: 'inherit', textDecoration: 'unset' }}
      >
        <Card {...props} />
      </Link>
    )
  }

  return <Card {...props} />
}

const Card: React.FC<ReminderCardProps> = ({
  reminder,
  shopButtonFilled,
  withShop,
  children,
  onShopClicked: _,
  ...otherProps
}) => {
  const { occasion, nextDate, name } = reminder
  const commonLocaliseText = useLocaleText()
  const todaysDate = new Date(Date.now())
  const daysUntilEvent = daysBetween(todaysDate, nextDate)

  return (
    <ContainerBox {...otherProps}>
      <Box py={6} pl={4} data-testid="occasion-icon">
        <OccasionIcon height="48px" width="48px" occasion={occasion} />
      </Box>
      <Box flexGrow={1} py={6} px={4}>
        <Box mb={3}>
          <Text typography="typeBodyCaption">
            {occasion === Occasion.CHRISTMAS
              ? commonLocaliseText('common.reminder_card.christmas')
              : commonLocaliseText('common.reminder_card.date', {
                  date: nextDate,
                })}
          </Text>
        </Box>
        <Box mb={3}>
          <Text
            as="p"
            typography={{
              xs: 'typeMobileDisplay05',
              md: 'typeDesktopDisplay06',
            }}
            mb={0}
          >
            {commonLocaliseText('common.reminder_card.heading', {
              occasion,
              name,
            })}
          </Text>
        </Box>
        <Box>
          <Text>
            {' '}
            {printDaysUntilMessage(daysUntilEvent, commonLocaliseText)}
          </Text>
        </Box>
      </Box>
      <Flex flexDirection="column" alignSelf="stretch" alignItems="flex-end">
        {children}
        {withShop && (
          <Box mt="auto">
            {shopButtonFilled ? (
              <PrimaryButton
                px={6}
                py={1}
                mx={4}
                my={4}
                data-testid="filled-shop-button"
              >
                {commonLocaliseText('common.reminder_card.shop')}
              </PrimaryButton>
            ) : (
              <TertiaryButton p={0} mr={6}>
                {commonLocaliseText('common.reminder_card.shop')}
              </TertiaryButton>
            )}
          </Box>
        )}
      </Flex>
    </ContainerBox>
  )
}

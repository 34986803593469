import { getBrowserCookies } from '@moonpig/web-core-cookies'
import { fetch } from '@moonpig/web-core-fetch'
import type { RequestInit, Response } from '@moonpig/web-core-fetch'

import { hasRefreshToken, getAuthUrl } from './utils'
import { getAuthToken } from './getAuthToken'
import { refreshAuthToken } from './refreshAuthToken'
import { SESSION_ID } from './cookies'

export const authFetch = async ({
  authBaseUrl = getAuthUrl(),
  path,
  ...init
}: { authBaseUrl?: string; path: string } & RequestInit): Promise<Response> => {
  const cookies = getBrowserCookies()
  const initDefault = {
    method: 'POST',
    credentials: 'include' as const,
    headers: {
      'mnpg-session-id': cookies[SESSION_ID],
    },
  }
  if (!hasRefreshToken(cookies)) {
    await getAuthToken({ authBaseUrl })
  }
  const opts = { ...initDefault, ...init }
  return fetch(`${authBaseUrl}${path}`, opts).then(response => {
    if (response.status === 401) {
      return refreshAuthToken({ authBaseUrl }).then(() =>
        fetch(`${authBaseUrl}${path}`, opts),
      )
    }
    return response
  })
}

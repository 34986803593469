import React, { FC } from 'react'
import { Inspiration, InspirationType } from './Inspiration'
import { InspirationItemType } from '../types'

type InspirationsProps = {
  inspirations: InspirationType[]
  getItemProps: (props: {
    itemIndex: number
    item: InspirationItemType
  }) => Record<string, unknown>
  getMenuProps: () => Record<string, unknown>
  startIndex?: number
}

export const Inspirations: FC<InspirationsProps> = ({
  inspirations,
  getItemProps,
  getMenuProps,
  startIndex = 0,
}) => {
  return (
    <ul {...getMenuProps()} data-testid={'lp-nav-search-inspirations'}>
      {inspirations.map((item, index) => {
        return (
          <Inspiration
            key={`${item.department}-${item.searchTerm}`}
            item={item}
            itemIndex={startIndex + index}
            getItemProps={getItemProps}
          />
        )
      })}
    </ul>
  )
}

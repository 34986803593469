import { Box } from '@moonpig/launchpad-components'
import React, { useEffect, useState } from 'react'
import { Region } from '@moonpig/web-core-types'
import { LoadingIndicator } from '@moonpig/web-shared-components'
import { useLoggedIn } from '@moonpig/web-core-auth'
import { Occasion } from '@moonpig/web-shared-types-graphql/graphqlTypes'
import { IncentivisedRemindersFormSignIn } from './IncentivisedRemindersFormSignIn'
import {
  getStoredReminders,
  clearStoredReminders,
  storeReminderInSessionStorage,
  setDeferredSaving,
} from '../../utils'
import { trackCreateReminder, trackReminderSelectEvent } from '../../analytics'
import { IncentivisedRemindersData } from '../../gql'
import { ReminderInput } from '../../types'
import { useCreateIncentivisedReminders } from '../../hooks/useCreateIncentivisedReminders'
import { IncentivisedRemindersCapture } from './IncentivisedRemindersCapture'
import { IncentivisedRemindersSuccess } from './IncentivisedRemindersSuccess'
import { IncentivisedRemindersFormContainer } from './IncentivisedRemindersFormContainer'
import { useReturnUrl } from './useReturnUrl'

enum Stages {
  Capture,
  Authenticate,
  Success,
}

type ReminderProps = {
  region: Region
  remindersData: IncentivisedRemindersData
  closeModal?: () => void
  reminderCount: number
  setReminderCount: (count: number) => void
}

const redirectToSignInPage = (region: string, returnUrl: string) => {
  window.location.assign(`/${region}/account/login?returnUrl=${returnUrl}`)
}

const storeReminder = ({
  name,
  relationship,
  occasionDate,
  occasion,
}: ReminderInput) => {
  storeReminderInSessionStorage({
    name,
    relationship,
    occasionDate,
    occasion,
  })
}

const getRemindersFromInput = ({
  christmasReminder,
  ...submittedReminder
}: ReminderInput) => {
  const newReminders = [submittedReminder]
  if (christmasReminder) {
    newReminders.push({
      ...submittedReminder,
      occasionDate: { day: 25, month: 12 },
      occasion: Occasion.CHRISTMAS,
    })
  }

  return newReminders
}

export const IncentivisedReminders = ({
  region,
  remindersData,
  closeModal,
  reminderCount,
  setReminderCount,
}: ReminderProps) => {
  const [currentStage, setCurrentStage] = useState(Stages.Capture)
  const returnUrl = useReturnUrl()

  const {
    createIncentivisedReminders,
    isCreatingIncentivisedReminders,
    createIncentivisedRemindersError,
  } = useCreateIncentivisedReminders()
  const { loggedIn: isLoggedIn } = useLoggedIn()

  const createReminders = async () => {
    const reminders = getStoredReminders()
    setCurrentStage(Stages.Success)
    await createIncentivisedReminders(reminders)

    reminders.forEach(({ occasion, source, occasionDate }, index, arr) => {
      trackCreateReminder({
        index: `${index + 1} / ${arr.length}`,
        origin: source,
        occasionDate,
        occasion,
      })
    })

    clearStoredReminders()
  }

  const handleReminderSubmit = async (input: ReminderInput) => {
    const newReminders = getRemindersFromInput(input)

    trackReminderSelectEvent({
      kind: 'INCENTIVISED_REMINDERS_NEXT_CTA_CLICK',
      label: `reminder modal | reminder ${reminderCount}`,
    })
    const newReminderCount = reminderCount + newReminders.length
    newReminders.forEach(storeReminder)
    setReminderCount(newReminderCount)

    const isFinalReminder =
      newReminderCount >= remindersData.numberOfRemindersInOffer
    if (!isFinalReminder) {
      return
    }

    if (isLoggedIn) {
      await createReminders()
    } else {
      setDeferredSaving()
      setCurrentStage(Stages.Authenticate)
    }
  }

  useEffect(() => {
    const storedReminders = getStoredReminders()
    setReminderCount(storedReminders.length)

    if (storedReminders.length) {
      if (storedReminders.length < remindersData.numberOfRemindersInOffer) {
        setCurrentStage(Stages.Capture)
      } else if (!isLoggedIn) {
        setCurrentStage(Stages.Authenticate)
      } else {
        createReminders()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn])

  if (isCreatingIncentivisedReminders) {
    return (
      <Box p={6}>
        <LoadingIndicator />
      </Box>
    )
  }

  return (
    <IncentivisedRemindersFormContainer
      error={Boolean(createIncentivisedRemindersError)}
    >
      {currentStage === Stages.Authenticate && (
        <IncentivisedRemindersFormSignIn
          src={remindersData.introImage.url}
          alt="Sign in Image"
          onClick={() => redirectToSignInPage(region, returnUrl)}
          createAccountLink={`/${region}/account/login?returnUrl=${returnUrl}`}
        />
      )}

      {currentStage === Stages.Capture && (
        <IncentivisedRemindersCapture
          reminderCount={reminderCount}
          voucherDescription={remindersData.voucherDescription}
          remindersRequired={remindersData.numberOfRemindersInOffer}
          onReminderSubmit={handleReminderSubmit}
          region={region}
        />
      )}

      {currentStage === Stages.Success && (
        <IncentivisedRemindersSuccess
          region={region}
          endTitle={remindersData.endTitle}
          endText={remindersData.endText}
          endImageUrl={remindersData.endImage.url}
          closeModal={closeModal}
        />
      )}
    </IncentivisedRemindersFormContainer>
  )
}

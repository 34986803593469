import { createLocaleTextHook } from '@moonpig/web-core-locale-text'

const dictionary = {
  'common.reminder_notification.view_all': {
    'en-GB': 'View All Reminders',
    'nl-NL': 'Bekijk alle momenten',
  },
  'reminders.incentivised.notification.error': {
    'en-GB': 'There was a problem retrieving this notification',
    'nl-NL': 'Er ging iets fout bij het ophalen van deze melding',
  },
  'reminders.incentivised.notification.cta': {
    'en-GB': 'Set Reminders',
    'nl-NL': 'Voeg moment toe',
  },
}

export const useLocaleText = createLocaleTextHook({
  dictionary,
})

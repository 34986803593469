import { LoggerConfig } from '@moonpig/web-core-monitoring'
import { APIGatewayProxyEvent, Context as LambdaContext } from 'aws-lambda'
import { NextPageContext } from 'next'

type PageContextRequest = NextPageContext['req'] & {
  awsLambda?: {
    event: APIGatewayProxyEvent
    context: LambdaContext
  }
}

export const createLoggerConfig = (
  ctx: NextPageContext,
  config: LoggerConfig['defaultMetadata'],
): LoggerConfig => {
  if (ctx.req) {
    const req = ctx.req as PageContextRequest
    if (req.awsLambda) {
      return { awsLambda: req.awsLambda, defaultMetadata: config }
    }
  }
  return { defaultMetadata: config }
}

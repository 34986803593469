import { z } from 'zod'
import { discountDataSchema, eventDataSchema } from '../common.schema'

export const RemoveDiscountSchema = z.object({
  event: z.literal('remove_discount'),

  discount_data: discountDataSchema,
  event_data: eventDataSchema,
})

export type RemoveDiscountEvent = z.infer<typeof RemoveDiscountSchema>

import { z } from 'zod'
import { eventDataSchema, marketingDataSchema } from '../common.schema'

export const MarketingConsentSchema = z.object({
  event: z.literal('marketing_consent'),

  marketing_data: marketingDataSchema,
  event_data: eventDataSchema,
})

export type MarketingConsentEvent = z.infer<typeof MarketingConsentSchema>

const DATES = {
  'common.dates.january': {
    'en-GB': 'January',
    'nl-NL': 'januari',
  },
  'common.dates.february': {
    'en-GB': 'February',
    'nl-NL': 'februari',
  },
  'common.dates.march': {
    'en-GB': 'March',
    'nl-NL': 'maart',
  },
  'common.dates.april': {
    'en-GB': 'April',
    'nl-NL': 'april',
  },
  'common.dates.may': {
    'en-GB': 'May',
    'nl-NL': 'mei',
  },
  'common.dates.june': {
    'en-GB': 'June',
    'nl-NL': 'juni',
  },
  'common.dates.july': {
    'en-GB': 'July',
    'nl-NL': 'juli',
  },
  'common.dates.august': {
    'en-GB': 'August',
    'nl-NL': 'augustus',
  },
  'common.dates.september': {
    'en-GB': 'September',
    'nl-NL': 'september',
  },
  'common.dates.october': {
    'en-GB': 'October',
    'nl-NL': 'oktober',
  },
  'common.dates.november': {
    'en-GB': 'November',
    'nl-NL': 'november',
  },
  'common.dates.december': {
    'en-GB': 'December',
    'nl-NL': 'december',
  },
}

const TIME = {
  'common.hr': { 'en-GB': 'hr', 'nl-NL': 'uur' },
  'common.hrs': { 'en-GB': 'hrs', 'nl-NL': 'uur' },
  'common.min': { 'en-GB': 'min', 'nl-NL': 'minuten' },
  'common.mins': { 'en-GB': 'mins', 'nl-NL': 'minuten' },
}

const PRODUCT = {
  'common.sold_out': { 'en-GB': 'sold out', 'nl-NL': 'Uitverkocht' },
  'common.make_it_a_multipack': {
    'en-GB': 'Make it a Multipack',
    'nl-NL': 'Ga voor een setje kaarten',
  },
  'common.quantity': { 'en-GB': 'Quantity', 'nl-NL': 'Aantal' },
  'common.select_quantity': {
    'en-GB': 'Select Quantity',
    'nl-NL': 'Kies je aantal',
  },
  'common.quantity_abbreviation': { 'en-GB': 'Qty', 'nl-NL': 'Aantal' },
  'common.this_product_is_currently_not_available_to_buy': {
    'en-GB': 'This product is currently not available to buy.',
    'nl-NL': 'Dit product wordt momenteel niet verkocht.',
  },
  'common.more_information': {
    'en-GB': 'More information',
    'nl-NL': 'Meer informatie',
  },
  'common.standard_cards': {
    'en-GB': 'Standard Cards',
    'nl-NL': 'Wenskaarten',
  },
  'common.with_envelopes': {
    'en-GB': 'With Envelopes',
    'nl-NL': 'Met enveloppen',
  },
  'common.off': { 'en-GB': 'off', 'nl-NL': 'korting' },
  'common.explore_related_categories': {
    'en-GB': 'Explore Related Categories',
    'nl-NL': 'Verken gerelateerde categorieën',
  },
}

const GENERAL = {
  'common.search': {
    'en-GB': 'Search…',
    'nl-NL': 'Zoeken…',
  },
  'common.product_details': {
    'en-GB': 'Product Details',
    'nl-NL': 'Omschrijving',
  },
  'common.delivery_options': {
    'en-GB': 'Delivery Options',
    'nl-NL': 'Bezorging',
  },
  'common.reviews': {
    'en-GB': 'Reviews',
    'nl-NL': 'Beoordeling',
  },
  'common.unboxing_information': {
    'en-GB': 'Unboxing Information',
  },
  'common.unboxing_information_standard_copy': {
    'en-GB':
      'Your flowers will arrive pre-arranged, ready to go in their favourite vase. The flowers will arrive in bud, ready to bloom within 1-2 days.  We provide easy to follow care instructions to keep your blooms fresh for as long as possible.',
  },
  'common.unboxing_information_letterbox_copy': {
    'en-GB':
      'Your flowers will arrive in letterbox packaging, ready to be arranged. The flowers will arrive in bud, ready to bloom within 1-2 days. We provide easy to follow care instructions to keep your blooms fresh for as long as possible.',
  },
  'common.select_size': {
    'en-GB': 'Select size',
    'nl-NL': 'Kies het formaat',
  },
  'common.size': {
    'en-GB': 'Size',
    'nl-NL': 'Formaat',
  },
  'common.personalise': {
    'en-GB': 'Personalise',
    'nl-NL': 'Personaliseren',
  },
  'common.add_to_basket': {
    'en-GB': 'Add to Basket',
    'nl-NL': 'In winkelmand',
  },
  'common.close': { 'en-GB': 'Close', 'nl-NL': 'Sluiten' },
  'common.continue': { 'en-GB': 'Continue', 'nl-NL': 'Doorgaan' },
  'common.people_also_viewed': {
    'en-GB': 'People also viewed',
    'nl-NL': 'Anderen bekeken ook',
  },
  'common.cards': {
    'en-GB': 'Cards',
    'nl-NL': 'Kaarten',
  },
  'common.personalised_cards': {
    'en-GB': 'Personalised Cards',
    'nl-NL': 'Gepersonaliseerde kaarten',
  },
  'common.gifts': {
    'en-GB': 'Gifts',
    'nl-NL': 'Cadeaus',
  },
  'common.flowers': {
    'en-GB': 'Flowers',
    'nl-NL': 'Bloemen',
  },
  'common.flowers_and_plants': {
    'en-GB': 'Flowers And Plants',
    'nl-NL': 'Bloemen en Planten',
  },
  'common.edit': {
    'en-GB': 'Edit',
    'nl-NL': 'Bewerken',
  },
  'common.delete': {
    'en-GB': 'Delete',
    'nl-NL': 'Verwijderen',
  },
  'common.cancel': {
    'en-GB': 'Cancel',
    'nl-NL': 'Annuleren',
  },
  'common.sorry_about_this': {
    'en-GB': 'Sorry about this …',
    'nl-NL': 'Het spijt ons…',
  },
  'common.something_went_wrong_when_loading_this_page': {
    'en-GB': 'Something went wrong when loading this page. Please try again.',
    'nl-NL':
      'Er ging iets mis bij het laden van deze pagina. Probeer het opnieuw.',
  },
  'common.try_again': {
    'en-GB': 'Try again',
    'nl-NL': 'Probeer het opnieuw',
  },
  'common.basket': {
    'en-GB': 'Basket',
    'nl-NL': 'Winkelmandje',
  },
  'common.loading': {
    'en-GB': 'Loading',
    'nl-NL': 'Bezig met laden',
  },
  'common.expand_or_collapse_panel': {
    'en-GB': (c: { title: string; collapsed: boolean }) => {
      return c.collapsed
        ? `Expand ${c.title} Panel`
        : `Collapse ${c.title} Panel`
    },
    'nl-NL': (c: { title: string; collapsed: boolean }) => {
      return c.collapsed
        ? `Paneel uitbreiden ${c.title}`
        : `Paneel inklappen ${c.title}`
    },
  },
  'common.button_to_select_title': {
    'en-GB': (c: { title: string }) => {
      return `Button to select ${c.title}`
    },
    'nl-NL': (c: { title: string }) => {
      return `Knop om ${c.title} te selecteren`
    },
  },
  'common.close_search': {
    'en-GB': 'Close search',
    'nl-NL': 'Zoekfunctie sluiten',
  },
  'common.clear_search_input': {
    'en-GB': 'Clear search input',
    'nl-NL': 'Zoekopdracht wissen',
  },
  'common.submit_search': {
    'en-GB': 'Submit search',
    'nl-NL': 'Zoekopdracht uitvoeren',
  },
  'common.error': {
    'en-GB': 'Error:',
    'nl-NL': 'Fout:',
  },
  'common.details': {
    'en-GB': 'Details:',
    'nl-NL': 'Gegevens:',
  },
  'common.tap_apply': {
    'en-GB': 'Tap to apply',
    'nl-NL': 'Klik om',
  },
  'common.the_code': {
    'en-GB': 'the code',
    'nl-NL': 'de kortingscode',
  },
  'common.to_basket': {
    'en-GB': 'to basket',
    'nl-NL': 'toe te passen op het winkelmandje',
  },
  'common.voucher_applied': {
    'en-GB': 'Voucher code applied',
    'nl-NL': 'Kortingscode toegepast',
  },
  'common.voucher_apply': {
    'en-GB': 'Apply voucher code',
    'nl-NL': 'Kortingscode toepassen',
  },
  'common.oops': {
    'en-GB': 'Oops!',
    'nl-NL': 'Oeps!',
  },
  'common.error_unknown': {
    'en-GB': "Oops! Something's not quite right",
    'nl-NL': 'Oeps! Er klopt iets niet',
  },
  'common.error_reload': {
    'en-GB': 'Please reload this page.',
    'nl-NL': 'Gelieve deze pagina opnieuw te laden.',
  },
  'common.error_try_again': {
    'en-GB': 'Something went wrong. Please refresh the page and try again.',
    'nl-NL': 'Er ging iets mis. Vernieuw de pagina en probeer het opnieuw.',
  },
  'common.sent_instantly_via_email': {
    'en-GB': 'Sent instantly via email',
    'nl-NL': 'Direct verstuurd via e-mail',
  },
  'common.enter_text': {
    'en-GB': 'Hit enter to search',
    'nl-NL': 'Druk op enter om te zoeken',
  },
  'common.recent_searches_in_department': {
    'en-GB': (c: { departmentTitle: string }) => `in ${c.departmentTitle}`,
    'nl-NL': (c: { departmentTitle: string }) => `in ${c.departmentTitle}`,
  },
  'common.clear_recent_searches': {
    'en-GB': 'Clear All',
    'nl-NL': 'Wis Alle',
  },
  'common.reminders': {
    'en-GB': 'Reminders',
    'nl-NL': 'Kalendermomenten',
  },
  'common.next': {
    'en-GB': 'Next',
    'nl-NL': 'Volgende',
  },
  'common.feature_construction': {
    'en-GB': 'This feature is under construction!',
    'nl-NL': 'Er wordt aan gewerkt!',
  },
  'common.working_hard': {
    'en-GB': "We're working hard to make it available again.",
    'nl-NL': 'We zorgen ervoor dat je dit straks weer kunt gebruiken.',
  },
  'common.create_group_card': {
    'en-GB': 'Want others to sign this card?',
    'nl-NL': 'Wil je dat anderen ook iets op de kaart zetten?',
  },
  'common.discount_details': {
    'en-GB': (discountedPercentage: number) => `(${discountedPercentage}% off)`,
    'nl-NL': (discountedPercentage: number) =>
      `(${discountedPercentage}% korting)`,
  },
  'common.price': {
    'en-GB': 'Price',
    'nl-NL': 'Prijs',
  },
  'common.price_explanation': {
    'en-GB': (price: string) => `Price ${price}`,
    'nl-NL': (price: string) => `Prijs ${price}`,
  },
  'common.price_with_discount_explanation': {
    'en-GB': (price: string, fullPrice: string, discountedPercentage: number) =>
      `Price ${price} was ${fullPrice}. ${discountedPercentage}% off`,
    'nl-NL': (price: string, fullPrice: string, discountedPercentage: number) =>
      `Prijs ${price} was ${fullPrice}. ${discountedPercentage}% korting`,
  },
}

export const dictionary = { ...DATES, ...TIME, ...PRODUCT, ...GENERAL }

import { TrackJS } from 'trackjs'
import { pageToAppConfig } from '../pageToAppConfig'
import { BrowserErrorTracker, Environment } from '../types'

export const installTrackJS = (
  environment: Environment,
): BrowserErrorTracker => {
  if (
    environment.environmentName === 'local' &&
    !environment.trackJSEnableLocal
  ) {
    return {
      setPageType: () => {},
      addMetadata: () => {},
      track: () => {},
    }
  }

  let currentTrackJSApplication = environment.trackJSEnableLocal
    ? 'moonpig-web-app-local'
    : environment.trackJSAppName

  TrackJS.install({
    token: environment.trackJSToken,
    application: currentTrackJSApplication,
  })

  TrackJS.configure({
    onError: payload => {
      // eslint-disable-next-line no-param-reassign
      payload.customer.application = currentTrackJSApplication

      try {
        const parsed = JSON.parse(payload.message)

        if (Array.isArray(parsed) && typeof parsed[0] === 'string') {
          const [message, context] = parsed

          // eslint-disable-next-line no-param-reassign
          payload.message = message

          Object.entries(context ?? {}).forEach(([key, value]) => {
            payload.metadata.push({ key, value: String(value) })
          })
        }
      } catch {
        // Not a structured log message
      }

      return true
    },
    version: environment.appVersion,
  })

  return {
    setPageType: pageType => {
      if (environment.environmentName !== 'prod') {
        return
      }

      currentTrackJSApplication =
        pageToAppConfig[pageType] || environment.trackJSAppName
    },
    addMetadata: (key, value) => TrackJS.addMetadata(key, value),
    track: error => TrackJS.track(error),
  }
}

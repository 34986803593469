import { logger } from '@moonpig/web-core-monitoring'
import { fetch } from '@moonpig/web-core-fetch'
import { getBrowserCookies } from '@moonpig/web-core-cookies'
import { SESSION_ID } from './cookies'
import { getAuthToken } from './getAuthToken'
import { getAuthUrl } from './utils'

export const refreshAuthToken = async ({
  authBaseUrl = getAuthUrl(),
}: {
  authBaseUrl?: string
} = {}): Promise<void> => {
  const ce = navigator.cookieEnabled
  const cookies = getBrowserCookies()

  const url = `${authBaseUrl}/refresh?ce=${ce}`

  const dedupe = cookies

  const req = await fetch(url, {
    dedupe,
    method: 'POST',
    credentials: 'include',
    headers: {
      'mnpg-session-id': cookies[SESSION_ID],
    },
  })
  if (!req.ok) {
    logger.fixToday('Failed to refresh the token', {
      responseCode: req.status,
    })
    if (req.status === 401 || req.status === 400) {
      await getAuthToken({ authBaseUrl })
    } else {
      throw new Error(`Refresh auth token request ${req.statusText} ce:${ce}`)
    }
  }
}

import React from 'react'
import { Region } from '@moonpig/web-core-types'
import { CustomLink } from '@moonpig/launchpad-components'
import { EventObject, TrackedAnchor } from '@moonpig/web-core-analytics'

import { createUtilitiesGaEvent } from './analytics'
import { UtilityName } from './types'

const makeUtilityTrackingAnchor = (
  eventPayload: EventObject,
  testId: string,
) => {
  const UtilityTrackingAnchor = (
    props: React.ComponentProps<typeof CustomLink>,
  ) => {
    return (
      <TrackedAnchor data-testid={testId} {...props} eventData={eventPayload} />
    )
  }

  return UtilityTrackingAnchor
}

export const createUtilityItems = ({
  region,
  localisedLabels,
  basketTotalItems,
}: {
  region: Region
  localisedLabels: { [key: string]: string }
  basketTotalItems: number
}) => {
  const basketUtilityItem = {
    to: `/${region}/basket/`,
    type: 'basket' as const,
    label: localisedLabels.basket,
    linkAs: makeUtilityTrackingAnchor(
      createUtilitiesGaEvent(UtilityName.BASKET),
      'lp-nav-utilitiles-basket',
    ),
    count: basketTotalItems,
  }

  const myAccountUtilityItem = {
    to: `/${region}/myaccount/`,
    type: 'account' as const,
    label: localisedLabels.account,
    linkAs: makeUtilityTrackingAnchor(
      createUtilitiesGaEvent(UtilityName.MY_ACCOUNT),
      'lp-nav-utilitiles-my-account',
    ),
    tooltip: localisedLabels.accountTooltip,
  }

  return [myAccountUtilityItem, basketUtilityItem]
}

import { z } from 'zod'
import { zodLowerCaseString } from '../common.schema'

export const CampaignDetailsSchema = z.object({
  event: z.literal('campaign_details'),

  aclid: zodLowerCaseString.optional(),
  campaign_id: zodLowerCaseString.optional(),
  campaign: zodLowerCaseString,
  medium: zodLowerCaseString,
  source: zodLowerCaseString,
  content: zodLowerCaseString.optional(),
  cp1: zodLowerCaseString.optional(),
  creative_format: zodLowerCaseString.optional(),
  marketing_tactic: zodLowerCaseString.optional(),
  source_platform: zodLowerCaseString.optional(),
  term: zodLowerCaseString.optional(),
})

export type CampaignDetailsEvent = z.infer<typeof CampaignDetailsSchema>

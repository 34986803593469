import React from 'react'
import Head from 'next/head'
import {
  defaultContentSecurityPolicy,
  generateCspString,
} from './contentSecurityPolicy'

export const contentSecurityPolicyMetaKey = 'mnpg-content-security-policy'
const contentSecurityPolicy = generateCspString(defaultContentSecurityPolicy)

export const DefaultContentSecurityPolicy = () => {
  return process.env.ENABLE_PROXY_API ? null : (
    <Head>
      <meta
        key={contentSecurityPolicyMetaKey}
        httpEquiv="Content-Security-Policy"
        content={contentSecurityPolicy}
      />
    </Head>
  )
}

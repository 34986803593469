import React, { createContext, FC, useContext } from 'react'

export type BrowserErrorTracker = {
  addMetadata(key: string, value: string): void
}

type BrowserErrorTrackerProviderProps = {
  browserErrorTracker: BrowserErrorTracker
}

const browserErrorTrackerContext = createContext<BrowserErrorTracker | null>(
  null,
)

export const BrowserErrorTrackerProvider: FC<
  React.PropsWithChildren<BrowserErrorTrackerProviderProps>
> = ({ browserErrorTracker, children }) => {
  return (
    <browserErrorTrackerContext.Provider value={browserErrorTracker}>
      {children}
    </browserErrorTrackerContext.Provider>
  )
}

export const useBrowserErrorTracker = (): BrowserErrorTracker => {
  const browserErrorTracker = useContext(browserErrorTrackerContext)

  /* istanbul ignore if */
  if (!browserErrorTracker) {
    throw new Error('Missing BrowserErrorTrackerProvider')
  }

  return browserErrorTracker
}

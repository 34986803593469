import { DEFAULT_TAGG_EXPERIENCE } from '../../constants'

export const getTaggExperience = (bucketName?: string | null): string => {
  if (!bucketName) {
    return DEFAULT_TAGG_EXPERIENCE
  }

  const sanitisedName = bucketName.toLowerCase().trim()
  return sanitisedName.length === 0 || sanitisedName === 'not bucketed'
    ? DEFAULT_TAGG_EXPERIENCE
    : bucketName
}

import { IAssignmentEvent } from '@eppo/js-client-sdk-common'
import { sanitizeStrings, trackGAEvent } from '@moonpig/web-core-analytics'
import { isServer } from '@moonpig/web-core-utils'

export const trackExperiment = (assignment: IAssignmentEvent) => {
  if (!assignment.variation || isServer) return null
  const { featureFlag, variation } = assignment
  const experimentEvent = buildExperimentEvent({ featureFlag, variation })
  if (!isExperimentAlreadyTracked(experimentEvent)) {
    trackGAEvent(experimentEvent)
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isExperimentAlreadyTracked = (experimentEvent: Record<string, any>) => {
  if (!window.dataLayer) {
    return false
  }

  return window.dataLayer
    ?.filter(event => event.event === 'abtest')
    .some(
      event =>
        event.event_data.action === experimentEvent.event_data.action &&
        event.event_data.label === experimentEvent.event_data.label,
    )
}

export const buildExperimentEvent = ({
  featureFlag,
  variation,
}: {
  featureFlag: string
  variation: string
}) => {
  const sanitizedVariation = sanitizeStrings(variation)
  const variationLabel =
    sanitizedVariation === 'not bucketed'
      ? 'no cohort assigned'
      : sanitizedVariation
  return {
    event: 'abtest',
    event_data: {
      category: 'ab test',
      action: sanitizeStrings(featureFlag),
      label: `${variationLabel} | exposure`,
      non_interaction: true,
      value: undefined,
    },
  }
}

import {
  removeSpaces,
  sanitizeStrings,
  trackGAEvent,
} from '@moonpig/web-core-analytics'
import { OccasionDate } from '../types'

export const DOMAIN = 'reminders'

type ReminderNormalized = {
  index: string
  origin: string
  days_until: number
  occasion: string
}

type ReminderWithDate = {
  index: string
  origin: string
  occasionDate: Date
  occasion: string
}

type ReminderWithDateMonth = {
  index: string
  origin: string
  occasionDate: OccasionDate
  occasion: string
}

type ReminderIndexProps = {
  sectionNumber?: number
  reminderIndex?: number
  numberOfItemsInBothSections?: number
  numberOfItemsInCurrentSection?: number
}

const sanitize = (str: string) => {
  return sanitizeStrings(str, { forwardSlash: true })
}

const adjust = (date: Date) => {
  const newDate = new Date(date.toISOString())
  newDate.setHours(0)
  newDate.setMinutes(0)
  newDate.setSeconds(0)
  newDate.setMilliseconds(0)
  return newDate
}

const daysBetween = (earlier: Date, later: Date) => {
  const adjustedEarlier = adjust(earlier)
  const adjustedLater = adjust(later)
  const timeUntilEvent = adjustedLater.getTime() - adjustedEarlier.getTime()
  return Math.ceil(timeUntilEvent / (1000 * 3600 * 24))
}

const daysToGoFromReminderDayMonth = (day: number, month: number): number => {
  const todayDate = adjust(new Date())
  const reminderDate = adjust(
    new Date(new Date().getFullYear(), month - 1, day),
  )
  if (todayDate.getTime() === reminderDate.getTime()) {
    return 0
  }

  if (todayDate.getTime() < reminderDate.getTime()) {
    return daysBetween(todayDate, reminderDate)
  }

  return daysBetween(
    todayDate,
    adjust(new Date(new Date().getFullYear() + 1, month - 1, day)),
  )
}

export const trackGenericReminderEvent = (
  intent: string,
  reminderData: ReminderNormalized,
): void => {
  const { days_until, origin, occasion, index } = reminderData

  trackGAEvent({
    event: `${intent}_reminder`,
    reminder_data: {
      index: removeSpaces(index),
      occasion: sanitize(occasion),
      days_until,
      origin: sanitize(origin),
    },
    // For GA3 backward compatibility
    event_data: {
      category: DOMAIN,
      action: `${sanitize(intent)} reminder`,
      label: `${sanitize(origin)} | ${sanitize(occasion)} | ${removeSpaces(
        index,
      )} | ${days_until}`,
      non_interaction: true,
      value: undefined,
    },
    // Web only //
    content_data: undefined,
    discount_data: undefined,
    ecommerce: undefined,
    error_data: undefined,
  })
}

export const trackCreateReminder = (reminderData: ReminderWithDateMonth) => {
  const {
    occasionDate: { day, month },
  } = reminderData
  const days_until = daysToGoFromReminderDayMonth(day, month)
  trackGenericReminderEvent('create', { ...reminderData, days_until })
}

export const trackEditReminder = (reminderData: ReminderWithDate) => {
  const { occasionDate } = reminderData
  const days_until = daysBetween(new Date(), occasionDate)
  trackGenericReminderEvent('edit', { ...reminderData, days_until })
}

export const trackUpdateReminder = (reminderData: ReminderWithDateMonth) => {
  const {
    occasionDate: { day, month },
  } = reminderData
  const days_until = daysToGoFromReminderDayMonth(day, month)
  trackGenericReminderEvent('update', { ...reminderData, days_until })
}

export const trackDeleteReminder = (reminderData: ReminderWithDate) => {
  const { occasionDate } = reminderData
  const days_until = daysBetween(new Date(), occasionDate)
  trackGenericReminderEvent('delete', { ...reminderData, days_until })
}

export const createReminderInflowGAEvent = (
  action: string,
  label: string,
  remindersData: { type: string; set: boolean }[],
) => {
  return {
    ecommerce: null,
    event: 'reminders_interaction',
    event_data: {
      category: DOMAIN,
      action,
      label,
      non_interaction: true,
      value: undefined,
    },
    reminder_data: remindersData,
  }
}

export const createReminderImpressionGAEvent = ({
  eventCategory,
  eventAction,
  eventLabel,
  itemId,
}: {
  eventCategory: string
  eventAction: string
  eventLabel: string
  itemId?: string
}) => {
  return {
    event: 'impression',
    content_data: {
      content_type: `${eventCategory} | ${eventAction} | ${eventLabel}`
        .substring(0, 100)
        .toLocaleLowerCase(),
      item_id: itemId?.toLocaleLowerCase(),
    },
    event_data: {
      category: eventCategory.toLocaleLowerCase(),
      action: eventAction.toLocaleLowerCase(),
      label: eventLabel.toLocaleLowerCase(),
      non_interaction: true,
      value: undefined,
    },
    product_data: undefined,
    // Web only //
    discount_data: undefined,
    ecommerce: undefined,
    error_data: undefined,
  }
}

// helper function for getting the index number of reminders needed for analytics
export const getReminderIndex = ({
  sectionNumber,
  reminderIndex,
  numberOfItemsInBothSections,
  numberOfItemsInCurrentSection,
}: ReminderIndexProps): string => {
  let index = '1/1'
  if (
    sectionNumber !== undefined &&
    reminderIndex !== undefined &&
    numberOfItemsInBothSections !== undefined &&
    numberOfItemsInCurrentSection !== undefined
  ) {
    index =
      sectionNumber === 0
        ? `${reminderIndex + 1} / ${numberOfItemsInBothSections}`
        : `${
            numberOfItemsInBothSections -
            numberOfItemsInCurrentSection +
            reminderIndex +
            1
          } / ${numberOfItemsInBothSections}`
  }
  return index
}

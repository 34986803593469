import React, { FC } from 'react'
import { Region } from '@moonpig/web-core-types'

import { DefaultLayout } from './components/DefaultLayout'
import { CheckoutLayout } from './components/CheckoutLayout'

type LayoutProps = React.ComponentProps<typeof DefaultLayout>

type FooterProps = LayoutProps['footer']

type NavItems = LayoutProps['navItems']

type HideSearchBarProps = LayoutProps['hideSearchBar']

type LayoutInitialProps =
  | {
      type: 'default'
      navItems: NavItems
      footerItems: FooterProps
      shouldHideFooter?: boolean
      hideSearchBar?: HideSearchBarProps
      hideGoogleOneTap?: boolean
      region: Region
      searchDepartment?: {
        name: string
        title: string
      }
      searchTerm?: string
      headerLogoIconUrl?: string
    }
  | {
      type: 'checkout'
      region: Region
      headerLogoIconUrl?: string
    }
  | {
      type: 'none'
    }

export const Layout: FC<
  React.PropsWithChildren<LayoutInitialProps>
> = props => {
  switch (props.type) {
    case 'default':
      return (
        <DefaultLayout
          navItems={props.navItems}
          headerLogoIconUrl={props.headerLogoIconUrl}
          footer={props.footerItems}
          searchDepartment={props.searchDepartment}
          searchTerm={props.searchTerm}
          region={props.region}
          shouldHideFooter={props.shouldHideFooter}
          hideSearchBar={props.hideSearchBar}
          shouldHideGoogleOneTap={props.hideGoogleOneTap}
        >
          {props.children}
        </DefaultLayout>
      )
    case 'checkout':
      return (
        <CheckoutLayout
          region={props.region}
          headerLogoIconUrl={props.headerLogoIconUrl}
        >
          {props.children}
        </CheckoutLayout>
      )
    default:
      return <>{props.children}</>
  }
}

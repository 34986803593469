import { gql, useLazyQuery } from '@moonpig/web-core-graphql'
import { useLoggedIn } from '@moonpig/web-core-auth'
import { createLocaleTextHook } from '@moonpig/web-core-locale-text'
import { Occasion } from '@moonpig/web-core-types-graphql'
import { useEffect } from 'react'
import { REMINDER_TEXT } from '../../SearchBar.locale'
import {
  GetAllRemindersForSearchQuery as Reminders,
  GetAllRemindersForSearchQueryVariables,
} from './__generated__/useSearchReminders'

const REMINDER_END_DATE_DAYS = 30
const MAX_SEARCH_REMINDERS = 2

const RemindersQuery = gql`
  query GetAllRemindersForSearch($endDate: String, $maxSearchReminders: Int) {
    reminders(input: { endDate: $endDate, take: $maxSearchReminders }) {
      id
      name
      occasion
      nextDate
    }
  }
`
const useLocaleText = createLocaleTextHook({
  dictionary: REMINDER_TEXT,
})

export const useSearchReminders = () => {
  const [fetchReminders, { data }] = useLazyQuery<
    Reminders,
    GetAllRemindersForSearchQueryVariables
  >(RemindersQuery)

  const { loggedIn } = useLoggedIn()
  const localiseText = useLocaleText()
  useEffect(() => {
    if (loggedIn) {
      const endDate = getEndDate()
      fetchReminders({
        variables: { endDate, maxSearchReminders: MAX_SEARCH_REMINDERS },
      })
    }
  }, [fetchReminders, loggedIn])

  return (
    data?.reminders?.map<SearchReminder>(r => ({
      id: r.id,
      label: localiseText('reminder_card.heading', {
        name: r.name,
        occasion: Occasion[r.occasion],
        withLineBreak: false,
      }),
      date: new Date(r.nextDate),
    })) || /* istanbul ignore next */ []
  )
}

export type SearchReminder = {
  id: string
  label: string
  date: Date
}

const getEndDate = () => {
  const endDate = new Date(Date.now())
  endDate.setDate(endDate.getDate() + REMINDER_END_DATE_DAYS)
  return endDate.toISOString()
}

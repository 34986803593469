import { gql } from '@moonpig/web-core-graphql'

export const GetSupportedReminderFacetsGQL = gql`
  query GetSupportedReminderFacets {
    reminderRelationships {
      relationship
      label
    }
    reminderOccasions {
      occasion
      label
      defaultDate {
        day
        month
      }
      defaultRelationship
    }
  }
`

import React, { FC } from 'react'
import { styled, breakpointDown } from '@moonpig/launchpad-utils'
import { IconSystemCross } from '@moonpig/launchpad-assets'
import { ICON_SIZE_PX } from '../constants'
import { sharedButtonStyles } from './sharedButtonStyles'
import { useLocaleText } from '../locale'

const StyledCloseButton = styled.button`
  ${sharedButtonStyles}
  top: 0px;
  right: 0px;

  ${breakpointDown('md')} {
    display: none;
  }
`

type CloseButtonProps = {
  onClick: () => void
} & React.HTMLProps<HTMLButtonElement>

export const CloseButton: FC<CloseButtonProps> = ({ onClick }) => {
  const t = useLocaleText()

  return (
    <StyledCloseButton
      data-testid="lp-nav-search-close-button"
      aria-label={t('search.close_search')}
      type="button"
      onClick={onClick}
    >
      <IconSystemCross aria-hidden width={ICON_SIZE_PX} height={ICON_SIZE_PX} />
    </StyledCloseButton>
  )
}

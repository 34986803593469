import { gql, GraphQLContext } from '@moonpig/web-core-graphql'
import {
  AppBannerQuery,
  AppBannerQueryVariables,
} from './__generated__/appBanner'

export type AppBannerInitialProps = {
  title: string
  subtitle?: string
  url: string
  shouldFormat: boolean
  voucherCode: string | null
  totalRatings: string
  rating: number
}

export const appBannerQuery = gql`
  query AppBannerQuery($preview: Boolean) {
    appBanner(preview: $preview) {
      __typename
      title
      subtitle
      url
      shouldFormat
      voucherCode
      totalRatings
      rating
    }
  }
`

export const getAppBannerInitialProps = async ({
  graphQL,
  preview,
}: {
  graphQL: { query: GraphQLContext['query'] }
  preview: boolean
}): Promise<AppBannerInitialProps | null> => {
  const result = await graphQL.query<AppBannerQuery, AppBannerQueryVariables>({
    query: appBannerQuery,
    variables: { preview },
  })

  return result.data.appBanner
}

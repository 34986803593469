import { z } from 'zod'
import {
  errorDataSchema,
  eventDataSchema,
  userDataSchema,
  zodCustomerTypeEnum,
  zodEmailString,
  zodHyphenatedString,
  zodLoginMethodEnum,
  zodNumber,
} from '../common.schema'

export const LogoutErrorSchema = z.object({
  event: z.literal('logout_error'),

  user_data: userDataSchema.extend({
    customer_id: zodHyphenatedString,
    customer_type: zodCustomerTypeEnum,
    email: zodEmailString,
    login_method: zodLoginMethodEnum,
    lifetime_order_count: zodNumber,
  }),
  event_data: eventDataSchema,
  error_data: errorDataSchema,
})

export type LogoutErrorEvent = z.infer<typeof LogoutErrorSchema>

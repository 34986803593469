import React, { FC } from 'react'
import { IconSystemReminder } from '@moonpig/launchpad-assets'
import { system as s } from '@moonpig/launchpad-system'
import { Box, Flex, Text } from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import { ReminderItemType } from '../types'

export type Reminder = {
  id: string
  value: string
}

const StyledImageContainer = styled(Flex)`
  padding-left: 10px;
  margin-right: auto;
  padding-top: 1px;
`
const StyledSearchReminder = styled.li`
  ${s({ px: 5, py: 4, color: 'colorTextBody' })}
  cursor: pointer;
  width: 320px;
  &:hover {
    ${s({ bgcolor: 'colorBackground02' })}
  }
`

const StyledIconSystemReminder = styled(IconSystemReminder)`
  ${s({ color: 'colorInteractionIcon' })}
  width: 20px;
  height: 20px;
`
type SearchReminderProps = {
  item: Reminder
  itemIndex: number
  getItemProps: (props: {
    itemIndex: number
    item: ReminderItemType
  }) => Record<string, unknown>
}

export const Reminder: FC<SearchReminderProps> = ({
  item,
  itemIndex,
  getItemProps,
}) => {
  return (
    <StyledSearchReminder
      tabIndex={0}
      {...getItemProps({
        itemIndex,
        item: { ...item, itemType: 'reminder' },
      })}
    >
      <Flex flexDirection="row" justifyContent="space-between">
        <Box marginTop="auto">
          <Text>{item.value}</Text>
        </Box>
        <StyledImageContainer alignItems="center" flexDirection="column">
          <StyledIconSystemReminder data-testid="lp-nav-search-reminder-icon" />
        </StyledImageContainer>
      </Flex>
    </StyledSearchReminder>
  )
}

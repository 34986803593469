import { z } from 'zod'
import { eventDataSchema } from '../common.schema'

export const SearchInputClearSchema = z.object({
  event: z.literal('search_input_clear'),

  event_data: eventDataSchema,
})

export type SearchInputClearEvent = z.infer<typeof SearchInputClearSchema>

const COMMERCETOOLS_IMAGE_ORIGIN = {
  url: 'https://images.cdn.europe-west1.gcp.commercetools.com',
  rel: 'dns-prefetch',
} as const

const CONTENTFUL_IMAGE_ORIGIN = {
  url: 'https://images.ctfassets.net',
  rel: 'dns-prefetch',
} as const

export const createLinkHeader = () => {
  const links = [
    process.env.STATIC_CDN_URL
      ? ({
          url: process.env.STATIC_CDN_URL,
          rel: 'preconnect',
        } as const)
      : undefined,
    COMMERCETOOLS_IMAGE_ORIGIN,
    CONTENTFUL_IMAGE_ORIGIN,
  ]

  return links
    .map(link => link && `<${link.url}>; rel=${link.rel}`)
    .filter(v => !!v)
    .join(', ')
}

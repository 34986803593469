export const SOFT_OPT_IN_REMINDER_TEXT = {
  'soft_opt_in_reminders.heading': {
    'en-GB': `We've set up a reminder so you never forget.`,
    'nl-NL': 'We geven je een seintje als dit moment er weer aankomt.',
  },
  'soft_opt_in_reminders.disclaimer': {
    'en-GB': ({ storeId }: { storeId: string }) =>
      `<p>By setting a reminder, you are consenting to receive emails and mobile app notifications about this reminder. View our <strong><a href="${`/${storeId}/privacy-notice/`}">privacy notice</a></strong> page for more details.</p>`,
    'nl-NL': /* istanbul ignore next */ ({ storeId }: { storeId: string }) => {
      return `<p>Hiermee geef je toestemming om e-mails en meldingen van Greetz te ontvangen. Check het  <strong><a href="/${storeId}/voorwaarden/privacy-reglement-greetz/app/">Privacybeleid</a></strong> voor meer info.</p>>`
    },
  },
  'soft_opt_in_reminder_card.edit': {
    'en-GB': 'Edit',
    'nl-NL': 'Bewerken',
  },
  'soft_opt_in_reminder_card.delete': {
    'en-GB': 'Delete',
    'nl-NL': 'Verwijderen',
  },
  'soft_opt_in.toggle_aria_label': {
    'en-GB': ({ reminderTitle }: { reminderTitle: string }) =>
      `Keep ${reminderTitle} reminder`,
  },
  'soft_opt_in.options_menu_label': {
    'en-GB': ({ reminderTitle }: { reminderTitle: string }) =>
      `Options menu for ${reminderTitle} reminder`,
  },
  'soft_opt_in.edit_reminder_label': {
    'en-GB': ({ reminderTitle }: { reminderTitle: string }) =>
      `Edit ${reminderTitle} reminder`,
  },
  'soft_opt_in.delete_reminder_label': {
    'en-GB': ({ reminderTitle }: { reminderTitle: string }) =>
      `Delete ${reminderTitle} reminder`,
  },
  'soft_opt_in.date': {
    'en-GB': ({ date }: { date: Date }) => {
      const suffix = ['th', 'st', 'nd', 'rd']
      const day = date.getDate()
      const v = day % 100
      return `${day}${
        suffix[(v - 20) % 10] || suffix[v] || suffix[0]
      } ${date.toLocaleString('en-gb', { month: 'short' })}`
    },
    'nl-NL': /* istanbul ignore next */ ({ date }: { date: Date }) => {
      const day = date.getDate()
      return `${day} ${date.toLocaleString('nl-nl', { month: 'short' })}`
    },
  },
  'soft_opt_in.reminder_deleted': {
    'en-GB': ({ reminderText }: { reminderText: string }) =>
      `Your reminder for ${reminderText} has been deleted.`,
    'nl-NL': ({ reminderText }: { reminderText: string }) =>
      `Het seintje voor ${reminderText} is uitgeschakeld.`,
  },
  'soft_opt_in.reminder_recreated': {
    'en-GB': ({ reminderText }: { reminderText: string }) =>
      `Woohoo! Your reminder for ${reminderText} has been saved!`,
    'nl-NL': ({ reminderText }: { reminderText: string }) =>
      `Gelukt! Het seintje voor ${reminderText} is ingeschakeld!`,
  },
  'soft_opt_in.toggle_error': {
    'en-GB': 'Oops! Something went wrong. Please try again later.',
    'nl-NL': 'Oeps, er ging iets niet goed. Probeer het later opnieuw.',
  },
}

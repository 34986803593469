import { z } from 'zod'
import { eventDataSchema } from '../common.schema'

export const ShareSchema = z.object({
  event: z.literal('share'),

  event_data: eventDataSchema,
})

export type ShareEvent = z.infer<typeof ShareSchema>

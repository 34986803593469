import React, { FC } from 'react'
import { IconSystemReminder } from '@moonpig/launchpad-assets'
import { system as s } from '@moonpig/launchpad-system'
import { Flex, Text } from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import { ReminderItemType } from '../types'
import { LIST_ICON_SIZE_PX } from '../constants'
import { useLocaleText } from '../../locale'

export type Reminder = {
  id: string
  value: string
  date: Date
}

const StyledSearchReminder = styled.li`
  cursor: pointer;
  ${s({ p: 3, color: 'colorTextLabel' })}

  &:hover, &:focus {
    ${s({ bgcolor: 'colorBackground02', borderRadius: '12px' })}
  }
  &:active {
    ${s({ bgcolor: 'colorBlack20' })}
  }
`

const StyledIconSystemReminder = styled(IconSystemReminder)`
  ${s({ color: 'colorInteractionControl' })}
  width: ${LIST_ICON_SIZE_PX};
  height: ${LIST_ICON_SIZE_PX};
`

const StyledIconWrapper = styled(Flex)`
  ${s({
    bgcolor: 'colorBackground01',
    borderRadius: '8px',
    p: 4,
    mr: 4,
    color: 'colorInteractionControl',
  })}
`

type SearchReminderProps = {
  item: Reminder
  itemIndex: number
  getItemProps: (props: {
    itemIndex: number
    item: ReminderItemType
  }) => Record<string, unknown>
}

export const Reminder: FC<SearchReminderProps> = ({
  item,
  itemIndex,
  getItemProps,
}) => {
  const localiseText = useLocaleText()
  const daysUntilText = localiseText('search.reminder.date', {
    dateOfEvent: item.date,
  })

  return (
    <StyledSearchReminder
      tabIndex={0}
      {...getItemProps({
        itemIndex,
        item: { ...item, itemType: 'reminder' },
      })}
    >
      <Flex alignItems="center">
        <StyledIconWrapper>
          <StyledIconSystemReminder data-testid="lp-nav-search-reminder-icon" />
        </StyledIconWrapper>
        <Text>
          {item.value}{' '}
          <Text color="colorBlack60" typography="typeBodyCaption">
            {daysUntilText}
          </Text>
        </Text>
      </Flex>
    </StyledSearchReminder>
  )
}

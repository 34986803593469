import React from 'react'
import Head from 'next/head'
import { theme } from '@moonpig/launchpad-theme'
import { ThemeProvider, styled } from '@moonpig/launchpad-utils'
import {
  Flex,
  LoadingIndicator as LaunchpadLoadingIndicator,
} from '@moonpig/launchpad-components'
import { Page } from '../../types'

const StyledContainer = styled.div`
  margin: 0 auto;
  text-align: center;
`

const LoadingIndicator = () => {
  return (
    <Flex
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
    >
      <LaunchpadLoadingIndicator />
    </Flex>
  )
}

const RedirectPage: Page = () => {
  return (
    <ThemeProvider theme={theme}>
      <Head>
        <title>Please wait while we redirect you.</title>
        <meta name="title" content="Redirecting" />
        <meta name="robots" content="noindex" />
      </Head>
      <StyledContainer>
        <LoadingIndicator />
      </StyledContainer>
    </ThemeProvider>
  )
}

RedirectPage.layout = 'default'

export { RedirectPage }

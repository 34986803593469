const adjust = (date: Date) => {
  const newDate = new Date(date.toISOString())
  newDate.setHours(0)
  newDate.setMinutes(0)
  newDate.setSeconds(0)
  newDate.setMilliseconds(0)
  return newDate
}

export const daysBetween = (earlier: Date, later: Date) => {
  const adjustedEarlier = adjust(earlier)
  const adjustedLater = adjust(later)
  const timeUntilEvent = adjustedLater.getTime() - adjustedEarlier.getTime()
  return Math.ceil(timeUntilEvent / (1000 * 3600 * 24))
}

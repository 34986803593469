import React, { FC } from 'react'
import { styled, breakpointUp } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { Box, Flex } from '@moonpig/launchpad-components'
import {
  FormControlLabel,
  RadioButton,
  RadioGroup,
} from '@moonpig/launchpad-forms'
import { theme } from '@moonpig/launchpad-theme'
import { StoreOption } from './types'

type StoreOptionsProps = {
  options: StoreOption[]
  selectedOption: StoreOption
  onChanged: (storeKey: StoreOption) => void
}

const StyledOption = styled(Box)<{ isChecked: boolean }>`
  border: solid;
  border-color: ${({ isChecked, theme: { colors } }) =>
    isChecked ? colors.colorInteractionButton : colors.colorBlack10};
  border-width: ${({ isChecked }) => (isChecked ? '2px' : '1px')};
  margin: ${({ isChecked }) => (isChecked ? '3px' : '4px')};

  ${breakpointUp('md')} {
    margin: ${({ isChecked }) => (isChecked ? '7px' : '8px')};
  }

  ${s({
    borderRadius: 2,
    paddingY: 5,
  })};
`

const StyledText = styled.p`
  ${s({
    m: 0,
    color: theme.colors.colorBlack60,
  })};
`

export const StoreOptions: FC<React.PropsWithChildren<StoreOptionsProps>> = ({
  options,
  selectedOption,
  onChanged,
}) => {
  return (
    <RadioGroup name="store" legend="">
      <Flex flexWrap="wrap">
        {options.map(option => {
          const { key, value, label, description = '' } = option
          const createOptionLabel = () => (
            <Flex flexDirection="column">
              <strong>{label}</strong>
              <StyledText>{description}</StyledText>
            </Flex>
          )

          return (
            <Box flexBasis={{ xs: '100%', md: '50%' }} key={key}>
              <StyledOption isChecked={selectedOption.key === key}>
                <FormControlLabel label={createOptionLabel()} id={key}>
                  <RadioButton
                    name={label}
                    value={value}
                    checked={selectedOption.key === key}
                    onChange={() => onChanged(option)}
                  />
                </FormControlLabel>
              </StyledOption>
            </Box>
          )
        })}
      </Flex>
    </RadioGroup>
  )
}

import React from 'react'
import { Text } from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { SelectBox } from './components/SelectBox'

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${s({
    py: 5,
    mr: 5,
    borderBottom: 1,
    borderColor: 'colorBlack10',
  })};
`

export const ExperimentsListItem: React.FC<{
  title: string
  values: string[]
  selectedValue: string
  onChange: ({ title, value }: { title: string; value: string }) => void
}> = ({ title, values, selectedValue, onChange }) => {
  const handleChange = (value: string) => {
    onChange({ title, value })
  }

  return (
    <StyledLabel htmlFor={`#${title}`}>
      <Text
        id={title}
        as="p"
        color="colorPrimary02"
        typography={{ xs: 'typeMobileBodyLabel', md: 'typeDesktopDisplay04' }}
        maxWidth={{ xs: 'calc(100% - 124px)', md: '550px' }}
        mb={0}
      >
        {title}:
      </Text>
      <SelectBox
        id={title}
        options={values}
        defaultValue="Not bucketed"
        selectedValue={selectedValue}
        onChange={handleChange}
      />
    </StyledLabel>
  )
}

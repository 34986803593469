type LocalStorageModel = {
  id: string
  totalCount: number
  loginMethod: string
}

export type PersistedCustomerData = {
  ctId: string
  lifetimeOrderCount: number
  loginMethod: string
}

export type GACustomerData = PersistedCustomerData & {
  id?: string
  isLoggedIn: string
}

const localStorageKey = 'mnpg_ga_user_data'

const getUserData = () => {
  try {
    const gaUserDataString = localStorage?.getItem(localStorageKey)
    return JSON.parse(gaUserDataString || '{}')
  } catch (e) {
    return {}
  }
}

export const getGACustomerData = (cookies: {
  [k: string]: string | undefined
}): GACustomerData => {
  const { id, totalCount, loginMethod = 'password' } = getUserData()

  return {
    id: cookies.MoonpigUserId,
    ctId: id,
    lifetimeOrderCount: totalCount,
    isLoggedIn: cookies.mnpg_is_authenticated || 'false',
    loginMethod,
  }
}

export const setGACustomerData = (customerData: PersistedCustomerData) => {
  const localStorageModel: LocalStorageModel = {
    id: customerData.ctId,
    totalCount: customerData.lifetimeOrderCount,
    loginMethod: customerData.loginMethod,
  }
  localStorage?.setItem(localStorageKey, JSON.stringify(localStorageModel))
}

import { css } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { spacingPx } from '@moonpig/launchpad-theme'

export const sharedButtonStyles = css`
  ${s({ p: 3, top: 0, color: 'colorInteractionIcon' })}
  height: ${spacingPx(12)};
  width: ${spacingPx(12)};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: transparent;

  &:not(:disabled) {
    cursor: pointer;
  }

  &[disabled] {
    ${s({ color: 'colorInteractionIcon' })}
    background-color: transparent;
  }
`

export const split = <T>(
  array: T[],
  predicate: (value: T) => boolean,
): [T[], T[]] => {
  return array.reduce<[T[], T[]]>(
    (acc, value) => {
      if (predicate(value)) {
        acc[0].push(value)
      } else {
        acc[1].push(value)
      }
      return acc
    },
    [[], []],
  )
}

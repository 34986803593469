import { z } from 'zod'
import { eventDataSchema } from '../common.schema'

export const RemoveFromWishlistSchema = z.object({
  event: z.literal('remove_from_wishlist'),

  event_data: eventDataSchema,
})

export type RemoveFromWishlistEvent = z.infer<typeof RemoveFromWishlistSchema>

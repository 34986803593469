import React, { FC, useContext, memo, useEffect, useRef } from 'react'
import { styled, breakpointDown, breakpoint } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'

import { NAV_BP, MOBILE_NAV_WIDTH_PX, TRANSITON_TIME_MS } from '../constants'
import { HeaderHeightContext } from '../HeaderWrapper'
import { MegaNavItemProps } from './types'
import { PrimaryItem } from './PrimaryItem'
import {
  MegaNavAction,
  OPEN_DROPDOWN,
  CLOSE_DROPDOWN,
  useMegaNavContext,
} from './MegaNavContext'

const OFFSCREEN_POSITION = 9999
const DROPDOWN_OPEN_DELAY = 300
const DROPDOWN_CLOSE_DELAY = 200

const StyledContent = styled.ul`
  display: flex;
  justify-content: center;
  height: 100%;
  ${s({ bgcolor: 'colorBackground01' })}

  ${breakpointDown(NAV_BP)} {
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction: column;
    justify-content: flex-start;
    width: ${MOBILE_NAV_WIDTH_PX};
    transition: transform ${TRANSITON_TIME_MS}
      cubic-bezier(0.33, 0.35, 0.14, 0.99);
    transform: translateX(-100%);

    &.is-open {
      transform: translateX(0);
    }
  }
`

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StyledDropdownCover = styled<any>('div').attrs(({ topPosition }) => ({
  style: { top: `${topPosition}px` },
}))`
  ${breakpoint(NAV_BP)} {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: -1;
    right: ${OFFSCREEN_POSITION}px;
    background-color: rgba(0, 0, 0, 0.25);
    opacity: 0;
    transition: opacity ${TRANSITON_TIME_MS}, right 0ms ${TRANSITON_TIME_MS};

    &.is-open {
      opacity: 1;
      right: 0;
      transition: opacity ${TRANSITON_TIME_MS}, right 0ms 0ms;
    }
  }
`

// IE11 returns 'Esc' everything else returns 'Escape'
const isEscapeKey = (key: string) => key === 'Esc' || key === 'Escape'

const WrappedContent: FC<
  React.PropsWithChildren<{
    isOpen?: boolean
    isDesktopNav?: boolean
    areAnyDesktopDropdownsOpen?: boolean
    dispatch: (action: MegaNavAction) => void
    items: MegaNavItemProps[]
    // eslint-disable-next-line react/display-name
  }>
> = memo(
  ({ isOpen, isDesktopNav, areAnyDesktopDropdownsOpen, dispatch, items }) => {
    const headerHeight = useContext(HeaderHeightContext)
    const focusedPrimaryItemIndex: React.MutableRefObject<number | undefined> =
      useRef(-1)

    const primaryItemButtons = useRef<
      Array<HTMLButtonElement | HTMLAnchorElement>
    >([])
    const openedNode = useRef<HTMLElement>(null)
    const openTimeout: React.MutableRefObject<number | undefined> = useRef()
    const closeTimeout: React.MutableRefObject<number | undefined> = useRef()
    const pendingDropdownOpen = useRef(false)

    useEffect(
      () => () => {
        clearTimeout(openTimeout.current)
        clearTimeout(closeTimeout.current)
      },
      [],
    )

    const handleMouseEnter = React.useCallback(
      (index: number) => {
        if (!items[index].dropdown?.length) {
          return
        }

        clearTimeout(closeTimeout.current)
        if (pendingDropdownOpen.current || !isDesktopNav) return

        pendingDropdownOpen.current = true

        if (areAnyDesktopDropdownsOpen) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          dispatch([OPEN_DROPDOWN, { index } as any])
          return
        }

        openTimeout.current = window.setTimeout(() => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          dispatch([OPEN_DROPDOWN, { index } as any])
        }, DROPDOWN_OPEN_DELAY)
      },
      [areAnyDesktopDropdownsOpen, dispatch, isDesktopNav, items],
    )

    const handleMouseLeave = React.useCallback(() => {
      if (!isDesktopNav) return
      pendingDropdownOpen.current = false

      clearTimeout(openTimeout.current)

      closeTimeout.current = window.setTimeout(() => {
        dispatch([CLOSE_DROPDOWN])
      }, DROPDOWN_CLOSE_DELAY)
    }, [dispatch, isDesktopNav, openTimeout])

    const maximumIndex = items.length - 1
    const handlePrimaryItemKeyDown = React.useCallback(
      (e: React.KeyboardEvent) => {
        const { key } = e
        const focusedPrimaryItemIndexCurrent =
          focusedPrimaryItemIndex.current || 0
        if (
          key === 'ArrowLeft' ||
          key === 'ArrowUp' ||
          (key === 'Tab' && e.shiftKey)
        ) {
          e.preventDefault()
          focusedPrimaryItemIndex.current =
            focusedPrimaryItemIndexCurrent <= 0
              ? maximumIndex
              : focusedPrimaryItemIndexCurrent - 1
          primaryItemButtons.current[focusedPrimaryItemIndex.current].focus()
          return undefined
        }

        if (key === 'ArrowRight' || key === 'ArrowDown') {
          e.preventDefault()
          focusedPrimaryItemIndex.current =
            focusedPrimaryItemIndexCurrent === maximumIndex
              ? 0
              : focusedPrimaryItemIndexCurrent + 1
          primaryItemButtons.current[focusedPrimaryItemIndex.current].focus()
          return undefined
        }

        if (key === 'Tab' && !e.shiftKey) {
          if (focusedPrimaryItemIndexCurrent === maximumIndex) {
            // Don't trap focus if on the last item
            return undefined
          }
          e.preventDefault()
          focusedPrimaryItemIndex.current = focusedPrimaryItemIndexCurrent + 1
          primaryItemButtons.current[focusedPrimaryItemIndex.current].focus()
          return undefined
        }

        return undefined
      },
      [maximumIndex],
    )

    const handlePrimaryItemFocus = React.useCallback((index: number) => {
      if (index === 0) {
        focusedPrimaryItemIndex.current = 0
      }
    }, [])

    useEffect(() => {
      const onKeyUp = ({ key }: KeyboardEvent) => {
        if (
          isEscapeKey(key) &&
          areAnyDesktopDropdownsOpen &&
          openedNode.current
        ) {
          openedNode.current.focus()
          dispatch([CLOSE_DROPDOWN])
        }
      }
      window.addEventListener('keyup', onKeyUp)

      return () => window.removeEventListener('keyup', onKeyUp)
    }, [areAnyDesktopDropdownsOpen, dispatch, openedNode])
    return (
      <>
        <StyledDropdownCover
          topPosition={headerHeight}
          data-testid="lp-nav-meganav-dropdown-cover"
          className={areAnyDesktopDropdownsOpen ? 'is-open' : ''}
        />
        <StyledContent
          data-testid="lp-nav-meganav-content"
          className={isOpen ? 'is-open' : ''}
          role="list"
        >
          {items.map((item, itemIndex) => {
            return (
              <PrimaryItem
                index={itemIndex}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                buttonTabIndex={itemIndex === 0 ? 0 : -1}
                primaryItemButtons={primaryItemButtons}
                onButtonKeyDown={handlePrimaryItemKeyDown}
                onButtonFocus={handlePrimaryItemFocus}
                openedNode={openedNode}
                // eslint-disable-next-line react/no-array-index-key
                key={itemIndex}
                item={item}
              />
            )
          })}
        </StyledContent>
      </>
    )
  },
)

WrappedContent.displayName = 'WrappedContent'

export const Content: FC<
  React.PropsWithChildren<{
    items: MegaNavItemProps[]
  }>
> = ({ items }) => {
  const [state, dispatch] = useMegaNavContext()
  const { isDesktopNav, areAnyDropdownsOpen, isOpen } = state
  return (
    <WrappedContent
      dispatch={dispatch}
      isDesktopNav={isDesktopNav}
      areAnyDesktopDropdownsOpen={isDesktopNav && areAnyDropdownsOpen}
      isOpen={isOpen}
      items={items}
    />
  )
}

import React from 'react'

import {
  TrackedButton,
  createNavigationEvent,
} from '@moonpig/web-core-analytics'

export const makeNavTrackingButton = (eventLabel: string) => {
  const NavTrackingButton = React.forwardRef<
    HTMLButtonElement,
    { isOpen: boolean }
  >((props, ref) => {
    const { isOpen } = props

    const eventPayload = createNavigationEvent({
      action: `header menu ${isOpen ? 'close' : 'expand'}`,
      label: eventLabel,
    })

    return <TrackedButton {...props} ref={ref} eventData={eventPayload} />
  })

  NavTrackingButton.displayName = 'NavTrackingButton'

  return NavTrackingButton
}

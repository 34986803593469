export const normaliseUrl = (url: string) => {
  const queryStringIndex = url.indexOf('?')
  const path = queryStringIndex === -1 ? url : url.slice(0, queryStringIndex)
  // do not normalise files e.g. robots.txt
  if (path.indexOf('.') !== -1) {
    return url
  }
  const queryString = queryStringIndex === -1 ? '' : url.slice(queryStringIndex)
  const normalisedPath = (path.endsWith('/') ? path : `${path}/`).toLowerCase()

  // do not normalise protocol relative URLs
  if (normalisedPath.startsWith('//')) {
    return url
  }

  return `${normalisedPath}${queryString}`
}

import { urlNormaliser } from '@moonpig/common-url-normaliser'
import { logger } from '@moonpig/web-core-monitoring'
import { getMatchingRedirect } from './getMatchingRedirect'
import { appendSlash } from './path-utilities/appendSlash'
import shouldAppendSlash from './path-utilities/shouldAppendSlash'
import trackingParamWriter from './path-utilities/trackingParamWriter'
import { LegacyRedirect } from './types'

const trackingParams = [
  'utm',
  'adid',
  'gclid',
  'gclsrc',
  'msclkid',
  'referrer',
  'clangct',
  'voucher',
  'emailcode',
  'sort',
  'CT',
  'tw_specials',
  'srsltid',
  'utm_keyword',
  'utm_term',
  'utm_content',
  'utm_medium',
  'utm_source',
  'utm_campaign',
  'awc',
]

export const redirectResponseProvider = async (
  request: { uri: string; querystring?: string },
  redirects: LegacyRedirect[],
  dynamicRedirects: LegacyRedirect[],
) => {
  try {
    const inputUrl = `${request.uri}?${request.querystring}`
    const normaliserResult = urlNormaliser(inputUrl)

    const normalisedUrl = normaliserResult.normalisedValue

    const matchingRedirect = await getMatchingRedirect(
      normalisedUrl,
      redirects,
      dynamicRedirects,
    )

    if (matchingRedirect) {
      let newPath = shouldAppendSlash(matchingRedirect.new)
        ? appendSlash(matchingRedirect.new, matchingRedirect.new.split('?')[1])
        : matchingRedirect.new

      const removedTrackingParams = normaliserResult.removedQueryParams.filter(
        value => trackingParams.includes(value.name),
      )

      if (removedTrackingParams.length > 0) {
        newPath = trackingParamWriter(newPath, removedTrackingParams)
      }

      try {
        const urlSafeRegex = /=([^&.]+)/gm
        const urlSafeSubstr = `=xxx`
        const inputSafeUrl = inputUrl.replace(urlSafeRegex, urlSafeSubstr)
        const normalisedSafeUrl = normalisedUrl.replace(
          urlSafeRegex,
          urlSafeSubstr,
        )
        const redirectSafeUrl = newPath.replace(urlSafeRegex, urlSafeSubstr)
        logger.info(
          `Redirecting from "${inputSafeUrl}" to "${redirectSafeUrl}" (normalised to "${normalisedSafeUrl}")`,
        )
      } catch (e) {
        logger.fixToday('Error logging redirect', {}, e)
      }

      return {
        status: '301',
        statusDescription: 'Found',
        source: normalisedUrl,
        headers: {
          location: [{ key: 'Location', value: newPath }],
        },
      } as const
    }
  } catch (ex) {
    logger.fixToday('Uncaught error in redirect', {}, ex)
  }
  return null
}

import { useCallback, useState } from 'react'
import { useMutation } from '@moonpig/web-core-graphql'
import { sanitizeStrings } from '@moonpig/web-core-analytics'
import { Source } from '@moonpig/web-shared-types-graphql/graphqlTypes'
import { logger } from '@moonpig/web-core-monitoring'
import { getNextDate } from '../utils/dateFormatting'
import { DeleteReminderGQL } from '../gql'
import { trackDeleteReminder, trackErrorEvent } from '../analytics'
import { ReminderInput } from '../types'

type DeleteReminderInput = {
  reminder: ReminderInput
  trackingIndex?: string
}

export type UseDeleteReminderResult = {
  deleteReminder: (
    options: DeleteReminderInput,
  ) => Promise<DeleteReminderResult>
  deleteReminderError: DeleteReminderErrorReason | null
  isDeleting: boolean
  resetDeleteReminderState: () => void
}

export type DeleteReminderErrorReason = 'reminder-not-found' | 'error'

export type DeleteReminderResult =
  | {
      type: 'success'
      reminderId: string
    }
  | {
      type: DeleteReminderErrorReason
    }

const getFormattedError = (typename?: string): DeleteReminderErrorReason => {
  switch (typename) {
    case 'ReminderNotFound':
      return 'reminder-not-found'
    default:
      return 'error'
  }
}

export const useDeleteReminder = (source?: Source): UseDeleteReminderResult => {
  const [deleteReminderError, setDeleteReminderError] =
    useState<DeleteReminderErrorReason | null>(null)
  const [performDelete, { loading: isDeleting, reset }] =
    useMutation(DeleteReminderGQL)

  const deleteReminder = async ({
    reminder,
    trackingIndex,
  }: DeleteReminderInput): Promise<DeleteReminderResult> => {
    try {
      const { occasion, occasionDate } = reminder
      const response = await performDelete({
        mutation: DeleteReminderGQL,
        variables: {
          id: reminder.id,
        },
      })

      const { data } = response

      if (data?.deleteReminder?.__typename === 'DeletedReminder') {
        trackDeleteReminder({
          index: trackingIndex || '1/na',
          occasion,
          occasionDate: getNextDate({
            month: occasionDate.month,
            day: occasionDate.day,
          }),
          origin: sanitizeStrings(source || Source.WEB_UNKNOWN, {
            forwardSlash: true,
          }),
        })
        return { type: 'success', reminderId: data.deleteReminder.id }
      }
      const errorReason = getFormattedError(data?.deleteReminder?.__typename)
      setDeleteReminderError(errorReason)
      trackErrorEvent({
        source: source || Source.WEB_UNKNOWN,
        kind: 'REMINDER_DELETION_ERROR',
        errorMessage: errorReason,
      })
      return { type: errorReason }
    } catch (e) {
      logger.warning(
        'Failed to delete reminder',
        {
          source: source || Source.WEB_UNKNOWN,
        },
        e,
      )

      trackErrorEvent({
        source: source || Source.WEB_UNKNOWN,
        kind: 'REMINDER_DELETION_ERROR',
        errorMessage: e.message,
      })
      setDeleteReminderError('error')
      return { type: 'error' }
    }
  }

  const resetDeleteReminderState = useCallback(() => {
    reset()
    setDeleteReminderError(null)
  }, [reset])

  return {
    deleteReminder,
    deleteReminderError,
    isDeleting,
    resetDeleteReminderState,
  }
}

import {
  parseCookies,
  setCookie as nookiesSet,
  destroyCookie as nookieDestroy,
} from 'nookies'
import type { NextPageContext } from 'next'

type Params = Parameters<typeof nookiesSet>
export type CookieSerializeOptions = Params[3]

export const getCookies = (ctx: NextPageContext): { [k: string]: string } =>
  parseCookies(ctx)

export const setCookie = (
  name: string,
  value: string,
  options: CookieSerializeOptions = {},
  ctx: NextPageContext,
) => {
  return nookiesSet(ctx || null, name, value, options)
}

export const destroyCookie = (
  name: string,
  options: CookieSerializeOptions = {},
  ctx: NextPageContext,
) => {
  nookieDestroy(ctx, name, options)
}

export const getBrowserCookies = (): { [k: string]: string } => parseCookies()

export const getBrowserCookie = (name: string): string | undefined =>
  parseCookies()[name]

export const setBrowserCookie = (
  name: string,
  value: string,
  options: CookieSerializeOptions = {},
) => {
  return nookiesSet(null, name, value, options)
}

export const destroyBrowserCookie = (
  name: string,
  options: CookieSerializeOptions = {},
) => {
  nookieDestroy(undefined, name, options)
}

import React from 'react'
import { createLinkHref } from '@moonpig/web-core-link'
import { CheckoutHeader } from '@moonpig/web-core-nav'
import { Region } from '@moonpig/web-core-types'
import { LogoLink } from '@moonpig/web-core-brand/components'
import { createNavigationEvent } from '@moonpig/web-core-analytics'
import { useLocaleText } from '../../AppShell.locale'

const buildHomeLink = (region: Region) => {
  const options = {
    url: `/${region}/`,
    trackEvent: createNavigationEvent({
      action: 'logos',
      label: 'Moonpig Logo',
    }),
  }

  const result = {
    href: createLinkHref(options),
  }

  return result
}

export const CheckoutLayout: React.FC<{
  children: React.ReactNode
  region: Region
  headerLogoIconUrl?: string
}> = ({ children, region, headerLogoIconUrl }) => {
  const t = useLocaleText()

  return (
    <div>
      <CheckoutHeader
        logoLink={
          <LogoLink
            href={buildHomeLink(region).href}
            title={t('logo_link')}
            complementaryImageUrl={headerLogoIconUrl}
          />
        }
      />
      {children}
    </div>
  )
}

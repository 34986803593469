/* eslint-disable react/no-array-index-key */
import { Box, Link } from '@moonpig/launchpad-components'
import React, { type FC } from 'react'
import type { MegaNavItemProps } from './types'

type Props = React.PropsWithChildren<{
  items: MegaNavItemProps['dropdown']
}>

export const SEOHiddenNavItems: FC<Props> = ({ items }) => (
  <>
    {items.map((item, index) => (
      <Box key={`${item.title}-${index}`}>
        <h3 data-testid={`lp-nav-dropdown-${index}-show-nodes`}>
          {item.title}
        </h3>
        <ul>
          {item.items.map((subItem, subIndex) => (
            <li key={`${subItem.href}-${subItem.title}`}>
              <Link
                href={subItem.href}
                data-testid={`lp-nav-sub-dropdown-${subIndex}-show-nodes-link`}
              >
                {subItem.title}
              </Link>
            </li>
          ))}
        </ul>
      </Box>
    ))}
  </>
)

import { DepartmentsEnum } from '@moonpig/web-core-types-graphql'
import { getSearchCriteria } from './searchCriteria'
import { getDepartmentTitle } from './getDepartmentTitle'

type SearchContext = {
  department: {
    name: string
    title: string
  }
  searchTerm: string
}

export const getSearchContext = (
  path: string,
  query: { [key: string]: string | string[] | undefined },
): SearchContext => {
  const searchTerm = query?.q as string
  let defaultDepartment = ['ALL_CARDS' as DepartmentsEnum]

  if (/\/flowers-and-plants\//i.test(path)) {
    defaultDepartment = ['ALL_FLOWERS_AND_PLANTS' as DepartmentsEnum]
  }

  if (
    /\/(?:(gifts)|(personalised-mugs)|(add-a-gift)|(gift-finder))\//i.test(path)
  ) {
    defaultDepartment = ['ALL_GIFTS' as DepartmentsEnum]
  }

  if (/\/cadeaus\//i.test(path)) {
    defaultDepartment = ['ALL_GIFTS' as DepartmentsEnum]
  }

  if (/\/bloemen-en-planten\//i.test(path)) {
    defaultDepartment = ['ALL_FLOWERS_AND_PLANTS' as DepartmentsEnum]
  }

  const searchCriteria = getSearchCriteria(query, defaultDepartment)
  const departmentName = searchCriteria?.department[0]
  return {
    department: {
      name: departmentName.toString().toLocaleLowerCase(),
      title: getDepartmentTitle(departmentName),
    },
    searchTerm,
  }
}

import { ApolloLink } from '@apollo/client/link/core'
import { logger } from '@moonpig/web-core-monitoring'

export const createLogLink = () =>
  new ApolloLink((operation, forward) => {
    if (process.env.GRAPHQL_LOG_REQUESTS) {
      logger.info(`[GraphQL request] Request for {operation_name}`, {
        operation_name: operation.operationName,
      })
    }

    return forward(operation).map(data => data)
  })

export const first = <T = string>(
  value: T | T[] | undefined,
): T | undefined => {
  if (Array.isArray(value)) {
    return value[0]
  }
  return value
}

export const toArray = <T>(value: T | T[]): T[] => {
  if (Array.isArray(value)) {
    return value
  }
  return [value]
}

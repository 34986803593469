import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  PropsWithChildren,
  FC,
} from 'react'

export type GoogleOneTapState =
  | 'authenticating'
  | 'success'
  | 'error'
  | 'warning'
  | 'idle'

type GoogleOneTapContext = {
  oneTapState: GoogleOneTapState
  setOneTapState: (state: GoogleOneTapState) => void
}

/* istanbul ignore next */
const GoogleOneTapContext = createContext<GoogleOneTapContext>({
  oneTapState: 'idle',
  setOneTapState: () => {},
})

export const GoogleOneTapProvider: FC<
  PropsWithChildren<{
    initialOneTapState?: GoogleOneTapState
  }>
> = ({ children, initialOneTapState = 'idle' }) => {
  const [oneTapState, setOneTapState] =
    useState<GoogleOneTapState>(initialOneTapState)

  const value = useMemo(() => ({ oneTapState, setOneTapState }), [oneTapState])

  return (
    <GoogleOneTapContext.Provider value={value}>
      {children}
    </GoogleOneTapContext.Provider>
  )
}

export const useGoogleOneTap = () => useContext(GoogleOneTapContext)

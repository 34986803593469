import React from 'react'
import NextLink from 'next/link'
import { logger } from '@moonpig/web-core-monitoring'
import { RouteApp } from '../types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RouteParamsValues = { [key: string]: any }

type Routes = {
  findByName(name: string): {
    getHrefAndAs(params: RouteParamsValues): {
      href: {
        pathname: string
        query: RouteParamsValues
      }
      as: string
      app: RouteApp
    }
  } | null
}

type LinkPropsByName = {
  name?: string
  params?: RouteParamsValues
}

type NextLinkProps = React.ComponentProps<typeof NextLink>

type Overwrite<T1, T2> = Pick<T1, Exclude<keyof T1, keyof T2>> & T2

type NextLinkPropsOptionalHref = Overwrite<
  NextLinkProps,
  {
    href?: string
  }
>

export type LinkProps = NextLinkPropsOptionalHref &
  LinkPropsByName & { children: React.ReactNode }

const getDerivedProps = (
  routes: Routes,
  name?: string,
  params?: RouteParamsValues,
) => {
  if (name) {
    const route = routes.findByName(name)
    if (route) {
      return route.getHrefAndAs(params || {})
    }
    logger.fixToday(`No route found with name '{route_name}'`, {
      route_name: name,
    })
  }
  return {}
}

const pick = <T, K extends keyof T>(
  obj: T,
  ...keys: K[]
): Partial<Pick<T, K>> => {
  const ret: Partial<Pick<T, K>> = {}
  keys.forEach(key => {
    ret[key] = obj[key]
  })
  return ret
}

const filterProps = (props: Partial<LinkProps>) =>
  pick(
    props,
    'as',
    'replace',
    'scroll',
    'shallow',
    'passHref',
    'prefetch',
    'href',
  )

export const createLinkComponent = (routes: Routes) => {
  const Link: React.FC<LinkProps> = ({ children, name, params, ...props }) => {
    const filteredProps = filterProps(props)
    const derivedProps = getDerivedProps(routes, name, params)
    const nextLinkProps = {
      ...filteredProps,
      ...derivedProps,
    }

    return (
      // Enabling prefetch breaks CSR at the moment due to non-string `href`
      // See: https://jira.moonpig.com/browse/WEB-155
      <NextLink href="/" {...nextLinkProps} prefetch={false}>
        {children}
      </NextLink>
    )
  }

  return Link
}

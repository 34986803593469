import { gql, useQuery } from '@moonpig/web-core-graphql'
import { GetSearchInspirationsQuery } from './__generated__/useSearchInspirations'

export type SearchTermInspiration = Extract<
  GetSearchInspirationsQuery['searchInspirations']['inspirations'][0],
  {
    __typename: 'SearchTermInspiration'
  }
>

const SearchInspirationsQuery = gql`
  query GetSearchInspirations {
    searchInspirations {
      inspirations {
        __typename
        ... on SearchTermInspiration {
          searchTerm
          department
        }
        ... on ProductInspiration {
          productSku
          department
        }
      }
    }
  }
`

export type SearchInspirationType = {
  value: string
  searchTerm: string
  department: string
  suggestionType: string
}

const filterSearchInspirations = (d: GetSearchInspirationsQuery) =>
  d?.searchInspirations?.inspirations.filter(
    (x): x is SearchTermInspiration => x.__typename === 'SearchTermInspiration',
  )

export const useSearchInspirations = () => {
  const { error, data, previousData } = useQuery<GetSearchInspirationsQuery>(
    SearchInspirationsQuery,
    { ssr: false },
  )

  if (error) {
    return []
  }

  let filteredData: SearchTermInspiration[] = []

  if (data) {
    filteredData = filterSearchInspirations(data)
  } else if (previousData) {
    filteredData = filterSearchInspirations(previousData)
  }

  return filteredData.map<SearchInspirationType>(x => {
    return {
      value: x.searchTerm,
      searchTerm: x.searchTerm,
      department: x.department,
      suggestionType: 'Inspiration Popular Search',
    }
  })
}

import { createLocaleTextHook } from '@moonpig/web-core-locale-text'

const dictionary = {
  account_linking_title: {
    'en-GB': 'Linking Your Account',
    'nl-NL': 'Koppel je account',
  },
  account_linking_description: {
    'en-GB': (provider: string) =>
      `To link your ${provider} account with Moonpig you need to enter your Moonpig password.`,
    'nl-NL': /* istanbul ignore next */ (provider: string) =>
      `Vul het wachtwoord in van je Greetz-account om je ${provider}-account te koppelen met Greetz.`,
  },
  account_linking_error: {
    'en-GB': 'Sign in failed',
    'nl-NL': 'Aanmelden niet gelukt',
  },
  account_linking_email_label: {
    'en-GB': 'Email',
    'nl-NL': 'E-mailadres',
  },
  account_linking_password_label: {
    'en-GB': 'Password',
    'nl-NL': 'Wachtwoord',
  },
  account_linking_button: {
    'en-GB': 'Link Account',
    'nl-NL': 'Koppel account',
  },
  error_title: {
    'en-GB': "Oops! We're unable to sign you in right now",
    'nl-NL': "Oops! We're unable to sign you in right now",
  },
  error_link_text: {
    'en-GB': 'Try again on the login page',
    'nl-NL': 'Try again on the login page',
  },
}

export const useOneTapLocaleText = createLocaleTextHook({
  dictionary,
})

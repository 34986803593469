import React, { FC } from 'react'
import { system as s } from '@moonpig/launchpad-system'
import { styled } from '@moonpig/launchpad-utils'
import { Text } from '@moonpig/launchpad-components'
import { InspirationItemType } from '../types'
import { useLocaleText } from '../../locale'

const StyledInspiration = styled.li`
  ${s({ px: 5, py: 4, color: 'colorTextBody' })}
  cursor: pointer;
  width: 320px;
  &:hover {
    ${s({ bgcolor: 'colorBackground02' })}
  }
`

export type InspirationType = {
  department: string
  searchTerm: string
  value: string
}

type InspirationProps = {
  item: InspirationType
  itemIndex: number
  getItemProps: (props: {
    itemIndex: number
    item: InspirationItemType
  }) => Record<string, unknown>
} & React.HTMLProps<HTMLElement>

export const Inspiration: FC<InspirationProps> = ({
  item,
  itemIndex,
  getItemProps,
}) => {
  const t = useLocaleText()
  const capitalizedSearchTerm = `${item.searchTerm[0].toUpperCase()}${item.searchTerm?.slice(
    1,
  )}`

  return (
    <StyledInspiration
      tabIndex={0}
      aria-label={`${t('search.search_for')} ${capitalizedSearchTerm}`}
      {...getItemProps({
        itemIndex,
        item: { ...item, itemType: 'inspiration' },
      })}
    >
      <Text color="colorTextBody">{capitalizedSearchTerm}</Text>
    </StyledInspiration>
  )
}

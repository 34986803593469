import { createLocaleTextHook } from '@moonpig/web-core-locale-text'

const dictionary = {
  cards: {
    'en-GB': 'Cards',
    'nl-NL': 'Kaarten',
  },
  close_the_smart_banner: {
    'en-GB': 'Close the smart banner',
    'nl-NL': 'De smart banner sluiten',
  },
  download_on_the_app_store: {
    'en-GB': 'Download on the App Store',
    'nl-NL': 'Download in de App Store',
  },
  get_it_on_google_play: {
    'en-GB': 'Get it on Google Play',
    'nl-NL': 'Ontdek het op Google Play',
  },
  logo_link: {
    'en-GB': 'Go to Moonpig home',
    'nl-NL': 'Ga naar de homepagina van Greetz',
  },
  my_account: {
    'en-GB': 'My Account',
    'nl-NL': 'Mijn account',
  },
  my_favourites: {
    'en-GB': 'My Favourites',
    'nl-NL': 'Mijn favorieten',
  },
  sign_in: {
    'en-GB': 'Sign In',
    'nl-NL': 'Inloggen',
  },
  basket: {
    'en-GB': 'Basket',
    'nl-NL': 'Winkelmand',
  },
  account: {
    'en-GB': 'Account',
    'nl-NL': 'MyGreetz',
  },
  account_tooltip: {
    'en-GB': '👋 Hey! Sign in here',
    'nl-NL': '👀 Hoi! Meld je hier aan',
  },
}

export const useLocaleText = createLocaleTextHook({
  dictionary,
})

/* eslint-disable jsx-a11y/no-redundant-roles */
import React, {
  FC,
  useState,
  memo,
  useEffect,
  useRef,
  useLayoutEffect,
} from 'react'
import { system as s } from '@moonpig/launchpad-system'
import { Box, Grid, ScreenReaderOnly } from '@moonpig/launchpad-components'
import {
  styled,
  breakpoint,
  breakpointDown,
  isBrowser,
} from '@moonpig/launchpad-utils'
import { IconSystemChevronRight as IconChevron } from '@moonpig/launchpad-assets'

import {
  NAV_BP,
  COLOR_KEYLINE,
  COLOR_LINK_HOVER,
  KEYLINE_SIZE_PX,
  ICON_SIZE_PX,
  TRANSITON_TIME_MS,
  MOBILE_NAV_WIDTH_PX,
  COLOR_DEFAULT_TEXT,
} from '../constants'
import { DropdownItemProps, DropdownProps } from './types'
import { useLocaleText } from '../locale'
import { Link } from '../Link'

const StyledSubDropdownItems = styled.div`
  ${breakpoint(NAV_BP)} {
    height: auto !important;
  }

  ${breakpointDown(NAV_BP)} {
    overflow: hidden;
    transition: height ${TRANSITON_TIME_MS};
  }
`

function setHeightFrameId(
  dropdownContentNode: React.MutableRefObject<HTMLElement | null>,
  applyHeight: (height: string) => void,
  isOpen: boolean,
) {
  return window.requestAnimationFrame(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { height } = dropdownContentNode.current!.getBoundingClientRect()
    applyHeight(`${height}px`)
    if (!isOpen) return window.setTimeout(() => applyHeight('0px'), 0)
    return undefined
  })
}

const useBrowserAnimateHeight = (
  dropdownNode: React.MutableRefObject<HTMLElement | null>,
  dropdownContentNode: React.MutableRefObject<HTMLElement | null>,
  isOpen: boolean,
) => {
  useLayoutEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const node = dropdownNode.current!
    const applyHeight = (height: string) => {
      node.style.height = height
    }
    let setAutoFrameId = 0

    const heightFrameId = setHeightFrameId(
      dropdownContentNode,
      applyHeight,
      isOpen,
    )

    const setAuto = () => {
      setAutoFrameId = window.requestAnimationFrame(() => applyHeight('auto'))
    }
    if (isOpen) {
      node.addEventListener('transitionend', setAuto)
    }

    return () => {
      node.removeEventListener('transitionend', setAuto)
      window.cancelAnimationFrame(heightFrameId)
      window.cancelAnimationFrame(setAutoFrameId)
    }
  }, [dropdownContentNode, dropdownNode, isOpen])
}

const noop = () => {}

const useAnimateHeight = isBrowser() ? useBrowserAnimateHeight : noop

const ItemsContainer: FC<
  React.PropsWithChildren<{
    dropdownIndex: number
    isOpen: boolean
    title: string
  }>
> = ({ dropdownIndex, isOpen, title, children }) => {
  const dropdownNode = React.useRef(null)
  const dropdownContentNode = React.useRef(null)

  useAnimateHeight(dropdownNode, dropdownContentNode, isOpen)

  return (
    <StyledSubDropdownItems
      ref={dropdownNode}
      data-testid={`lp-nav-sub-dropdown-${dropdownIndex}-items`}
    >
      <ul
        ref={dropdownContentNode}
        aria-label={title}
        data-testid={`lp-nav-sub-dropdown-${title}`}
      >
        {children}
      </ul>
    </StyledSubDropdownItems>
  )
}

const StyledTitle = styled.h3`
  ${s({
    px: 6,
    py: 5,
  })}
  position: relative;
  margin-bottom: 0;
  color: ${COLOR_DEFAULT_TEXT};

  ${breakpoint(NAV_BP)} {
    ${s({
      px: 0,
      pt: 0,
      pb: 4,
      mb: 4,
      typography: 'typeBodyCaption',
      fontWeight: 700,
    })}
    border-bottom: ${KEYLINE_SIZE_PX} solid ${COLOR_KEYLINE};
  }

  ${breakpointDown(NAV_BP)} {
    ${s({ typography: 'typeButtonLabel' })}

    &:hover,
    &:focus {
      color: ${COLOR_LINK_HOVER};
    }
  }
`

const StyledToggle = styled.button<{ isOpen: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: ${MOBILE_NAV_WIDTH_PX};

  ${breakpoint(NAV_BP)} {
    display: none;
  }
`

const StyledSubDropdownItem = styled.li`
  ${breakpointDown(NAV_BP)} {
    &:last-child {
      ${s({ pb: 6 })}
    }
  }
`

const StyledLink = styled(Link)`
  display: inline-block;
  color: inherit;
  ${s({
    py: { xs: 5, [NAV_BP]: 3 },
    pl: { xs: 12, [NAV_BP]: 0 },
    typography: 'typeBodyCaption',
  })}

  &:hover,
  &:focus {
    text-decoration: underline;
    ${s({ color: 'colorInteractionButton' })}
  }

  ${breakpointDown(NAV_BP)} {
    display: block;
  }
`

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledChevron = styled(({ isOpen, ...props }) => (
  <IconChevron {...props} />
)).attrs(({ isOpen }) => ({
  style: {
    transform: `translateY(-50%) rotate(${isOpen ? '-' : ''}90deg)`,
  },
}))<{ isOpen: boolean }>`
  color: ${COLOR_DEFAULT_TEXT};
  position: absolute;
  top: 50%;
  right: 12px;
  width: ${ICON_SIZE_PX};
  height: ${ICON_SIZE_PX};
  ${breakpoint(NAV_BP)} {
    display: none;
  }
`

const StyledContainer = styled.div`
  ${breakpointDown(NAV_BP)} {
    border-bottom: ${KEYLINE_SIZE_PX} solid ${COLOR_KEYLINE};
  }
`

export type SubDropdownItem = Omit<DropdownProps, 'items'> & {
  items: DropdownItemProps[][]
}

type Props = {
  subDropdown: SubDropdownItem
  dropdownIndex: number
  onLinkKeyDown: (e: React.KeyboardEvent) => void
  isDesktopNav: boolean
  focusedItem: object | undefined
}

export const SubDropdown: FC<React.PropsWithChildren<Props>> = memo(
  ({
    subDropdown: { title, items, buttonAs },
    dropdownIndex = 0,
    onLinkKeyDown,
    isDesktopNav,
    focusedItem,
  }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [renderMobileItems, setRenderMobileItems] = useState(false)

    const timeout = useRef<number | undefined>(undefined)

    const t = useLocaleText()

    useEffect(() => {
      const REMOVE_NODES_TIMEOUT_MS = 1000

      if (isDesktopNav) return

      if (isOpen) {
        clearTimeout(timeout.current)
        setRenderMobileItems(true)
      } else {
        timeout.current = window.setTimeout(() => {
          setRenderMobileItems(false)
        }, REMOVE_NODES_TIMEOUT_MS)
      }
    }, [setRenderMobileItems, isOpen, isDesktopNav])

    const renderListItem = (item: DropdownItemProps) => {
      return (
        // eslint-disable-next-line react/jsx-indent
        <StyledSubDropdownItem key={item.title}>
          <StyledLink
            href={item.href}
            onKeyDown={onLinkKeyDown}
            onBlur={e => e.preventDefault()}
            isFocused={item === focusedItem}
            data-testid={`lp-nav-sub-dropdown-${dropdownIndex}-link`}
          >
            {item.title}
          </StyledLink>
        </StyledSubDropdownItem>
      )
    }

    const renderList = (i: SubDropdownItem['items']) => {
      return i.map((item, index, bucket) => {
        if (bucket.length === 1) {
          return item.map(renderListItem)
        }

        return (
          <Box
            as="li"
            width={{ xs: '100%', [NAV_BP]: 1 / bucket.length }}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          >
            <Box as="ul">{item.map(renderListItem)}</Box>
          </Box>
        )
      })
    }

    const renderSingleColumn = () => (
      <ItemsContainer
        title={title}
        dropdownIndex={dropdownIndex}
        isOpen={isOpen}
      >
        {isOpen || renderMobileItems || isDesktopNav ? renderList(items) : null}
      </ItemsContainer>
    )

    const renderMultiColumn = () => (
      <Grid
        justifyContent="center"
        as="ul"
        data-testid={`lp-nav-sub-dropdown-${title}`}
      >
        {renderList(items)}
      </Grid>
    )

    return (
      <StyledContainer>
        <StyledTitle data-testid={`lp-nav-sub-dropdown-${dropdownIndex}-title`}>
          <StyledToggle
            isOpen={isOpen}
            as={buttonAs}
            data-testid={`lp-nav-sub-dropdown-${dropdownIndex}-toggle`}
            aria-label={`${
              isOpen
                ? t('mega_nav.sub_dropdown.close', title)
                : t('mega_nav.sub_dropdown.open', title)
            }`}
            tabIndex={-1}
            onClick={() => {
              setIsOpen(!isOpen)
            }}
            role="button"
          >
            <StyledChevron
              data-testid={`lp-nav-sub-dropdown-${dropdownIndex}-chevron`}
              isOpen={isOpen}
              aria-hidden
            />
          </StyledToggle>
          <span>{title}</span>
          <ScreenReaderOnly>
            {t('mega_nav.sub_dropdown.category')}
          </ScreenReaderOnly>
        </StyledTitle>
        {items.length === 1 ? renderSingleColumn() : renderMultiColumn()}
      </StyledContainer>
    )
  },
)

SubDropdown.displayName = 'SubDropdown'

import React from 'react'
import {
  ProgressIndicator,
  StepStatus,
  ActiveStepProgressPercentage,
  Text,
} from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { useLocaleText } from './locale'

type IncentivisedRemindersStepper = {
  currentStep: number
  voucherDescription: string
  remindersRemaining: number
  remindersRequired: number
}

const BoldText = styled(Text)`
  ${s({
    color: 'colorInteractionIcon',
    typography: 'typeDisplay05',
  })}
`

export const IncentivisedRemindersStepper: React.FC<
  IncentivisedRemindersStepper
> = ({
  currentStep,
  voucherDescription,
  remindersRemaining,
  remindersRequired,
}) => {
  const localiseFindText = useLocaleText()

  const createSteps = () => {
    const steps = []
    const remindersSet = remindersRequired - remindersRemaining

    for (let i = 0; i < remindersRequired; i += 1) {
      const label = `${localiseFindText(
        'reminders.incentivised.form.reminders_set',
        {
          reminderNo: i + 1,
        },
      )}`

      const getStepStatus = () => {
        if (remindersSet > i) {
          return { label, status: 'completed' as StepStatus.completed }
        }
        if (remindersSet < i) {
          return { label, status: 'incomplete' as StepStatus.incomplete }
        }
        return {
          label,
          status: 'active' as StepStatus.active,
          progressPercentage: 25 as ActiveStepProgressPercentage,
        }
      }

      steps.push(getStepStatus())
    }

    return steps
  }

  const steps = createSteps()

  return (
    <>
      <ProgressIndicator steps={steps} />
      <Text display="block" mt={6} mb={6}>
        <strong>
          {localiseFindText('reminders.incentivised.form.discount.set', {
            remindersRemaining,
            showMore: currentStep > 1,
          })}{' '}
        </strong>
        {localiseFindText('reminders.incentivised.form.discount.for')}{' '}
        <BoldText>{voucherDescription}</BoldText>{' '}
        <span>
          {localiseFindText('reminders.incentivised.form.discount.use')}
        </span>
      </Text>
    </>
  )
}

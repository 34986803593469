import { z } from 'zod'
import { errorDataSchema, eventDataSchema } from '../common.schema'

export const AccountLinkErrorSchema = z.object({
  event: z.literal('account_link_error'),

  event_data: eventDataSchema,
  error_data: errorDataSchema,
})

export type AccountLinkErrorEvent = z.infer<typeof AccountLinkErrorSchema>

import { logger, type Metrics } from '@moonpig/web-core-monitoring'
import {
  getAuthBaseUrl,
  isAuthData,
  isServer as checkServer,
  getAuthSecretHeader,
} from './utils'
import { AuthData } from './types'
import { HEADER_SESSION_ID } from './constants'
import { DedupeFetch } from './dedupeFetchJson'

export const fetchAccessToken = async ({
  sessionId,
  metrics,
  fetch,
}: {
  sessionId?: string
  metrics: Metrics
  fetch: DedupeFetch
}): Promise<AuthData | undefined> => {
  const baseUrl = getAuthBaseUrl()

  const isServer = checkServer()
  const url = isServer
    ? `${baseUrl}/token`
    : `${baseUrl}/token?ce=${navigator.cookieEnabled}`

  const headers: { [key: string]: string } = {
    ...(isServer ? getAuthSecretHeader() : {}),
    ...(sessionId ? { [HEADER_SESSION_ID]: sessionId } : {}),
  }

  const response = await metrics.traceAsync(
    {
      traceName: 'fetch-auth-token',
      metricName: 'fetch-auth-token',
    },
    () =>
      fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers,
      }),
  )

  if (!response.ok) {
    throw new Error(`Fetch auth token response ${response.statusText}`)
  }

  if (isServer) {
    const data = response.json
    if (isAuthData(data)) {
      return data
    }

    logger.fixToday('Error fetching access token: invalid auth data')
  }
}

export const isSessionStorageAvailable = () => {
  try {
    return typeof sessionStorage === 'object'
  } catch {
    /* istanbul ignore next */
    return false
  }
}

const getItem = (key: string) => {
  try {
    const item = sessionStorage.getItem(key)
    return item && JSON.parse(item)
  } catch {
    return null
  }
}

const setItem = (key: string, value: unknown) => {
  try {
    sessionStorage.setItem(key, JSON.stringify(value))
  } catch {
    return undefined
  }
}

const removeItem = (key: string) => {
  try {
    return sessionStorage.removeItem(key)
  } catch {
    return undefined
  }
}

const clear = () => {
  try {
    return sessionStorage.clear()
  } catch {
    return undefined
  }
}

export const sessionStore = {
  getItem,
  setItem,
  removeItem,
  clear,
}

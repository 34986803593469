/* eslint-disable camelcase */
import React from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { trackGAEvent } from './trackGAEvent'
import { EventObject } from './schemaTypes'

type TrackedAnchorProps = {
  className?: string
  target?: string
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
  children?: React.ReactNode
  to: string
  eventData: EventObject
}

// Prevent passing through props which don't belong in the DOM
const StyledAnchor = styled.a``

export const TrackedAnchor = React.forwardRef<
  HTMLAnchorElement,
  TrackedAnchorProps
>((props, ref) => {
  const {
    to,
    children,
    className,
    eventData,
    target,
    onClick: orginalOnClick = () => {},
    ...rest
  } = props
  return (
    <StyledAnchor
      className={className}
      href={to}
      target={target}
      onClick={event => {
        orginalOnClick(event)
        trackGAEvent(eventData)
      }}
      ref={ref}
      {...rest}
    >
      {children}
    </StyledAnchor>
  )
})

TrackedAnchor.displayName = 'TrackedAnchor'

import React, { FC } from 'react'
import {
  NavigationalSuggestion,
  NavigationalSuggestionType,
} from './NavigationalSuggestion'
import { UrlSuggestionItemType } from '../types'

type NavigationalSuggestionsProps = {
  navigationalSuggestions: NavigationalSuggestionType[]
  maxNavigationalSuggestions: number
  getItemProps: (props: {
    itemIndex: number
    item: UrlSuggestionItemType
  }) => Record<string, unknown>
  getMenuProps: () => Record<string, unknown>
}

export const NavigationalSuggestions: FC<NavigationalSuggestionsProps> = ({
  navigationalSuggestions,
  maxNavigationalSuggestions,
  getItemProps,
  getMenuProps,
}) => {
  return (
    <ul
      data-testid="lp-nav-search-navigational-suggestions"
      {...getMenuProps()}
    >
      {navigationalSuggestions.map(
        (navigationalSuggestion, index) =>
          index < maxNavigationalSuggestions && (
            <NavigationalSuggestion
              item={navigationalSuggestion}
              itemIndex={index}
              getItemProps={getItemProps}
              key={navigationalSuggestion.value}
            />
          ),
      )}
    </ul>
  )
}

import { getInstance } from '@eppo/node-server-sdk'

export type GetExperimentVariant = (
  experimentKey: string,
  params: { fallback: string },
) => string

export const setupGetExperimentVariant = ({
  subjectKey,
}: {
  subjectKey: string
}) => {
  const getExperimentVariant: GetExperimentVariant = (
    experimentKey,
    { fallback },
  ) => {
    const client = getInstance()
    const variation = client.getStringAssignment(
      experimentKey,
      subjectKey,
      {},
      fallback,
    )
    return variation
  }
  return getExperimentVariant
}

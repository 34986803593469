import { DepartmentsEnum } from '@moonpig/web-core-types-graphql'
import { getParentDepartment } from './getParentDepartment'
import { departmentMappings } from './categoriesDepartmentsMapping'

const getDepartment = (productCategories?: string): string => {
  if (productCategories) {
    const productCategoriesArray = productCategories.split('|')
    const department =
      departmentMappings[parseInt(productCategoriesArray[0], 10)]

    return department
      ? getParentDepartment(department).toLowerCase()
      : 'all_cards'
  }

  return ''
}

const getPromotionId = (facetQuery?: string): string => {
  return facetQuery ? facetQuery.replace('promotionid:', '') : ''
}

export const getSearchFilters = (query: { filters?: string }) => {
  const urlFilters = query.filters || ''
  const facets = urlFilters.split('.').filter(x => x !== '' && x.includes(':'))
  return facets.reduce<
    {
      facetKey: string
      group: string
      userApplied?: boolean
    }[]
  >((curr, x) => {
    const [group, facetKeyLabelPair] = x.split(':')
    const keyOrLabelWithKey = facetKeyLabelPair.split('|')
    const [, facetKey] =
      keyOrLabelWithKey.length > 1
        ? keyOrLabelWithKey
        : ['', ...keyOrLabelWithKey]
    return facetKey
      ? [...curr, { facetKey, group, userApplied: true }]
      : [...curr]
  }, [])
}

export const getSearchFilterWithLabel = (query?: string) => {
  if (!query) {
    return undefined
  }

  const suggestionParts = query.split(':')
  if (suggestionParts.length < 3) {
    return undefined
  }
  return {
    group: suggestionParts[0],
    facetKey: suggestionParts[1],
    label: suggestionParts[2],
    userApplied: true,
  }
}

export const getSearchCriteria = (
  query: {
    product_categories?: string
    d?: string
    f?: string
    filters?: string
    fq?: string
    promotionId?: string
  },
  defaultDepartment: DepartmentsEnum[] = ['ALL_CARDS' as DepartmentsEnum],
): {
  department: DepartmentsEnum[]
  filters: {
    facetKey: string
    group: string
    userApplied?: boolean
  }[]
  promotionId?: string
} => {
  const urlDepartments = query.d || getDepartment(query.product_categories)
  const promotionId = getPromotionId(query.fq)

  return {
    department: urlDepartments
      ? urlDepartments.split('.').map(x => x.toUpperCase() as DepartmentsEnum)
      : defaultDepartment,
    filters: getSearchFilters(query),
    promotionId: promotionId || query.promotionId,
  }
}

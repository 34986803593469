import { z } from 'zod'
import {
  contentDataSchema,
  eventDataSchema,
  productDataSchema,
} from '../common.schema'

export const ImpressionSchema = z.object({
  event: z.literal('impression'),

  content_data: contentDataSchema,
  event_data: eventDataSchema,
  product_data: productDataSchema,
})

export type ImpressionEvent = z.infer<typeof ImpressionSchema>

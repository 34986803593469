import {
  GraphQLOptions,
  GraphQLInitialState,
  GraphQLContext,
  createServerGraphQLContext,
  createBrowserGraphQLContext,
} from '@moonpig/web-core-graphql'
import type { AuthenticatedFetch } from '@moonpig/web-core-fetch'
import { isServer } from '@moonpig/web-core-utils'

let cachedGraphQLContext: GraphQLContext | undefined

const getCachedGraphQLContext = (): GraphQLContext | undefined =>
  cachedGraphQLContext

const setCachedGraphQLContext = (ctx: GraphQLContext) => {
  cachedGraphQLContext = ctx
}
export const deleteCachedGraphQLContext = () => {
  cachedGraphQLContext = undefined
}

export const getGraphQLContext = (
  options: GraphQLOptions,
  fetch: AuthenticatedFetch,
  initialState?: GraphQLInitialState,
): GraphQLContext => {
  if (isServer) {
    return createServerGraphQLContext(options, fetch, initialState)
  }

  const ctx = getCachedGraphQLContext()

  if (ctx) {
    ctx.setOptions(options)
    return ctx
  }

  const browserGraphQLContext = createBrowserGraphQLContext(
    options,
    fetch,
    initialState,
  )

  setCachedGraphQLContext(browserGraphQLContext)

  return browserGraphQLContext
}

import { logger, type Metrics } from '@moonpig/web-core-monitoring'
import {
  getAuthBaseUrl,
  isAuthData,
  isServer as checkServer,
  getAuthSecretHeader,
} from './utils'
import { fetchAccessToken } from './fetchAccessToken'
import { HEADER_SESSION_ID } from './constants'
import { logFixToday } from './logger'
import { AuthData } from './types'
import { DedupeFetch } from './dedupeFetchJson'

export const fetchRefreshToken = async ({
  sessionId,
  refreshToken,
  metrics,
  fetch,
}: {
  sessionId?: string
  refreshToken?: string
  metrics: Metrics
  fetch: DedupeFetch
}): Promise<AuthData | undefined> => {
  const baseUrl = getAuthBaseUrl()
  const isServer = checkServer()

  const url = isServer
    ? `${baseUrl}/refresh`
    : `${baseUrl}/refresh?ce=${navigator.cookieEnabled}`

  const headers: { [key: string]: string } = {
    ...(isServer ? getAuthSecretHeader() : {}),
    ...(sessionId ? { [HEADER_SESSION_ID]: sessionId } : {}),
    ...(refreshToken && isServer
      ? { authorization: `Bearer ${refreshToken}` }
      : {}),
  }

  const response = await metrics.traceAsync(
    {
      traceName: 'fetch-auth-refresh',
      metricName: 'fetch-auth-refresh',
    },
    () =>
      fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers,
      }),
  )

  if (!response.ok) {
    logFixToday('Failed to refresh the token', {
      responseCode: response.status,
    })
    if (response.status === 400 || response.status === 401) {
      return fetchAccessToken({ sessionId, metrics, fetch })
    }
    throw new Error(`Refresh auth token request ${response.statusText}`)
  }

  if (isServer) {
    const data = response.json
    if (isAuthData(data)) {
      return data
    }

    logger.fixToday('Error fetching refresh token: invalid auth data')
  }
}

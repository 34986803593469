import { sanitizeStrings, trackGAEvent } from '@moonpig/web-core-analytics'
import { useEffect } from 'react'

export const useTrackExperiment = ({
  key,
  variant,
}: {
  key: string
  variant?: string
}) => {
  useEffect(() => {
    if (!variant) return
    const experimentEvent = buildExperimentEvent({ key, variant })
    if (!isExperimentAlreadyTracked(experimentEvent)) {
      trackGAEvent(experimentEvent)
    }
  }, [key, variant])
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isExperimentAlreadyTracked = (experimentEvent: Record<string, any>) => {
  if (!window.dataLayer) {
    return false
  }

  return window.dataLayer
    ?.filter(event => event.event === 'abtest')
    .some(
      event =>
        event.event_data.action === experimentEvent.event_data.action &&
        event.event_data.label === experimentEvent.event_data.label,
    )
}

export const buildExperimentEvent = ({
  key,
  variant,
}: {
  key: string
  variant: string
}) => {
  const sanitizedVariation = sanitizeStrings(variant)
  const variationLabel =
    sanitizedVariation === 'not bucketed'
      ? 'no cohort assigned'
      : sanitizedVariation
  return {
    event: 'abtest',
    event_data: {
      category: 'ab test',
      action: sanitizeStrings(key),
      label: `${variationLabel} | exposure`,
      non_interaction: true,
      value: undefined,
    },
  }
}

import { redirect } from '@moonpig/web-core-routing'
import { trackGAEvent } from '@moonpig/web-core-analytics'
import {
  IconFlagUnitedKingdom,
  IconFlagIreland,
  IconFlagNetherlands,
} from '@moonpig/launchpad-assets'
import { Header as LaunchpadHeader } from '@moonpig/web-core-nav'
import { useStore } from '@moonpig/web-core-stores'
import { useCallback } from 'react'
import { Store } from '@moonpig/web-core-types'
import {
  createSelectStoreGAEvent,
  createDismissStoreSelectorModalGAEvent,
  createOpenStoreSelectorModalGAEvent,
} from './analytics/GAEvents'

type HeaderProps = React.ComponentProps<typeof LaunchpadHeader>
type StoreSelectorConfig = HeaderProps['storeSelectorConfig']

type StoreOption = {
  label: string
  shortLabel?: string
  icon?: React.ElementType | null
  key: string
  value: string
  description?: string
}

const localeToFlag = (localeId: string): React.ElementType | null => {
  switch (localeId.toLowerCase()) {
    case 'en-gb':
      return IconFlagUnitedKingdom
    case 'en-ie':
      return IconFlagIreland
    /* istanbul ignore next */
    case 'nl-nl':
      return IconFlagNetherlands
  }
  /* istanbul ignore next */
  return null
}

const createOptions = (defaultStore: Store, stores: Store[]): StoreOption[] => {
  const options = stores.map<StoreOption>(store => {
    return {
      key: store.id,
      id: store.id,
      value: store.id,
      label: store.name,
      shortLabel: store.shortName,
      icon: localeToFlag(store.locale.language),
    }
  })

  if (options.length > 1) {
    return [
      ...options,
      {
        label: 'Rest of World',
        icon: null,
        key: 'rest-of-world',
        value: defaultStore.id,
        description: `We will take you to the ${defaultStore.shortName} store`,
      },
    ]
  }

  return []
}

const storeIdToPath = (id: string) => `/${id}/`

export const useStoreSelectorConfig = (): StoreSelectorConfig => {
  const { store: currentStore, stores, defaultStore } = useStore()

  const storeOptions = createOptions(defaultStore, stores)

  const onStoreSelection = useCallback(
    (storeOption: StoreOption) => {
      if (storeOption.value !== currentStore.id) {
        redirect({ path: storeIdToPath(storeOption.value) })
      }
      trackGAEvent(createSelectStoreGAEvent(storeOption.value))
    },
    [currentStore.id],
  )

  const selectedIndex = storeOptions.findIndex(
    option => option.key === currentStore.id,
  )

  if (storeOptions.length <= 1) {
    return undefined
  }

  return {
    selectedIndex,
    storeOptions,
    onStoreSelection,
    onModalOpen: () => {
      trackGAEvent(createOpenStoreSelectorModalGAEvent())
    },
    onModalDismiss: () => {
      trackGAEvent(createDismissStoreSelectorModalGAEvent())
    },
  }
}

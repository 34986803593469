import { graphQlExperimentsWhitelist } from './graphQlExperimentsWhitelist'

const getActiveExperiments = (currentDate: Date): string[] => {
  return graphQlExperimentsWhitelist
    .filter(e => e.expiry >= currentDate)
    .map(e => e.key)
}

export const buildExperimentsHeader = (
  experiments: {
    [key: string]: string
  },
  currentDate: Date = new Date(),
): string => {
  const experimentWhitelist = getActiveExperiments(currentDate)
  return Object.keys(experiments)
    .filter(key => experimentWhitelist.includes(key))
    .sort()
    .map(key => `${key}=${experiments[key]}`)
    .join(';')
}

import React from 'react'
import Head from 'next/head'

import logoSquare from '../../../assets/moonpig/images/logo-square.png'
import logoSquareWin8 from '../../../assets/moonpig/images/logo-square-win8.png'

export const GlobalHead = () => (
  <Head>
    <link rel="icon" type="image/x-icon" href="/favicon.ico" />
    <meta property="og:image" content={logoSquare} key="image" />
    <meta property="og:site_name" content="Moonpig" />
    <meta httpEquiv="x-ua-compatible" content="IE=edge" />
    <meta name="msvalidate.01" content="EFEAA4EF6245A1C2FA69117AF91BDC43" />
    <meta name="msapplication-TileColor" content="#dae7f1" />
    <meta
      name="facebook-domain-verification"
      content="m1grp3yre854sblydf9igrph3ldzoc"
    />
    <meta name="msapplication-TileImage" content={logoSquareWin8} />
    <meta name="version" content={process.env.APP_VERSION} />
  </Head>
)

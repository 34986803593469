import React, { FC } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { CustomLink, LinkAsType } from '@moonpig/launchpad-components'
import { system as s } from '@moonpig/launchpad-system'
import { FooterNavSection } from './FooterNavSection'
import { useLocaleText } from '../locale'

const ICON_WIDTH_PX = '33px'
const ICON_HEIGHT_PX = '24px'

const StyledRegionLink = styled(CustomLink)`
  display: block;
`

const StyledIconWrapper = styled.div`
  display: flex;
  padding: 3px;
  ${s({ border: 2, borderColor: 'transparent' })}

  &:hover,
  &.is-active {
    ${s({
      border: 2,
      borderRadius: 1,
      borderColor: 'colorInteractionIconHover',
    })}
  }
`

export type ItemProps = {
  linkHref: string
  label: string
  shortLabel: string
  current: boolean
  linkAs?: LinkAsType
  icon: React.ElementType
}

export type RegionProps = {
  items?: ItemProps[]
}

export const RegionSelector: FC<React.PropsWithChildren<RegionProps>> = ({
  items = [],
}) => {
  const t = useLocaleText()

  return (
    <div data-testid="lp-nav-footernav-region">
      {items.length > 0 ? (
        <>
          <FooterNavSection
            title={t(`footer.nav.region`)}
            description={t('footer.nav.region.selector')}
          >
            {items.map(
              ({
                linkHref,
                label,
                shortLabel,
                current,
                linkAs,
                icon: Icon,
              }) => (
                <li key={shortLabel}>
                  <StyledRegionLink
                    to={linkHref}
                    aria-label={t('footer.nav.region.change', label)}
                    linkAs={linkAs}
                    title={label}
                  >
                    <StyledIconWrapper className={current ? 'is-active' : ''}>
                      <Icon width={ICON_WIDTH_PX} height={ICON_HEIGHT_PX} />
                    </StyledIconWrapper>
                  </StyledRegionLink>
                </li>
              ),
            )}
          </FooterNavSection>
        </>
      ) : null}
    </div>
  )
}

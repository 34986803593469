import { DepartmentsEnum } from '@moonpig/web-core-types-graphql'

export const departmentMap: {
  [key: string]: { name: string; title: string; enum: DepartmentsEnum }
} = {
  Cards: {
    name: 'all_cards',
    title: 'Cards',
    enum: DepartmentsEnum.ALL_CARDS,
  },
  Gifts: {
    name: 'all_gifts',
    title: 'Gifts',
    enum: DepartmentsEnum.ALL_GIFTS,
  },
  FlowersAndPlants: {
    name: 'all_flowers_and_plants',
    title: 'Flowers & Plants',
    enum: DepartmentsEnum.ALL_FLOWERS_AND_PLANTS,
  },
}

import { z } from 'zod'
import { eventDataSchema } from '../common.schema'

export const AccountDelinkSuccessSchema = z.object({
  event: z.literal('account_delink_success'),

  event_data: eventDataSchema,
})

export type AccountDelinkSuccessEvent = z.infer<
  typeof AccountDelinkSuccessSchema
>

import React, { createContext, FC, useContext, useMemo } from 'react'

type SessionId = {
  sessionId: string
  shortSessionId: string
}

const sessionIdContext = createContext<SessionId>({
  sessionId: '',
  shortSessionId: '',
})

export const SessionIdProvider: FC<
  React.PropsWithChildren<{ sessionId: string }>
> = ({ children, sessionId }) => {
  const value = useMemo(
    () => ({ sessionId, shortSessionId: sessionId.slice(-8) }),
    [sessionId],
  )
  return (
    <sessionIdContext.Provider value={value}>
      {children}
    </sessionIdContext.Provider>
  )
}

export const useSessionId = () => useContext(sessionIdContext)

import { z } from 'zod'
import {
  basketDataSchema,
  ecommerceSchema,
  eventDataSchema,
  zodNumber,
} from '../common.schema'
import { refinedEcommerceItemSchema } from '../refinements/purchase-ecommerce.refine'

export const AddToCartSchema = z.object({
  event: z.literal('add_to_cart'),
  basket_data: basketDataSchema.extend({
    size: zodNumber,
  }),
  event_data: eventDataSchema,

  ecommerce: ecommerceSchema.extend({
    items: z.array(refinedEcommerceItemSchema),
  }),
})

export type AddToCartEvent = z.infer<typeof AddToCartSchema>

const regionPrefixes = ['/uk', '/us', '/au', '/ie', '/nl']

const isRegionalUrl = (uri: string) =>
  regionPrefixes.some(
    regionPrefix => uri.startsWith(`${regionPrefix}/`) || uri === regionPrefix,
  )

const pointsToFile = (uri: string) => /\/[^/]+\.[^/]+$/.test(uri)

const shouldAppendSlash = (uri: string) => {
  return !uri.endsWith('/') && !pointsToFile(uri) && isRegionalUrl(uri)
}

// eslint-disable-next-line import/no-default-export
export default shouldAppendSlash

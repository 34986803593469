import { gql } from '@moonpig/web-core-graphql'

export const GetSuggestedQuickAddsGQL = gql`
  query GetSuggestedQuickAdds {
    suggestQuickAdds {
      occasion
      date {
        day
        month
      }
    }
  }
`

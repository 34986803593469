import React, { FC } from 'react'
import { system as s } from '@moonpig/launchpad-system'
import { styled } from '@moonpig/launchpad-utils'
import { Flex, Text } from '@moonpig/launchpad-components'
import { IconSystemTrendUp } from '@moonpig/launchpad-assets'
import { InspirationItemType } from '../types'
import { useLocaleText } from '../../locale'
import { LIST_ICON_SIZE_PX } from '../constants'

const StyledInspiration = styled.li`
  ${s({ p: 3, color: 'colorTextLabel' })}
  cursor: pointer;
  &:hover,
  &:focus {
    ${s({ bgcolor: 'colorBackground02', borderRadius: '12px' })}
  }

  &:active {
    ${s({ bgcolor: 'colorBlack20' })}
  }
`

const StyledIconWrapper = styled(Flex)`
  ${s({
    bgcolor: 'colorBackground01',
    borderRadius: '8px',
    p: 4,
    mr: 4,
    color: 'colorInteractionControl',
  })}
`
const StyledText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`

export type InspirationType = {
  department: string
  searchTerm: string
  value: string
}

type InspirationProps = {
  item: InspirationType
  itemIndex: number
  getItemProps: (props: {
    itemIndex: number
    item: InspirationItemType
  }) => Record<string, unknown>
} & React.HTMLProps<HTMLElement>

export const Inspiration: FC<InspirationProps> = ({
  item,
  itemIndex,
  getItemProps,
}) => {
  const t = useLocaleText()
  const capitalizedSearchTerm = `${item.searchTerm[0].toUpperCase()}${item.searchTerm?.slice(
    1,
  )}`

  return (
    <StyledInspiration
      tabIndex={0}
      aria-label={`${t('search.search_for')} ${capitalizedSearchTerm}`}
      {...getItemProps({
        itemIndex,
        item: { ...item, itemType: 'inspiration' },
      })}
    >
      <Flex alignItems="center">
        <StyledIconWrapper>
          <IconSystemTrendUp
            width={LIST_ICON_SIZE_PX}
            height={LIST_ICON_SIZE_PX}
          />
        </StyledIconWrapper>
        <StyledText>{capitalizedSearchTerm}</StyledText>
      </Flex>
    </StyledInspiration>
  )
}

import { useEffect, useState } from 'react'

const TAGGSTAR_PUSH_EVENT_NAME = 'taggstar:pushEventReady' as const

const isPushEventAvailable = (): boolean =>
  typeof window !== 'undefined' &&
  !!window.taggstar &&
  !!window.taggstar.pushEvent

export const useTaggEventStatus = () => {
  const [canPublishEvent, setCanPublishEvent] = useState(isPushEventAvailable())

  useEffect(() => {
    const onPushEventReady = () => {
      setCanPublishEvent(true)
    }

    if (isPushEventAvailable()) {
      onPushEventReady()
    } else {
      document.addEventListener(TAGGSTAR_PUSH_EVENT_NAME, onPushEventReady)
    }

    return () =>
      document.removeEventListener(TAGGSTAR_PUSH_EVENT_NAME, onPushEventReady)
  }, [])

  return canPublishEvent
}

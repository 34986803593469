import { Box } from '@moonpig/launchpad-components'
import React, { useEffect } from 'react'
import { useLoggedIn } from '@moonpig/web-core-auth'
import { gql, useLazyQuery } from '@moonpig/web-core-graphql'
import { UpcomingReminders } from './UpcomingReminders'
import { IncentivisedRemindersCreator } from './IncentivisedRemindersCreator'
import {
  UpcomingOccasionsQuery,
  UpcomingOccasionsQueryVariables,
} from './__generated__/RemindersNotification'
import { UpcomingOccasions_reminders } from './types-graphql'
import { Reminder } from '../../types'

export const REMINDERS_QUERY = gql`
  query UpcomingOccasions($endDate: String) {
    reminders(input: { take: 3, endDate: $endDate }) {
      id
      name
      occasion
      relationship
      nextDate
    }
  }
`

const gqlReminderToEntity = (
  gqlReminder: UpcomingOccasions_reminders,
): Reminder => ({
  id: gqlReminder.id,
  name: gqlReminder.name,
  occasion: gqlReminder.occasion,
  relationship: gqlReminder.relationship,
  nextDate: new Date(gqlReminder.nextDate),
})

export const RemindersNotification = () => {
  const { loggedIn } = useLoggedIn()
  const [query, { data, loading, called }] = useLazyQuery<
    UpcomingOccasionsQuery,
    UpcomingOccasionsQueryVariables
  >(REMINDERS_QUERY)

  useEffect(() => {
    if (loggedIn) {
      const date = new Date(Date.now())
      date.setDate(date.getDate() + 30)
      query({ variables: { endDate: date.toISOString() } })
    }
  }, [query, loggedIn])
  const reminders = data?.reminders.map(gqlReminderToEntity)
  const isLoading = loading || (loggedIn === true && !called)
  const hasReminders = !!(reminders && reminders.length)
  return (
    <Box ml={6} mr={6} mt={8} mb={8}>
      <Box
        borderBottom={hasReminders ? 1 : 0}
        borderColor="colorBorder03"
        pb={8}
        mb={8}
      >
        <UpcomingReminders reminders={reminders} loading={isLoading} />
      </Box>
      <IncentivisedRemindersCreator hasReminders={hasReminders} />
    </Box>
  )
}

import { useEffect, useRef } from 'react'
import { getBrowserCookies } from '@moonpig/web-core-cookies'
import { isLoggedIn } from '@moonpig/web-core-auth'
import { BRAND_LABEL } from '@moonpig/web-core-brand/config'
import { useAnalytics } from '@moonpig/web-core-stores'
import { createScreenViewEvent } from './createScreenViewEvent'
import { trackGAEvent } from './trackGAEvent'
import { RenderType, ScreenViewScreenData } from './types'

export type PageTracking = {
  type: string
  disableDefaultPageTracking?: boolean
}

type ScreenViewProperties = {
  pageType: string
  renderType: RenderType
}

const checkDocumentAvailable = () => typeof document !== 'undefined'

export const removeBrandFromTitle = <T extends string | undefined>(
  title: T,
): T => {
  if (title === undefined) {
    return title
  }

  const brand = BRAND_LABEL.toLowerCase()

  let result = title.toLowerCase()

  if (result.startsWith(brand)) {
    const parts = title.split('|')
    result = parts.slice(1).join('|').trim()
  }

  if (result.endsWith(brand)) {
    const parts = title.split('|')
    result = parts.slice(0, -1).join('|').trim()
  }

  return result as T
}

const trackScreenView = ({ pageType, renderType }: ScreenViewProperties) => {
  const isDocumentAvailable = checkDocumentAvailable()
  const screenData: ScreenViewScreenData = {
    renderType,
    documentReferrer: isDocumentAvailable
      ? document.referrer
      : /* istanbul ignore next */ undefined,
    documentTitle: isDocumentAvailable
      ? document.title
      : /* istanbul ignore next */ undefined,
    documentUrl: isDocumentAvailable
      ? document.location.href
      : /* istanbul ignore next */ undefined,
  }

  const screenClass = pageType
  const title = removeBrandFromTitle(screenData.documentTitle)
  const screenTitle = title ? ` | ${title}` : /* istanbul ignore next */ ''

  trackGAEvent(
    createScreenViewEvent({
      screenClass,
      screenName: `${screenClass}${screenTitle}`,
      screenData,
    }),
  )
}

export const useTrackScreenView = ({
  type: pageType,
  disableDefaultPageTracking = false,
}: PageTracking) => {
  const { gtmClient: settings } = useAnalytics()
  const userLoggedIn = isLoggedIn(getBrowserCookies())
  const trackedPage = useRef('')
  useEffect(() => {
    if (disableDefaultPageTracking) return
    let renderType: RenderType = 'server'

    if (trackedPage.current && trackedPage.current !== pageType) {
      trackedPage.current = ''
      renderType = 'client'
    }

    /* istanbul ignore next */
    if (!trackedPage.current && settings.containerId) {
      trackScreenView({
        pageType,
        renderType,
      })
      trackedPage.current = pageType
    }
  }, [
    userLoggedIn,
    pageType,
    trackedPage,
    disableDefaultPageTracking,
    settings.containerId,
  ])
}
